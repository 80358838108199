<template>
  <div>
    <!-- 电商管理page页面 -->
    <a-card title="清分协议管理" style="margin: 20px">
      <!-- 筛选区域 -->
      <div class="selectBox">
        <div class="item">
          <span>所属机构</span>
          <DepartmentChoose :department-code.sync="paramsData.departmentCode" @change="handleQuery"></DepartmentChoose>
        </div>
        <div class="item">
          <span>协议编号</span>
          <a-input v-model.trim="paramsData.mchNo" placeholder="请输入协议编号" />
        </div>
        <div class="item">
          <span>电商名称</span>
          <a-input v-model.trim="paramsData.mchName" placeholder="请输入电商名称" />
        </div>
        <div class="item">
          <span>电商钱包</span>
          <a-input v-model.trim="paramsData.accountNo" placeholder="请输入电商钱包" />
        </div>
        <div class="item">
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </div>
      </div>
      <!-- 表格部分 -->
      <div class="tableBox">
        <!-- 操作按钮 -->
        <div class="handleBtn">
          <a-button type="primary" v-if="$authority('clearAddBtn')" @click="addBtn"> 新增</a-button>
          <a-button @click="clearBalanceSync" v-if="$authority('clearSyncBtn')" type="primary">余额同步</a-button>
          <a-button type="primary" @click="viewBtn" v-if="$authority('clearViewBtn')">查看</a-button>
        </div>
        <!-- 表格 -->
        <div class="table-main">
          <a-table size="small" :scroll="{ x: 1800, }" :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }" :columns="columns" :customRow="clickrow" :data-source="data" :pagination="false"
            :rowKey="(record, index) => `${record.id}`">
          </a-table>
        </div>
      </div>
      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(paramsData.pageNo) == 1" @click="Homepage">
          首页
        </a-button> <a-pagination v-model.trim="paramsData.pageNo" :page-size-options="pageSizeOptions" :total="total"
          show-size-changer :page-size="paramsData.pageSize" @change="handleChangePage" @showSizeChange="onShowSizeChange"
          :item-render="itemRender">
        </a-pagination>
        <a-button @click="Trailingpage"
          :disabled="paramsData.pageNo == Math.ceil(Number(total) / Number(paramsData.pageSize))" class="Trailingpage">
          尾页
        </a-button>
      </div>
      <!-- 新增协议弹框 -->
      <a-modal title="代销协议登记" :visible="isVisibleAddDialog" @cancel="isVisibleAddDialog = false" :centered="true"
        :body-style="{ textAlign: 'center' }" :footer="null">
        <div class="container">
          <div class="container-item">
            <a-row>
              <a-col :span="6">回款电商</a-col>
              <a-col :span="18">
                <MchChooseDialog :goodsType="'40'" @chooseDone="chooseMchDone" v-model="addData.mchName" mch-type="0">
                </MchChooseDialog>
              </a-col>
            </a-row>
          </div>
          <div class="container-item">
            <a-row>
              <a-col :span="6">一级待清算户</a-col>
              <a-col :span="18">
                <a-select v-model="addData.accountNoSettling1" @change="changeAccount" placeholder="请选择账户">
                  <a-select-option v-for="item in clearAccountList" :key="item.id" :item="item"
                    :value="item.accountNoSettling1">
                    <div>{{ item.accountNameSettling1 }}</div>
                    <WalletInfo :accountName="item.accountNameSettling1" :accountNo="item.accountNoSettling1">
                    </WalletInfo>
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </div>
          <div class="container-item">
            <a-row>
              <a-col :span="6">二级待清算户</a-col>
              <a-col :span="18">
                <a-select v-model="addData.accountNoSettling2" @change="changeAccountTwo" placeholder="请选择账户">
                  <a-select-option v-for="item in clearAccountList" :key="item.id" :item="item"
                    :value="item.accountNoSettling1">
                    <div>{{ item.accountNameSettling1 }}</div>
                    <WalletInfo :accountName="item.accountNameSettling1" :accountNo="item.accountNoSettling1">
                    </WalletInfo>
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </div>
          <div class="container-item">
            <a-row>
              <a-col :span="6">协议编号</a-col>
              <a-col :span="18">
                <!-- AGENT-{{ addData.mchNo }} -->
                {{ addData.contractNo }}
              </a-col>
            </a-row>
          </div>
          <div class="container-item">
            <a-row>
              <a-col :span="6"></a-col>
              <a-col :span="18">
                <a-button @click="isVisibleAddDialog = false">取消</a-button>
                <a-button type="primary" @click="handleOk">保存</a-button>

              </a-col>
            </a-row>
          </div>
        </div>

      </a-modal>
    </a-card>
  </div>
</template>
<script>
import {message} from "ant-design-vue";
import {BizDict, BizDictCascade} from "@/utils/bizDict/kvMap";
import {handleChannelNo} from '@/utils/commonMethods'
import WalletInfo from '@/view/components/WalletInfo.vue'
import MchChooseDialog from '../../components/ChooseDialog/MchChooseDialog.vue';
import Balance from '@/view/components/Balance.vue';
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";

const data = [];
export default {
  components: {DepartmentChoose, WalletInfo, MchChooseDialog, Balance },
  name: "ClearAgreementManage",
  data() {
    const columns = [
      {
        title: "No",
        customRender: (text, record, index) =>
          `${Number(this.paramsData.pageNo - 1) *
          Number(this.paramsData.pageSize) +
          Number(index + 1)
          }`,
        align: "center",
        width: 60,
      },
      {
        title: "渠道",
        dataIndex: "channelNo",
        align: "center",
        width: 120,
        customRender: (text) => {
          return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), text, '1')
        },
      },
      {
        title: "协议编号",
        dataIndex: "contractNo",
        align: "center",
        // width: 260,
      },
      {
        title: "电商编号",
        dataIndex: "mchNo",
        align: "center",
        // width: 120,
      },
      {
        title: "电商钱包",
        dataIndex: "accountNo",
        align: "left",
        width: 300,
        customRender: (text, row) => {
          return <WalletInfo accountClass='6' showAccountNo={false} showAccountName={true} accountNo={text} accountName={row.accountName}></WalletInfo>
        }
      },
      // {
      //   title: "待清算账户",
      //   align: "center",
      //   customRender: (text, record) => {
      //     return <div >
      //       <div style="display:flex;margin-bottom:5px">
      //         <a-tag color="#6d000e">待清算1</a-tag>
      //         <span>{record.accountNoSettling1}</span>
      //       </div>
      //       <div style="display:flex">
      //         <a-tag color="#6d000e">待清算2</a-tag>
      //         <span>{record.accountNoSettling2}</span>
      //       </div>
      //
      //     </div>
      //   }
      // },
      // {
      //   title: "一级待清算户",
      //   dataIndex: "accountNoSettling1",
      //   align: "center",
      //   customRender: (text) => {
      //     return <div style="display:flex">
      //       <a-tag color="#6d000e">待清算</a-tag>
      //       <span>{text}</span>
      //     </div>

      //   }

      // },

      // {
      //   title: "二级待清算户",
      //   dataIndex: "accountNoSettling2",
      //   align: "center",
      //   customRender: (text) => {
      //     return <div style="display:flex">
      //       <a-tag color="#6d000e">待清算</a-tag>
      //       <span>{text}</span>
      //     </div>

      //   }
      // },
      {
        title: "一级待清算",
        dataIndex: "settling1Balance",
        // width: 220,
        // ellipsis: true,
        align: "center",
        customRender: (text, row) => {
          // console.log(row);
          return <WalletInfo accountClass='9' showAccountNo={false} accountNo={row.accountNoSettling1} accountName={row.accountNameSettling1} balance={text}></WalletInfo>
        }
      },
      {
        title: "二级待清算",
        dataIndex: "settling2Balance",
        align: "center",
        customRender: (text, row) => {
          return <WalletInfo accountClass='9' showAccountNo={false} accountNo={row.accountNoSettling2} accountName={row.accountNameSettling2} balance={text}></WalletInfo>
        }
      },

      {
        title: "所属机构",
        dataIndex: "departmentId",
        align: "center",
        customRender: (text) => {
          return this.findItemById(this.departmentData, text);
        },
      },
    ];
    return {
      // 机构
      departmentData: {},
      data,
      columns,
      selectedRowKeys: [],
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      total: 0,
      // 筛选参数
      paramsData: {
        departmentId: undefined,
        departmentCode: undefined,
        // 当前页，
        pageNo: 1,
        // 一页多少条
        pageSize: 10,
        // 协议编号
        contractNo: "",
        // 电商名称
        mchName: "",
        accountNo: '',//钱包

      },
      // 新增协议数据
      addData: {
        departmentId: undefined,
        channelNo: '',//渠道编号
        mchNo: '',//电商编号
        mchName: '',//电商名称
        contractNo: '',//协议编号
        contractType: '1',//协议类型 代销协议
        accountNo: '',//电商A2 协议账号
        accountName: '',//电商A2 协议户名
        accountNoSettling1: '',//	待清算账号1
        accountNameSettling1: '',//待清算户名1
        accountNoSettling2: '',//待清算账号2
        accountNameSettling2: '',//	待清算户名2
      },
      // 待清算户列表
      clearAccountList: [],
      // 选中的客户数组
      selectCustData: [],
      isVisibleAddDialog: false
    };
  },
  created() {
    this.busiDict = { ...BizDict, ...BizDictCascade };
    // 获取机构列表
  },
  mounted() {

    if (sessionStorage.getItem('msgInfo')) {
      // 从store里读取筛选条件
      this.paramsData = this.$store.state.searchMsgInfo
    }
    // this.paramsData.departmentId = this.$session.getItem("companyId");
    // 获取表格
    this.getTableData();

  },
  deactivated() {
    sessionStorage.removeItem('msgInfo')

  },
  destroyed() {
    sessionStorage.removeItem('msgInfo')
  },
  methods: {
    // 选中电商
    chooseMchDone(mch) {
      // console.log(mch, 'mch done');
      this.addData.departmentId = mch.departmentId;
      this.addData.accountName = mch.accountName
      this.addData.accountNo = mch.accountNoA2
      this.addData.channelNo = mch.channelNo
      this.addData.mchNo = mch.mchNo
      this.addData.mchName = mch.mchName
      this.addData.contractNo = 'AGENT-' + mch.mchNo
      // 拿渠道获取清算账户 settleAccountApi
      this.getClearAccount()
      this.addData.accountNoSettling1 = ''
      this.addData.accountNoSettling2 = ''
      this.addData.accountNameSettling1 = ''
      this.addData.accountNameSettling2 = ''
    },
    // 获取清算账户
    async getClearAccount() {
      let data = {
        pageNo: 1,
        pageSize: 1000
      }
      data.obj = {
        channelNo: this.addData.channelNo ? this.addData.channelNo : ''
      }
      let res = await this.api.settleAccountApi(data)
      // let res = await this.api.getClearAccountApi({
      //   channelNo: this.addData.channelNo
      // })
      if (res.code == 200) {
        this.clearAccountList = res.data.records
      }
    },
    //新增弹框里的 一级清算账户change
    changeAccount(val, option) {
      // console.log(val);
      // console.log(option);
      this.addData.accountNameSettling1 = option.data.attrs.item.accountNameSettling1
    },
    //新增弹框里的 二级清算账户change
    changeAccountTwo(val, option) {
      // console.log(val);
      console.log(option);
      this.addData.accountNameSettling2 = option.data.attrs.item.accountNameSettling1
    },
    // 新增按钮
    addBtn() {
      this.isVisibleAddDialog = true
      // 获取清算账户
      this.getClearAccount()
    },

    // 查看跳转
    viewBtn() {


      if (this.selectCustData.length == 0 || !this.selectCustData) {
        this.$message.warning('请先选择协议')
        return
      }
      if (this.selectCustData.length > 1 || !this.selectCustData) {
        this.$message.warning('请先选择一个协议')
        return
      }
      // 进入详情页存储筛选条件
      this.$store.commit('saveSearchMsgInfo', this.form)
      this.$store.commit('saveSettlementAccountInfo', this.selectCustData)
      this.$router.push('/Mch/settleAccountsDetails')
    },

    // 余额同步
    async clearBalanceSync() {
      if (this.selectCustData.length == 0 || this.selectCustData.length > 1) {
        return this.$message.warning('只能选中一个客户')
      }
      let id = this.selectCustData[0].id
      let res = await this.api.clearBalanceSyncApi({
        id
      })
      if (res.code == 200) {
        this.$message.success(res.info)
        // 重新获取表格
        this.getTableData()
      }
      this.selectedRowKeys = []
      this.selectCustData = []
    },
    // 获取表格数据
    async getTableData() {
      const res = await this.api.getMchContractApi(this.paramsData);
      // console.log(res);
      if (res.code == 200) {
        this.data = res.data.records;
        this.paramsData.pageNo = res.data.current;
        this.paramsData.pageSize = res.data.size;
        this.total = res.data.total;
      } else {
        return message.error(res.info);
      }
    },
    // 根据id找所属机构
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name;
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind);
          if (foundName) {
            return foundName;
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null;
    },

    // 查询
    handleQuery() {
      this.selectCustData = []
      this.selectedRowKeys = []
      this.paramsData.pageNo = 1;
      this.getTableData();
    },
    // 重置
    handleReset() {
      this.selectCustData = []
      this.selectedRowKeys = []
      this.paramsData = {
        departmentId: undefined,
        // 当前页，
        pageNo: 1,
        // 一页多少条
        pageSize: 10,
        // 协议编号
        contractNo: "",
        // 电商名称
        mchName: "",
        accountNo: '',//钱包
      };
      this.getTableData();
    },

    // 确认新增
    async handleOk() {
      let res = await this.api.addMchContractApi(this.addData)
      if (res.code == 200) {
        this.$message.success(res.info)
        this.getTableData()
      } else {
        this.$message.error(res.info)
      }
      this.addData = {
        departmentId: undefined,
        channelNo: '',//渠道编号
        mchNo: '',//电商编号
        mchName: '',//电商名称
        contractNo: '',//协议编号
        contractType: '1',//协议类型 代销协议
        accountNo: '',//电商A2 协议账号
        accountName: '',//电商A2 协议户名
        accountNoSettling1: '',//	待清算账号1
        accountNameSettling1: '',//待清算户名1
        accountNoSettling2: '',//待清算账号2
        accountNameSettling2: '',//	待清算户名2
      },
        this.isVisibleAddDialog = false;
    },
    // 选中表格的事件函数
    onSelectChange(selectedRowKeys, row) {
      // console.log(selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
      this.selectCustData = row;

    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            let index = this.selectedRowKeys.findIndex((item) => id == item)
            if (index == -1) {
              this.selectedRowKeys.push(id)
              this.selectCustData.push(record)

            } else {

              this.selectedRowKeys.splice(index, 1)
              this.selectCustData.splice(index, 1)
            }
          }
        }
      }
    },
    // 改变当前页的事件函数
    // 改变pagesize的事件函数
    onShowSizeChange(current, pageSize) {
      // console.log(current);
      // console.log(pageSize);
      this.selectCustData = []
      this.selectedRowKeys = []
      this.paramsData.pageNo = current;
      this.paramsData.pageSize = pageSize;
      this.selectedRowKeys = [];
      this.getTableData();
    },
    // 改变当前页的事件
    handleChangePage(page) {
      this.selectCustData = []
      this.selectedRowKeys = []
      this.paramsData.pageNo = page;
      this.selectedRowKeys = [];
      this.getTableData();
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.selectCustData = []
      this.selectedRowKeys = []
      this.paramsData.pageNo = 1,
        this.getTableData()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.selectCustData = []
      this.selectedRowKeys = []
      this.paramsData.pageNo = Math.ceil(Number(this.total) / Number(this.paramsData.pageSize))
      this.getTableData()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-card-body {
  padding: 24px 34px;
}

.selectBox {
  display: flex;
  flex-wrap: wrap;

  /deep/ .item {
    margin-bottom: 20px;
    margin-right: 20px;

    .ant-input {
      width: 200px;
      margin-left: 20px;
    }

    .ant-select {
      width: 200px;
      margin-left: 20px;
    }
  }
}

.tableBox {
  margin-bottom: 20px;

  .handleBtn {
    margin-bottom: 20px;
  }
}

.ant-col-18 {
  width: 200px;
}

.container-item {
  .ant-row {
    display: flex;
    align-items: center;

    .ant-select {
      width: 240px;
    }
  }

  margin-bottom: 20px;
}

/deep/.paginationBox {
  // float: right;
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;

  // .ant-pagination-options {
  //   float: left;
  // }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}
</style>
