<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>原始订单管理</span> <a-icon type="right" />
            <span style="font-weight: 500">修改记录</span>
          </div>
        </div>
      </template>
      <template slot="extra">
        <a-button @click="toBack">
          返回
        </a-button>
      </template>
      <!-- 修改记录查看 -->
      <div class="tablebox">
        <!-- 表格 -->
        <div class="tableBody">
          <a-table ref="table" :rowKey="(record, index) => `${record.id}`" paginati :columns="columns" :data-source="data"
            :pagination="false">
          </a-table>
        </div>
      </div>

      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
          首页
        </a-button> <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
          show-size-changer :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
          :item-render="itemRender">
          <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
        </a-pagination>
        <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
          class="Trailingpage">
          尾页
        </a-button>
      </div>
    </a-card>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      total: 0, // 数量
      form: {
        pageSize: 10,
        pageNo: 1,
        orderNo: '',
      },
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
            `${Number(this.form.pageNo - 1) * Number(this.form.pageSize) +
            Number(index + 1)
            }`,
          align: 'center',
          width: 55,
        },
        {
          title: '订单号',
          dataIndex: 'orderNo',
          align: 'center',
          width: 200,
          ellipsis: true,
        },
        {
          title: '商品名称',
          dataIndex: 'goodsName',
          align: 'center',
          ellipsis: true,
        },
        {
          title: '订单金额',
          dataIndex: 'orderAmount',
          align: 'center',
          width: 100,
        },
        {
          title: '结算金额',
          dataIndex: 'settleAmount',
          align: 'center',
          width: 100,
        },
        {
          title: '退款金额',
          dataIndex: 'refundAmount',
          align: 'center',
          width: 100,
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatus',
          align: 'center',
          width: 100,
          customRender:(text)=> {
            return {
              "0": "待支付",
              "1": "已支付",
              "2": "待发货",
              "3": "已发货",
              "4": "已完成",
              "5": "已关闭",
            }[text]
          }
        },
        {
          title: '下单时间',
          dataIndex: 'orderTime',
          align: 'center',
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          align: 'center',
        }
      ],
      data: [],
    }
  },
  created() {
    // this.form.departmentId = this.$route.query.departmentId
    // this.form.ids.push(this.$route.query.id)
    this.busiOriginalRevisionApi()
  },
  mounted() {
    sessionStorage.setItem('msgInfo', true)
  },
  methods: {
    // 获取table列表
    async busiOriginalRevisionApi() {
      const res = await this.api.busiOriginalRevisionApi({
        ...this.form,
        orderNo: this.$route.query.orderNo
      })

      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }

      this.total = res.data.total
      this.form.pageNo = res.data.current
      this.form.pageSize = res.data.size

      this.data = res.data.records
    },
    pagechange(val) {

      this.form.pageNo = val
      this.busiOriginalRevisionApi()
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.form.pageSize = pageSize
      this.busiOriginalRevisionApi()
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = 1,
        this.busiOriginalRevisionApi()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = Math.ceil(Number(this.total) / Number(this.form.pageSize))
      this.busiOriginalRevisionApi()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    toBack() {
      this.$router.back(-1)
    }
  },
}
</script>

<style lang="less" scoped>
.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-table-thead>tr>th {
    background-color: #f2f2f2;
  }

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .right {
    button {
      height: 24px;
    }
  }
}

.tablebox {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.paginationBox {
  display: flex;
  justify-content: flex-end;

  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}

/deep/ .ant-card-head-title {
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .dianshang {
      margin-left: 50px;
    }
  }
}
</style>
