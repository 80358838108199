export default {
  setItem:(key,value)=>{
     sessionStorage.setItem(key,value)
  },
  getItem:(key)=>{
    return sessionStorage.getItem(key)
  },
  moveItem:(key) =>{
    sessionStorage.moveItem(key)
  },
  clear:() => {
    sessionStorage.clear()
  }
}