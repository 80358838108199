// 身份证校验
export const validatorCardNumber = (rule, value, callback) => {
  let custNumReg =
    /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  if (value === '') {
    callback(new Error('请填写身份证号码'))
  } else if (!custNumReg.test(value)) {
    callback(new Error('身份证不符合规范'))
  } else {
    let lastStr = value.substring(value.length-1)
    if (lastStr === 'x') {
      callback(new Error('身份证最后一位字母要求大写!'))
    } else {
      callback()
    }
  }
}
