<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>汇总订单管理</span> <a-icon type="right" />
            <span style="font-weight: 500">查看</span>
          </div>
        </div>
      </template>
      <template slot="extra">
        <a-button @click="toBack">
          返回
        </a-button>
      </template>
      <!-- 原始订单管理查看 -->
      <div class="viewbox">
        <!-- 汇总订单信息 -->
        <div class="Basicinformation">
          <h2>汇总订单信息</h2>
          <div class="list">
            <div class="item">
              <div class="row">汇总订单编号</div>
              <div class="row">订单状态</div>
              <div class="row">清算状态</div>
              <div class="row">更新人</div>
              <div class="row">预计退款总额（¥）</div>
              <div class="row">实际退款总额（¥）</div>
              <div class="row">放款总额（累计融资金额）（¥）</div>
              <div class="row">累计已补足差额（¥）</div>
            </div>
            <div class="item">
              <div class="row">
                {{ ordersdetails.busiCollectOrders?.orderNo ? ordersdetails.busiCollectOrders.orderNo : '----' }}
              </div>
              <div class="row">
                {{ ordersdetails.busiCollectOrders?.orderStatus ?
                  customRender(ordersdetails.busiCollectOrders.orderStatus)
                  : '----' }}
              </div>
              <div class="row">
                {{ ordersdetails.busiCollectOrders?.settleStatus ?
                  settleStatus(ordersdetails.busiCollectOrders?.settleStatus)
                  : '----' }}
              </div>
              <div class="row">
                {{ ordersdetails.busiCollectOrders?.updater ? ordersdetails.busiCollectOrders.updater : '----' }}
              </div>
              <div class="row">

                <Balance :amount="ordersdetails.busiCollectOrders?.orderAmount2"></Balance>
              </div>

              <div class="row">

                <Balance :amount="ordersdetails.busiCollectOrders?.orderAmount4"></Balance>

              </div>
              <div class="row">
                <Balance :amount="ordersdetails.busiCollectOrders?.loanAmountSum"></Balance>
              </div>
              <div class="row">
                <Balance :amount="ordersdetails.busiCollectOrders?.supDiffAmount"></Balance>
              </div>
            </div>
            <div class="item">
              <div class="row">汇总订单生成时间</div>
              <div class="row">订单总额（¥）</div>
              <div class="row">清算时间</div>
              <div class="row">更新时间</div>
              <div class="row">已发货未收款总额（¥）</div>
              <div class="row">预计应收款总额（¥）</div>
              <div class="row">订单融资额度（可融资额度）（¥）</div>
            </div>
            <div class="item">
              <div class="row">
                {{ ordersdetails.busiCollectOrders?.createTime ? ordersdetails.busiCollectOrders.createTime : '----'
                }}
              </div>
              <div class="row">
                <Balance :amount="ordersdetails.busiCollectOrders?.orderAmount"></Balance>
              </div>
              <div class="row">
                {{ ordersdetails.busiCollectOrders?.settleTime ? ordersdetails.busiCollectOrders.settleTime : '----'
                }}
              </div>
              <div class="row">
                {{ ordersdetails.busiCollectOrders?.updateTime ? ordersdetails.busiCollectOrders.updateTime : '----'
                }}
              </div>
              <div class="row">

                <Balance :amount="ordersdetails.busiCollectOrders?.orderAmount1"></Balance>
              </div>
              <div class="row">

                <Balance :amount="ordersdetails.busiCollectOrders?.orderAmount3"></Balance>
              </div>
              <div class="row">

                <Balance :amount="ordersdetails.busiCollectOrders?.creditLimit"></Balance>
              </div>
            </div>
          </div>
        </div>
        <!-- 贷款信息 -->

        <div class="Loaninformation">
          <h2>贷款信息</h2>
          <div class="list">
            <div class="head">
              <span>贷款产品编号</span>
              <span>贷款合同编号</span>
              <span>申请人账号</span>
              <span>申请人户名</span>
              <span>贷款账号（借据编号）</span>
              <span>贷款账号序号</span>
              <span>放款金额（元）</span>
              <span>放款时间</span>
              <span>还款状态</span>
              <span>还款本金</span>
              <span>还款利息</span>
              <span>还款时间</span>
            </div>
            <div class="body" v-for="(item, ind) in ordersdetails.busiCollectOrdersCreditList" :key="ind">
              <span>{{ item.productId }}</span>
              <span>{{ item.contractNo }}</span>
              <span>{{ item.accountNo }}</span>
              <span>{{ item.accountName }}</span>
              <span>{{ item.loanacctno }}</span>
              <span>{{ item.acctseqno }}</span>
              <span>
                <Balance :amount="item.loanAmount"></Balance>
              </span>
              <span>{{ item.loanDate }}</span>
              <span><RepayStatus :text="item.repayStatus"></RepayStatus></span>
              <span>
                <Balance :amount="item.repayAmountBj"></Balance>
              </span>
              <span>
                <Balance :amount="item.repayAmountLx"></Balance>

              </span>
              <span>{{ item.repayTime }}</span>
            </div>
          </div>
        </div>


        <!-- 服务费信息 -->

        <div class="Loaninformation">
          <h2>服务费信息</h2>
          <div class="list">
            <div class="head">
              <span>NO</span>
              <span>服务费项目编号</span>
              <span>服务费项目名称</span>
              <span>服务费账号</span>
              <span>服务费户名</span>
              <span>贷款账号（借据编号）</span>
              <span>服务费金额（元）</span>

            </div>
            <div class="body" v-for="(item, ind) in ordersdetails.busiCollectOrdersFeeList" :key="ind">
              <span>{{ ind + 1 }}</span>
              <span>{{ item.projectNo }}</span>
              <span>{{ item.projectName }}</span>
              <span>{{ item.feeAccountNo }}</span>
              <span>{{ item.feeAccountName }}</span>
              <span>{{ item.loanacctno }}</span>
              <span>
               <Balance :amount="item.feeAmount"></Balance>
              </span>
            </div>
          </div>
        </div>



      </div>
    </a-card>
  </div>
</template>

<script>
import Balance from '@/view/components/Balance.vue'

export default {
  components: {
    Balance,
    RepayStatus:{
      props:{ text:{ type:String, } },
      render(){
         if (this.text === '0') {
           return <span><a-tag color="grey">未还款</a-tag></span>
         } else if (this.text === '1') {
           return <span><a-tag color="green">还款成功</a-tag></span>
         } else if (this.text === '2') {
           return <span><a-tag color="red">还款失败</a-tag></span>
         } else if (this.text === '3') {
           return <span><a-tag color="yellow">部分还款</a-tag></span>
         } else {
           return <span><a-tag color="grey">{this.text}</a-tag></span>
         }
      }
    }
  },
  data() {
    return {
      ordersdetails: {},
    }
  },
  created() {

    this.getcollectordersdetails()

  },
  mounted() {
    sessionStorage.setItem('msgInfo', true)
  },
  methods: {
    async getcollectordersdetails() {
      const res = await this.api.collectordersdetailsApi(this.$route.query.id)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }

      this.ordersdetails = res.data

    },
    customRender: (text) => {

      if (Number(text) == 0) {
        return '已登记'

      } else if (Number(text) == 1) {
        return '已修正'
      }
      else if (Number(text) == 2) {
        return '已结算'
      } else if (Number(text) == 9) {
        return '已完结'
      } else {
        return text
      }
    },
    settleStatus: (text) => {

      if (Number(text) == 0) {
        return '未清算'

      } else if (Number(text) == 1) {
        return '已清算并还款 '
      }
      else if (Number(text) == 2) {
        return '清算失败'
      } else {
        return text
      }
    },
    toBack() {
      this.$router.back(-1)
    }
  },
}
</script>

<style lang="less" scoped>
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .right {
    button {
      height: 24px;
    }
  }
}

.viewbox {

  // 可控电商
  .Controllede-commerce {
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    min-height: 192px;

    .item {
      width: 800px;
      min-height: 192px;
      box-shadow: 0 0 3px#d7d7d7;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;

      .left {
        flex: 1;
        height: 100%;
        border-right: 1px solid#f2f2f2;
        padding: 15px 30px;

        h2 {
          font-weight: 700;
          margin-bottom: 20px;
          font-size: 16px;
        }

        p {
          margin-bottom: 15px;
          display: flex;
          font-size: 16px;

          span {
            &:first-child {
              margin-right: 25px;
              display: flex;
              min-width: 60px;
            }
          }
        }
      }

      .right {
        flex: 1;
        padding: 15px 35px;
        padding-top: 50px;
        display: flex;
        justify-content: space-between;

        .leftbox {
          flex: 1;

          h2 {
            font-weight: 700;
            font-size: 18px;
            margin-top: 20px;
            padding-left: 32px;
          }

          h3 {
            font-size: 16px;
            padding-left: 32px;
          }
        }

        span {
          display: flex;
          width: 1px;
          background-color: #f2f2f2;
          height: 50px;
          margin-top: 10px;
          margin-right: 8px;
        }

        .rightbox {
          flex: 1;

          h2 {
            font-weight: 700;
            font-size: 18px;
            margin-top: 20px;
            display: flex;
            padding-left: 32px;
          }

          h3 {
            font-size: 16px;
            display: flex;
            padding-left: 32px;
          }
        }
      }
    }
  }

  .Basicinformation {
    h2 {
      margin-bottom: 12px;
      font-weight: 500;
    }

    .list {
      margin-bottom: 20px;
      display: flex;

      .item {
        flex: 1;

        .row {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:nth-child(2n + 1) {
          .row {
            background-color: #f2f2f2;
            height: 35px;
            width: 100%;
          }
        }

        &:nth-child(2n) {
          .row {
            height: 35px;
            width: 100%;
            border-bottom: 1px solid #f2f2f2;

            &:first-child {
              border-top: 1px solid #f2f2f2;
            }
          }
        }

        &:nth-child(4) {
          .row {
            height: 35px;
            width: 100%;

            border-right: 1px solid #f2f2f2;
          }
        }
      }
    }
  }



  .Loaninformation {
    min-height: 188px;
    padding: 16px;
    border: 1px solid #f2f2f2;
    margin-bottom: 25px;

    h2 {
      margin-bottom: 15px;
      font-weight: 500;
    }

    .list {
      .head {
        display: flex;
        // ul li:nth-child(-n+5) {
        //   /* 在这里设置样式 */
        //   color: red;
        // }

        // ul li:nth-last-child(-n+5) {
        //   /* 在这里设置样式 */
        //   color: blue;
        justify-content: space-between;
        // }

        span {
          flex: 1;
          display: flex;

          align-items: center;
          height: 32px;
          justify-content: center;

          // &:nth-child(-n+5) {
          //   /* 在这里设置样式 */
          //   color: red;
          // }
          background-color: #f2f2f2;
        }
      }

      .body {
        display: flex;

        // ul li:nth-last-child(-n+5) {
        //   /* 在这里设置样式 */
        //   color: blue;
        justify-content: space-between;
        // }

        span {
          flex: 1;
          display: flex;
          height: 52px;

          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #f2f2f2;
          border-right: 1px solid #f2f2f2;

          &:nth-child(1) {
            /* 在这里设置样式 */
            border-left: 1px solid #f2f2f2;
          }
        }
      }
    }
  }

  .Amountdisplay {
    width: 100%;
    min-height: 268px;
    padding: 16px;
    border: 1px solid #f2f2f2;

    h2 {
      font-weight: 600;
      margin-bottom: 25px;
    }

    .context {
      padding: 0 20px;
      height: 100%;
      display: flex;
      justify-content: space-between;

      .left {
        flex: 1;


        div {
          margin-bottom: 25px;
          display: flex;

          span {
            &:first-child {
              width: 154px;
              display: flex;
              margin-right: 70px;
            }
          }
        }
      }

      .right {
        position: relative;
        flex: 1;
        height: 100%;

        div {
          margin-bottom: 25px;
          display: flex;


          span {
            &:first-child {
              display: flex;
              margin-right: 70px;
              width: 140px;
            }
          }
        }

        .balance {
          position: absolute;
          right: 50px;
          bottom: 90px;
        }

        .cofim {
          position: absolute;
          right: 50px;
          bottom: 40px;
        }
      }
    }

  }
}

/deep/ .ant-card-head-title {
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .dianshang {
      margin-left: 50px;
    }
  }
}
</style>
