<template>
  <div>
    <a-card title="代理商收入" style="margin: 20px">
      <div class="Walletbox">
        <!-- !表单区域 -->
        <div class="formbox">
          <!-- 所属机构 -->
          <div class="inputbox">
            <div class="lefttitle">所属机构</div>
            <!-- 所属机构 -->
            <a-tree-select v-model.trim="departmentCode" style="width: 200px"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="treeData" placeholder="请选择所属机构"
              tree-default-expand-all>
            </a-tree-select>
          </div>

          <div class="inputbox">
            <div class="lefttitle">电商编号</div>
            <a-input v-model.trim="selectData.mchNo" placeholder="请输入电商编号"></a-input>
          </div>

          <div class="inputbox">
            <div class="lefttitle">电商名称：</div>
            <a-input v-model.trim="selectData.shopName" placeholder="请输入电商名称"></a-input>

          </div>
          <div class="inputbox">
            <div class="lefttitle">数据日期：</div>
            <a-range-picker v-model.trim="searchtime" valueFormat="YYYY-MM-DD" style="width: 200px;" />
          </div>
          <!-- <div class="inputbox">
            <div class="lefttitle">可控电商:</div>
            <a-input v-model.trim="selectData.shopName" placeholder="请输入店铺名称"></a-input>
          </div> -->
          <!-- 查询 -->
          <a-button type="primary" @click="query">查询</a-button>
          <!-- 重置 -->
          <a-button>重置</a-button>
        </div>
      </div>

      <div class="table-title">
        <a-button type="link" @click="downloadDialy">下载日报</a-button>
      </div>
      <a-table ref="table2" :rowKey="(record, index) => `${record.id}`" bordered :columns="columnsMchDialy"
        :data-source="dataMchDialy" size="small" :pagination="false" :scroll="{ y: 800, x: 1800 }">
      </a-table>
    </a-card>

  </div>
</template>
<script>
import { utils, writeFile } from 'xlsx';
import dayjs from 'dayjs';
import Balance from '@/view/components/Balance'
export default {
  name: 'accountList',
  components: {
    Balance
  },
  data() {
    return {
      searchtime: [],
      departmentCode: undefined,
      selectData: {
        shopNo: '',
        shopName: '',
        entrustedPayer: '',
        lender: ''
      },
      dataMchDialy: [

      ],
      columnsMchDialy: [
        {
          title: '电商信息',
          children: [
            {
              title: "电商编号",
              dataIndex: "电商编号",
              align: "center",
              width: 200,
              sorter: (a, b) => a['电商编号'].localeCompare(b['电商编号']),
            },
            {
              title: "融资电商",
              dataIndex: "融资电商",
              align: "center",
              width: 200,
              sorter: (a, b) => a['融资电商'].localeCompare(b['融资电商']),
            },
            {
              title: "可控电商",
              dataIndex: "可控电商",
              align: "center",
              width: 200,
              sorter: (a, b) => a['可控电商'].localeCompare(b['可控电商']),
            },


          ],
        },
        {
          title: '费用收取(估算)',
          children: [
            {
              title: "已收费用",
              dataIndex: "已收费用",
              align: "center",
              width: 120,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['已收费用'] - b['已收费用'],
            },
            {
              title: "其中:核心企业费用",
              dataIndex: "其中:核心企业费用",
              align: "center",
              width: 120,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['其中:核心企业费用'] - b['其中:核心企业费用'],
            },
            {
              title: "其中:渠道应收费用",
              dataIndex: "其中:渠道应收费用",
              align: "center",
              width: 120,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['其中:渠道应收费用'] - b['其中:渠道应收费用'],
            },
            {
              title: "其中:渠道费用",
              dataIndex: "其中:渠道费用",
              align: "center",
              width: 120,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['其中:渠道费用'] - b['其中:渠道费用'],
            }, {
              title: "其中:银行利息",
              dataIndex: "其中:银行利息",
              align: "center",
              width: 120,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['其中:银行利息'] - b['其中:银行利息'],
            }, {
              title: "其中:平台服务费",
              dataIndex: "其中:平台服务费",
              align: "center",
              width: 120,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['其中:平台服务费'] - b['其中:平台服务费'],
            },
          ],
        },
      ],



    }
  },
  computed: {
  },
  async created() {
    let startTime = '2023-01-01'
    let endTime = dayjs().format('YYYY-MM-DD')
    this.$set(this.searchtime, 0, startTime)
    this.$set(this.searchtime, 1, endTime)
    await this.getCustList()
    this.getMchDialyTable()
  },

  methods: {
    // 获取所属机构下拉框数据
    async getCustList() {
      let data = {
        username: this.$session.getItem('username'),
      }
      const res = await this.api.agencyList(data)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      let trees = []
      this.institutiontrees = res.data
      trees.push(res.data)
      this.treeData = this.getResettingTrees(trees)
        ? this.getResettingTrees(trees)
        : []
      this.departmentCode = this.$session.getItem("companyCode");
    },
    // 进行循环递归处理数据
    getResettingTrees(tree) {
      // 定义子级数组
      if (tree.length == 0) return []
      let arr = []
      tree.forEach((item) => {
        // 递归循环将每一项都处理城组件需要的的格式 [ {title:"" children:[{title:""}]  }]
        arr.push({
          title: item.coreDepartment.name,
          value: item.coreDepartment.code,
          key: item.coreDepartment.id,
          children:
            item.children && item.children.length > 0
              ? this.getResettingTrees(item.children)
              : [],
        })
      })
      // 处理好的数组返回出去
      return arr
    },
    async getMchDialyTable() {
      let data = {
        sqlParame: {
          depatCode: this.departmentCode,
          starDate: this.searchtime[0],
          endDate: this.searchtime[1],
          ...this.selectData
        },
        key: 'e_commerce_daily'
      }
      let res = await this.api.getRzDetailsApi(data)
      if (res.code == 200) {
        this.dataMchDialy = res.data
      }
    },
    query() {
      this.getMchDialyTable()
    },
    // 下载日报
    downloadDialy() {
      // 创建一个新的工作簿
      const wb = utils.book_new();
      // 创建一个新的工作表
      const ws = utils.aoa_to_sheet([]);
      // 设置合并单元格以创建多级表头
      ws['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }, // 合并第一行的9
        { s: { r: 0, c: 3 }, e: { r: 0, c: 7 } }, // 合并第一行的7
      ];
      // // 添加父级表头
      const parentHeaders = [
        // 第一行 - 多个父级表头
        ['电商信息', "", "", '费用收取(估算)', "", "", "", "", ""
        ],

        // 第二行 - 子级表头
        ["电商编号", "融资电商", "可控电商", "已收费用", "其中:核心企业费用", "其中:渠道应收费用", "其中:渠道费用", "其中:银行利息", "其中:平台服务费",]
      ];

      utils.sheet_add_aoa(ws, parentHeaders, { origin: 'A1' });

      // 提取数据并添加到工作表
      const dataRows = this.dataMchDialy.map(dataRow => {
        return [dataRow['电商编号'], dataRow['融资电商'], dataRow['可控电商'], dataRow['已收费用'], dataRow['其中:核心企业费用'], dataRow['其中:渠道应收费用'], dataRow['其中:渠道费用'], dataRow['其中:银行利息'], dataRow['其中:平台服务费'],];
      });

      utils.sheet_add_aoa(ws, dataRows, { origin: 'A3' });
      utils.book_append_sheet(wb, ws, '电商日报');
      writeFile(wb, '电商日报.xlsx');

    },
    // 点击重置表单
    reset() {
      this.selectData.shopNo = ''
      this.selectData.shopName = ''
      this.departmentCode = undefined
      // 获取表格数据
      this.getMchDialyTable()
    },
  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped>
.ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}

.table-title {
  display: flex;
  justify-content: end;
}

/* 自定义表头样式 */
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

.Walletbox {
  width: 100%;

  .formbox {
    display: flex;
    flex-wrap: wrap;

    .inputbox {
      width: 280px;
      margin-right: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .lefttitle {
        margin-right: 8px;
      }

      input {
        width: 200px;
      }
    }

    .reset {
      width: 64px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }

    button {
      margin-bottom: 20px;
    }
  }

  .Controls {
    padding-top: 10px;
    margin-bottom: 20px;

    button {
      min-width: 64px;
      margin-right: 25px;
    }

    .edit {
      background-color: #d7d7d7;
      color: #fff;
    }
  }

  .tablebox {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }

  .paginationBox {
    display: flex;
    justify-content: flex-end;

    // .ant-pagination-options {
    //   float: left;
    // }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: 1px solid #d9d9d9;
      width: 60px;
    }
  }

  // 开户弹框
}

.Openanaccount {
  padding: 30px;

  .personage {
    width: 100%;
    height: 40px !important;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 30px;
  }

  .firm {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 20px;
  }
}

.ant-modal-footer {
  border: none;
  padding-bottom: 20px;
}

.ant-modal-content {
  width: 420px;
}

// 保证金
.Securitydeposit,
.SecuritydepositRelease {
  padding-bottom: 20px;

  .userinfo {
    padding: 20px;
    background-color: #f5f8ff;
    min-height: 130px;

    div {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .blane {
      margin-bottom: 0px;
    }

    margin-bottom: 30px;
  }

  .Payoverbox {
    display: flex;
    padding-right: 0px;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;
    margin-bottom: 20px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }

    .Payover {
      .ant-input {
        position: relative;
        width: 220px;
        padding-right: 0px !important;
      }

      .ant-input-suffix {
        position: absolute;
        width: 40px;
        right: 0px;
        top: 0;
        margin-top: 16px;
        margin-left: 20px;
        height: 32px;
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .codebox {
    display: flex;
    display: flex;
    padding-right: 0px;
    // justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }

    .code {
      width: 110px;
      margin-right: 10px;
    }
  }
}

.SecuritydepositSuccessful {
  h3 {
    padding: 0 20px;
    margin-bottom: 20px;
  }

  .info {
    padding: 20px;
    width: 100%;
    min-height: 90px;
    background-color: #f5f8ff;

    div {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
</style>
