<template>
  <div>
    <a-modal title="绑定银行卡" :visible="visible" @cancel="clickCancel" :centered="true">

      <div class="form-box">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="left">
          <a-form-model-item label="户名：">   {{selectedAccount.accountName}}
          </a-form-model-item>
          <!-- 个人 -->
          <a-form-model-item label="银行卡号:" prop="primary_account" v-if="selectedAccount.accountProperty === '1'">
            <a-input placeholder="请输入银行卡号" v-model.trim="form.primary_account"></a-input>
          </a-form-model-item>
          <div v-if="selectedAccount.accountProperty === '2'">
            <!-- 企业 -->
            <a-form-model-item label="银行结算账号:" prop="primary_account2">
              <a-input placeholder="请输入银行卡号" v-model.trim="form.primary_account2"></a-input>
            </a-form-model-item>
            <a-form-model-item label="开户行名称:" prop="bank_name">
              <a-input placeholder="请填写开户行名称" v-model.trim="form.bank_name"></a-input>
              <a-button type="link" @click="isShowBankChoose = true" style="display: inline-block;">快速检索</a-button>
              <span style="display: inline-block;">
                <BankChoose v-if="isShowBankChoose" @changeBank="changeBank"></BankChoose>
              </span>
            </a-form-model-item>
            <a-form-model-item label="开户行行号:" prop="bank_no">
              <a-input placeholder="请输入12位开户行号, 咨询开户行" v-model.trim="form.bank_no"></a-input>
            </a-form-model-item>
          </div>

          <a-form-model-item label="默认银行卡:" prop="set_default">
            <a-radio-group v-model.trim="form.set_default">
              <a-radio-button value="1">
                是
              </a-radio-button>
              <a-radio-button value="0">
                否
              </a-radio-button>

            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="短信验证码:" prop="verify_code">
            <a-input placeholder="请输入验证码" v-model.trim="form.verify_code">
              <CountdownBtn slot="suffix" :wait-time="60" :error-wait-time="10" :send-call="sendVerifyCode">
              </CountdownBtn>
            </a-input>
          </a-form-model-item>



        </a-form-model>
      </div>
      <template slot="footer">
        <a-button @click="clickCancel">取消
        </a-button>
        <a-button type="primary" @click="onConfirmDialog">绑定</a-button>
      </template>

    </a-modal>
  </div>
</template>
<script>

import CountdownBtn from "@/view/components/CountdownBtn.vue";
import BankChoose from "@/view/components/BankChoose.vue";
export default {
  name: "PaymentAuthDialog",
  props: {
    // 是否显示弹框
    visible: {
      required: true,
      type: Boolean
    },
    // 传来的选中数据
    selectedAccount: {
      required: true,
      type: Object
    },

  },
  components: {
    CountdownBtn,
    BankChoose
  },
  data() {
    let checkCertNum = (rule, value, callback) => {
      let custNumReg =
        /^62\d{14}(\d{3})?$/;
      if (value === "") {
        callback(new Error("请输入任意银行的银行卡号，仅支持16位或19位“62”开头的银联储蓄卡"));
      } else if (!custNumReg.test(value)) {
        callback(new Error("请输入任意银行的银行卡号，仅支持16位或19位“62”开头的银联储蓄卡"));
      } else {
        callback();

      }
    };
    return {
      checkCertNum,
      isShowBankChoose: false,
      // form数据
      form: {
        customer_no: '',//客户编号
        customer_name: '',//客户名称
        channel_no: '',//渠道编号
        app_no: '',//应用编号
        account_no: '',//a1账号
        account_property: '',//账号类型：1个人2企业
        id_no: '',//证件号码
        primary_account: '',//银行卡号(个人)
        primary_account2: '',//银行卡号(企业)
        bank_no: '',//开户行号
        bank_name: '',//开户行名
        set_default: '0',//设为默认
        verify_code: '',//验证码(短信类型传4)
        mobile: "",//手机号
      },
      rules: {
        // 个人
        primary_account: [
          {
            required: true,
            // message: '请输入任意银行的结算账号',
            validator: checkCertNum,
            trigger: 'blur',
          },],
        // 企业
        primary_account2: [
          {
            required: true,
            message: '请输入任意银行的结算账号',
            trigger: 'blur',
          },
        ],
        bank_no: [{ required: true, message: '请输入12位开户行号, 咨询开户行', trigger: 'blur' }],
        bank_name: [{ required: true, message: '请填写开户行名称', trigger: 'blur' }],
        set_default: [{ required: true, message: '请选择是否默认', trigger: 'change' }],
        // verify_code: [{ required: true, message: '请填写验证码', trigger: 'blur' }],

      },
    };
  },

  watch: {},
  computed: {

  },
  created() {

  },
  methods: {
    changeBank(obj) {
      this.form.bank_no = obj.value
      this.form.bank_name = obj['option'].componentOptions.children[0].text
      // 校验
      this.$nextTick(() => {
        this.$refs.ruleForm.validateField(['bank_name', 'bank_no']);
      });
    },
    //  取消弹框
    clickCancel() {
      this.$emit('handleBind', {
        bool: false
      })
    },

    async sendVerifyCode() {
      //TODO: 进行必输项校验

      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // 校验成功
          //相应参数的参数的验证
          // 短信发送参数组装
          let data = {
            // 短信类型
            tradeType: 4,
            // 账号
            accountNo: this.selectedAccount.accountNo,
            accountName: this.selectedAccount.accountName,
            channelNo: this.selectedAccount.channelNo,
            appNo: this.selectedAccount.appNo,
            cardNo: this.selectedAccount.accountProperty === '1' ? this.form.primary_account : this.form.primary_account2,//卡号
            idNo: this.selectedAccount.certnum,//身份证号
            mobile: this.selectedAccount.mobile
          };

          let res = await this.api.vefifycodesend(data);
          if (res.code == 200) {
            this.$message.success('发送验证码成功!')
          } else {
            return this.$message.error(res.info)
          }

        }
      })


    },

    // 弹框确定按钮
    onConfirmDialog() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 校验成功
          if (this.form.verify_code == "") {
            return this.$message.warning("请填写验证码");
          }
          // 调用绑定接口
          this.bindBank();
        }
      })


    },
    async bindBank() {
      let data = JSON.parse(JSON.stringify(this.form))
      // 定义要传给后端的数据
      let paramsData = {}
      paramsData.customer_no = this.selectedAccount.custnum // 客户编号
      paramsData.customer_name = this.selectedAccount.custname//客户名称
      paramsData.channel_no = this.selectedAccount.channelNo//渠道编号
      paramsData.app_no = this.selectedAccount.appNo//应用编号
      paramsData.account_no = this.selectedAccount.accountNo//a1账号
      paramsData.account_property = this.selectedAccount.accountProperty//账号类型
      paramsData.id_no = this.selectedAccount.certnum//证件号码
      paramsData.set_default = data.set_default//是否默认银行卡
      paramsData.verify_code = data.verify_code//验证码
      paramsData.mobile = this.selectedAccount.mobile//手机号
      if (this.selectedAccount.accountProperty === '1') {
        paramsData.primary_account = data.primary_account

      } else if (this.selectedAccount.accountProperty === '2') {
        paramsData.primary_account = data.primary_account2
        paramsData.bank_no = data.bank_no
        paramsData.bank_name = data.bank_name
      }

      let res = await this.api.bindBankCardApi(paramsData)
      if (res.code === 200) {
        this.$message.success(res.info)
        // 关闭弹框
        this.$emit('handleBind', {
          bool: false,
          type: 'success'
        })
        //置空表单数据
        this.form = {
          customer_no: '',//客户编号
          customer_name: '',//客户名称
          channel_no: '',//渠道编号
          app_no: '',//应用编号
          account_no: '',//a1账号
          account_property: '',//账号类型：1个人2企业
          id_no: '',//证件号码
          primary_account: '',//银行卡号(个人)
          primary_account2: '',//银行卡号(企业)
          bank_no: '',//开户行号
          bank_name: '',//开户行名
          set_default: '0',//设为默认
          verify_code: '',//验证码(短信类型传4)
        }
      } else {
        this.$message.error(res.info)

      }
    }

  },
};
</script>

<style lang="less" scoped>
/deep/.ant-modal {
  width: 660px !important;

  .ant-modal-content {
    width: 100%;
  }
}

.form-box {
  padding-left: 10px;

  /deep/ .ant-form-item {
    display: flex;

    .ant-form-item-label-left {
      width: 100px;
    }

    .ant-input {
      width: 200px;
    }

    .ant-select {
      width: 200px;
    }
  }

  /deep/ .ant-input-affix-wrapper .ant-input-suffix {
    right: -120px;
  }

  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    width: 200px;
  }

}
</style>