<template>
  <div id="authority">
    <a-card title="权限管理" style="margin:20px">
      <!-- 操作栏 -->
      <div class="action">
        <a-button type="primary" v-if="$authority('authority_add')" @click="addOneHandle">一级新增</a-button>
        <a-button type="primary" v-if="$authority('authority_add')" @click="addHandle">子级新增</a-button>
        <a-button v-if="$authority('authority_update')" @click="updateHandle">修改</a-button>
        <a-button v-if="$authority('authority_delete')" type="danger" :disabled="selectedRowKeys.length === 0"
          @click="deleteHandle">删除</a-button>
      </div>
      <!-- 表格 -->
      <div class="table">
        <a-table :columns="columns" :data-source="tableData" :row-selection="rowSelection" :pagination="false"
          :customRow="clickrow" :rowKey="(record, index) => `${record.id}`">
          <template slot="number_index" slot-scope="text">
            <span>
              {{ text }}
            </span>
          </template>
          <template slot="superId" slot-scope="text">
            <span>
              {{ parentHandle(text) }}
            </span>
          </template>
        </a-table>
      </div>
      <!-- page -->
      <div class="page">
        <a-pagination :current="currentPage" :total="total" show-size-changer show-quick-jumper style="float:right"
          @change="pageChange" @showSizeChange="sizePageChange" :show-total="total => `共 ${total} 条`" />
      </div>
      <!-- 模态框 -->
      <div class='modal' v-if="modalBool">
        <view-modal :modalBool="modalBool" :modalTitle="modalTitle" :selectedRows="selectedRows"
          @okModalHandle="okModalHandle" @cancelModalHandle="cancelModalHandle" ref="modalChild"></view-modal>
      </div>
    </a-card>
  </div>
</template>
<script>
import Modal from "./Modal"
const columns = [
  {
    title: '序号',
    dataIndex: 'id',
    align: 'center',
    scopedSlots: { customRender: 'number_index' },
  },
  {
    title: '资源名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '父级资源',
    dataIndex: 'superId',
    align: 'center',
    scopedSlots: { customRender: 'superId' },
  },
  {
    title: '资源层级',
    dataIndex: 'grade',
    align: 'center',
  },
  {
    title: '资源路径',
    dataIndex: 'path',
    align: 'center',
  },
  {
    title: '权限路径',
    dataIndex: 'authUrls',
    align: 'center',
  }
];
export default {
  name: 'authorityPage',
  components: {
    "view-modal": Modal,
  },
  data() {
    return {
      data: [],
      tableData: [],
      dataSuperId: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      columns,
      modalBool: false,
      modalTitle: "操作",
      selectedRowKeys: [],
      selectedRows: [{}],
      selectedValue: [{}]
    }
  },
  created() {
    this.tableRequestHandle()
  },
  methods: {
    //查看
    looKhandle(text, val, index) {
      console.log(val, text, index, 'val')
    },
    //页码操作
    pageChange(page, pageSize) {
      const data = this.data
      this.currentPage = page
      this.tableData = data.slice((page - 1) * pageSize, page * pageSize)
    },
    sizePageChange(current, size) {
      const data = this.data
      this.tableData = data.slice((current - 1) * size, current * size)
    },
    //数据增删改
    addOneHandle() {
      const sum = this.selectedRowKeys
      if (sum.length !== 0) return this.$message.warn("请不要选择资源")
      this.selectedRows = [{}]
      this.modalTitle = "新增数据"
      this.modalBool = true
    },
    addHandle() {
      const sum = this.selectedRowKeys
      if (sum.length !== 1) return this.$message.warn("请选择父级资源")
      this.selectedRows = [{}]
      this.modalTitle = "新增数据"
      this.modalBool = true
    },
    updateHandle() {
      const sum = this.selectedRowKeys
      if (sum.length !== 1) return this.$message.warn("只能选择一条数据")
      this.modalTitle = "修改数据"
      this.modalBool = true
    },
    deleteHandle() {
      const data = this.selectedRowKeys
      const tableData = this.tableData
      const currentPage = this.currentPage
      this.$confirm({
        // title: '删除数据',
        // content: () => "是否删除此条数据？此过程不可逆！",
        title: '是否删除此条数据？此过程不可逆！',
        onOk: () => {
          this.$axios.apiPost("/user/function/del", data).then(res => {
            if (res.code !== 200) return this.$message.error(res.info)
            if (tableData.length === data.length && currentPage > 1) {
              this.currentPage = currentPage - 1
            }
            this.selectedRowKeys = []
            this.tableRequestHandle()
            this.$message.success(res.info)
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    //Modal操作
    okModalHandle(id) {
      const sum = this.selectedRowKeys
      const data = this.selectedValue[0]
      this.$refs['modalChild'].form.validateFields((err, values) => {
        if (!err) {
          let obj = {}
          if (id) {
            obj = {
              ...data,
              ...values,
            }
            this.$axios.apiPost('/user/function/add', obj).then(res => {
              if (res.code !== 200) return this.$message.error(res.info)
              this.tableRequestHandle()
              this.selectedRowKeys = []
              this.$message.success("修改成功")
            })
          } else if (sum.length === 0) {
            obj = {
              ...values,
              superId: 0,
              grade: 1
            }
            this.$axios.apiPost('/user/function/add', obj).then(res => {
              if (res.code !== 200) return this.$message.error(res.info)
              this.tableRequestHandle()
              this.$message.success(res.info)
            })
          } else {
            obj = {
              ...values,
              superId: data.id,
              grade: data.grade + 1
            }
            this.$axios.apiPost('/user/function/add', obj).then(res => {
              if (res.code !== 200) return this.$message.error(res.info)
              this.tableRequestHandle()
              this.$message.success(res.info)
            })
          }
          this.modalBool = false
          this.selectedRowKeys = []
        }
      })
    },
    cancelModalHandle() {
      this.modalBool = false
    },
    //table信息
    tableRequestHandle() {
      this.$axios.apiGet('/user/function/list').then(res => {
        if (res.code !== 200) return this.$message.error(res.info)
        let tableData = [];
        const data = res.data
        function loop(parent, childrenList) {
          return parent.map(e => {
            const childrens = childrenList.filter(c => c.superId === e.id);
            if (childrens.length > 0) {
              e.children = [];
              e.children.push(...childrens);
              loop(e.children, childrenList);
            }
            return e;
          });
        }
        if (data.length > 0) {
          const parentList = data.filter(e => !e.superId);
          const childrenList = data.filter(e => e.superId);
          tableData = loop(parentList, childrenList);
        }
        this.data = tableData
        this.total = tableData.length
        this.dataSuperId = data
        this.tableData = tableData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
      })
    },
    //父级资源
    parentHandle(superId) {
      const superR = this.dataSuperId.filter(item => item.id === superId);
      return superR.length > 0 ? superR[0].name : '无';
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            this.selectedRowKeys = [id]
            this.selectedRows = [record]
            // let index = this.selectedRowKeys.findIndex((item) => id == item)
            // if (index == -1) {
            //   this.selectedRowKeys.push(id)
            //   this.selectedRows.push(record)
            //
            // } else {
            //
            //   this.selectedRowKeys.splice(index, 1)
            //   this.selectedRows.splice(index, 1)
            // }
          }
        }
      }
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
          this.selectedValue = selectedRows
        },
        selectedRowKeys: this.selectedRowKeys
      };
    },
  }
}
</script>
<style lang="less" scoped>
#authority {
  .page {
    margin: 12px 0;
  }

  .table {
    margin-top: 24px;
  }
}
</style>