<template>
  <div>
    <a-modal
      title="权限信息"
      :visible="lookBool"
      @cancel="handleCancel"
      :footer="null"
    >
     <div class="tree">
       <a-tree
        showLine
        :default-checked-keys="treeNode"
        :checkedKeys="treeNode"
        :tree-data="treeData"
        checkable
        defaultExpandAll
        checkStrictly
      />
     </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props:{
    lookBool:Boolean,
    treeData:Array,
    treeNode:Array
  },
  data() {
    return {
    };
  },
  
  methods: {
    handleCancel() {
      this.$emit("cancelPassHandle")
    },
  },
};
</script>

<style lang="less" scoped>
  .tree{
    height: 368px;
    overflow: auto;
  }
</style>