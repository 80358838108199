<template>
  <div id="organization">
    <a-card title="机构管理" style="margin:20px">
      <!-- 操作栏 -->
      <div class="action">
        <a-button v-if="$authority('organization_add')" type="primary" @click="addHandle">新增</a-button>
        <a-button v-if="$authority('organization_update')" @click="updateHandle">修改</a-button>
        <a-button v-if="$authority('organization_delete')" type="danger" :disabled="selectedRowKeys.length !== 1"
          @click="deleteHandle">删除</a-button>
      </div>
      <!-- 表格 -->
      <div class="table">
        <a-table :columns="columns" :data-source="tableData" :row-selection="rowSelection" :pagination="false"
          :customRow="clickrow" :rowKey="(record, index) => `${record.id}`">
          <template slot="parentId" slot-scope="text">
            <span>
              {{ levelPart(text) }}
            </span>
          </template>
          <template slot="note" slot-scope="text">
            <span>
              {{ apiTypeStatus[text] }}
            </span>
          </template>
        </a-table>
      </div>
      <!-- page -->
      <div class="page">
        <a-pagination :current="currentPage" :total="total" show-size-changer show-quick-jumper style="float:right"
          @change="pageChange" @showSizeChange="sizePageChange" :show-total="total => `共 ${total} 条`" />
      </div>
      <!-- 模态框 -->
      <div class='modal' v-if="modalBool">
        <view-modal :modalBool="modalBool" :modalTitle="modalTitle" :selectedRows="selectedRows" :dataPart="data"
          @okModalHandle="okModalHandle" @cancelModalHandle="cancelModalHandle" ref="modalChild"></view-modal>
      </div>
    </a-card>
  </div>
</template>
<script>

import Modal from "./Modal";
const columns = [
  {
    title: '机构ID',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: '机构名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '机构级别',
    dataIndex: 'level',
    align: 'center',
  },
  {
    title: '上级机构',
    dataIndex: 'parentId',
    align: 'center',
    scopedSlots: { customRender: 'parentId' },
  },
  {
    title: '备注',
    dataIndex: 'note',
    align: 'center',
  },
  // {
  //   title: '请求最大量',
  //   dataIndex: 'apiCallTimes',
  //   align: 'center',
  // },
  // {
  //   title: '请求时间段',
  //   dataIndex: 'apiExpireTime',
  //   align: 'center',
  // },
  // {
  //   title: '请求权限类型',
  //   dataIndex: 'apiType',
  //   align: 'center',
  //   scopedSlots: { customRender: 'apiType' },
  // }
];
export default {
  name: 'organizationPage',
  components: {
    "view-modal": Modal,
  },
  data() {
    return {
      data: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      columns,
      modalBool: false,
      modalTitle: "操作",
      selectedRowKeys: [],
      selectedRows: [{}],
      apiTypeStatus: { 1: "白名单", 2: "灰名单", 3: "黑名单" }
    }
  },
  created() {
    this.tableRequestHandle()
  },
  methods: {
    //页码操作
    pageChange(page, pageSize) {
      const data = this.data
      this.currentPage = page
      this.tableData = data.slice((page - 1) * pageSize, page * pageSize)
    },
    sizePageChange(current, size) {
      const data = this.data
      this.tableData = data.slice((current - 1) * size, current * size)
    },
    //数据增删改
    addHandle() {
      this.selectedRowKeys = []
      this.selectedRows = [{}]
      this.modalTitle = "新增数据"
      this.modalBool = true
    },
    updateHandle() {
      const sum = this.selectedRowKeys
      if (sum.length !== 1) return this.$message.warn("只能选择一条数据")
      this.modalTitle = "修改数据"
      this.modalBool = true
    },
    deleteHandle() {
      const data = this.selectedRowKeys
      const tableData = this.tableData
      const currentPage = this.currentPage
      this.$confirm({
        // title: '删除数据',
        // content: () => "是否删除此条数据？此过程不可逆！",
        title: '是否删除此条数据？此过程不可逆！',
        onOk: () => {
          this.$axios.apiPost('/user/department/del?id=' + data).then(res => {
            if (res.code !== 200) return this.$message.error(res.info)
            if (tableData.length === data.length && currentPage > 1) {
              this.currentPage = currentPage - 1
            }
            this.tableRequestHandle()
            this.selectedRowKeys = []
            this.$message.success(res.info)
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    //Modal操作
    okModalHandle() {
      const id = this.selectedRows[0].id
      this.$refs['modalChild'].form.validateFields((err, values) => {
        if (!err) {
          console.log(values, 'modal')
          if (!id) {
            this.$axios.apiPost("/user/department/save", values).then((res) => {
              if (res.code !== 200) return this.$message.error(res.info)
              this.tableRequestHandle()
              this.$message.success(res.info)
            })
          } else {
            this.$axios.apiPost("/user/department/save?id=" + id, values).then((res) => {
              if (res.code !== 200) return this.$message.error(res.info)
              this.tableRequestHandle()
              this.selectedRowKeys = []
              this.$message.success("修改成功")
            })
          }
          this.modalBool = false
        }
      })
    },
    cancelModalHandle() {
      this.modalBool = false
    },
    //上级机构
    levelPart(text) {
      const parentCompany = this.data.filter(e => e.id === text)[0];
      if (parentCompany) {
        return parentCompany.name;
      } else {
        return text;
      }
    },
    //table数据
    tableRequestHandle() {
      this.$axios.apiGet('/user/department/list').then(res => {
        if (res.code !== 200) return this.$message.error(res.info)
        const data = res.data
        var sum_level = []
        var arrObj = []
        for (const value of data) {
          if (sum_level.indexOf(value.level) == -1) {
            sum_level.push(value.level)
          }
        }
        var max = Math.max.apply(this, sum_level)
        var min = Math.min.apply(this, sum_level)
        for (let i = min; i < max + 1; i++) {
          first(i, data)
        }
        function first(min, data) {
          for (let value of data) {
            if (value.level == min) {
              if (value.parentId) {
                if (arrObj.length) {
                  for (let value_1 of arrObj) {
                    recu(value_1, value)
                  }
                } else {
                  arrObj.push({
                    ...value,
                    children: []
                  })
                }
              } else {
                arrObj.push({
                  ...value,
                  children: []
                })
              }
            }
          }
        }
        function recu(value_1, value) {
          if (value_1.id == value.parentId) {
            value_1.children.push({
              ...value,
              children: []
            })
            return
          } else {
            if (!value_1.children) {
              return
            }
            for (let value_2 of value_1.children) {
              recu(value_2, value)
            }
          }
        }
        deleteChildren(arrObj)
        function deleteChildren(arrObj) {
          for (let value of arrObj) {
            if (value.children.length) {
              deleteChildren(value.children)
            } else {
              delete value.children
            }
          }
        }
        this.data = data
        this.tableData = arrObj
        this.total = data.length
      })
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            // let index = this.selectedRowKeys.findIndex((item) => id == item)
            this.selectedRowKeys = [id]
            this.selectedRows = [record]
            // if (index == -1) {
            //   this.selectedRowKeys.push(id)
            //   this.selectedRows.push(record)
            // } else {
            //
            //   this.selectedRowKeys.splice(index, 1)
            //   this.selectedRows.splice(index, 1)
            // }
          }
        }
      }
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
        selectedRowKeys: this.selectedRowKeys
      };
    },
  }
}
</script>
<style lang="less" scoped>
#organization {
  .page {
    margin: 12px 0;
  }

  .table {
    margin-top: 24px;
  }

  .form {
    margin-bottom: 16px;
  }
}
</style>