<template>
  <!-- 文件存储信息 -->
  <div>
    <a-card title="文件存储信息" style="margin: 20px">
      <div class="loan">
        <!-- !表单区域 -->
        <div class="formbox">

          <!--         文件编号-->
          <div class="inputbox">
            <div class="lefttitle">文件编号</div>
            <a-input v-model.trim="form.fileNo" placeholder="请输入文件编号" />
          </div>
          <!--         文件路径-->
          <div class="inputbox">
            <div class="lefttitle">文件路径</div>
            <a-input v-model.trim="form.filePath" placeholder="请输入文件路径" />
          </div>
          <!-- 文件名称-->
          <div class="inputbox">
            <div class="lefttitle">文件名称</div>
            <a-input v-model.trim="form.fileName" placeholder="请输入文件名称" />
          </div>
          <!-- 文件后缀-->
          <div class="inputbox">
            <div class="lefttitle">文件后缀</div>
            <a-input v-model.trim="form.fileExt" placeholder="请输入文件名称" />
          </div>
          <!-- 存储方式-->
          <div class="inputbox">
            <div class="lefttitle">存储方式</div>

            <a-select style="width: 200px;" v-model.trim="form.saveMode" placeholder="请选择存储方式">
              <a-select-option v-for="item in filesaveMode" :key="item.label" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </div>
          <!-- 	渠道编号-->
          <div class="inputbox">
            <div class="lefttitle"> 渠道编号</div>
            <a-input v-model.trim="form.channelNo" placeholder="请输入渠道编号" />
          </div>
          <!-- 	渠道文件编号-->
          <div class="inputbox">
            <div class="lefttitle"> 渠道文件编号</div>
            <a-input v-model.trim="form.channelFileNo" placeholder="请输入渠道文件编号" />
          </div>




          <!-- 查询 -->

          <a-button class="inquire" @click="search" type="primary" v-if="$authority('FinancingwhitelistQuery')">
            查询
          </a-button>
          <!-- 重置 -->
          <a-button @click="reset" class="reset">重置</a-button>
        </div>

        <div class="Applyfor" v-if="false">
          <!--  新增  Add-->
          <a-button type="primary" v-if="$authority('FinancingwhitelistQuery')">
            新增
          </a-button>
          <!--  审批-->
          <a-button type="primary" v-if="$authority('FinancingwhitelistQuery')"> 编辑 </a-button>

          <!-- 删除 -->
          <a-button v-if="$authority('FinancingwhitelistQuery')"> 删除 </a-button>

        </div>
        <div class="tablebox">
          <!-- 表格 -->
          <!-- :row-selection="{
              selectedRowKeys: selectedRowKeys,
              hideDefaultSelections: true,
              onChange: onSelectChange,
            }" -->
          <div class="tableBody">
            <a-table ref="table" size="small" :scroll="{ x: 2500, }" :rowKey="(record, index) => `${record.id}`" paginati
              :columns="columns" :data-source="data" :pagination="false">
              <span slot="accountNoSq" slot-scope="text"> <a-tag color="blue">钱包</a-tag>{{ text }}</span>
              <span slot="accountNoDb" slot-scope="text"> <a-tag color="blue">钱包</a-tag>{{ text }}</span>
            </a-table>
          </div>
          <!-- 分页部分 -->
          <div class="paginationBox">
            <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
              首页
            </a-button> <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
              show-size-changer :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
              :item-render="itemRender">
              <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
            </a-pagination>
            <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
              class="Trailingpage">
              尾页
            </a-button>
          </div>
        </div>
      </div>
    </a-card>

  </div>
</template>

<script>

export default {
  name: 'Filestorageinformation',
  data() {
    return {
      treeData: [],
      deleteshow: false, // 贷款弹窗

      total: 0,
      modalBool: false,
      Examineandapproveshow: false,
      selectedRowKeys: [],
      selectedRows: [], // 选数数组的值,
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      institutiontrees: {},
      data: [],

      form: {
        pageNo: 1,
        pageSize: 10,
        fileNo: '', //文件编号

        filePath: '', // 	文件路径
        fileName: '', // 文件名称
        fileExt: '',//文件后缀
        saveMode: undefined,//存储方式
        channelNo: '',// 渠道编号
        channelFileNo: ''// 渠道文件编号



      },
      filesaveMode: [{
        label: '本地文件',
        value: '00',

      },
      {
        label: 'fastdfs',
        value: '01',

      },
      {
        label: 'OSS',
        value: '11',

      },
      {
        label: 'NAS',
        value: '22',

      },
      {
        label: 'CFS',
        value: '31',

      },
      {
        label: 'VSAN',
        value: '41',

      },
      {
        label: '下游系统',
        value: '51',

      },],
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
            `${Number(this.form.pageNo - 1) * Number(this.form.pageSize) +
            Number(index + 1)
            }`,
          align: 'center',
        },
        {
          title: '文件编号',
          dataIndex: 'fileNo',
          align: 'center',
          width: 200,

        },


        {
          title: '文件路径',
          dataIndex: 'filePath',
          align: 'center',
          ellisis: true,
          width: 580,

        },


        {
          title: '文件名称	',
          dataIndex: 'fileName',
          align: 'center',
          width: 580,

        },
        {
          title: '文件后缀',
          dataIndex: 'fileExt',
          align: 'center',
          ellisis: true
        },
        {
          title: '存储方式',
          dataIndex: 'saveMode',
          align: 'center',
          ellisis: true,
          customRender: (text) => {
            return this.filesaveModefifter(text) ? this.filesaveModefifter(text) : text
          }

        },
        {
          title: '文件说明',
          dataIndex: 'fileNote',
          align: 'center',
          ellisis: true
        },
        {
          title: '使用标志',
          dataIndex: 'useFlag',
          align: 'center',
          ellisis: true,
          customRender: (text) => {
            if (Number(text) === 0) {
              return '未使用'
            } if (Number(text) === 1) {
              return '已使用'
            }
          },
        },

        {
          title: '渠道编号',
          dataIndex: 'channelNo',
          align: 'center',

        },
        {
          title: '渠道文件编号',
          dataIndex: 'channelFileNo',
          align: 'center',
          ellisis: true
        },
      ],
    }
  },
  created() {

    this.getcommercePage()
    // this.form.departmentId = sessionStorage.getItem("companyId")

  },
  methods: {
    // 选择所属机构去触发
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    // 获取所属机构下拉框数据
    async getCustList() {
      let data = {
        username: this.$session.getItem('username'),
      }
      const res = await this.api.agencyList(data)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      let trees = []
      this.institutiontrees = res.data
      trees.push(res.data)

      this.treeData = this.getResettingTrees(trees)
        ? this.getResettingTrees(trees)
        : []
    },




    // 获取table列表
    async getcommercePage() {



      const res = await this.api.sysfilemanagerApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.total = res.data.total
      this.form.pageNo = res.data.current
      this.form.pageSize = res.data.size

      this.data = res.data.records
    },


    filesaveModefifter(text) {
      let name = ''
      this.filesaveMode.forEach((item) => {
        if (text == item.value) {
          name = item.label
        }
      })
      return name
    },
    // 进行循环递归处理数据
    getResettingTrees(tree) {
      // 定义子级数组
      if (tree.length == 0) return []
      let arr = []

      tree.forEach((item) => {
        // 递归循环将每一项都处理城组件需要的的格式 [ {title:"" children:[{title:""}]  }]
        arr.push({
          title: item.coreDepartment.name,
          value: item.coreDepartment.id,
          key: item.coreDepartment.id,
          children:
            item.children && item.children.length > 0
              ? this.getResettingTrees(item.children)
              : [],
        })
      })
      // 处理好的数组返回出去
      return arr
    },
    // 点击重置表单
    reset() {
      this.form = {
        pageNo: 1,
        pageSize: 10,
        fileNo: '', //文件编号

        filePath: '', // 	文件路径
        fileName: '', // 文件名称
        fileExt: '',//文件后缀
        saveMode: undefined,//存储方式
        channelNo: '',// 渠道编号
        channelFileNo: ''// 渠道文件编号
      },
        this.getcommercePage()
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.form.pageSize = pageSize
      this.getcommercePage()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = 1,
        this.getcommercePage()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = Math.ceil(Number(this.total) / Number(this.form.pageSize))
      this.getcommercePage()
    },
    pagechange(val) {
      this.form.pageNo = val

      this.getcommercePage()
    },
    // 搜索查询
    search() {
      this.form.pageNo = 1
      this.getcommercePage()
    },

    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      // if (selectedRows.length > 1) {
      //   //获取选中的数据的key
      //   var selectNumber = this.selectedRowKeys[1]
      //   //清空选中的key
      //   this.selectedRowKeys = []
      //   //选中的数据的key重新赋值给selectedRowKeys
      //   this.selectedRowKeys.push(selectNumber)
      // }
      // //获取选中的数据详细信息
      // this.selectedRows = selectedRows[0]

    },
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj == {}) return
      if (dataObj.coreDepartment.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },

  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped>
.ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}

/* 自定义表头样式 */
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

.ant-modal-footer {

  padding-bottom: 20px;
}

.ant-modal-content {
  width: 420px;
}

.loan {
  .formbox {
    display: flex;
    flex-wrap: wrap;

    .inputbox {
      width: 260px;
      margin-right: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      input {
        width: 180px;
      }

      .lefttitle {
        min-width: 80px;
      }
    }

    .zhaungtai {
      width: 280px;
      margin-right: 120px;
    }

    .reset {
      width: 64px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }

    button {
      margin-bottom: 20px;
    }

    .ant-tag {
      cursor: pointer;
    }
  }

  .tablebox {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    margin-top: 15px;

    .ant-tag {
      cursor: pointer;
    }
  }

  .paginationBox {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;

    // .ant-pagination-options {
    //   float: left;
    // }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: 1px solid #d9d9d9;
      width: 60px;
    }
  }

  .Applyfor {
    padding-top: 10px;
    margin-bottom: 20px;
  }
}
</style>
