//上传图片
import axios from '@/utils/axios'

import urlConfig from '@/utils/urlConfig'

export default {
  uploadImage: (data) =>
    axios.apiPost(urlConfig.busiURL + '/file/manager/upload', data),
  //上传图片
  downloadImage: (data) =>
    axios.apiPost(
      urlConfig.busiURL + '/file/manager/getAccessUrlByFileNo?file_no=' + data
    ),
  // 删除文件
  delImageApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/cust/vedio/delete', data),
  // 编辑文件
  editImageApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/cust/vedio/update', data),
  // ocr 识别 调用文件上传 
  ocrrecognizeUpApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/base/ocr/recognizeUp', data),
// 下载模板
  downLoadTemplateApi: (data) => axios.apiPost(urlConfig.busiURL + '/base/configparam/getMapList', data),
// 图表接口
getEchartsDataApi: (data) => axios.apiPost(urlConfig.busiURL + '/statisticalRawSql/executeSQL', data),

  //发票到账明细导入
  filesUploadApi: (formData) =>
      axios.apiFormPost(urlConfig.busiURL + '/file/manager/upload', formData),
}
