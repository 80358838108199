<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>服务费配置</span> <a-icon type="right" /> <span>{{ title }}</span>
          </div>
          <div class="right">
            <!-- 确认 -->
            <a-button type="primary" @click="confirm" v-if="$route.query.type !== 'view'"> 确认 </a-button>
            <!-- 取消 -->
            <a-button @click="cancel"> 取消 </a-button>
          </div>
        </div>
      </template>
      <!-- 电商新增页面 -->
      <div class="Electronicbusiness">

        <div class="formbox">
          <div class="toptitle">基础信息*</div>
          <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <!-- 服务费名称 -->
            <a-form-model-item label="服务费名称" class="feeName" ref="feeName" prop="feeName">
              <a-input v-model.trim="form.feeName" :disabled="$route.query.type == 'view'" placeholder="请输入服务费名称" />
            </a-form-model-item>
            <!-- 服务费编号-->
            <a-form-model-item label="服务费编号" class="feeNo" ref="feeNo" prop="feeNo">
              <a-input type="text" v-model="form.feeNo" :disabled="$route.query.type == 'view'" placeholder="请输入服务费编号" />
            </a-form-model-item>
            <!-- 定额费用 -->
            <a-form-model-item v-if="Number(form.feeType) == 1 && Number(form.feeType) != 3" ref="fixedFee" label="定额费用"
              prop="fixedFee">
              <a-input v-model="form.fixedFee" type="number" placeholder="请输入定额费用 " addon-after="元" />
            </a-form-model-item>
            <!-- 单笔费用 /默认阈值比例-->
            <a-form-model-item v-if="Number(form.feeType) != 1 && Number(form.feeType) != 3" ref="feeProportion"
              :label="form.feeType == '4' ? '默认阈值比例' : '单笔费用'" prop="feeProportion">
              <a-input type="number" v-model="form.feeProportion"
                :placeholder="form.feeType == '4' ? '请输入默认阈值比例' : '请输入单笔费用'" />
            </a-form-model-item>
            <!-- 费用最小值 -->
            <a-form-model-item label="费用最小值" v-if="Number(form.feeType) == 2 && Number(form.feeType) != 3" prop="feeMin">
              <a-input v-model="form.feeMin" type="number" placeholder="请输
              
              入费用最小值 " addon-after="元" />
            </a-form-model-item>
            <!-- 费用最大值 -->
            <a-form-model-item label="费用最大值" v-if="Number(form.feeType) == 2 && Number(form.feeType) != 3" prop="feeMax">
              <a-input v-model="form.feeMax" type="number" placeholder="请输入费用最大值 " addon-after="元" />
            </a-form-model-item>
            <!-- 服务费类型 -->
            <a-form-model-item label="服务费类型" class="feeType" ref="feeType" prop="feeType">
              <a-radio-group name="radioGroup" :disabled="$route.query.type == 'view'" v-model="form.feeType">
                <a-radio :value="item.value" v-for="(item, index) in feeTypelist" :key="'feeType' + index">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <!-- 备注 -->
            <a-form-model-item label="备注" class="note" ref="note" prop="note">
              <a-input type="textarea" style="width: 320px;height: 100px;margin-top:10px ;margin-bottom: 10px;"
                :disabled="$route.query.type == 'view'" v-model.trim="form.note" placeholder="请填写备注" />
            </a-form-model-item>
          </a-form-model>
        </div>
        <!-- 服务费规则 -->
        <div class="formbox Anchor">
          <div class="toptitle">服务费规则</div>
          <a-button type="primary" v-if="$route.query.type !== 'view' && Number(form.feeType) == 3" @click="addrule"> 新增
          </a-button>
        </div>
        <div class="tablebox">
          <!-- 表格 -->
          <div class="tableBody">
            <a-table ref="table" :rowKey="(record, index) => `${record.id}`" paginati :columns="columns"
              :data-source="form.feeFdRules" :pagination="false">
              <template slot="Controls" slot-scope="text, record,index">
                <div class="Controlsbox">
                  <a-button :disabled="$route.query.type == 'view'" @click="Editing(record, index)">编辑</a-button>
                  <a-button :disabled="$route.query.type == 'view'" @click="Deleterow(record, index)">删除</a-button>
                </div>
              </template>
            </a-table>
          </div>

        </div>
      </div>
    </a-card>
    <!--  新增规则 ，按分段，弹层- -->
    <a-modal :maskClosable="false" :body-style="{ textAlign: 'center' }" title=" 新增规则" @cancel="addruleshowscale = false"
      :centered="true" :visible="addruleshowscale">
      <div class="addform">
        <a-form-model ref="addruleForm" :model="addScaleForm" :rules="addrulescalerules" :label-col="addlabelCol"
          :wrapper-col="addwrapperCol">
          <!--规则名称 -->
          <a-form-model-item ref="feeName" label="规则名称" prop="feeName">
            <a-input v-model.trim="addScaleForm.feeName" placeholder="请输入规则名称" />
          </a-form-model-item>
          <!-- 类型 -->
          <a-form-model-item label="类型" class="feeType" ref="feeType" prop="feeType">
            <a-radio-group v-model="addScaleForm.feeType" name="radioGroup">
              <a-radio v-for="(item, index) in feeFdTypeList" :value="item.value" :key="'feeFdType' + index">
                {{ item.label }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <!--  区间-->
          <template>
            <div class="range-box" v-for="(item, index) in addScaleForm.rangeList" :key="'rangeList' + index">
              <div class="range-header">
                <div class="left">区间{{ index + 1 }}</div>
                <div class="right">
                  <a-button shape="circle" :disabled="isEdit" v-if="index == addScaleForm.rangeList.length - 1"
                    @click="addRangeData(index, '1')">
                    <a-icon type="plus" />
                  </a-button>

                  <a-button shape="circle" v-if="addScaleForm.rangeList.length != 1" @click="addRangeData(index, '2')">
                    <a-icon type="minus" />
                  </a-button>
                </div>
              </div>
              <div class="range-container">
                <!-- 金额 -->
                <span class="mark">*</span>
                <a-form-model-item label="金额" class="num">
                  <a-col :span="12">
                    <a-form-model-item :prop="`rangeList[${index}].feeMin`" :rules="{
                      required: true,
                      message: '请输入订单金额最小值',
                      trigger: 'blur',
                    }" style="margin-right: 8px;">
                      <a-input type="number" v-model="item.feeMin" class="inp-box" placeholder="订单金额最小值" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-model-item :prop="`rangeList[${index}].feeMax`" :rules="{
                      required: true,
                      message: '请输入订单金额最大值',
                      trigger: 'blur',
                    }">
                      <a-input type="number" v-model="item.feeMax" class="inp-box" placeholder="订单金额最大值" />
                    </a-form-model-item>
                  </a-col>
                </a-form-model-item>
                <!-- 定额费用 -->
                <a-form-model-item label="定额费用" v-if="addScaleForm.feeType == '11'" :prop="`rangeList[${index}].fixedFee`"
                  :rules="{
                    required: true,
                    message: '请填写单笔服务费费用',
                    trigger: 'blur',
                  }">
                  <a-input type="number" v-model="item.fixedFee" placeholder="请填写单笔服务费费用" addon-after="元" />
                </a-form-model-item>
                <!-- 单笔费用 -->
                <a-form-model-item label="单笔费用" :prop="`rangeList[${index}].feeProportion`" :rules="{
                  required: true,
                  message: '请填写单笔服务费收费比例',
                  trigger: 'blur',
                }" v-else>
                  <a-input type="number" placeholder="请填写单笔服务费收费比例" v-model="item.feeProportion" addon-after="%" />
                </a-form-model-item>
                <!-- 备注 -->
                <a-form-model-item ref="note" label="备注" :prop="`rangeList[${index}].note`" :rules="{
                  required: true,
                  message: '请填写备注',
                  trigger: 'blur',
                }">
                  <a-input v-model.trim="item.note" style="height: 100px;" type="textarea" placeholder="请填写备注" />
                </a-form-model-item>
              </div>

            </div>
          </template>
        </a-form-model>
      </div>

      <template v-slot:footer>
        <a-button type="primary" @click="isSaveAddFeeRule">保存</a-button>
        <a-button @click="addruleshowscale = false">取消</a-button>
      </template>
    </a-modal>
    <!--  确认弹框- -->
    <a-modal title="提示" :body-style="{ textAlign: 'center' }" :maskClosable="false" @cancel="confirmDialog = false"
      :centered="true" :keyboard="false" :visible="confirmDialog">
      <p>{{ modalText }}</p>
      <template v-slot:footer>
        <a-button type="primary" @click="saveHandle">{{ confirmText }}</a-button>
        <a-button @click="cancelHandle">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>


export default {
  data() {
    return {
      addlabelCol: { span: 8 },
      addwrapperCol: { span: 16 },
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },
      title: "新增",
      treeData: [],
      type: 'save',
      formtype: 'add',
      editingindex: '',
      addruleshowscale: false,// 新增规则弹窗 按分段
      form: {
        feeName: '',// 服务费名称
        feeNo: '',// 服务费编号
        fixedFee: '',//定额费用
        feeProportion: '',//单笔费用
        feeMax: '',//费用最小值
        feeMin: '',//费用最大值
        feeType: '1',// 服务费类型
        feeFdRules: [],//手续费分段规则
        note: '',// 备注
      },
      // 服务费类型
      feeTypelist: [{
        label: '定额',
        value: '1',
      }, {
        label: "比例",
        value: "2",
      }, {
        label: "分段",
        value: "3",
      },
      {
        label: "日息",
        value: "4",
      }],
      rules: {
        feeName: [
          { required: true, message: '请输入服务费名称', trigger: 'blur' },
        ],
        feeNo: [
          { required: true, message: '请输入服务费编号', trigger: 'blur' },
        ],
        fixedFee: [
          { required: true, message: '请输入定额费用', trigger: 'blur' },
        ],
        // 需要修改提示语
        feeProportion: [
          { required: true, message: '请输入单笔费用', trigger: 'blur' },
        ],
        feeMax: [
          { required: true, message: '请输入费用最大值', trigger: 'blur' },
        ],
        feeMin: [
          { required: true, message: '请输入费用最小值', trigger: 'blur' },
        ],
        feeType: [
          { required: true, message: '请选择服务费类型', trigger: 'change' },
        ],
        note: [
          { required: true, message: '请输入备注', trigger: 'blur' },
        ],

        //
      },
      // 新增规则 ，按分段
      addScaleForm: {
        feeName: '',
        feeType: '11',
        rangeList: [{
          fixedFee: '',
          feeProportion: '',
          feeMin: '',
          feeMax: '',
          note: '',
        }]
      },
      // 分段类型
      feeFdTypeList: [
        {
          label: '定额分段',
          value: '11'
        },
        {
          label: '按比例分段',
          value: '21'
        }
      ],
      // 按分段，按比例-
      addrulescalerules: {
        feeName: [
          { required: true, message: '请输入规则名称', trigger: 'blur' },
        ],
        feeType: [
          { required: true, message: '不能为空', trigger: 'change' },
        ],

      },
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
            `${index + 1
            }`,
          align: 'center',
        },

        {
          title: '规则名称',
          dataIndex: 'feeName',
          align: 'center',
        },

        {
          title: '规则编号',
          dataIndex: 'feeNo',
          align: 'center',

        },
        {
          title: '服务费类型',
          dataIndex: 'feeType',
          align: 'center',
          customRender: (text) => {
            if (Number(text) == 11) {
              return '定额分段 '
            }
            if (Number(text) == 21) {
              return '按比例分段 '
            }
          }

        },

        {
          title: '备注',
          dataIndex: 'note',
          align: 'center',

        },
        {
          title: '操作',
          align: 'center',
          key: 'Controls',
          width: 200,
          scopedSlots: { customRender: 'Controls' },
        },
      ],
      // 保存弹框
      confirmDialog: false,
      modalText: '',
      confirmText: '保存',
      saveType: '',
      delOrEditIndex: '',//删除和编辑的索引,
      isEdit: false,
    }
  },
  computed: {

  },
  async created() {

    // this.getsalePlatformInfolist()

    if (this.$route.query.type === "add") {
      this.title = ' 新增'
    } else if (this.$route.query.type == "edit") {
      this.getFeeRulesDetails()
      this.title = ' 编辑'
    } else if (this.$route.query.type == "view") {
      this.title = ' 查看'
      this.getFeeRulesDetails()
    }
    // 加载选中客户信息
    if (this.$route.params.type === "add") {
      this.onCustChosen({ custnum: this.$route.params.custnum })
    }
  },
  mounted() {
    if (this.$route.query.type == 'edit' || this.$route.query.type == 'view') {
      sessionStorage.setItem('msgInfo', true)
    }
  },
  methods: {
    // 新增服务费规则
    addrule() {
      // 清空数据
      this.addScaleForm = {
        feeName: '',
        feeType: "11",
        rangeList: [{
          fixedFee: '',
          feeProportion: '',
          feeMin: '',
          feeMax: '',
          note: '',
        }]
      }
      // 打开弹框
      this.addruleshowscale = true
    },
    // 添加和删除区间
    addRangeData(index, type) {
      if (type == '1') {
        // 添加
        let obj = {
          fixedFee: '',
          feeProportion: '',
          feeMin: '',
          feeMax: '',
          note: '',
        }
        this.addScaleForm.rangeList.splice(index, 0, obj)
      } else if (type == '2') {
        // 删除
        this.addScaleForm.rangeList.splice(index, 1)
      }

    },
    // 新增规则保存
    isSaveAddFeeRule() {
      // form弹框，校验
      this.$refs.addruleForm.validate((valid) => {
        if (valid) {
          // 校验通过
          // 按分段
          if (this.isEdit) {
            // 编辑
            console.log('edit');
            // console.log(this.addScaleForm, 'this.addScaleForm');
            this.addScaleForm.rangeList.forEach(item => {
              let obj = {
                ...item,
                feeName: this.addScaleForm.feeName,
                feeType: this.addScaleForm.feeType,
              }
              this.form.feeFdRules.splice(this.delOrEditIndex, 1, obj)
            })
          } else {
            console.log('add');
            this.addScaleForm.rangeList.forEach(item => {
              let obj = {
                ...item,
                feeName: this.addScaleForm.feeName,
                feeType: this.addScaleForm.feeType,
              }
              this.form.feeFdRules.push(obj)
            })
          }

          this.addruleshowscale = false

        } else {
          this.$message.warning('请先完成填写')
        }
      })
    },
    // 店铺确认提示弹窗
    confirm() {
      // 表单校验
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmDialog = true
          this.modalText = '是否确认新增服务费信息?'
          this.saveType = 'saveForm'
        } else {
          this.$message.warning('请先完成信息填写')
        }
      })
    },
    cancel() {
      this.confirmDialog = true
      this.modalText = '是否取消新增？取消后该页面信息将不会被保存'
      this.confirmText = '确认'
      this.saveType = 'isTopCancel'
    },
    // 弹框确认事件
    saveHandle() {
      if (this.saveType == 'saveForm') {
        // 保存新增数据，调用保存接口
        this.feeRuleAdd()
        this.confirmDialog = false
      } else if (this.saveType == 'isTopCancel') {
        this.$router.push('/base/servicechargeallocation')
      } else if (this.saveType == 'delete') {
        this.form.feeFdRules.splice(this.delOrEditIndex, 1)
        this.confirmDialog = false
      }
    },
    // 弹框取消事件
    cancelHandle() {
      this.confirmDialog = false
    },
    async feeRuleAdd() {
      let data = {}
      if (this.form.feeType == '1') {
        // 定额
        data.fixedFee = this.form.fixedFee
      } else if (this.form.feeType == '2') {
        // 按比例
        data.feeProportion = this.form.feeProportion
        data.feeMax = this.form.feeMax
        data.feeMin = this.form.feeMin
      } else if (this.form.feeType == '4') {
        data.feeProportion = this.form.feeProportion
      }
      if (this.$route.query.type == 'edit') {
        data.id = this.$route.query.id
      }
      data.feeName = this.form.feeName
      data.feeNo = this.form.feeNo
      data.feeType = this.form.feeType
      data.note = this.form.note
      data.feeFdRules = this.form.feeFdRules
      let res = await this.api.feeRuleaddApi(data)
      if (res.code == 200) {
        this.$router.push('/base/servicechargeallocation')
      } else {
        return this.$message.error(res.info)
      }
    },
    // 编辑
    Editing(item, index) {
      // console.log(item, index);
      this.addruleshowscale = true
      let obj = JSON.parse(JSON.stringify(item))
      let newForm = {
        feeName: obj.feeName,
        feeType: obj.feeType,
      }
      newForm.rangeList = [{
        fixedFee: obj.fixedFee,
        feeProportion: obj.feeProportion,
        feeMin: obj.feeMin,
        feeMax: obj.feeMax,
        note: obj.note,
      }]
      // 回显
      this.addScaleForm = newForm
      this.isEdit = true
      this.delOrEditIndex = index

    },
    // 删除
    Deleterow(item, index) {
      this.confirmDialog = true
      this.modalText = '是否删除该条数据？'
      this.confirmText = '确认'
      this.saveType = 'delete'
      this.delOrEditIndex = index
    },
    async getFeeRulesDetails() {
      // console.log(this.$route.query.id);
      let res = await this.api.feeRuleDetailsApi(this.$route.query.id)
      if (res.code == 200) {
        console.log(res);
        this.form = res.data
      } else {
        return this.$message.error(res.info)
      }
    }
  },

}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>

<style lang="less" scoped>
.range-header {
  display: flex;
  justify-content: space-between;
  color: #595959;

}

.range-container {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 8px;
  margin: 8px 0;
  position: relative;

  .mark {
    color: #f5222d;
    position: absolute;
    top: 20px;
    left: 8px;
    margin-right: 8px;
  }

  /deep/ .num {
    .ant-form-item-label {
      padding-left: 10px;
    }
  }



}

.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

/deep/ .ant-modal-header {
  height: 40px;
  padding: 0px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 700;

}

.saveloading {
  /deep/ .ant-modal-footer {
    border: none;
  }

  .text {
    text-align: center;
  }
}

.Controlsbox {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: none;

    &:first-child {

      color: #57c6f4,
    }

    &:last-child {
      margin-left: 20px;
      color: #d9001b,
    }
  }
}

.addform {
  /deep/.ant-form-item-label {
    text-align-last: left;
  }

  /deep/.ant-form-item {
    margin-bottom: 10px;
  }



}

/deep/.ant-modal-close-x {
  height: 40px;
  line-height: 40px;
}

/deep/.ant-card-head {
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #fff;
}

/deep/.ant-modal-content {
  width: 600px;
}

.formbox {
  .toptitle {
    font-size: 15px;
    font-weight: 700;
    height: 38px;
    color: #262626;
  }
}

.Anchor {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  .tablebox {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .ant-tag {
      cursor: pointer;
    }
  }

}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      &:last-child {
        font-size: 15px;
      }
    }
  }

  .right {
    button {
      height: 38px;
      border: none;

      &:first-child {
        width: 108px;

        color: #ffff;
      }

      &:last-child {
        width: 108px;
        background-color: #d7d7d7;
        color: #ffff;
      }
    }
  }
}

.Electronicbusiness {
  padding-bottom: 100px;

  .departmentId {
    /deep/ .ant-form-item-control-wrapper {
      border-top: 1px solid #d7d7d7;
    }
  }


  /deep/ .ant-form-item-label {
    min-height: 45px;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  /deep/.ant-input-suffix {
    right: 0;
    width: 40px;
  }



  /deep/ .ant-form-item {
    background-color: #f2f2f2;
    min-height: 50px;
    margin-bottom: 0px !important;
  }

  /deep/ .ant-form-item-control-wrapper {
    padding-left: 35px;
    padding-left: 20px;
    background-color: #fff;
    border-bottom: 1px solid #d7d7d7;
    border-left: none;
    display: flex;
    min-height: 50px;
    align-items: center;

    .ant-form-item-control {
      min-width: 200px;

    }
  }


}
</style>
