<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>汇总订单</span> <a-icon type="right" />
            <span style="font-weight: 500">修订记录</span>
          </div>
        </div>
      </template>
      <template slot="extra">
        <a-button @click="toBack">
          返回
        </a-button>
      </template>
      <div class="formbox">
        <!-- 订单编号 -->
        <div class="inputbox">
          <div class="lefttitle">订单编号</div>
          <a-input v-model.trim="form.orderNo" placeholder="请输入订单编号" />
        </div>

        <!-- 订单状态 -->
        <div class="inputbox">
          <div class="lefttitle">订单状态</div>
          <a-select placeholder="请选择订单状态" v-model.trim="form.orderStatus" style="width: 180px">
            <a-select-option v-for="item in orderStatuslist" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>

        <!-- 清算状态 -->
        <div class="inputbox">
          <div class="lefttitle">清算状态</div>

          <a-select placeholder="请选择清算状态" v-model.trim="form.settleStatus" style="width: 180px">
            <a-select-option v-for="(item, index) in settleStatuslist" :key="index" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>

        <!-- 同步状态 -->
        <div class="inputbox">
          <div class="lefttitle">同步状态</div>

          <a-select placeholder="请选择清算状态" v-model.trim="form.synStatus" style="width: 180px">
            <a-select-option v-for="item in synStatuslist" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <!-- 查询 -->

        <a-button @click="search" type="primary"> 查询 </a-button>
        <!-- 重置 -->
        <a-button @click="reset" class="reset">重置</a-button>
      </div>
      <!-- 同步 -->
      <div class="synchronization">
        <a-button @click="synchronization" type="primary">同步 </a-button>
      </div>
      <!-- 修改记录查看 -->
      <div class="tablebox">
        <!-- 表格 -->
        <div class="tableBody">
          <a-table ref="table" :row-selection="{
            selectedRowKeys: selectedRowKeys,
            hideDefaultSelections: true,
            onChange: onSelectChange,
          }" :scroll="{ x: 3500, }" :rowKey="(record, index) => `${record.id}`" :customRow="clickrow"
            :columns="columns" :data-source="data" :pagination="false">
          </a-table>
        </div>
      </div>

      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
          首页
        </a-button> <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
          show-size-changer :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
          :item-render="itemRender">
          <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
        </a-pagination>
        <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
          class="Trailingpage">
          尾页
        </a-button>
      </div>
    </a-card>
    <!-- 同步 -->
    <a-modal title="提示" :maskClosable="true" :centered="true" :visible="synchronizationvisible"
      @cancel="synchronizationvisible = false">
      <div class="synchronizationvisible">
        <h3>是否确认同步？</h3>
      </div>
      <template v-slot:footer>
        <div class="SecuritydepositSuccessfulcustom-footer">
          <a-button type="primary" @click="confirm">确认</a-button>
          <a-button @click="synchronizationvisible = false" class="reset">取消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Balance from '@/view/components/Balance.vue'
import dayjs from 'dayjs'
export default {
  components: {
    Balance
  },
  data() {
    return {
      selectedRows: [], // 选数数组的值,
      synchronizationvisible: false,
      selectedRowKeys: [],
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      total: 0, // 数量
      form: {
        pageSize: 10,
        pageNo: 1,
        orderNo: '', // 订单编号
        orderStatus: undefined, // 订单状态
        settleStatus: undefined, // 清算状态
        synStatus: undefined, // 同步状态
      },
      // 订单状态
      orderStatuslist: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '已登记',
          value: '0',
        },
        {
          label: '已修正',
          value: '1',
        },
        {
          label: '已结算',
          value: '2',
        },
        {
          label: '已完结',
          value: '9',
        },
      ],
      // 	清算状态
      settleStatuslist: [
        {
          label: '未清算 ',
          value: '0',
        },

        {
          label: '已清算并还款',
          value: '1',
        },
        {
          label: '清算失败',
          value: '2',
        },
      ],
      // 同步状态
      synStatuslist: [
        {
          label: '未同步 ',
          value: '0',
        },
        {
          label: '已同步',
          value: '1',
        },
      ],
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
            `${Number(this.form.pageNo - 1) * Number(this.form.pageSize) +
            Number(index + 1)
            }`,
          align: 'center',
        },
        {
          title: '订单编号',
          dataIndex: 'orderNo',
          align: 'center',
          customRender: (text) => (text ? text : '---'),
        },
        {
          title: '订单类型',
          dataIndex: 'orderType',
          align: 'center',
          customRender: (text) => {
            if (Number(text) == 0) {
              return '汇总订单'
            } else if (Number(text) == 1) {
              return '特殊汇总订单'
            } else if (Number(text) == 3) {
              return '资金类汇总订单'
            } else {
              return text
            }
          },
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatus',
          align: 'center',
          customRender: (text) => {
            if (Number(text) == 0) {
              return '已登记'
            } else if (Number(text) == 1) {
              return '已修正'
            } else if (Number(text) == 2) {
              return '已结算'
            } else if (Number(text) == 9) {
              return '已完结'
            } else {
              return text
            }
          },
        },
        {
          title: '订单总额',
          dataIndex: 'orderAmount',
          align: 'center',
          customRender: (text) => {
            return <Balance amount={text}></Balance>

          },
        },
        {
          title: '已发货未收款总额',
          dataIndex: 'orderAmount1',
          align: 'center',
          customRender: (text) => {
            return <Balance amount={text}></Balance>

          },
        },
        {
          title: '预计退款总额',
          dataIndex: 'orderAmount2',
          align: 'center',
          customRender: (text) => {
            return <Balance amount={text}></Balance>

          },
        },
        {
          title: '预计应收款总额',
          dataIndex: 'orderAmount3',
          align: 'center',
          customRender: (text) => {
            return <Balance amount={text}></Balance>

          },
        },
        {
          title: '实际退款总额',
          dataIndex: 'orderAmount4',
          align: 'center',
          customRender: (text) => {
            return <Balance amount={text}></Balance>

          },
        },
        {
          title: '订单融资额度',
          dataIndex: 'creditLimit',
          align: 'center',
          customRender: (text) => {
            return <Balance amount={text}></Balance>

          },
        },
        {
          title: '放款总额',
          dataIndex: 'loanAmountSum',
          align: 'center',
          customRender: (text) => {
            return <Balance amount={text}></Balance>

          },
        },
        {
          title: '累计已补足差额',
          dataIndex: 'supDiffAmount',
          align: 'center',
          customRender: (text) => {
            return <Balance amount={text}></Balance>

          },
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center',
          customRender: (text) => (text ? text : '---'),
        },
        {
          title: '清算状态',
          dataIndex: 'settleStatus',
          align: 'center',
          customRender: (text) => {
            if (Number(text) == 0) {
              return '未清算'
            } else if (Number(text) == 1) {
              return '已清算并还款 '
            } else if (Number(text) == 2) {
              return '清算失败'
            } else {
              return text
            }
          },
        },
        {
          title: '清算时间',
          dataIndex: 'settleTime',
          align: 'center',
          customRender: (text) => (text ? text : '---'),
        },
        {
          title: '操作类型',
          dataIndex: 'operationType',
          align: 'center',
          customRender: (text) => {
            if (Number(text) == 0) {
              return ' 登记'
            } else if (Number(text) == 1) {
              return '修正'
            } else if (Number(text) == 2) {
              return '作废'
            } else {
              return text
            }
          },
        },

        {
          title: '同步状态',
          dataIndex: 'channelNo',
          align: 'center',
          customRender: (text) => {

            if (Number(text) == 0) {
              return ' 未同步'
            } else if (Number(text) == 1) {
              return '已同步'
            } else {
              return text
            }
          },
        },
        {
          title: '同步时间',
          dataIndex: 'synTime',
          align: 'center',
          customRender: (text) => {
            return text != null ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '---'
          }
        },
        {
          title: '同步结果信息',
          dataIndex: 'synResultMsg',
          align: 'center',
          customRender: (text) => {
            return text != null ? text : '---'
          }
        },
      ],

      data: [],
    }
  },
  created() {
    this.form.orderNo = this.$route.query.orderNo
    this.getcollectordersRecordsApi()
  },
  mounted() {
    sessionStorage.setItem('msgInfo', true)
  },
  methods: {
    // 获取table列表
    async getcollectordersRecordsApi() {
      const res = await this.api.collectordersrevisionnRecordListApi(this.form)

      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }

      this.total = res.data.total
      this.form.pageNo = res.data.current
      this.form.pageSize = res.data.size

      this.data = res.data.records
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = 1,
        this.getcollectordersRecordsApi()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = Math.ceil(Number(this.total) / Number(this.form.pageSize))
      this.getcollectordersRecordsApi()
    },
    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      if (selectedRows.length > 1) {
        //获取选中的数据的key
        var selectNumber = this.selectedRowKeys[1]
        //清空选中的key
        this.selectedRowKeys = []
        //选中的数据的key重新赋值给selectedRowKeys
        this.selectedRowKeys.push(selectNumber)
      }
      //获取选中的数据详细信息
      this.selectedRows = [selectedRows[0]]
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            let index = this.selectedRowKeys.findIndex((item) => id == item)
            if (index == -1) {
              this.selectedRowKeys = [id]
              this.selectedRows = [record]
            } else {
              this.selectedRowKeys = []
              this.selectedRows = []
            }
          }
        }
      }
    },
    pagechange(val) {
      this.form.pageNo = val
      this.getcollectordersRecordsApi()
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.form.pageSize = pageSize
      this.getcollectordersRecordsApi()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    search() {
      this.getcollectordersRecordsApi()
    },
    reset() {
      this.form = {
        pageSize: 10,
        pageNo: 1,
        orderNo: this.$route.query.orderNo, // 订单编号
        orderStatus: undefined, // 订单状态
        settleStatus: undefined, // 清算状态
        synStatus: undefined, // 同步状态
      }
      this.getcollectordersRecordsApi()
    },
    // 同步
    synchronization() {
      if (this.selectedRows?.length == 0 || !this.selectedRows) {
        this.$message.warning('请先选择账户')
        return
      }
      // this.synchronizationvisible = true
      this.confirm()
    },
    // 确认同步
    async confirm() {
      const res = await this.api.collectordersorderSynApi(
        this.selectedRows[0].orderNo
      )
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$message.success('同步成功')
      // this.synchronizationvisible = false
      this.getcollectordersRecordsApi()
    },
    toBack() {
      this.$router.back(-1)
    }
  },
}
</script>

<style lang="less" scoped>
.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-table-thead>tr>th {
    background-color: #f2f2f2;
  }

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .right {
    button {
      height: 24px;
    }
  }
}

.formbox {
  display: flex;

  flex-wrap: wrap;

  .inputbox {
    width: 260px;
    margin-right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    input {
      width: 180px;
    }
  }

  button {
    margin-bottom: 20px;
  }

  .reset {
    width: 64px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
}

.tablebox {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.paginationBox {
  display: flex;
  justify-content: flex-end;

  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}

.synchronization {
  margin-bottom: 20px;
  margin-top: 30px;
}

/deep/ .ant-card-head-title {
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .dianshang {
      margin-left: 50px;
    }
  }
}

/deep/.ant-modal-content {
  width: 420px;
}

.synchronizationvisible {
  h3 {
    padding: 0 20px;
    // margin-bottom: 20px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
