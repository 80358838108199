<template>
  <div>
    <a-input placeholder="请选择钱包" v-model="chosenShowName" disabled>
      <a-button slot="suffix" type="primary" :disabled="$route.query.type == 'view' || isDisabled"
        @click="startChoose">选择</a-button>
    </a-input>
    <a-modal v-if="choosing" :visible="true" title="请选择钱包" :footer="null" @cancel="onCancel" width="1200">
      <div class="table-header">
        <div class="header-item">
          <a-form layout="inline">
            <a-form-item label="所属机构">
              <DepartmentChoose :department-code.sync="form.departmentCode" :disabled="!searchable" @change="changeDepartment"></DepartmentChoose>
            </a-form-item>
            <!--            <a-form-item label="客户编号">-->
            <!--              <a-input v-model="custnum" :disabled="!searchable"></a-input>-->
            <!--            </a-form-item>-->
            <a-form-item label="客户名称">
              <a-input v-model="form.custname" :disabled="!searchable"></a-input>
            </a-form-item>
            <a-form-item label="钱包编号">
              <a-input v-model="form.accountNo" :disabled="!searchable"></a-input>
            </a-form-item>
            <a-form-item label="手机号">
              <a-input v-model="form.mobile" :disabled="!searchable"></a-input>
            </a-form-item>
            <a-form-item label="钱包类型" v-if="openAccountClass">
              <a-select default-value="" v-model="form.accountClass" :disabled="!searchable">
                <a-select-option value="">所有</a-select-option>
                <a-select-option value="0">活期</a-select-option>
                <a-select-option value="6">冻结</a-select-option>
                <a-select-option value="7">贷款</a-select-option>
                <a-select-option value="8">分户</a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </div>
        <div class="header-item">
          <a-button type="primary" @click="onSearch">查询</a-button>
          <a-button @click="onReset">重置</a-button>
        </div>
      </div>
      <div class="table-main">
        <a-table :columns="columns" :data-source="data" :pagination="false" rowKey="accountNo" size="small">
          <span slot="action" slot-scope="text, record">
            <a-button @click="onChoose(record, text)" type="primary">选择</a-button>
          </span>
        </a-table>
      </div>
      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-pagination v-model="pageNo" :total="total" :page-size="pageSize" @change="onPagesizeChange">
        </a-pagination>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {BizDict} from "@/utils/bizDict/kvMap";
import WalletInfo from "@/view/components/WalletInfo.vue";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";

export default {
  name: 'WalletChooseDialog',
  components: {DepartmentChoose, WalletInfo },
  model: {
    prop: 'chosenShowName',
    event: 'change'
  },
  props: {
    scope: {
      required: false,
      type: Object,
    },
    chosenShowName: {
      required: false,
      type: String,
    },
    departmentCode: { // 机构
      required: false,
      type: String,
    },
    accountClass: {
      required: false,
      type: String,
    },
    openAccountClass: {
      required: false,
      type: Boolean,
      default: false,
    },
    custnum: { // 客户号
      required: false,
      type: String,
    },
    custname: { // 客户名称
      required: false,
      type: String,
    },
    accountNo: { // 钱包编号
      required: false,
      type: String
    },
    accountProperty: {// 1:个人 2:对公
      required: false,
      type: String,
    },
    searchable: { // 是否可以自定义搜索
      required: false,
      type: Boolean,
      default: true,
    },
    isDisabled: {
      required: false,
      type: Boolean,
    }
  },
  data() {
    const columns = [
      {
        title: '客户类型',
        dataIndex: 'accountProperty',
        align: 'center',
        customRender: (text) => this.CstTp.find(i => i.value === text)?.label
      },
      {
        title: '客户名称',
        dataIndex: 'accountName',
        align: 'center',
      },
      {
        title: '钱包编号',
        dataIndex: 'accountNo',
        align: 'center',
        customRender: (text, row) => {
          return <WalletInfo accountName={row.accountName} accountNo={row.accountNo} accountClass={row.accountClass}></WalletInfo>
        }
      },
      {
        title: '手机号',
        dataIndex: 'mobile',
        align: 'center',
        width: 120,
        customRender: (text) => {
          let starizedText = text.replace(/^(.{3}).+(.{4}$)/, "$1****$2")
          return <a-tooltip><template slot="title"> {{ text }} </template> {starizedText} </a-tooltip>
        }
      },
      {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        align: 'center',
      },
    ]
    const data = []
    return {
      choosing: false,
      CstTp: BizDict.CstTp,
      mobile: '',
      pageNo: 1,
      pageSize: 10,
      total: 0,
      columns,
      data,
      form: {
        pageNo: 1,
        pageSize: 10,
        departmentCode: undefined,
        custnum: '',
        custname: "",
        accountNo: '',
        accountName: '',
        accountClass: "",
        accountProperty: '',
        mobile: '',
      }
    }
  },
  created() {

  },
  methods: {
    startChoose() {
      this.form.departmentCode = this.departmentCode
      this.form.accountClass = this.accountClass
      this.form.custnum = this.custnum
      this.form.custname = this.custname
      this.form.accountNo = this.accountNo
      this.form.accountName = this.accountName
      this.form.accountProperty = this.accountProperty
      // console.log(this.form);
      this.choosing = true
      // 加载客户列表
      this.loadTableData()
    },
    // 获取列表信息page
    async loadTableData() {
      let queryParam = this.form
      let res = await this.api.custaccountPageApi(queryParam)

      if (res.code === 200) {
        this.data = res.data.records
        this.pageNo = res.data.current
        this.pageSize = res.data.size
        this.total = res.data.total
      } else {
        return this.$message.error(res.info)
      }
    },
    // 点击选择
    onChoose(record) {
      this.choosing = false
      this.$emit("change", record.accountName)
      this.$emit('chooseDone', record, this.scope)
    },
    // 分页事件
    onPagesizeChange(page) {
      this.form.pageNo = page
      this.loadTableData()
    },
    onSearch() {
      this.form.pageNo = 1
      this.loadTableData()
    },
    changeDepartment() {
      this.form.pageNo = 1
      this.loadTableData()
    },
    onReset() {
      this.form.departmentCode = undefined
      this.form.custname = ''
      this.form.accountNo = ''
      this.form.mobile = ''
      this.loadTableData()
    },
    //取消事件
    onCancel() {
      this.choosing = false
      this.$emit('chooseCancel')
    },
  },
}
</script>

<style lang="less" scoped>
.table-hide {
  display: none;
}

.table-show {
  display: revert;
}

/deep/ .ant-modal {
  width: 1200px;

  .ant-modal-content {
    width: 100%;
  }
}

/deep/ .table-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;

  .ant-select-selection--single {
    width: 200px;
  }


}

.paginationBox {
  margin: 10px 0;
  display: flex;
  justify-content: end;
}

/deep/ .ant-input-suffix {
  width: 0;
}
</style>
