export const getFormatTime = (time, format) => {
  // console.log(time,'date')
  if (!time) {
    return ''
  }
  let date = new Date(time)
  let year = date.getFullYear()
  let month = (date.getMonth() + 1 + '').padStart(2, '0')
  let day = (date.getDate() + '').padStart(2, '0')
  let hour = (date.getHours() + '').padStart(2, '0')
  let minute = (date.getMinutes() + '').padStart(2, '0')
  let second = (date.getSeconds() + '').padStart(2, '0')
  if (format == 'yyyy-mm') {
    return `${year}-${month}`
  } else if (format == 'yyyy-mm-dd') {
    return `${year}-${month}-${day}`
  } else if (format == 'yyyy-mm-dd HH:mm:ss') {
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
  }
  //以此类推，是哪种格式就把哪种格式写上去
}
// 处理账户状态的方法
export const handleAccountStatus = (val) => {
  switch (val) {
    case '0':
      return '正常'
    case '1':
      return '删除'
    case '2':
      return '挂失'
    case '3':
      return '锁定 '
    case '4':
      return '销户'
    case '5':
      return '不动户'
    case '6':
      return '睡眠户'
    case '7':
      return '止付'
    case '8':
      return '证件过期'
    default:
      return '---'
  }
}
// 处理客户类型细分的方法
export const handleCustTypeSub = (val) => {
  switch (val) {
    case '0101':
      return '法人企业'
    case '0102':
      return '非法人企业'
    case '0103':
      return '经营性个人客户'
    case '0104':
      return '锁事业单位'
    case '0105':
      return '社会团体'
    case '0106':
      return '党政机关'
    case '0107':
      return '金融机构'
    case '0199':
      return '其他'
    case '0201':
      return '单一集团客户'
    case '0202':
      return '综合集团客户'
    case '0203':
      return '准集团客户'
    case '0204':
      return '联保体客户'
    case '03':
      return '消费类个人客户'
    case '04':
      return '个体工商户'
    case '05':
      return '同业客户'
    case '06':
      return '专业市场客户'
    case '07':
      return '产/行业集群'
    case '08':
      return '网格化商圈'
    default:
      return '-----'
  }
}
// 处理渠道方法
export const handleChannelNo = (arr, channelNum, type) => {
  if (arr.length === 0) {
    return '---'
  }
  for (let i = 0; i < arr.length; i++){
    if (type === '1') {
      
      // 渠道
      if (arr[i].channelNo === channelNum) {
        return arr[i].channelName
      }
    } else if (type === '2') {
      //应用
      if (arr[i].appNo === channelNum) {
        return arr[i].channelName
      }
    }
  }
}

/**
 * 求迪卡尔积
 * @param arr
 * @returns {*|(function(*, *): *[])}
 */
export const cartesian = (arr) => {
  return [].reduce.call(arr, function (col, set) {
    let res = [];
    col.forEach(c => {
      set.forEach(s => {
        let t = [].concat(Array.isArray(c) ? c : [c]);
        t.push(s);
        res.push(t);
      })
    });
    return res;
  });
}

/**
 * 将数组根据字段进行合并计算
 *  如以下数据list=[{"姓名":"张三","年龄":18,"性别":"男"},{"姓名":"李四","年龄":19,"性别":"女"},{"姓名":"王五","年龄":19,"性别":"男"}]
 *  如计算不同性别人的年龄之和、人数：groupBy(list, ["性别"],{count:["姓名"],sum:["年龄"] }
 *  返回：  [{"性别":"男","合计#年龄":37,"计数#姓名":2},{"性别":"女","合计#年龄":19,"计数#姓名":1}]
 * @param list 数组,
 * @param groupFields 分组字段
 * @param aggrFields 汇聚字段
 * @param compact 是否删除不存在值的分组记录
 * @returns {*[]}
 */
export const groupBy = (list, groupFields, aggrFields, compact=false) =>{
  let sumFields = aggrFields['sum']||[]
  let countFields = aggrFields['count']||[]
  let maxFields = aggrFields['max']||[]
  let minFields = aggrFields['min']||[]
  let calculated = list.reduce((acc, cur)=>{
    // 为方便计算，把多个分组字段合并成一个字段
    let key = groupFields.map(field=>cur[field]).join("#")
    let accVal = acc[key]??{}
    sumFields.forEach(field=>{
      accVal["合计#"+field] = (cur[field]??0) + (accVal["合计#"+field]??0)
    })
    countFields.forEach(field=>{
      accVal["计数#"+field] = 1 + (accVal["计数#"+field]??0)
    })
    maxFields.forEach(field=>{
      accVal["最大值#"+field] = Math.max(cur[field]??Number.MIN_VALUE , accVal["最大值#"+field]??Number.MIN_VALUE)
    })
    minFields.forEach(field=>{
      accVal["最小值#"+field] = Math.min(cur[field]??Number.MAX_VALUE , accVal["最小值#"+field]??Number.MAX_VALUE)
    })
    acc[key] = accVal
    return acc
  }, {})
  let result = []
  let keys = []
  if(compact){
    keys = Object.keys(calculated)
  }else{
    keys = cartesian(groupFields.map(field=>Array.from(new Set(list.map(item=>item[field])))))
    .map(arr=>arr.join("#"))
  }
  for(let o in keys){
    let obj = {}
    let groupValues = keys[o].split("#")
    for(let j=0, l=groupFields.length; j<l; j++){
      obj[groupFields[j]] = groupValues[j]
    }
    result.push(Object.assign(obj, calculated[keys[o]]))
  }
  return result
}

export const generateRandomString = (length)=>{
  const characters='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  let result = ''
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random()*characters.length)
    result += characters.charAt(randomIndex)
  }
  return result
}