<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>汇总订单管理</span>
          </div>
          <div class="right">
            <!-- 所属机构 -->
            <DepartmentChoose :department-code.sync="form.departmentCode" @change="departmentIdchange"></DepartmentChoose>

            <!--选择商户-->
            <a-select class="dianshang" placeholder="请选择商户" :dropdownMatchSelectWidth="false" v-model.trim="form.mchNo"
              :filter-option="filterOption" :show-search="true" @change="mchChange"
              style="min-width: 300px;margin-right:50px" allowClear>
              <a-select-option v-for="item  in mchNolist" :title="item.label" :key="item.value" :value="item.value">
                <span><a-tag color="purple">融</a-tag>{{ item.value }}-{{ item.label }}<br /><a-tag color="blue"
                    v-if="item.fatherName">控</a-tag>{{ item.fatherName || "" }}</span>
              </a-select-option>
            </a-select>
          </div>
        </div>
      </template>

      <!-- 查询 -->
      <div class="formbox">
        <!-- 商品类型 -->
        <div class="inputbox">
          <div class="lefttitle">商品类型</div>
          <a-select placeholder="请选择商品类型" v-model.trim="form.goodsType" style="width: 200px" allowClear>
            <a-select-option v-for="item in goodsTypelist" :key="item.value" :value="item.value">
              <a-tag :color="item.color">{{ item.label }}</a-tag>
            </a-select-option>
          </a-select>
        </div>
        <!-- 清算状态 -->
        <div class="inputbox">
          <div class="lefttitle">清算状态</div>
          <a-select placeholder="请选择清算状态" v-model.trim="form.settleStatus" style="width: 200px" allowClear>
            <a-select-option v-for="item in settleStatuslist" :key="item.value" :value="item.value">
              <a-tag :color="item.color">{{ item.label }}</a-tag>
            </a-select-option>
          </a-select>
        </div>
        <div class="inputbox">
          <div class="lefttitle">订单日期</div>
          <a-date-picker style="width: 200px" v-model.trim="form.orderDate" valueFormat="YYYY-MM-DD"
                         placeholder="请选择订单日期" />
        </div>
        <div class="inputbox">
          <div class="lefttitle">计划清算日</div>
          <a-date-picker style="width: 200px" v-model.trim="form.estSettleDate" valueFormat="YYYY-MM-DD"
                         placeholder="请选择计划清算日期" />
        </div>
        <div class="inputbox">
          <div class="lefttitle">实际清算日</div>
          <a-date-picker style="width: 200px" v-model.trim="form.settleTime" valueFormat="YYYY-MM-DD HH:mm:ss"
                         placeholder="请选择实际清算日期" />
        </div>

        <!-- 订单类型 -->
<!--        <div class="inputbox">-->
<!--          <div class="lefttitle">订单类型</div>-->
<!--          <a-select placeholder="请选择订单类型" v-model.trim="form.orderType" style="width: 200px" allowClear>-->
<!--            <a-select-option v-for="item in orderTypelist" :key="item.value" :value="item.value">-->
<!--              {{ item.label }}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
<!--        </div>-->
        <!-- 订单状态 -->
<!--        <div class="inputbox">-->
<!--          <div class="lefttitle">订单状态</div>-->
<!--          <a-select placeholder="请选择订单状态" v-model.trim="form.orderStatus" style="width: 200px" allowClear>-->
<!--            <a-select-option v-for="item in orderStatuslist" :key="item.value" :value="item.value">-->
<!--              {{ item.label }}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
<!--        </div>-->

        <!-- 订单编号 -->
        <div class="inputbox">
          <div class="lefttitle">订单编号</div>
          <a-input v-model.trim="form.orderNo" placeholder="请输入订单编号" />
        </div>
        <!-- 贷款人 -->
        <div class="inputbox">
          <div class="lefttitle">贷款人</div>
          <a-input v-model.trim="form.lender" placeholder="请输入贷款姓名" />
        </div>
        <!-- 查询 -->
        <a-button @click="search" type="primary"> 查询 </a-button>
        <!-- 重置 -->
        <a-button @click="reset" class="reset">重置</a-button>
      </div>
      <!-- 订单导出 -->
      <div class="Toleadinto">

<!--        <a-button @click="specialSummaryorder" type="primary">-->
<!--          追加特殊订单-->
<!--        </a-button>-->
        <!--        <a-button @click="Modifiedsummaryrecord" type="primary">-->
        <!--          修正汇总订单-->
        <!--        </a-button>-->
        <a-button-group>
          <a-button @click="view" type="normal" :disabled="selectedRows.length!==1" v-if="$authority('summaryOrderView')" icon="eye"> 查看 </a-button>
<!--          <a-button @click="settleAll" type="primary" v-if="$authority('settleAll')">-->
<!--            批量清算-->
<!--          </a-button>-->
        </a-button-group>

        <a-button-group>
          <a-button @click="Liquidationandrepayment" :disabled="selectedRows.length!==1" type="normal" v-if="$authority('summaryOrderClearingRepayment')">清算详情</a-button>
          <a-button @click="settle" type="primary" :disabled="selectedRows.length!==1" icon="transaction" v-if="$authority('settleAll')">清算</a-button>
        </a-button-group>

        <a-button-group>
          <a-button @click="Generatesummaryorder" :disabled="!form.mchNo" icon="folder-add" type="primary">追加</a-button>
          <a-button @click="orderToCancel" :disabled="selectedRows.length!==1" type="danger" icon="delete" v-if="$authority('summarizeOrderCancel')">作废</a-button>
        </a-button-group>

        <a-button-group style="float:right">
          <a-button type="link" @click="Revisionrecord" :disabled="selectedRows.length!==1" v-if="$authority('summarizeOrderRevisionRecords')" icon="history"> 修订记录 </a-button>
          <a-button type="link" icon="download" @click="derive" :disabled="selectedRows.length!==1" v-if="$authority('summaryOrdersExportOrders')">订单</a-button>
          <a-button type="link" icon="download" @click="deriveItem" :disabled="selectedRows.length!==1" v-if="$authority('summaryOrdersExportItemOrders')">明细</a-button>
        </a-button-group>
        <!-- 不要忘记修改权限 -->
      </div>

      <div class="tablebox">
        <!-- 表格 -->
        <div class="tableBody">
          <a-table :scroll="{ x: 2700 }" size="small" ref="table" :row-selection="{
            selectedRowKeys: selectedRowKeys,
            hideDefaultSelections: true,
            onChange: onSelectChange,
          }" :rowKey="(record, index) => `${record.id}`" :customRow="clickrow" :columns="columns" :data-source="data"
            :pagination="false">
          </a-table>
        </div>
      </div>

      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
          首页
        </a-button> <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
          show-size-changer :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
          :item-render="itemRender">
          <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
        </a-pagination>
        <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
          class="Trailingpage">
          尾页
        </a-button>
      </div>

    </a-card>
  </div>
</template>

<script>
import { handleChannelNo } from '@/utils/commonMethods'
import Balance from '@/view/components/Balance.vue'
import dayjs from "dayjs";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
export default {
  name: "summaryOrderPage",
  components: {DepartmentChoose, Balance },
  data() {
    return {
      selectedRows: [], // 选数数组的值,
      selectedRowKeys: [],
      title: '原始订单导入',
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      total: 0, // 数量
      time: [],

      mchNolist: [], // 商户列表,
      exportList: [],//导出的全部数据
      form: {
        pageSize: 10,
        pageNo: 1,
        startTime: '',
        endTime: '',
        mchNo: undefined, // 商户编号
        // 订单编号
        orderNo: '',
        // 订单日期
        orderDate: '',
        // 订单类型
        orderType: undefined,
        // 商品类型
        goodsType: undefined,
        // 订单状态
        orderStatus: undefined,
        // 清算状态
        settleStatus: undefined,
        // 计划清算日
        estSettleDate: undefined,
        // 订单融资额度
        creditLimit: '',
        // 已发货未收款总额
        orderAmount1: '',
        // 预计退款总额
        orderAmount2: '',
        // 预计应收总额
        orderAmount3: '',
        // 订单总额
        orderAmount: '',
        // 实际退款总额
        orderAmount4: '',
        // 放款总额
        loanAmountSum: '',
        // 	累计已补足差额
        supDiffAmount: '',
        lender: '',//贷款人
        settleTime: '',//清算时间
      },
      // 订单类型
      orderTypelist: [
        {
          label: '汇总订单',
          value: '0',
        },
        {
          label: '特殊汇总订单',
          value: '1',
        },
      ],
      // 商品类型
      goodsTypelist: [
        {
          label: '商品特殊',
          value: '10',
          color: '#DB5409'
        },
        {
          label: '商品',
          value: '11',
          color: '#DB5409'
        },
        {
          label: '资金',
          value: '30',
          color: '#DBC309'
        },
        {
          label: '代销',
          value: '40',
          color: '#01D1DB'
        },
        {
          label: '代销特殊',
          value: '41',
          color: '#01D1DB'
        },
        {
          label: '权益',
          value: '50',
          color: '#7509DB'
        },
     ],
      // 订单状态
      orderStatuslist: [
        {
          label: '已登记',
          value: '0',
        },
        {
          label: '已修正',
          value: '1',
        },
        {
          label: '已结算',
          value: '2',
        },
        {
          label: '已完结',
          value: '9',
        },
      ],
      // 	清算状态
      settleStatuslist: [
        {
          label: '未清算 ',
          value: '0',
          color: 'blue'
        },
        {
          label: '已清算',
          value: '1',
          color: 'green'
        },
        {
          label: '清算失败',
          value: '2',
          color: 'red'
        },
      ],
      // 操作状态
      operationStatuslist: [
        {
          label: '处理中 ',
          value: '0',
        },
        {
          label: '处理成功',
          value: '1',
        },
        {
          label: '处理失败',
          value: '2',
        },
      ],
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
            `${Number(this.form.pageNo - 1) * Number(this.form.pageSize) +
            Number(index + 1)
            }`,
          width: 60,
          align: 'center',

        },
        {
          title: '订单日期',
          dataIndex: 'orderDate',
          align: 'center',
          width: 100,
          fixed: 'left',
        },
        {
          title: '商户编号',
          dataIndex: 'mchNo',
          align: 'center',
          customRender: (text) => (text ? text : '---'),
          width: 100,
          fixed: 'left',
        },
        {
          title: '商户',
          dataIndex: 'mchName',
          align: 'center',
          width: 270,
          fixed: 'left',
          ellipsis: true,
          customRender: (text, scope) => {
            let goodsType = this.goodsTypelist.find(item=>item.value===scope.goodsType)
            if(goodsType) {
              return <span style="display:flex;flex-direction: row ">
                <a-tag color={goodsType.color}>{goodsType.label}</a-tag>
                <span style="flex: auto;">{text}</span>
            </span>
            }else{
              return text
            }
          }
        },
        {
          title: '订单编号',
          dataIndex: 'orderNo',
          align: 'center',
          width: 230,
          ellipsis: true,
        },
        {
          title: '订单总额',
          dataIndex: 'orderAmount',
          align: 'center',
          // customRender: (text) => (text ? text.toFixed(2) : '0.00'),
          width: 140,
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          }
        },
        {
          title: '预计结算额',
          dataIndex: 'orderAmount3',
          align: 'center',
          // customRender: (text) => (text ? text.toFixed(2) : '0.00'),
          width: 140,
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          }
        },

        {
          title: '可融资总额度',
          dataIndex: 'creditLimit',
          align: 'center',
          // customRender: (text) => (text ? text.toFixed(2) : '0.00'),
          width: 140,
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          }
        },
        {
          title: '已使用额度',
          dataIndex: 'loanAmountSum',
          align: 'center',
          // customRender: (text) => (text ? text.toFixed(2) : '0.00'),
          width: 140,
          customRender: (text) => {
            if(text>0)
            return <Balance amount={text} dcflag="c"></Balance>
          }
        },
        {
          title: '剩余额度',
          align: 'center',
          width: 140,
          customRender: (text, scope) => {
            if(scope.creditLimit - scope.loanAmountSum === 0)
              return <Balance amount={0} dcflag="c"></Balance>
            else
              return <Balance amount={scope.creditLimit - scope.loanAmountSum} dcflag="d"></Balance>
          }
        },
        {
          title: '超额退款',
          dataIndex: 'orderAmount2',
          align: 'center',
          // customRender: (text) => (text ? text.toFixed(2) : '0.00'),
          width: 140,
          customRender: (text, row) => {
            if(row.goodsType === '10')
              return <Balance amount={Math.max(row.orderAmount4 - row.orderAmount2, 0)} dcflag={row.orderAmount4 > row.orderAmount2 ? "c" : "d"}></Balance>
            else
              return ''
          }
        },
        {
          title: '累计已补差额',
          dataIndex: 'supDiffAmount',
          align: 'center',
          // customRender: (text) => (text ? text.toFixed(2) : '0.00'),
          width: 120,
          customRender: (text,row) => {
            if(row.goodsType === '10')
              return <Balance amount={text}></Balance>
            else
              return ""
          }
        },
        {
          title: '清算状态',
          dataIndex: 'settleStatus',
          align: 'center',
          width: 80,
          customRender: (text, row) => {
            let settleStatus = this.settleStatuslist.find(item=> item.value === text)
            return <a-tooltip >
              <template slot="title">
                {row.settleRemark}
              </template>
              <a-tag color={settleStatus.color}>{settleStatus.label}</a-tag>
            </a-tooltip>
          },
        },
        {
          title: '计划清算日',
          dataIndex: 'estSettleDate',
          align: 'center',
          width: 100,
          customRender: (text, row) => {
            if (text > dayjs().add(3, 'day').format("YYYY-MM-DD") || row.settleStatuslist == '1') {
              return text
            }
            if (text < dayjs().format("YYYY-MM-DD")) {
              return <a-tag color="red">{text}</a-tag>
            }
            if (text == dayjs().format("YYYY-MM-DD")) {
              return <a-tag color="orange">{text}</a-tag>
            }
            if (text > dayjs().format("YYYY-MM-DD")) {
              return <a-tag color="yellow">{text}</a-tag>
            }
            return text
          }
        },
        {
          title: '实际清算时间',
          dataIndex: 'settleTime',
          align: 'center',
          width: 190,
          customRender: (text) => (text ? text : '---'),
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center',
          width: 190,
          customRender: (text) => (text ? text : '---'),
        },
        {
          title: '渠道',
          dataIndex: 'channelNo',
          align: 'center',
          customRender: (text) => {
            return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), text, '1')
          },
          width: 100,
          ellipsis: true
        },
      ],
      data: [],
    }
  },
  created() {
    // 默认获取缓存的
    this.departmentId = this.$session.getItem('companyId')
    this.departmentCode = this.$session.getItem('companyCode')

  },
  mounted() {
    if (sessionStorage.getItem('msgInfo')) {
      this.form = this.$store.state.searchMsgInfo
      this.departmentId = this.form.departmentId
      this.departmentCode = this.form.departmentCode
    }
    Object.assign(this.form, this.$route.query)
    // this.form=this.$route.query

    this.getcollectorders()
    this.getmchshopsApi()
  },
  deactivated() {
    sessionStorage.removeItem('msgInfo')

  },
  destroyed() {
    sessionStorage.removeItem('msgInfo')
  },
  methods: {
    filterOption(input, option) {
      if (input.length < 2) return false;
      const children = option.componentOptions.children[0].children.map(m => m.text).filter(m => m)

      return (
        children.find(m => m.toLowerCase().indexOf(input.toLowerCase()) >= 0)
      );
    },
    // 选择所属机构去触发
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    // 获取所属机构下拉框数据
    async getmchshopsApi() {
      this.mchNolist = []
      const res = await this.api.mchshopsApi({
        pageNo: 1,
        pageSize: 10000,
        mchType: 1,  //默认融资商户
        departmentCode: this.form.departmentCode,
      })
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }

      // mchNolist
      this.mchNolist = res.data.records.map(x => Object.assign(x, {
        label: x.mchName,
        value: x.mchNo
      }))
    },

    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {
      // this.selectedRowKeys = selectedRowKeys
      // this.selectedRows = selectedRows
      // if (selectedRows.length > 1) {
      //   //获取选中的数据的key
      //   var selectNumber = this.selectedRowKeys[1]
      //   //清空选中的key
      //   this.selectedRowKeys = []
      //   //选中的数据的key重新赋值给selectedRowKeys
      //   this.selectedRowKeys.push(selectNumber)
      // }
      // //获取选中的数据详细信息
      // this.selectedRows = selectedRows[0]
      // console.log(this.selectedRows, this.selectedRowKeys);
      // 每次训中都要清空上次输入得数据
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            let index = this.selectedRowKeys.findIndex((item) => id == item)
            if (index == -1) {
              this.selectedRowKeys = [id]
              this.selectedRows = [record]
            } else {
              this.selectedRowKeys = []
              this.selectedRows = []
            }
          }
        }
      }
    },
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称

      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },

    onOk(value) {
      this.form.startTime = value[0]
      this.form.endTime = value[1]
    },
    // 所选机构触发
    departmentIdchange() {
      this.getmchshopsApi()
    },
    mchChange() {
      this.getcollectorders()
    },
    // 获取table列表
    async getcollectorders() {
      const res = await this.api.collectordersApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.total = res.data.total
      this.form.pageNo = res.data.current
      this.form.pageSize = res.data.size
      this.data = res.data.records
    },

    pagechange(val) {
      console.log(val)
      this.form.pageNo = val
      this.getcollectorders()
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.form.pageSize = pageSize
      this.getcollectorders()
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = 1,
        this.getcollectorders()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = Math.ceil(Number(this.total) / Number(this.form.pageSize))
      this.getcollectorders()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 搜索
    search() {
      this.form.pageNo = 1
      this.getcollectorders()
    },
    // 重置

    reset() {
      this.departmentId = this.$session.getItem('companyId')
      this.form = {
        departmentCode: undefined,
        pageSize: 10,
        pageNo: 1,
        startTime: '',
        endTime: '',
        mchNo: undefined, // 商户编号
        // 订单编号
        orderNo: '',
        // 商品类型
        goodsType: undefined,
        // 订单类型
        orderType: undefined,
        // 订单状态
        orderStatus: undefined,
        // 清算状态
        settleStatus: undefined,

        // 订单融资额度
        creditLimit: '',
        // 已发货未收款总额
        orderAmount1: '',
        // 预计退款总额
        orderAmount2: '',
        // 预计应收总额
        orderAmount3: '',
        // 订单总额
        orderAmount: '',
        // 实际退款总额
        orderAmount4: '',
        // 放款总额
        loanAmountSum: '',
        // 	累计已补足差额
        supDiffAmount: '',
        lender: '',//贷款人
        settleTime: '',//清算时间
      },
        this.time = []
      this.getcollectorders()
    },
    // 获取所有的列表数据
    async getTables() {
      let data = JSON.parse(JSON.stringify(this.form))
      data.pageSize = 100000
      const res = await this.api.collectordersApi(data)
      if (res.code === 200) {
        this.exportList = res.data.records
      }
    },

    // 订单导出
    async derive() {
      // 获取所有的列表数据
      await this.getTables()
      let arr = []
      // this.selectedRows.forEach((item) => {
      //   arr.push(item.id)
      // })
      this.exportList.forEach((item) => {
        arr.push(item.id)
      })
      const res = await this.api.collectordersexportApi({
        ...this.form,
        ids: arr,
      })

      this.$uploadfile.ExcelDownload('汇总订单', res)
    },


    // 订单原始明细导出
    async deriveItem() {
      let arr = []
      this.selectedRows.forEach((item) => {
        arr.push(item.id)
      })

      if (arr.length > 0) {
        if (arr.length > 1) {
          this.$message.warning('请选择一条汇总订单')
          return
        }

        const res = await this.api.collectordersitemexportApi({
          // pageNo: this.form.pageNo,
          // pageSize: this.form.pageSize,
          ...this.form,
          ids: arr,
        });

        const selectRowData = this.selectedRows[0]
        this.$uploadfile.ExcelDownload(`汇总订单原始明细_${selectRowData.mchNo}_${selectRowData.orderNo}`, res);
      } else {
        // 在 arr 为空时执行的逻辑
        this.$message.warning('请选择汇总订单')
      }
    },

    // 查看
    view() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择订单')
        return
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning('查看只能选择一笔订单')
        return
      }
      // 存储筛选条件
      this.$store.commit('saveSearchMsgInfo', this.form)
      this.$router.push({
        path: '/orders/Summaryorderview',
        query: {
          id: this.selectedRows[0].id,
        },
      })
    },
    // 生成汇总订单
    async Generatesummaryorder() {
      if (!this.form.mchNo) {
        this.$message.warning('请先选择商户')
        return
      }

      // if (!this.form.orderDate) {
      //   this.$message.warning('请先选择订单日期')
      //   return
      // }

      // 默认生成当天汇总订单
      let today = dayjs().format("YYYY-MM-DD")
      const res = await this.api.collectOrdersGenerateApi({
        orderDate: today,
        mchNo: this.form.mchNo,
        mchName: this.mchNolist.find(i => i.value === this.form.mchNo)?.label
      })
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$message.success('订单生成成功')
      this.getcollectorders()

    },
    // 追加特殊订单
    async specialSummaryorder() {
      if (!this.form.mchNo) {
        this.$message.warning('请先选择商户')
        return
      }
      const res = await this.api.collectOrdersSpecialApi({
        mchNo: this.form.mchNo,
        mchName: this.mchNolist.find(i => i.value === this.form.mchNo)?.label,
        orderType: '1'
      })
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$message.success('订单生成成功')
      this.getcollectorders()
    },
    // 修正汇总记录
    async Modifiedsummaryrecord() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择账户')
        return
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning('修正汇总订单只能选择一个账户')
        return
      }

      if (
        Number(this.selectedRows[0].orderStatus) == 0 ||
        Number(this.selectedRows[0].orderStatus) == 1
      ) {

        const res = await this.api.collectordersamendmentApi({
          orderNo: this.selectedRows[0].orderNo,
        })
        if (res.code !== 200) {
          this.$message.error(res.info)
          return
        }
        this.$message.success('订单修正成功')
        this.getcollectorders()
      } else {
        this.$message.warning('此订单状态不能进行修正')
      }
    },
    // 修订记录
    Revisionrecord() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择账户');
        return
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning('修订记录查看只能选择一个账户');
        return

      }
      // 存储筛选条件
      this.form.departmentId = this.departmentId
      this.$store.commit('saveSearchMsgInfo', this.form)
      this.$router.push({
        path: '/orders/Summarizeorderrevisionrecords',
        query: {
          orderNo: this.selectedRows[0].orderNo
        }
      })
      // query: {
      //     orderNo: this.selectedRows[0].orderNo
      //   }
    },
    async orderToCancel(){
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择一条记录');
        return
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning('一次只能作废一笔订单!');
        return
      }
      this.$confirm({
        title: '确定要作废该订单吗？',
        content: '作废后，无法恢复',
        okText: '确定',
        cancelText: '取消',
        onOk: async () => {
          await this.orderCancel()
        },
      })
    },
    //订单作废
    async orderCancel() {
      const orderNo = this.selectedRows[0].orderNo
      const res = await this.api.collectordersorderCancelApi(
        orderNo
      )
      if (res.code === 200) {
        this.$message.success(res.info)
        await this.settle();
        await this.getcollectorders()
      } else {
        this.$message.error(res.info)
      }
    },
    // 清算还款页面
    Liquidationandrepayment() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择账户')
        return
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning('清算还款只能选择一个账户')
        return
      }
      // 存储筛选条件
      this.$store.commit('saveSearchMsgInfo', this.form)
      this.$router.push({
        path: '/orders/Liquidationandrepayment',
        query: {
          id: this.selectedRows[0].id,
        },
      })
    },
    // 单笔清算还款
    async settle() {
      const res = await this.api.collectorderssettleApi(this.selectedRows[0].id,)
      if (res.code !== 200) {
        this.$message.error(res.info);
        return
      }
      this.$message.success('清算还款成功');

    },
    settleAll() {
      this.api.collectordersSettleAll()
    }
  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped>
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

.formbox {
  display: flex;
  flex-wrap: wrap;

  .inputbox {
    width: 320px;
    margin-right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    // .lefttitle {
    //   width: 120px;
    // }

    input {
      width: 200px;
    }
  }

  .reset {
    width: 64px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  button {
    margin-bottom: 20px;
  }
}

.Toleadinto {
  button {
    margin-bottom: 20px;
  }
}

.inquire {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.tablebox {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.paginationBox {
  display: flex;
  justify-content: flex-end;

  padding: 0 32px;
  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}

/deep/ .ant-card-head-title {
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .dianshang {
      margin-left: 50px;
    }
  }
}

</style>
