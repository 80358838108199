import axios from '@/utils/axios'
import urlConfig from '@/utils/urlConfig'
// apiGet apiPost apiDown
export default {
  //GET /cust-person-info/page
  // 客户管理-个人客户-基础信息
  custPage: (data) =>
    axios.apiPost(
      urlConfig.busiURL +
        '/cust/person/page?' +
        'pageNo=' +
        data.pageNo +
        '&pageSize=' +
        data.pageSize,
      data.obj
    ),
  // 客户管理-个人客户- 个人客户保存
  // custSave: (data) => axios.apiPost(urlConfig.busiURL + '/cust/person/save', data),
  // 客户管理-个人客户-详情-保存
  custDetailSave: (data) =>
    axios.apiPost(urlConfig.busiURL + '/cust/person/detail/save', data),
  // 客户管理-个人客户-查看用户
  getCustData: (data) =>
    axios.apiPost(
      urlConfig.busiURL + '/cust/person/getByCustnum?' + 'custnum=' + data
    ),
  // 客户管理-个人客户-查看用户-个人信息删除关联用户
  delPersonConnectUserApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/cust/user/delete', data),
  // 客户管理-个人客户-关联用户-获取用户名
  getAssociatedCust: (data) =>
    axios.apiGet(urlConfig.userURL + '/user/childrenList', data),
  // 客户管理-个人客户-关联用户-新增
  addAssociatedCust: (data) =>
    axios.apiPost(urlConfig.busiURL + '/cust/user/add', data),
  // 客户管理-企业客户-基础信息-page
  custEnterprise: (data) =>
    axios.apiPost(
      urlConfig.busiURL +
        '/cust/company/page?' +
        'pageNo=' +
        data.pageNo +
        '&pageSize=' +
        data.pageSize,
      data.obj
    ),
  // 客户管理-企业客户-获取客户信息
  getEnterpriseCust: (data) =>
    axios.apiPost(
      urlConfig.busiURL + '/cust/company/getByCustnum?custnum=' + data,
      {}
    ),
  // 客户管理-企业客户-新增-单位与个人信息-page
  getEandpList: (data) =>
    axios.apiPost(
      urlConfig.busiURL +
        '/cust/eandp/page?' +
        'pageNo=' +
        data.pageNo +
        '&pageSize=' +
        data.pageSize,
      data.obj
    ),
  // 客户管理-企业客户-新增-单位与单位信息-page
  getEandeList: (data) =>
    axios.apiPost(
      urlConfig.busiURL +
        '/cust/eande/page?' +
        'pageNo=' +
        data.pageNo +
        '&pageSize=' +
        data.pageSize,
      data.obj
    ),
  // 客户管理-企业客户-新增
  addEnterprise: (data) =>
    axios.apiPost(urlConfig.busiURL + '/cust/company/detail/save', data),
  // 客户管理-企业客户-关联用户-批量保存
  EnterpriseAssociatedCustApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/cust/user/saveOrUpdateBatch', data),
  // 所属机构下拉框
  agencyList: (data) =>
    axios.apiGet(urlConfig.userURL + '/department/childrenList', data),
  // 其他下拉框
  selectList: (data) =>
    axios.apiPost(urlConfig.busiURL + '/sys/enum/getMapList', data),
  // 基础管理参数配置 新增
  configparamadd: (data) =>
    axios.apiPost(urlConfig.busiURL + '/base/configparam/add', data),
  // 基础管理参数配置 修改
  configparamaupdate: (data) =>
    axios.apiPost(urlConfig.busiURL + '/base/configparam/update', data),
  // 基础管理参数配置 删除 
  configparamadelete: (data) =>
    axios.apiPost(urlConfig.busiURL + '/base/configparam/delete', data),
  
  // 贷款产品查询
  creditProductInfopageApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/creditProductInfo/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
  // 贷款产品新增
  creditProductInfoaddApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/creditProductInfo/add', data),
  // 贷款产品修改
  creditProductInfoupdateApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/creditProductInfo/update', data),
  // 贷款产品删除
  creditProductInfodeleteApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/creditProductInfo/delete', data),
  // 销售平台管理查询
  salePlatformInfopageApi: (data) =>
  axios.apiPost(urlConfig.busiURL + '/salePlatformInfo/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
  // 销售销售平台管理新增
  salePlatformInfoaddApi: (data) =>
  axios.apiPost(urlConfig.busiURL + '/salePlatformInfo/add', data),
   // 销售销售平台管理修改
   salePlatformInfoupdateApi: (data) =>
  axios.apiPost(urlConfig.busiURL + '/salePlatformInfo/update', data),
   // 销售销售平台管理删除
   salePlatformInfodeleteApi: (data) =>
   axios.apiPost(urlConfig.busiURL + '/salePlatformInfo/delete', data),
  // 核心企业管理list
  corecompanypageApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/core/company/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
  //  核心企业管理新增
  corecompanyaddApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/core/company/add', data),
  // 核心企业管理删除    
  corecompanydeleteApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/core/company/delete', data),
  //核心企业管理修改 
  corecompanyupdateApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/core/company/update', data),
  // 文件存储信息
  sysfilemanagerApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/sys/file/manager/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
  // 文件存储方式查询
  filemodelpageApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/sys/file/model/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
  // 文件存储方式新增
  filemodeladdApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/sys/file/model/add', data),
 // 文件存储方式编辑
  filemodelupdateApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/sys/file/model/update', data),
 // 文件存储方式删除  
  filemodeldeleteApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/sys/file/model/delete', data),
  // 短信记录查询
  smspageApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/sms/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
  // 短信查询模版
  smsmpageApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/sms/model/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
   // 短信模版新增
   smsmodeladdApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/sms/model/add', data),
    // 短信模版修改
    smsmodelupdateApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/sms/model/update', data),
     // 短信模版删除
     smsmodeldeleteApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/sms/model/delete', data),
    //  基础管理-手续费规则
    feeRulepageApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/feeRule/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
      // 手续费规则删除
    feeRuledeleteApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/feeRule/delete', data),
      // 手续费规则新增
    feeRuleaddApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/feeRule/addOrUpdate', data),
   // 手续费规则详情
   feeRuleDetailsApi: (data) =>
   axios.apiPost(urlConfig.busiURL + '/feeRule/getDetails?id='+data),
  // 授权回调地址   
    threeplatkuaishoureuriApi: (data) =>
    axios.apiGet(urlConfig.busiURL + '/threeplat/kuaishou/reuri?code=' + data.code + '&state=' + data.state),
    // 查询交易明细导出
    accountTradeDetailExportApi: (data) =>
    axios.apiPostDown(urlConfig.busiURL + '/cust/account/queryTradeDetailExport', data),


    //开户信息暂存
    custOpenAccTaskPage: (data) => axios.apiPost(urlConfig.busiURL + '/custOpenAccTask/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
    custOpenAccTaskAddTime: (data) => axios.apiGet(urlConfig.busiURL + '/custOpenAccTask/addTime?time=' + data.time + '&id=' + data.id),
    custOpenAccTaskIncreaSmsNum: (data) => axios.apiGet(urlConfig.busiURL + '/custOpenAccTask/increaSendSmsNum?id=' + data.id),
    custOpenAccTaskDetailApi: (data) => axios.apiGet(urlConfig.busiURL + '/custOpenAccTask/detail?id=' + data.id),
    custOpenAccTaskDelApi: (data) => axios.apiPost(urlConfig.busiURL + '/custOpenAccTask/del', data),
}
