var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"设置密码","visible":_vm.passBool},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{span: 4, offset: 1},"wrapperCol":{span: 18, offset: 1},"colon":false,"labelAlign":"left"}},[_c('a-form-item',{attrs:{"label":"新密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              { 
                rules: [{ required: true, message: '请输入新密码' }],
              },
            ]),expression:"[\n              'password',\n              { \n                rules: [{ required: true, message: '请输入新密码' }],\n              },\n            ]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{attrs:{"label":"密码确认"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              { 
                validateTrigger: 'blur',
                rules: [
                  { required: true, message: '请输入密码' },
                  { validator: _vm.checkConfirm }
                ],
              },
            ]),expression:"[\n              'name',\n              { \n                validateTrigger: 'blur',\n                rules: [\n                  { required: true, message: '请输入密码' },\n                  { validator: checkConfirm }\n                ],\n              },\n            ]"}],attrs:{"type":"password"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }