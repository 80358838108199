<template>
  <!-- 短信模版 -->
  <div>
    <a-card title="短信模版" style="margin: 20px">
      <div class="loan">
        <!-- !表单区域 -->
        <div class="formbox">
          <!-- 模板编号 -->
          <div class="inputbox">
            <div class="lefttitle">模板编号</div>
            <a-input v-model.trim="form.modelNo" placeholder="请输入模板编号" />
          </div>
          <!-- 启用标记 -->
          <div class="inputbox">
            <div class="lefttitle">启用标记</div>

            <a-select style="width: 200px;" v-model.trim="form.msgSwitch" placeholder="请选择启用标记">
              <a-select-option v-for="item in msgSwitchlist" :key="item.label" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </div>
          <!-- 查询 -->
          <a-button class="inquire" @click="search" type="primary" v-if="$authority('SmstemplateQuery')">
            查询
          </a-button>
          <!-- 重置 -->
          <a-button @click="reset" class="reset">重置</a-button>
        </div>

        <div class="Applyfor">
          <!--  新增  Add-->
          <a-button @click="newAdd" type="primary" v-if="$authority('SmstemplateAdd')">
            新增
          </a-button>
          <!--  编辑-->
          <a-button @click="edit" type="primary" v-if="$authority('SmstemplateEdit')"> 编辑 </a-button>

          <!-- 删除 -->
          <a-button @click="Deletebutton" type="danger" v-if="$authority('SmstemplateDel')"> 删除 </a-button>

        </div>
        <div class="tablebox">
          <!-- 表格 -->
          <div class="tableBody">
            <a-table ref="table" size="small" :rowKey="(record, index) => `${record.id}`" :row-selection="{
              selectedRowKeys: selectedRowKeys,
              hideDefaultSelections: true,
              onChange: onSelectChange,
            }" :customRow="clickrow" :columns="columns" :data-source="data" :pagination="false">
            </a-table>
          </div>
          <!-- 分页部分 -->
          <div class="paginationBox">
            <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
              首页
            </a-button> <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
              show-size-changer :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
              :item-render="itemRender">
              <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
            </a-pagination>
            <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
              class="Trailingpage">
              尾页
            </a-button>
          </div>
        </div>
      </div>
    </a-card>
    <a-modal :title="addform.id ? '编辑' : '新增'" :visible="modalBool" @ok="handleOk" @cancel="modalBool = false">
      <a-form-model ref="addruleForm" :model="addform" :label-col="{ span: 6, offset: 1 }"
        :wrapperCol="{ span: 15, offset: 1 }" labelAlign="left">
        <div class="context">
          <!--  模版编号 -->
          <a-form-model-item label="模版编号 " :rules="[{ required: true, message: '请输入模版编号', trigger: 'blur', }]"
            prop="modelNo">
            <a-input v-model.trim="addform.modelNo" placeholder="请输入模版编号" />
          </a-form-model-item>
          <!-- 	短信内容 -->
          <a-form-model-item label="短信内容" :rules="[{ required: true, message: '请输入短信内容', trigger: 'blur', }]"
            prop="content">
            <a-input v-model.trim="addform.content" type="textarea" placeholder="请输入短信内容" />
          </a-form-model-item>


          <!-- 启用标记  -->
          <a-form-model-item label="启用标记 " :rules="[{ required: true, message: '请选择启用标记', trigger: 'change', }]"
            prop="msgSwitch">
            <a-select v-model.trim="addform.msgSwitch" placeholder="请选择启用标记">
              <a-select-option v-for="item in msgSwitchlist" :key="item.label" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>


        </div>

      </a-form-model>
    </a-modal>
    <!-- 删除 -->
    <a-modal title="提示" :maskClosable="true" @cancel="deleteshow = false" :centered="true" :visible="deleteshow">
      <div class="Openanaccount">
        是否确认删除所勾选数据？删除后数据将不可恢复
      </div>
      <template v-slot:footer>
        <div class="custom-footer">
          <a-button type="primary" @click="Confirmdelete">确认</a-button>
          <a-button @click="deleteshow = false">取消</a-button>

        </div>

      </template>
    </a-modal>

  </div>
</template>

<script>

import { BizDict } from "@/utils/bizDict/kvMap";
// import { handleChannelNo } from '@/utils/commonMethods'
import dayjs from "dayjs";
export default {
  name: 'smstemplatePage',
  data() {
    return {
      treeData: [],
      deleteshow: false, // 贷款弹窗
      statuslist: BizDict.LoanAppStatus,
      total: 0,
      modalBool: false,
      Examineandapproveshow: false,
      selectedRowKeys: [],
      selectedRows: [], // 选数数组的值,
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      institutiontrees: {},

      data: [],
      addform: {

        modelNo: '', // 模版编号
        msgSwitch: undefined,// 启用标记
        content: '',// 短信内容
      },
      form: {
        pageNo: 1,
        pageSize: 10,
        modelNo: '', // 模版编号
        msgSwitch: undefined,// 启用标记

      },
      msgSwitchlist: [{
        label: '关闭',
        value: '0',
      }, {
        label: "开启",
        value: "1",
      }],


      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
            `${Number(this.form.pageNo - 1) * Number(this.form.pageSize) +
            Number(index + 1)
            }`,
          align: 'center',
        },

        {
          title: '模板编号',
          dataIndex: 'modelNo',
          align: 'center',
          ellisis: true,
        },
        {
          title: '短信内容',
          dataIndex: 'content',
          align: 'center',
          ellisis: true,
        },
        {
          title: '启用标记',
          dataIndex: 'msgSwitch',
          align: 'center',
          ellisis: true,
          customRender: (text) => {
            if (Number(text) == 0) {
              return '关闭'
            }
            if (Number(text) == 1) {
              return '开启'
            }
          }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center',
          ellisis: true,
          customRender: (text) => {
            return text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : ''
          }
        },
        {
          title: '创建人',
          dataIndex: 'creater',
          align: 'center',
          ellisis: true,
        },
        {
          title: '记录状态',
          dataIndex: 'recordStatus',
          align: 'center',
          ellisis: true,
          customRender: (text) => {
            if (Number(text) == 0) {
              return '正常'
            }
            if (Number(text) == 1) {
              return '删除'
            }
          }
        },


      ],
    }
  },
  created() {
    this.getcommercePage()
  },
  methods: {
    // 选择所属机构去触发
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    // 获取所属机构下拉框数据
    async getCustList() {
      let data = {
        username: this.$session.getItem('username'),
      }
      const res = await this.api.agencyList(data)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      let trees = []
      this.institutiontrees = res.data
      trees.push(res.data)

      this.treeData = this.getResettingTrees(trees)
        ? this.getResettingTrees(trees)
        : []
    },


    // 获取table列表
    async getcommercePage() {



      const res = await this.api.smsmpageApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.total = res.data.total
      this.form.pageNo = res.data.current
      this.form.pageSize = res.data.size

      this.data = res.data.records
    },


    // 点击新增
    newAdd() {
      this.addform = {

        modelNo: '', // 模版编号
        msgSwitch: undefined,// 启用标记
        content: '',// 短信内容
      },
        this.modalBool = true
      // 获取钱包和产品信息

    },

    // 进行循环递归处理数据
    getResettingTrees(tree) {
      // 定义子级数组
      if (tree.length == 0) return []
      let arr = []

      tree.forEach((item) => {
        // 递归循环将每一项都处理城组件需要的的格式 [ {title:"" children:[{title:""}]  }]
        arr.push({
          title: item.coreDepartment.name,
          value: item.coreDepartment.id,
          key: item.coreDepartment.id,
          children:
            item.children && item.children.length > 0
              ? this.getResettingTrees(item.children)
              : [],
        })
      })
      // 处理好的数组返回出去
      return arr
    },
    // 点击重置表单
    reset() {
      this.form = {
        pageNo: 1,
        pageSize: 10,
        modelNo: '', // 模版编号
        msgSwitch: undefined,// 启用标记

      },
        this.getcommercePage()
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.form.pageSize = pageSize
      this.getcommercePage()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = 1,
        this.getcommercePage()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = Math.ceil(Number(this.total) / Number(this.form.pageSize))
      this.getcommercePage()
    },
    pagechange(val) {
      this.form.pageNo = val

      this.getcommercePage()
    },
    // 搜索查询
    search() {
      this.form.pageNo = 1
      this.getcommercePage()
    },


    // 点击新增确认
    handleOk() {
      // 首先进行表单校验
      this.$refs.addruleForm.validate((valid) => {
        if (valid) {

          if (this.addform.id) {
            // 
            this.api.smsmodelupdateApi(this.addform).then((res) => {
              if (res.code !== 200) {
                this.$message.warning(res.info)
                return
              }

              this.$message.success('编辑成功')
              this.addform = {

                modelNo: '', // 模版编号
                msgSwitch: undefined,// 启用标记
                content: '',// 短信内容
              },
                this.modalBool = false
              this.selectedRowKeys = []
              this.selectedRows = [] // 选数数组的值,
              this.getcommercePage()

            }
            )

          } else {
            this.api.smsmodeladdApi(this.addform).then((res) => {
              if (res.code !== 200) {
                this.$message.warning(res.info)
                return
              }

              this.$message.success('新增成功')
              this.addform = {

                modelNo: '', // 模版编号
                msgSwitch: undefined,// 启用标记
                content: '',// 短信内容
              },
                this.modalBool = false
              this.selectedRowKeys = []
              this.selectedRows = [] // 选数数组的值,
              this.getcommercePage()

            }
            )
          }



        } else {
          this.$message.warning("请先填写信息");
        }
      });

    },
    // 点击编辑
    edit() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择账户')
        return
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning('查看只能选择一个账户账户')
        return
      }

      let data = JSON.parse(JSON.stringify(this.selectedRows[0]))
      this.addform = data
      this.modalBool = true
    },


    // 删除
    Deletebutton() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择账户')
        return
      }

      this.deleteshow = true
    },
    // 点击删除
    async Confirmdelete() {
      let data = []
      this.selectedRows.forEach((item) => {
        data.push(item.id)
      })
      const res = await this.api.smsmodeldeleteApi(data)
      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
      this.$message.success('删除成功')
      this.selectedRowKeys = []
      this.selectedRows = [] // 选数数组的值,
      this.deleteshow = false
      this.getcommercePage()
    },
    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      // if (selectedRows.length > 1) {
      //   //获取选中的数据的key
      //   var selectNumber = this.selectedRowKeys[1]
      //   //清空选中的key
      //   this.selectedRowKeys = []
      //   //选中的数据的key重新赋值给selectedRowKeys
      //   this.selectedRowKeys.push(selectNumber)
      // }
      // //获取选中的数据详细信息
      // this.selectedRows = selectedRows[0]

    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            let index = this.selectedRowKeys.findIndex((item) => id == item)
            if (index == -1) {
              this.selectedRowKeys.push(id)
              this.selectedRows.push(record)

            } else {

              this.selectedRowKeys.splice(index, 1)
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj == {}) return
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },

  },
}
</script>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped>
.ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}

/* 自定义表头样式 */
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

.ant-modal-footer {

  padding-bottom: 20px;
}

.ant-modal-content {
  min-width: 420px;
}



.loan {
  .formbox {
    display: flex;
    flex-wrap: wrap;

    .inputbox {
      width: 260px;
      margin-right: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      input {
        width: 180px;
      }

      .lefttitle {
        min-width: 80px;
      }
    }

    .zhaungtai {
      width: 280px;
      margin-right: 120px;
    }

    .reset {
      width: 64px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }

    button {
      margin-bottom: 20px;
    }

    .ant-tag {
      cursor: pointer;
    }
  }

  .tablebox {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .ant-tag {
      cursor: pointer;
    }
  }

  .paginationBox {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;

    // .ant-pagination-options {
    //   float: left;
    // }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: 1px solid #d9d9d9;
      width: 60px;
    }
  }

  .Applyfor {
    padding-top: 10px;
    margin-bottom: 20px;
  }
}
</style>
