<template>
  <!-- 带清算账户管理列表页 -->
  <div>
    <a-card title="待清算账户" style="margin: 20px">
      <!-- 头部筛选区域 -->
      <div class="selected-box">
        <!-- 机构ID -->
        <div class="selected-box-item">
          <span class="left-item">机构</span>
          <DepartmentChoose :department-id.sync="selectedData.departmentId" @change="handleQuery"></DepartmentChoose>
        </div>
        <!-- 待清算账号1 -->
        <div class="selected-box-item">
          <span class="left-item">待清算账号</span>
          <a-input v-model.trim="selectedData.accountNoSettling1" placeholder="请输入待清算账号"></a-input>
        </div>
        <!-- 待清算户名1 -->
        <div class="selected-box-item">
          <span class="left-item">待清算户名</span>
          <a-input v-model.trim="selectedData.accountNameSettling1" placeholder="请输入待清算户名"></a-input>
        </div>


        <div class="selected-box-item" style="display: block">
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button style="margin-left: 20px" @click="handleReset">重置</a-button>
        </div>
      </div>
      <!-- 表格区域 -->
      <div class="table-box">
        <div class="table-box-handle">
          <a-button type="primary" @click="addBtn">
            新增
          </a-button>
          <a-button type="primary" @click="editBtn">修改</a-button>
          <a-button type="primary" @click="viewBtn">查看</a-button>
          <a-button type="danger" @click="delBtn">删除</a-button>
        </div>
        <div class="table-main">
          <a-table size="small" :scroll="{ x: 1500, }" :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }" :customRow="clickrow" :columns="columns" :data-source="data" :pagination="false"
            :rowKey="(record, index) => `${record.id}`">
          </a-table>
        </div>
      </div>
      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(pageNo) == 1" @click="Homepage">
          首页
        </a-button> <a-pagination v-model.trim="pageNo" :page-size-options="pageSizeOptions" :total="total"
          show-size-changer :page-size="pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
          :item-render="itemRender">
          <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
        </a-pagination>
        <a-button @click="Trailingpage" :disabled="pageNo == Math.ceil(Number(total) / Number(pageSize))"
          class="Trailingpage">
          尾页
        </a-button>
      </div>
      <!-- 删除按钮弹框 -->
      <div class="del-dialog">
        <a-modal title="提示" :body-style="{ textAlign: 'center' }" :visible="isShowDelDialog" :centered="true"
          @ok="handleOk" @cancel="isShowDelDialog = false">
          <p>是否确认删除?</p>
        </a-modal>
      </div>
      <!-- 新增清算账户弹框 -->
      <div class="add-dialog">
        <!-- 新增担保人弹框 -->
        <a-modal :title="isEdit ? '待清算账户编辑' : '待清算账户新增'" :visible="isVisibleAddDialog" @cancel="isCancel" :centered="true"
          :body-style="{ textAlign: 'center' }" :footer="null">
          <a-form-model ref="addruleForm" :rules="rules" :model="addSettleAccountData" :label-col="{ span: 7, offset: 1 }"
            :wrapperCol="{ span: 12, offset: 1 }" labelAlign="left">
            <a-form-model-item label="所属机构:" prop="departmentId">
              <DepartmentChoose :department-id.sync="addSettleAccountData.departmentId"></DepartmentChoose>
            </a-form-model-item>
            <a-form-model-item label="渠道:" prop="channelNo">
              <a-select :disabled="isView" v-model="addSettleAccountData.channelNo" placeholder="请选择渠道">
                <a-select-option v-for="(item, ind) in JSON.parse(this.$session.getItem('coreCompanyList'))"
                  :key="'channelNo' + ind" :value="item.channelNo">{{ item.channelName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="清算账户户名:" prop="accountNameSettling1">
              <a-input :disabled="isView" v-model="addSettleAccountData.accountNameSettling1"
                placeholder="请输入清算账户户名"></a-input>
            </a-form-model-item>
            <a-form-model-item label="清算账户:" prop="accountNoSettling1">
              <a-input :disabled="isView" v-model="addSettleAccountData.accountNoSettling1"
                placeholder="请输入清算账户"></a-input>
            </a-form-model-item>
          </a-form-model>
          <div class="container-item">
            <a-row>
              <a-col :span="6"></a-col>
              <a-col :span="18">
                <a-button @click="isCancel">取消</a-button>
                <a-button v-if="!isView" type="primary" @click="onSubmit">保存</a-button>

              </a-col>
            </a-row>
          </div>
        </a-modal>
      </div>
    </a-card>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
// import { handleChannelNo } from '@/utils/commonMethods'
import WalletInfo from '@/view/components/WalletInfo.vue'
import dayjs from 'dayjs'
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
const data = []

export default {
  name: 'SettleAccounts',
  props: {},
  components: {DepartmentChoose, WalletInfo },
  data() {
    return {
      // 表格数据
      data,
      columns: [
        {
          title: 'No',
          customRender: (text, record, index) =>
            `${Number(this.pageNo - 1) * Number(this.pageSize) +
            Number(index + 1)
            }`,
          align: 'center',
          key: 'sort',
          width: 55
        },
        {
          title: '	渠道名称',
          dataIndex: 'channelName',
          key: 'channelName',
          align: 'center',
        },
        {
          title: '待清算账号',
          dataIndex: 'accountNoSettling1',
          key: 'accountNoSettling1',
          align: 'center',

          ellipsis: true,
          customRender: (text, record) => {

            return <WalletInfo accountNo={text} accountName={record.accountNameSettling1} showBank={false} showBalance={false}></WalletInfo>
          }
        },
        {
          title: '待清算户名',
          dataIndex: 'accountNameSettling1',
          key: 'accountNameSettling1',
          align: 'center',

        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          align: 'center',
          customRender: (text) => {
            return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
          }
        },
      ],
      selectedRowKeys: [],
      // 筛选参数
      selectedData: {
        // 机构ID
        departmentId: undefined,
        // 	待清算账号1
        accountNoSettling1: '',
        // 待清算户名1
        accountNameSettling1: '',
        // 渠道编号
        channelNo: undefined,
      },
      addSettleAccountData: {
        departmentId: undefined,
        appNo: '',
        channelNo: undefined,//	渠道编号
        channelName: '',
        accountNoSettling1: '',
        accountNameSettling1: '',
      },
      rules: {
        departmentId: [{ required: true, message: '请选择所属机构', trigger: 'change' },],
        channelNo: [{ required: true, message: '请选择渠道', trigger: 'change' },],
        accountNameSettling1: [{ required: true, message: '请选择清算账户1', trigger: 'blur' },],
        accountNoSettling1: [{ required: true, message: '请填写清算账号1', trigger: 'blur' },],

      },
      settleAccountId: "",
      isVisibleAddDialog: false,
      isView: false,
      isEdit: false,
      // 当前页
      pageNo: 1,
      // 一页显示多少条
      pageSize: 10,
      // 总数
      total: 0,
      //分页大小
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      isShowDelDialog: false,
      selectCustData: [],
      channelNameList: []
    }
  },
  computed: {},
  watch: {},
  created() {
    // 获取机构列表
    this.channelNameList = JSON.parse(this.$session.getItem('coreCompanyList'))

  },
  mounted() {
    if (sessionStorage.getItem('msgInfo')) {
      this.selectedData = this.$store.state.searchMsgInfo
    }
    // 获取列表
    this.getPayProtocolList()
  },
  deactivated() {
    sessionStorage.removeItem('msgInfo')

  },
  destroyed() {
    sessionStorage.removeItem('msgInfo')
  },
  methods: {
    chooseAccount1Done(wallet) {
      // console.log(wallet);
      this.addSettleAccountData.accountNoSettling1 = wallet.accountNo
      this.addSettleAccountData.departmentId = wallet.departmentId
      this.addSettleAccountData.appNo = wallet.appNo
      this.addSettleAccountData.channelNo = wallet.channelNo

    },

    filterChannelName() {
      let channelName = ''
      this.channelNameList.reduce((pre, item) => {
        if (item.channelNo === this.addSettleAccountData.channelNo) {
          channelName = item.channelName
        }
      }, '')
      return channelName
    },
    isCancel() {
      this.isVisibleAddDialog = false
      this.isView = false
      this.isEdit = false
    },

    // 校验
    onSubmit() {
      this.isEdit = false
      this.$refs.addruleForm.validate(async (valid) => {
        if (valid) {
          // 校验成功调用保存接口
          this.addSettleAccount()
        } else {
          console.log("error submit!!");
        }
        this.addSettleAccountData = {
          departmentId: undefined,
          appNo: '',
          channelNo: undefined,//	渠道编号
          channelName: '',
          accountNoSettling1: '',
          accountNameSettling1: '',
        }
      });
    },
    async addSettleAccount() {
      this.addSettleAccountData.channelName = this.filterChannelName()
      let res = await this.api.addSettleAccountApi(this.addSettleAccountData)
      if (res.code == 200) {
        this.$message.success(res.info)
        this.selectCustData = []
        this.selectedRowKeys = []
        // 获取表格
        this.getPayProtocolList()
      } else {
        this.$message.error(res.info)
      }
      // 关闭弹框
      this.isVisibleAddDialog = false;
    },
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj == {}) return
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },

    // 获取列表函数
    async getPayProtocolList() {
      let data = {}
      data.pageNo = this.pageNo
      data.pageSize = this.pageSize
      // obj是个对象
      data.obj = JSON.parse(JSON.stringify(this.selectedData))
      let res = await this.api.settleAccountApi(data)
      if (res.code == 200) {
        this.data = res.data.records
        this.pageNo = res.data.current
        this.pageSize = res.data.size
        this.total = res.data.total
      } else {
        this.$message.error(res.info)
      }
    },
    // 查询
    handleQuery() {
      this.pageNo = 1
      this.selectedRowKeys = []
      this.getPayProtocolList()
    },
    // 重置
    handleReset() {
      this.selectedRowKeys = []
      this.pageNo = 1
      this.pageSize = 10
      this.selectedData = {
        // 机构ID
        departmentId: undefined,
        // 	待清算账号1
        accountNoSettling1: '',
        // 待清算户名1
        accountNameSettling1: '',
        // 待清算账号2
        accountNoSettling2: '',
        // 待清算户名2
        accountNameSettling2: '',
        // 渠道编号
        channelNo: undefined,
      }
      this.getPayProtocolList()
    },
    // 改变pagesize的事件函数
    onShowSizeChange(current, pageSize) {
      // console.log(current);
      // console.log(pageSize);
      this.pageNo = current
      this.pageSize = pageSize
      this.getPayProtocolList()
    },
    // 改变当前页的事件
    handleChangePage(page) {
      this.pageNo = page
      this.getPayProtocolList()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.pageNo = 1,
        this.getPayProtocolList()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.pageNo = Math.ceil(Number(this.total) / Number(this.pageSize))
      this.getPayProtocolList()
    },
    pagechange(val) {
      this.pageNo = val
      this.getPayProtocolList()
    },
    // 选中行的回调
    onSelectChange(val, selectedRows) {
      this.selectedRowKeys = val
      if (selectedRows.length > 1) {
        //获取选中的数据的key
        var selectNumber = this.selectedRowKeys[1]
        //清空选中的key
        this.selectedRowKeys = []
        //选中的数据的key重新赋值给selectedRowKeys
        this.selectedRowKeys.push(selectNumber)
      }
      //获取选中的数据详细信息
      this.selectCustData = selectedRows

    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString();
            if (id == this.selectedRowKeys[0]) {
              this.selectedRowKeys = []
              this.selectCustData = []
            } else {
              this.selectedRowKeys = []
              this.selectedRowKeys.push(id)
              this.selectCustData = record
            }
          }
        }
      }
    },
    addBtn() {
      this.addSettleAccountData = {
        departmentId: undefined,
        appNo: '',
        channelNo: undefined,//	渠道编号
        channelName: '',
        accountNoSettling1: '',
        accountNameSettling1: '',
      }
      this.isVisibleAddDialog = true
      this.selectCustData = []
      this.selectedRowKeys = []
    },
    // 编辑跳转
    editBtn() {
      // console.log(this.selectCustData, '00')
      this.isEdit = true
      if (this.selectCustData.length == 0) {
        return message.warning('只能选择一个客户!')
      } else {
        this.settleAccountId = this.selectCustData.id
        this.details()
      }
    },
    // 查看跳转
    viewBtn() {
      // console.log(this.selectCustData, 'this.selectCustData');
      if (this.selectCustData.length == 0) {
        return message.warning('只能选择一个客户!')
      } else {
        this.isView = true
        // 调用详情接口
        this.settleAccountId = this.selectCustData.id
        this.details()
      }
    },
    // 删除
    delBtn() {
      if (this.selectCustData.length == 0) {
        return message.warning('只能选择一个客户!')
      } else {
        this.settleAccountId = this.selectCustData.id
        this.isShowDelDialog = true
      }
    },
    // 详情
    async details() {
      let res = await this.api.detailsSettleAccountApi(this.settleAccountId)
      if (res.code == 200) {
        this.addSettleAccountData = res.data
        // 打开弹框
        this.isVisibleAddDialog = true
      } else {
        this.$message.error(res.info)
      }
    },
    // 弹框确认删除事件
    async handleOk() {
      let res = await this.api.delSettleAccountApi(this.settleAccountId)
      if (res.code == 200) {
        this.$message.success(res.info)
        this.selectedRowKeys = []
      } else {
        return this.message.error(res.info)
      }
      this.getPayProtocolList()
      this.isShowDelDialog = false
    },
  },
}
</script>

<style lang="less" scoped>
.selected-box {
  display: flex;
  flex-wrap: wrap;

  /deep/ .selected-box-item {
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    flex-basis: 300px;
    margin-bottom: 20px;
    align-items: center;

    .left-item {
      margin-right: 15px;
    }

    .ant-input {
      width: 200px;
    }

    .ant-select-selection--single {
      width: 200px;
    }
  }
}

.table-box {
  .table-box-handle {
    margin-bottom: 20px;
  }
}

.paginationBox {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}
</style>
