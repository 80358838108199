<template>
  <div>
    <div class="item">
      <!-- 图片回显 -->
      <div class="img-show" v-if="imgSrc">
        <img :src="imgSrc" alt="">
      </div>
      <div class="text">
        <span style="margin-right: 20px; display: inline-block; width: 100px">正面免冠照</span>
        <a-button @click="start" style="width: 120px; height: 38px" :type="successed ? '' : 'primary'">
          {{ successed ? "重新采集" : "去采集" }}
        </a-button>
      </div>

    </div>
    <!--人脸信息采集弹窗 -->
    <a-modal title="信息采集" class="Facecollection" :keyboard="false" :maskClosable="false" :centered="true"
      :visible="Facecollectionshow">
      <div class="dimensionalcode">
        <div class="img-box">
          <canvas ref="qrcodeCanvas"></canvas>
        </div>
      </div>
      <p>1.请使用手机自带相机或微信扫码拍照上传</p>
      <p>2.完成后点下方“完成”按钮</p>
      <template v-slot:footer>
        <!-- 提现 -->
        <div style="text-align: center" class="custom-footer">
          <a-button type="primary" @click="complete">完成</a-button>
          <a-button @click="Facecollectionshow = false">取消</a-button>
        </div>
      </template>
    </a-modal>


  </div>
</template>
<script>
import qrcode from "qrcode";

export default {
  name: "FaceCollect",

  props: {
    custname: {
      required: true,
      type: String,
    },
    id_no: {
      required: true,
      type: String,
    },
    channel_no: String,
    app_no: {
      required: true,
      type: String,
    },

  },
  data() {
    return {
      timeout: [],
      timeoutHnd: undefined,
      Facecollectionshow: false,
      baseurl: "",
      taseno: "",
      imgSrc: "",
      successed: false, //是否已采集成功
    };
  },
  async created() {
    const records = await this.api.downLoadTemplateApi(["data_collection"]);
    if (records.code !== 200) {
      this.$message.warning(records.info);
    }
    this.baseurl = records.data.data_collection.constValue;
  },
  methods: {
    // 点击去采集进行人脸采集
    async start() {
      if (!(this.custname && this.id_no && this.channel_no && this.app_no)) {
        this.$message.warning("请先完善身份信息");
        return;
      }
      let data = {
        channel_no: this.channel_no,
        app_no: this.app_no,
        id_no: this.id_no,
        name: this.custname,
      };

      this.successed = false
      this.Facecollectionshow = true;
      // facePhotographApi
      const res = await this.api.facePhotographApi(data);
      if (res.code !== 200) {
        this.$message.warning(res.info);
        return;
      }
      this.taskno = res.data;
      let url = this.baseurl + this.taskno;

      this.Facecollectionshow = true;

      let that = this;
      this.$nextTick(() => {
        const canvasDiv = that.$refs.qrcodeCanvas;
        const options = {
          width: 250,
          height: 250,
        };
        qrcode.toCanvas(canvasDiv, url, options, (error) => {
          if (error) {
            this.$message.error("无法获取二维码，请联系管理员")
            console.error("二维码生成失败", error);
          }
        });
        window.clearTimeout(this.timeoutHnd)
        // this.timeout = [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 10, 10, 10, 20, 30, 60] // 兼顾用户体验与服务器压力，设置自动检查周期，自动检查上传结果的间隔，用完后不再自动检测
        let timeout = []
        for (let i = 0; i < 200; i++) {
          timeout.push(5)
        }
        // console.log(timeout, 'timeout');
        this.timeoutHnd = window.setTimeout(this.testComplete, 5000)
      });
    },
    // 回显图片
    downLoadPhoto(data) {
      this.api.downloadImage(data.file_no).then((res) => {
        if (res.code !== 200) {
          this.$message.error(res.info);
        }
        this.successed = true
        this.Facecollectionshow = false
        this.imgSrc = res.data;
      });
    },
    // 自动确认上传完成
    testComplete() {
      if (this.successed || !this.Facecollectionshow) { return }//已经成功或容器已经关闭
      this.api.facePhotographQueryApi("" + this.taskno)
        .then(res => {
          if (res.data.task_status === "1") {
            this.$emit("success", res.data, this.scope);
            this.$message.info("照片采集成功")
            // 调用下载图片方法
            this.downLoadPhoto(res.data)
          }
          else if (res.data.task_status === "0") {// 还未上传， 等待一段时间后再来过
            let timeout = this.timeout.shift()
            if (timeout) {
              this.timeoutHnd = setTimeout(this.testComplete, timeout * 1000);
            }
          }
        })


    },
    // 人工确认上传完成， 点击采集完成进行校验
    async complete() {
      let res = await this.api.facePhotographQueryApi("" + this.taskno);
      if (res.code != 200) {
        this.$message.warning(res.info);
        return;
      }
      if (res.data.task_status === "1") {
        this.$emit("success", res.data, this.scope);
        this.$message.info("照片采集成功")

        this.downLoadPhoto(res.data)
      }
      if (res.data.task_status === "2") {
        this.$emit("fail", res.data, this.scope);
        this.Facecollectionshow = false
      }
      if (res.data.task_status === "0") {
        this.$message.info("请先扫码进行人脸识别认证")
      }
    },
  },
};
</script>

<style scoped lang="less">
.item {
  // width: 100%;
  // height: 100%;
  display: flex;
  align-items: center;

  .img-show {
    width: 200px;
    height: 122px;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
      // object-fit: cover;
    }


  }
}

.Facecollection {
  .dimensionalcode {
    text-align: center;
    display: flex;
    min-height: 250px;
    align-items: center;
    justify-content: center;

    .img-box {
      width: 250px;
      height: 250px;
      margin-bottom: 20px;
    }
  }

  p {
    margin-bottom: 0px;
    color: #333333;
  }

  .custom-footer {
    button {
      width: 88px;

    }
  }
}
</style>
