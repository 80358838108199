<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="img-box">
          <img src="../../assets//logo.svg" alt="">
        </div>
        <span>电商服务工具平台</span>
      </template>

      <!--标题  -->
      <div class="title">第三方应用授权结果通知</div>

      <!-- 授权成功 -->

      <div class="Successful" v-if="Successful == true"><img src="../../assets/succeed.svg" alt="">
        <span>授权成功</span>
      </div>

      <div class="login" v-if="Successful == true">
        <a-button @click="login" type="primary">
          去登录
        </a-button>

      </div>
      <!-- 授权失败 de-authorize -->
      <div class="Lose" v-if="Successful == false"><img src="../../assets/notice.svg" alt="">
        <span>授权失败，请重新操作授权</span>
      </div>

      <div class="login" v-if="Successful == false">
        <a-button @click="getKsAuthorizedAddress($route.query.state)" type="primary">
          去授权
        </a-button>

      </div>
    </a-card>

  </div>
</template>
<script>


export default {
  data() {
    return {
      Successful: '',
    }
  },
  created() {

    this.getthreeplatkuaishoureuri()
  },
  methods: {
    login() {

      this.$router.push('/login');
    },
    async getKsAuthorizedAddress(value) {

      const res = await this.api.getKsAuthorizedAddressApi(value);

      if (res.code !== 200) {
        this.$message.warning(res.info);
        return;
      }
      window, open(res.data);
    },
    async getthreeplatkuaishoureuri() {
      let data = {
        code: this.$route.query.code,
        state: this.$route.query.state,
      }
      const res = await this.api.threeplatkuaishoureuriApi(data)
      if (res.code !== 200) {
        this.Successful = false
      } else {
        this.Successful = true
      }
    }
  }
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>

<style lang="less" scoped>
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

/deep/.ant-card-body {
  height: 70vh;
}

/deep/ .ant-card-head-title {
  display: flex;
  align-items: center;

  img {
    width: 100px;
    height: 40px;
    margin-right: 20px;
  }
}

.title {
  padding-left: 30px;
}

.Successful,
.Lose {
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 180px;
  align-items: center;

  img {
    width: 48px;
    height: 48px;
  }

  span {
    display: flex;
    font-weight: 600;
    margin-left: 30px;
    font-size: 16px;
  }

}

.login {
  margin-top: 48px;
  display: flex;
  justify-content: center;
}

/deep/ .ant-modal-header {
  height: 40px;
  padding: 0px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 700;

}



/deep/.ant-modal-close-x {
  height: 40px;
  line-height: 40px;
}

/deep/.ant-card-head {
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #fff;
}
</style>
