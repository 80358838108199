<template>
  <!-- 商户管理新增、编辑、查看页面 -->
  <a-card :title="title" style="margin: 20px">
    <template slot="extra">
      <a-button type="primary" @click="onSubmit" v-if="!isView()">保存</a-button>
      <a-button @click="clickShowDialog('cancel')">取消</a-button>
    </template>
    <!-- 主体部分 -->
    <div class="container">
      <div class="conHeader" style="margin: 10px 0; color: #000">基础信息</div>
      <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="left">
        <a-form-model-item ref="name" label="融资主体" prop="accountName">
          <WalletChooseDialog v-model="form.accountName" :custname="form.custname" :account-class="'0'"
            @chooseDone="onWalletChosen">
          </WalletChooseDialog>
        </a-form-model-item>
        <a-form-model-item ref="name" label="钱包编号" prop="" v-if="form.accountNoA1">
          <div v-if="form.mchType == '1'" class="wallet-box">
            <WalletInfo :account-no="form.accountNoA1" :account-class="'0'" :account-name="form.accountName"
              :channel-name="channelName" ></WalletInfo>
          </div>
          <div v-if="form.mchType == '0'" class="wallet-box">
            <WalletInfo :account-no="form.accountNoA2" :account-class="'6'" :account-name="form.accountName"
              :channel-name="channelName"></WalletInfo>
          </div>
        </a-form-model-item>
        <!-- 商户编号 -->
        <a-form-model-item label="商户编号" prop="mchNo">
          <a-input :disabled="!isAdd()" v-model.trim="form.mchNo" placeholder="请输入商户编号(请与银行确认)"></a-input>
        </a-form-model-item>
        <!-- 商户名称 -->
        <a-form-model-item label="商户名称" prop="mchName">
          <a-input :disabled="!isAdd()" v-model.trim="form.mchName" placeholder="请输入商户名称"></a-input>
        </a-form-model-item>
        <!-- 融资商户 -->
        <div v-if="form.mchType == '1'">
          <div class="contain-header" style="margin: 10px 0; color: #000">
            融资商户
          </div>
          <!-- 商品类目 -->
          <a-form-model-item ref="name" label="商品类目" prop="goodsType">
            <a-radio-group :disabled="isView()" placeholder="请选择商品类目" v-model="form.goodsType"
              @change="handleGoodsType($event)">
              <a-radio-button value="11"> 商品类</a-radio-button>
              <a-radio-button value="10"> 商品类(特殊)
                <a-popover>
                  <a-icon type="info-circle" theme="twoTone"/>
                  <template #content>
                    该类订单尾款会清算至可控A1
                  </template>
                </a-popover>
              </a-radio-button>
<!--              <a-radio-button value="20"> 娱乐类</a-radio-button>-->
              <a-radio-button value="30"> 资金类</a-radio-button>
              <a-radio-button value="40">代销类</a-radio-button>
<!--              <a-radio-button value="41">代销类(特殊)</a-radio-button>-->
              <a-radio-button value="50">权益类</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <!-- 回款周期 -->
          <a-form-model-item ref="name" prop="orderType" >
            <template #label>
              <span>回款周期
              <a-popover>
                <a-icon type="info-circle" theme="twoTone"/>
                <template #content>
                  无论订单是否已融资，系统会在指定周期后发起订单清算
                </template>
              </a-popover>
                </span>
            </template>
            <a-radio-group :disabled="isView()" v-model="form.orderType">
              <a-radio-button value="T+22"> 22天</a-radio-button>
              <a-radio-button value="T+25"> 25天</a-radio-button>
              <a-radio-button value="T+30"> 30天</a-radio-button>
              <a-radio-button value="T+180"> 180天</a-radio-button>
<!--              <a-radio-button value="T+10"> 10天</a-radio-button>-->
<!--              <a-radio-button value="T+1"> 1天</a-radio-button>-->
            </a-radio-group>
          </a-form-model-item>
          <!-- 融资比例 -->
          <a-form-model-item ref="name" label="融资比例" prop="refundRate">
            <a-input :disabled="isView()" type="number" v-model.trim="form.creditProp" suffix="%"
              @blur="checkIsVilide($event, 'creditProp')"></a-input>
          </a-form-model-item>
          <!-- 预计退货率 -->
          <a-form-model-item ref="name" label="预计退货率" prop="refundRate">
            <a-input :disabled="isView()" type="number" v-model.trim="form.refundRate" suffix="%"
              @blur="checkIsVilide($event, 'refundRate')"></a-input>
          </a-form-model-item>
          <!-- 借款期限 -->
          <a-form-model-item ref="name" prop="period">
            <template #label>
              <span>借款期限
              <a-popover>
                <a-icon type="info-circle" theme="twoTone"/>
                <template #content>
                  借款从发生到结清的期限，若超出该周期，贷款将逾期，会产生罚息并影响客户征信。
                </template>
              </a-popover>
                </span>
            </template>
            <a-select :disabled="true" v-model="form.period" placeholder="请选择借款期限">
              <a-select-option :value="30">30天</a-select-option>
              <a-select-option :value="45">45天</a-select-option>
              <a-select-option :value="60">60天</a-select-option>
              <a-select-option :value="90">90天</a-select-option>
              <a-select-option :value="180">180天</a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 订单有效期 -->
          <a-form-model-item ref="validityPeriod" prop="validityPeriod">
            <a-select :disabled="true" v-model="form.validityPeriod" placeholder="请选择订单融资期限">
              <a-select-option :value="1">1天</a-select-option>
              <a-select-option :value="90">90天</a-select-option>
            </a-select>
            <template #label>
              <span>订单融资期限
              <a-popover>
                <a-icon type="info-circle" theme="twoTone"/>
                <template #content>
                  超出该期限的汇总订单将无法融资，如：代销类订单可融资期限为1天，表示次日未融资的订单将自动失效，需要重新追加生成
                </template>
              </a-popover>
                </span>
            </template>
          </a-form-model-item>
          <!-- 服务费规则 -->
          <a-form-model-item label="综合服务费" prop="feeRuleNo"
            v-if="form.goodsType == '30' || form.goodsType === '40' || form.goodsType === '41'">
            <a-select v-model="form.feeRuleNo" placeholder="请选择服务费规则" :disabled="isView()">
              <a-select-option v-for="item in feeRuleList" :key="item.id" :value="item.feeNo">{{ item.feeName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 收款账号 -->
          <a-form-model-item  prop="feeAccountNo"
            v-if="form.goodsType == '30' || form.goodsType === '40' || form.goodsType === '41'"
            :rules="[{ required: form.goodsType === '30' || form.goodsType === '40' || form.goodsType === '41', message: '请填写收款账号', trigger: 'blur' }]">
            <WalletChooseDialog v-model="form.feeAccountName" account-property="2" :account-class="'0'"
              @chooseDone="onFeeWalletChosen" :isDisabled="isEdit()">
            </WalletChooseDialog>
            <template #label>
              <span>收费账号
              <a-popover>
                <a-icon type="info-circle" theme="twoTone"/>
                <template #content>
            订单清算时，在扣除利息与其他手续费后，待清算剩余资金将转入该账号
                </template>
              </a-popover>
                </span>
            </template>
          </a-form-model-item>
          <!-- 回款控制 -->
          <a-form-model-item ref="name" label="回款控制">
            <a-checkbox :disabled="isView() || isEdit()" @change="changeControl($event)" v-model="isControl"
              style="margin-right: 20px">
              回款至其他主体（可控商户）
            </a-checkbox>
            <!-- <MchChooseDialog v-if="isControl" v-model="form.fatherName" mch-type="0" custtypecd="2"
              @chooseDone="onMchChosen"></MchChooseDialog> -->
            <WalletChooseDialog v-model="form.fatherName" v-if="isControl" :account-class="'0'"
              @chooseDone="controlWalletChosen" :isDisabled="isEdit()">
            </WalletChooseDialog>
          </a-form-model-item>
          <!-- 代销提成 -->
          <a-form-model-item ref="name"  prop="royalty"
            v-if="form.goodsType === '40' || form.goodsType === '41'">
            <template #label>
              <span>代销提成
              <a-popover>
                <a-icon type="info-circle" theme="twoTone"/>
                <template #content>
                  代销客户清分支付时，默认支付金额占待清分余额的比例。实际支付金额可超出该比例
                </template>
              </a-popover>
                </span>
            </template>
            <a-input :disabled="isView()" type="number" v-model.trim="form.royalty" suffix="%"
              @blur="checkIsVilide($event, 'creditProp')"></a-input>
          </a-form-model-item>
          <!-- 指定贷款产品 -->
          <a-form-model-item ref="name" label="指定贷款产品" prop="productId" v-if="false">
            <ProductChooseDialog v-model="form.productName" :channelNo="form.channelNo" :departmentId="form.departmentId"
              @chooseDone="onProductChosen">
            </ProductChooseDialog>
          </a-form-model-item>
        </div>
        <!-- 手续费项目 -->
        <div>
          <div class="contain-header" style="margin: 20px 0; color: #000">
            清算还款手续费
            <span style="float: right">
              <a-button v-if="!isView()" type="primary" @click="dialogClick('add')">新增</a-button>
            </span>
          </div>
          <table class="UserTableBody">
            <thead>
              <tr>
                <th>No</th>
                <th>服务费名目</th>
                <th>服务费规则</th>
                <th>收款方钱包</th>
                <th>备注</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in form.mchFeeInfos" :key="index">
                <!-- No -->
                <td>
                  <span>{{ index + 1 }}</span>
                </td>
                <!-- 服务费明目 -->
                <td>
                  <span>{{ item.projectName }}</span>
                </td>
                <!-- 服务费编号 -->
<!--                <td>-->
                  <!-- <span>{{ `FEE-PROJ-${item.feeRuleNo}` }}</span> -->
<!--                  <span>{{ item.projectNo }}</span>-->
<!--                </td>-->
                <!-- 服务费规则 -->
                <a-select v-model="item.feeRuleNo" placeholder="服务费规则,可搜索" :disabled="isView() " show-search :filter-option="filterOption">
                  <a-select-option v-for="item in feeRuleList" :key="item.id" :value="item.feeNo">{{ item.feeName }}</a-select-option>
                </a-select>
                <!-- 收款方钱包 -->
                <td>
                  <WalletInfo v-if="item.feeAccountNo" :account-no="item.feeAccountNo" :account-name="item.feeAccountName" :show-account-name="true" ></WalletInfo>
                </td>
                <!-- 备注-->
                <td>
                  <span>{{ item.note }}</span>
                </td>
                <!-- 操作 -->
                <td v-if="!isView()">
                  <span>
                    <a-button type="link" icon="edit" @click="dialogClick('edit', index)"></a-button>
                    <a-button type="link" @click="clickShowDialog('del', index)">
                    <a-icon type="delete" theme="twoTone" two-tone-color="red"/>
                    </a-button>
                  </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div  v-if="$authority('mchPayFee')">
          <div class="contain-header" style="margin: 20px 0; color: #000">
            支付手续费
            <span style="float: right">
              <a-button v-if="!isView()" type="primary" @click="dialogClick('payfeeAdd')">新增</a-button>
            </span>
          </div>
          <table class="UserTableBody">
            <thead>
            <tr>
              <th>No</th>
              <th>服务费名目</th>
              <th>服务费规则</th>
              <th>收款方钱包</th>
              <th>备注</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in form.mchFeePayInfos" :key="index">
              <!-- No -->
              <td>
                <span>{{ index + 1 }}</span>
              </td>
              <!-- 服务费明目 -->
              <td>
                <span>{{ item.projectName }}</span>
              </td>
              <!-- 服务费编号 -->
              <!--                <td>-->
              <!-- <span>{{ `FEE-PROJ-${item.feeRuleNo}` }}</span> -->
              <!--                  <span>{{ item.projectNo }}</span>-->
              <!--                </td>-->
              <!-- 服务费规则 -->
              <a-select v-model="item.feeRuleNo" placeholder="服务费规则,可搜索" :disabled="isView() " show-search :filter-option="filterOption">
                <a-select-option v-for="item in feePayRuleList" :key="item.id" :value="item.feeNo">{{ item.feeName }}</a-select-option>
              </a-select>
              <!-- 收款方钱包 -->
              <td>
                <WalletInfo v-if="item.feeAccountNo" :account-no="item.feeAccountNo" :account-name="item.feeAccountName" :show-account-name="true" ></WalletInfo>
              </td>
              <!-- 备注-->
              <td>
                <span>{{ item.note }}</span>
              </td>
              <!-- 操作 -->
              <td v-if="!isView()">
                  <span>
                    <a-button type="primary" ghost @click="dialogClick('payfeeEdit', index)">
                      编辑
                    </a-button>
                    <a-button type="danger" ghost @click="clickShowDialog('payfeeDel', index)">
                      删除
                    </a-button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- 渠道信息 -->
        <div>
          <div class="contain-header" style="margin: 20px 0; color: #000">
            渠道信息
          </div>
          <a-form-model-item ref="name" >
            <CustChooseDialog custtypecd="2" v-model="agentCustname"
                :custnum="form.agentCustnum"
                @chooseDone="onAgentChosen">
            </CustChooseDialog>
            <template #label>
              <span>二级渠道
              <a-popover>
                <a-icon type="info-circle" theme="twoTone"/>
                <template #content>
                仅影响日报口径，若不配置，则取手续费配置的合作方渠道
                </template>
              </a-popover>
                </span>
            </template>
          </a-form-model-item>
        </div>
        <!-- 审批信息 -->
        <!-- <div v-if="this.$route.query.type === 'approve'">
          <div class="contain-header" style="margin: 20px 0; color: #000">
            审批信息
          </div>
          <div class="contain_body">
            <div class="body_item">
              <div class="item_left">
                当前状态:
              </div>
              <div class="item_right">
                <span v-if="form.checkStatus === '0'">待审批</span>
                <span v-else>已审批</span>
              </div>
            </div>
            <div class="body_item">
              <div class="item_left">
                客户经理:
              </div>
              <div class="item_right">
                <a-input v-model="form.custmgrnum" placeholder="请输入客户经理号"></a-input>
              </div>
            </div>
          </div>
          <div class="contain_bottom">
            <a-button type="primary" @click="pushToBank">审批通过</a-button>
            <a-button @click="cancelBtn">取消</a-button>
          </div>
        </div> -->
        <div v-if="this.$route.query.type === 'approve'">
          <div class="contain-header" style="margin: 20px 0; color: #000">
            审批信息
          </div>
        <div style="margin-bottom: 15px;" >
          <a-row>
            <a-col :span="4">当前状态:</a-col>
            <a-col :span="4">
              <span v-if="form.checkStatus === '0'">待审批</span>
              <span v-else>已审批</span>
            </a-col>
          </a-row>
        </div>
        <a-row class="no-print">
          <a-col :span="12">
            <div class="container-item ">
              <a-row>
                <a-col :span="8">推荐人:</a-col>
                <a-col :span="16" style="display: flex">
                  <a-input v-if="!showInputUserSelect" v-model="form.inputUserName" placeholder="请输入推荐人名称"></a-input>
                  <a-select v-if="showInputUserSelect" v-model="form.custmgrnum" @change="onInputUserChange" size="small"
                    placeholder="请选择推荐人">
                    <a-select-option v-for=" item  in  presetInputUserList " :key="item.inputUserId"
                      :value="item.inputUserId">
                      {{ item.inputUserId + "-" + item.inputUserName }}
                    </a-select-option>
                  </a-select>
                  <a-button v-if="!showInputUserSelect" @click="showInputUserSelect = true" type="link">选择常用</a-button>
                  <a-button v-if="showInputUserSelect" @click="showInputUserSelect = false" type="link">手动输入</a-button>
                </a-col>
              </a-row>
            </div>
            <div class="container-item " v-if="!showInputUserSelect">
              <a-row>
                <a-col :span="8">法人机构名称:</a-col>
                <a-col :span="16" style="display: flex">
                  <a-input v-model="form.corpOrgName" placeholder="请输入法人机构名称"></a-input>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8">所属机构名称:</a-col>
                <a-col :span="16" style="display: flex">
                  <a-input v-model="form.operateOrgName" placeholder="请输入所属机构名称"></a-input>
                </a-col>
              </a-row>
            </div>

          </a-col>
          <a-col :span="12">
            <div class="container-item" v-if="!showInputUserSelect">
              <a-row>
                <a-col :span="8">推荐人工号:</a-col>
                <a-col :span="16" style="display: flex">
                  <a-input v-model="form.inputUserId" placeholder="请输入推荐人工号"></a-input>
                </a-col>
              </a-row>
            </div>
            <div class="container-item" v-if="!showInputUserSelect">
              <a-row>
                <a-col :span="8">法人机构号:</a-col>
                <a-col :span="16" style="display: flex">
                  <a-input v-model="form.corpOrgId" placeholder="请输入法人机构号"></a-input>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8">所属机构号:</a-col>
                <a-col :span="16" style="display: flex">
                  <a-input v-model="form.operateOrgId" placeholder="请输入所属机构号"></a-input>
                </a-col>
              </a-row>
            </div>


          </a-col>
        </a-row>
          <div class="contain_bottom">
            <a-button type="primary" @click="pushToBank">审批通过</a-button>
            <a-button @click="cancelBtn">取消</a-button>
          </div>
        </div>
      </a-form-model>
    </div>
    <!-- 取消和保存/删除弹框 -->
    <div class="dialog-box">
      <a-modal title="提示" :visible="isVisibleDialog" :body-style="{ textAlign: 'center' }" :centered="true"
        @cancel="clickCancel">
        <template slot="footer">
          <a-button type="primary" @click="clickConfirm">{{
            confirmText
          }}</a-button>
          <a-button @click="clickCancel">取消</a-button>
        </template>
        <p>{{ dialogText }}</p>
      </a-modal>
    </div>
    <!-- 新增手续费弹框 -->
    <AddServiceFeeDialog ref="AddServiceFeeDialog" :propsData="propsData" :custnum="form.custnum" :payFee="propsData.payFee"
      departmentId="form.departmentId" @handleEmit="handleEmit"></AddServiceFeeDialog>
  </a-card>
</template>

<script>
// 引入新增手续费弹框
import AddServiceFeeDialog from "../../Components/AddServiceFeeDialog.vue";
import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog.vue";
import MchChooseDialog from "@/view/components/ChooseDialog/MchChooseDialog.vue";
import WalletInfo from "@/view/components/WalletInfo.vue";
// 贷款产品组件
import ProductChooseDialog from "@/view/components/ChooseDialog/ProductChooseDialog.vue";
import CustChooseDialog from "@/view/components/ChooseDialog/CustChooseDialog.vue";
export default {
  name: "AddMchManage",
  components: {CustChooseDialog, AddServiceFeeDialog, WalletChooseDialog, MchChooseDialog, WalletInfo, ProductChooseDialog },
  data() {
    return {
      propsData: {
        isShowDialog: false,
        type: "",
        payFee: '',
      },
      showInputUserSelect: true,
      // 选中手续费数据的索引
      selectDataIndex: "",
      // 渠道（核心企业）名称
      channelName: undefined,
      controlName: '',
      // 表单数据
      agentCustname: '',
      form: {
        accountNoA1KK: '',
        accountNoA2KK: '',
        custtypecdKK: "",
        // 所属机构
        departmentId: undefined,
        // 渠道编号
        channelNo: undefined,
        // 应用编号
        appNo: "",
        // 商户编号
        mchNo: "",
        // 商户名称
        mchName: "",
        // 商户类型
        mchType: "1",
        // 店铺类型(客户类型)
        custtypecd: "",
        // 客户编号
        custnum: undefined,
        // 客户名称
        custname: "",
        // 电子账户
        accountNoA1: undefined,
        accountNoA2: '',
        // 商户户名
        accountName: "",
        // 商品类目(产品类型)
        goodsType: "11",
        // 回款周期(订单类型)
        orderType: "T+22",
        // 融资比例
        creditProp: 75,
        // 预计退货率
        refundRate: 5,
        royalty: 10,//代销提成
        // 	贷款产品编号
        productId: '',
        productName: '',
        // 借款期限
        period: 90,
        validityPeriod: 90,
        // 服务费规则编号
        feeRuleNo: undefined,
        feeAccountNo: '',
        feeAccountName: '',
        // 父id(可控商户的id)
        fatherId: undefined,
        // 可控商户名称
        fatherName: undefined,
        // 商户服务费项目表
        mchFeeInfos: [],
        mchFeePayInfos: [],
        // 客户经理号
        custmgrnum: '',
        inputUserName: '',
        corpOrgName: "",
        corpOrgId: "",
        operateOrgName: '',
        operateOrgId: '',
        // 渠道客户号
        agentCustnum: '',
        // 渠道客户名称
      },
      //页面数据
      rules: {
        // 所属机构
        departmentId: [
          { required: true, message: "请选择机构", trigger: "change" },
        ],
        // 核心企业
        channelNo: [
          { required: true, message: "请选择核心企业", trigger: "change" },
        ],
        // 商户编号
        mchNo: [{ required: true, message: "请填写商户编号", trigger: "blur" }],
        // 商户名称
        mchName: [
          { required: true, message: "请填写商户名称", trigger: "blur" },
        ],
        // 商户类型
        mchType: [
          { required: true, message: "请选择商户类型", trigger: "change" },
        ],
        // 店铺类型(客户类型)
        custtypecd: [
          { required: true, message: "请选择店铺类型", trigger: "change" },
        ],
        // 客户
        custnum: [{ required: true, message: "请选择客户", trigger: "change" }],
        // 电子账户
        accountName: [{ required: true, message: '请选择电子账户', trigger: 'change' }],
        //商品类目
        goodsType: [
          { required: true, message: "请选择商品目录", trigger: "change" },
        ],
        //回款周期
        orderType: [
          { required: true, message: "请选择回款周期", trigger: "change" },
        ],
        //预计退款率
        refundRate: [
          { required: true, message: "请填写预计退货率", trigger: "blur" },
        ],
        // 代销提成
        royalty: [
          { required: true, message: "请填写代销提成", trigger: "blur" },
        ],
        //指定贷款产品
        // productId: [
        //   { required: true, message: "请选择贷款产品", trigger: "change" },
        // ],
        //借款期限
        period: [
          { required: true, message: "请选择借款期限", trigger: "change" },
        ],
        //订单作废期限
        validityPeriod: [
          { required: true, message: "请选择订单作废期限", trigger: "change" },
        ],
        // 服务费规则
        feeRuleNo: [{
          required: true,
          message: '请选择服务费规则',
          trigger: 'change'
        }],

      }, //验证
      // 树形结构
      treeData: [],
      // 平级结构
      institutiontrees: [],
      // 核心企业数据列表
      institutionList: [],
      // 核心企业值
      institutionValue: undefined,
      // 客户数据列表
      customerList: [],
      // 电子账户列表
      mchAccountList: [],
      // 可控商户check
      isControl: false,
      // 可控商户数据
      controlMchList: [],
      // 贷款产品数据
      loanList: [],
      // 父组件内的弹框
      isVisibleDialog: false,
      // 控制弹框类型(保存/取消/删除)
      dialogType: "",
      // 父组件内的弹框文本
      dialogText: "",
      // 父组件内的确认按钮文本
      confirmText: '',
      feeRuleList: [],
      feePayRuleList: [],
      presetInputUserList: this.$store.state.mchInputUser,
    };
  },
  watch: {},
  created() {
    // 规则
    this.getFeeRule()
    if (this.$route.query.type == "add") {
      // do nothing
    } else if (this.$route.query.type == "edit") {
      this.getMchManageData();
    } else if (this.$route.query.type == "view") {
      this.getMchManageData();
    } else if (this.$route.query.type == "approve") {
      this.getMchManageData();

    }
  },
  mounted() {
    if (this.$route.query.type != 'add') {
      sessionStorage.setItem('msgInfo', true)
    }
  },
  computed: {
    title: function () {
      if (this.$route.query.type == "add") {
        return "商户管理>新增";
      } else if (this.$route.query.type == "edit") {
        return "商户管理>编辑";
      } else if (this.$route.query.type == "view") {
        return "商户管理>查看";
      } else if (this.$route.query.type == "approve") {
        return "商户管理>审批";
      } else {
        return "";
      }
    },
    isView() {
      return function () {
        return this.$route.query.type == "view" || this.$route.query.type === 'approve';
      };
    },
    isEdit() {
      return function () {
        return this.$route.query.type == "edit";
      };
    },
    isAdd() {
      return function () {
        return this.$route.query.type == "add";
      };
    }
  },
  methods: {
    onInputUserChange(inputUserId) {
      let inputUser = this.presetInputUserList.find(s => s.inputUserId == inputUserId)
      this.form.inputUserId = inputUser.inputUserId
      this.form.inputUserName = inputUser.inputUserName
      this.form.corpOrgId = inputUser.corpOrgId
      this.form.corpOrgName = inputUser.corpOrgName
      this.form.operateOrgId = inputUser.operateOrgId
      this.form.operateOrgName = inputUser.operateOrgName
    },
    // 审批
    async pushToBank() {
      if(this.form.inputUserId === '' || this.form.inputUserName === ''
      || this.form.corpOrgId === '' || this.form.corpOrgName === ''
      || this.form.operateOrgId === '' || this.form.operateOrgName === ''){
        this.$message.error('推荐人信息字段不能为空')
        return
      }
      let data = JSON.parse(JSON.stringify(this.form))
      // 融资比例
      data.creditProp = data.creditProp / 100
      // 预计退货率
      data.refundRate = data.refundRate / 100
      //代销提成
      // data.royalty = data.royalty / 100
      data.synch = '0'
      let res = await this.api.newAddMchManageApi(data);
      if (res.code === 200) {
        //调用同步接口
        let res1 = await this.api.mchSyncToApi({
          id: this.$route.query.id,
        })
        if (res1.code !== 200) {
          this.$message.error(res1.info)
        } else {
          this.$message.success(res1.info)
          this.$router.back(-1)
        }
      } else {
        this.$message.error(res.info)
      }
    },
    cancelBtn() {
      // 点击审批旁边的取消直接跳回列表页
      this.$router.back(-1)
    },
    async controlWalletChosen(control) {
      // console.log(control, 'control');
      this.form.custtypecdKK = control.accountProperty
      this.form.accountNoA1KK = control.accountNo
      let res = await this.api.custaccountPageApi({
        pageNo: 0,
        pageSize: 1000,
        custnum: control.custnum,
        channelNo: control.channelNo
      })
      if (res.code !== 200) {
        this.$message.error("获取钱包信息出错啦，请稍后再试")
      }
      this.form.accountNoA2KK = res.data.records.find(acct => acct.majorAccountNo === this.form.accountNoA1KK && acct.accountClass === "6")?.accountNo
    },
    // 选中回款可控商户
    async onMchChosen(mch) {
      if (!mch.id) {
        return; //不存在父级可控商户
      }
      this.form.fatherId = mch.id
      // 根据商户id获取商户名称
      let res = await this.api.getMchManageDataApi({ id: mch.id })
      this.form.fatherName = res.data?.mchName
      // 用于编辑查看时候的回显
      this.isControl = true
      // this.form.fatherId = mch.mchNo
    },
    // 商户类型change事件
    // changeMchType(event) {
    //   let value = event.target.value;
    //   if (value === "0") {
    //     // 可控商户,默认选中企业法人，调用change事件
    //     // this.form.custtypecd = "2";
    //     this.changeCusttypecd();
    //   } else if (value === "1") {
    //     // 融资商户
    //     // this.form.custtypecd = "2";
    //   }
    // },
    // 店铺类型change事件
    changeCusttypecd() {
      this.form.custnum = undefined;
    },
    onProductChosen(product) {
      // console.log(product, 'product');
      this.form.productId = product.productId;
    },
    // 回款控制
    async changeControl() {
      if (!this.isControl) {
        this.form.fatherId = ""
        this.form.fatherName = ""
      }
    },
    // 新增或编辑手续费按钮
    dialogClick(type, ind) {
      this.selectDataIndex = ind;
      if (type == "add") {
        // 新增
        this.propsData.type = "add";
        this.propsData.payFee = '0';
      } else if (type == "edit") {
        // 编辑
        this.propsData.payFee = '0';
        this.propsData.type = "edit";
        // this.form.mchFeeInfos[ind]
        this.$refs.AddServiceFeeDialog.form = JSON.parse(
            JSON.stringify(this.form.mchFeeInfos[ind])
        );
      } else if (type == "payfeeAdd") {
        // 新增
        this.propsData.type = "payfeeAdd";
        this.propsData.payFee = '1';
      } else if (type == "payfeeEdit") {
        // 编辑
        this.propsData.type = "payfeeEdit";
        this.propsData.payFee = '1';
        this.$refs.AddServiceFeeDialog.form = JSON.parse(
            JSON.stringify(this.form.mchFeePayInfos[ind])
        );
      }
      this.propsData.isShowDialog = true;

    },
    // 处理子组件传值弹框事件
    handleEmit(obj) {
      console.log("this.propsData.type",this.propsData.type)
      if (obj.type == "ok") {
        console.info(this.form, this.form.mchFeeInfos)
        // 确认按钮
        // console.log(obj.data, 'formdata');
        if (this.propsData.type == "add") {
          this.form.mchFeeInfos.push(obj.data);
        } else if (this.propsData.type == "edit") {
          this.form.mchFeeInfos.splice(this.selectDataIndex, 1, obj.data);
        } else if (this.propsData.type == "payfeeAdd") {
          console.log( obj.data, "payfeeAdd")
          this.form.mchFeePayInfos.push(obj.data);
          console.info("mchFeePayInfos", this.form.mchFeePayInfos)
        } else if (this.propsData.type == "payfeeEdit") {
          console.log( obj.data, "payfeeEdit")
          this.form.mchFeePayInfos.splice(this.selectDataIndex, 1, obj.data);
        }
      } else if (obj.type == "cancel") {
        // 取消按钮
      }
      this.propsData.isShowDialog = obj.bool;
    },
    // 删除手续费项目
    async delFeeProject() {
      if (this.$route.query.type == "edit") {
        // 调用删除接口
        let idArr = [];
        idArr.push(this.form.mchFeeInfos[this.selectDataIndex].id);
        let res = await this.api.delMchFeeDataApi(idArr);
        if (res.code == 200) {
          console.log(res, "删除成功");
        }
      }
      this.form.mchFeeInfos.splice(this.selectDataIndex, 1);

    }, // 删除手续费项目
    async delPayFeeProject() {
      console.log("payfeeDel",this.$route.query.type)
      if (this.$route.query.type == "edit") {
        // 调用删除接口
        let idArr = [];
        idArr.push(this.form.mchFeePayInfos[this.selectDataIndex].id);
        console.log("idArr",idArr)
        let res = await this.api.delMchFeePayDataApi(idArr);
        if (res.code == 200) {
          console.log(res, "删除成功");
        }
      }
      console.log("this.form.mchFeePayInfos",this.form.mchFeePayInfos)
      this.form.mchFeePayInfos.splice(this.selectDataIndex, 1);
      console.log("this.form.mchFeePayInfos",this.form.mchFeePayInfos)
    },
    // 保存接口
    async saveEvent() {
      console.info(this.form)
      if (this.form.mchFeePayInfos.length > 1) {
        this.$message.error("支付服务费项目只能有一个")
        return
      }
      let data = JSON.parse(JSON.stringify(this.form))
      // 融资比例
      data.creditProp = data.creditProp / 100
      // 预计退货率
      data.refundRate = data.refundRate / 100
      //代销提成
      // data.royalty = data.royalty / 100

      if (this.$route.query.type == 'add') {
        let res1 = await this.api.newAddMchManageApi(data)
        if (res1.code == 200) {
          this.$router.push({
            name: "MchManage",
            query: {
              fromAddPage: true
            }
          });
        } else {
          this.$message.error(res1.info)
        }
      } else if (this.$route.query.type == "edit") {
        data.synch = '0'
        let res2 = await this.api.newAddMchManageApi(data);
        if (res2.code == 200) {
          this.$message.success(res2.info)
          this.$router.push({
            name: "MchManage",
            query: {
              fromEditPage: true
            }
          });
        } else {
          this.$message.error(res2.info)
        }
      }
    },
    // 校验
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        // console.log(valid, 'valid')
        if (valid) {
          // 校验成功打开弹框
          this.clickShowDialog("save");
        } else {
          console.log("error submit!!");
        }
      });
    },
    // 重置
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    //点击保存/取消/删除事件
    clickShowDialog(type, ind) {
      this.selectDataIndex = ind;
      if (type == "save") {
        if (this.form.mchFeeInfos.length === 0) {
          return this.$message.error('请配置手续费')
        }
        if (this.form.mchFeeInfos.find(fee => !fee.feeRuleNo)) {
          return this.$message.error('请配置手续费')
        }
        this.dialogType = "save";
        this.confirmText = "保存";
        this.dialogText = "是否确认新增商户信息？";
      } else if (type == "cancel") {
        if (this.$route.query.type !== 'view') {
          this.dialogType = "cancel";
          this.confirmText = "确认";
          this.dialogText = "是否取消新增？取消后该页面信息将不会被保存";
        } else {
          this.$router.back(-1)
        }
      } else if (type == "del") {
        this.dialogType = "del";
        this.confirmText = "确认";
        this.dialogText = "是否删除该手续费项目?";
      } else if (type == "payfeeDel") {
        this.dialogType = "payfeeDel";
        this.confirmText = "确认";
        this.dialogText = "是否删除该支付服务费项目?";
      }
      this.isVisibleDialog = true;
    },
    // 父组件弹框确认事件
    clickConfirm() {
      if (this.dialogType == "save") {
        // 弹框类型是保存,调用保存接口
        this.saveEvent();
      } else if (this.dialogType == "cancel") {
        // 弹框是取消
        // this.$store.commit('delKeepAliveList', 'MchManage')
        this.$router.push({
          name: "MchManage",
        });
      } else if (this.dialogType == "del") {
        // 弹框是删除手续费
        this.delFeeProject();
      } else if (this.dialogType == "payfeeDel") {
        // 弹框是删除手续费
        this.delPayFeeProject();
      }
      this.isVisibleDialog = false;
    },
    // 父组件弹框取消事件
    clickCancel() {
      this.isVisibleDialog = false;
    },
    // 获取服务费规则
    async getFeeRule() {
      let data = {};
      let res = await this.api.getCommisionFeeRuleApi(data);
      if (res.code == 200) {
        // console.log(res, '服务费规则');
        this.feeRuleList = res.data;
        this.feePayRuleList =  res.data.filter(item => item.feeType !== '4');
        console.log(this.feePayRuleList,"feePayRuleList")
      }
    },

    // 返回手续费规则的方法
    findFeeRuleName(id) {
      // console.log(this.feeRuleList);
      let ruleName = "";
      for (let i = 0; i < this.feeRuleList.length; i++) {
        if (this.feeRuleList[i].feeNo == id) {
          ruleName = this.feeRuleList[i].feeName;
        }
      }
      return ruleName;
    },
    // 校验融资比例和预计退货率
    checkIsVilide(event, type) {
      if (Number(this.form.refundRate) > 100) {
        this.form.refundRate = ''
        return this.$message.error('融资比例不能超过100!')
      }
      if (Number(this.form.creditProp) > 100) {
        this.form.creditProp = ''
        return this.$message.error('预计退货率不能超过100!')
      }
      if (Number(this.form.royalty) > 100) {
        this.form.royalty = ''
        return this.$message.error('代销提成不能超过100!')
      }
    },
    // 选择钱包
    async onWalletChosen(wallet) {
      console.log(wallet, 'wallet');
      // 回填钱包归属客户号
      this.form.custnum = wallet.custnum
      this.form.accountNoA1 = wallet.accountNo
      // this.form.mchName = this.form.mchName ? this.form.mchName : wallet.accountName
      this.form.mchName = wallet.accountName
      // 回填有无经营企业
      this.form.custtypecd = wallet.accountProperty
      // 选中的钱包只有A1账号，需要用A1再去取一次A2
      let queryParam = {
        custnum: this.form.custnum,
        // channelNo: this.form.channelNo,
        // 应该是选中客户的渠道
        channelNo: wallet.channelNo,
      };
      let res = await this.api.custaccountPageApi({
        pageNo: 0,
        pageSize: 1000,
        ...queryParam
      })
      if (res.code !== 200) {
        this.$message.error("获取钱包信息出错啦，请稍后再试")
      }
      // 回填钱包相关信息
      let walletInfo = res.data.records.find(acct => acct.accountNo === this.form.accountNoA1)
      console.log(walletInfo);
      this.form.channelNo = walletInfo.channelNo;
      this.form.appNo = walletInfo.appNo;
      this.form.accountNoA1 = walletInfo.accountNo;
      this.form.accountName = walletInfo.accountName;
      this.form.custname = walletInfo.custname;
      this.form.departmentId = walletInfo.departmentId;
      this.form.accountNoA2 = res.data.records.find(acct => acct.majorAccountNo === this.form.accountNoA1 && acct.accountClass === "6")?.accountNo
      // console.info("2", this.form.accountName)
      // 核心企业即渠道名称，直接取session缓存中根据channelNo对应
      this.channelName = JSON.parse(this.$session.getItem("coreCompanyList"))
        .find(i => i.channelNo === this.form.channelNo)?.channelName
      // 刷新钱包对应归属渠道的贷款产品
      // this.getProductList();
      this.initFeeRules(walletInfo.channelNo)
    },

    async onAgentChosen(cust) {
      this.form.agentCustnum = cust.custnum
      // 加载代理商信息
      if (this.form.agentCustnum) {
        this.api.getEnterpriseCust(this.form.agentCustnum)
        .then(res => {
          if (res.code == 200) {
            this.agentCustname = res.data.custCompanyInfo.custname;
          }
        })
      }
    },
    // 获取详请
    async getMchManageData() {
      // console.log(this.$route.query, 'query');
      let data = {};
      data.id = this.$route.query.id;
      let res = await this.api.getMchManageDataApi(data);
      if (res.code == 200) {
        // 融资比例
        res.data.creditProp = res.data.creditProp * 100
        res.data.refundRate = res.data.refundRate * 100
        // res.data.royalty = res.data.royalty * 100

        this.form = res.data
        // 获取贷款产品结果
        this.form.mchFeeInfos = this.form.mchFeeInfos ?? []
        this.form.mchFeePayInfos = this.form.mchFeePayInfos ?? []
        if (res.data.fatherId) {
          // 触发并加载商户钱包、 回款商户信息
          // await this.onWalletChosen({ accountNo: this.form.accountNoA1, custnum: this.form.custnum, accountName: this.form.mchName, accountProperty: this.form.custtypecd })
          await this.onMchChosen({ id: this.form.fatherId })
          await this.onAgentChosen({custnum: this.form.agentCustnum})
        }
      }
    },
    // 商品类目
    handleGoodsType(event) {
      const value = event.target.value;
      if (value === '30' || value === '40' || value === '41'|| value === '50') {
        // 为资金或者代销类目时， 退货率0%  融资比例100%, 1天后订单作废
        this.form.creditProp = 100
        this.form.refundRate = 0
        this.form.validityPeriod = 1
      } else {
        // 其他类的， 默认退货率5%  融资比例 75%， 90天订单作废
        this.form.creditProp = 75
        this.form.refundRate = 5
        this.form.validityPeriod = 90
      }
    },
    onFeeWalletChosen(wallet) {
      this.form.feeAccountNo = wallet.accountNo
    },
    initFeeRules(channelNo) {
      if(this.form.mchFeeInfos?.length===0){
        this.form.mchFeeInfos = JSON.parse(JSON.stringify(this.$store.state.mchFeeTpl[channelNo]))
      }
      if(this.form.mchFeePayInfos?.length===0){
        this.form.mchFeePayInfos = JSON.parse(JSON.stringify(this.$store.state.mchPayFeeTpl[channelNo]))
      }
    },
    filterOption(input, option) {
      const text = option.componentOptions.children[0].text
      return text.indexOf(input) >= 0 || text.replaceAll(/\./g,'').indexOf(input) >= 0
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-card-head {
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #fff;
}

/deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;

  .ant-form-item-label {
    width: 260px;
    background-color: rgba(242, 242, 242, 1);
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
    border: 1px solid rgba(242, 242, 242, 1);
    padding: 5px 40px;

    .ant-input,
    .el-input__inner {
      max-width: 200px;
    }

    .ant-input-affix-wrapper {
      width: 200px;
    }

    .ant-select {
      width: 200px;
    }

    .ant-calendar-picker {
      width: 200px;
    }

    .wallet-box {
      width: 220px;
    }
  }

  /deep/ .ant-form-item-control-wrapper {
    min-height: 180px;
  }

  .remind-text-area {
    width: 100%;
    height: 40px;
    position: relative;

    .remind-text {
      color: red;
    }

    .remind-text1 {
      position: absolute;
      left: 175px;
      top: 0px;
    }

    .remind-text2 {
      position: absolute;
      left: 560px;
      top: 0px;
    }
  }
}

.UserTableBody {
  // min-width: 800px;
  width: 100%;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;

  thead {
    tr:nth-child(1) {
      background-color: #f2f2f2;
      text-align: center;
      height: 30px;
    }
  }

  tbody {
    td {
      min-width: 100px;
      box-sizing: border-box;
      padding: 5px;
      border-right: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
    }

    td:last-child {
      text-align: center;

      .ant-btn {
        margin-right: 0;
        border: none;
      }
    }

    span {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }
}

.container-item {
  margin-bottom: 15px;
  margin-right: 15px;

  .ant-row {
    display: flex;
    align-items: center;

    .ant-input,
    .ant-select {
      width: 200px;
    }
  }
}

.contain_body {
  margin-bottom: 20px;

  .body_item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .item_left {
      margin-right: 20px;
    }

  }
}

.pricebox {
  width: 100%;

  /deep/ .ant-form-item-label {
    min-height: 180px;
    padding-left: 30px;
    display: flex;
    align-items: center;
  }

  /deep/ .ant-form-item-control-wrapper {
    min-height: 180px;
  }

  .row {
    display: flex;
    margin-top: 25px;

    .item {
      margin-left: 50px;
      display: flex;
      // width: 600px;
      align-items: center;

      /deep/.ant-upload {
        width: 200px;
        height: 122px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        padding: 0px;
        overflow: hidden;

        .anticon-plus {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .ant-upload.ant-upload-select-picture-card {
        border: 1px solid #ccc;
      }

      .ant-upload.ant-upload-select-picture-card>.ant-upload {
        padding: 0;
      }

      .ant-upload-select-picture {
        width: 200px;
        height: 122px;
        border: 0;
      }
    }
  }
}
</style>