import Vue from 'vue';
import Antd from 'ant-design-vue';
import {message} from "ant-design-vue";
import App from './App.vue';
import VueRouter from 'vue-router';
import routes from '@/route/route';
import axios from '@/utils/axios';
import authority, {authorityInit} from '@/utils/authority';
import session from '@/utils/session';
import store from "@/store";
import moment from 'moment';
import JsonViewer from 'vue-json-viewer'
import uploadfile from './utils/files.js'
import api from './api/Api'
import ElementUI from 'element-ui';
import "./css/theme.scss";
import VueClipboard from 'vue-clipboard2'
Vue.use(ElementUI);
// JSON数据展示
Vue.use(JsonViewer)
//UI
Vue.use(Antd)
Vue.use(VueClipboard)
Vue.prototype.$message = message
Vue.prototype.$uploadfile = uploadfile
message.config({
    duration: 3,// 持续时间
    top: `100px`, // 到页面顶部距离
    maxCount: 3 // 最大显示数, 超过限制时，最早的消息会被自动关闭
});
//请求
Vue.prototype.$axios = axios
//权限
Vue.prototype.$authority = authority
Vue.prototype.$authorityInit = authorityInit
//本地存储
Vue.prototype.$session = session
//时间格式化
Vue.prototype.$moment = moment
// 接口地址
Vue.prototype.api = api
//路由
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export const router = new VueRouter({
    routes,
    mode: 'history',
})
//路由拦截
router.beforeEach((to, from, next) => {
    const isLogin = session.getItem('isLogin')
    const url = JSON.parse(session.getItem("authPage"))
    const title = to.matched[0]?.meta
    document.title = title + '-电商服务工具平台'
    // 如果用户未能验证身份，则 `next` 会被调用两次
    if (to.path !== '/login' && !isLogin) {
        next('/login')
        session.clear()
    }
    //页面过滤
    // if(to.path !== "/login" && to.path !== "/home"&& to.path !== "/404" && !(url.some((item)=>item.path === to.path))){
    //   next('/404')
    // }
    next()
})
new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
