<template>
  <div>
    <a-input placeholder="请选择产品" v-model="chosenShowName" disabled>
      <a-button slot="suffix" type="primary" :disabled="$route.query.type == 'view' || isDisabled"
        @click="startChoose">选择</a-button>
    </a-input>
    <a-modal v-if="choosing" visible="true" title="请选择产品" :footer="null" @cancel="onCancel" width="1200">
      <div class="table-header">
        <div class="header-item">
          <a-form layout="inline">
            <a-form-item label="所属机构">
              <DepartmentChoose :department-code.sync="departmentCode" :disabled="!searchable" @change="loadTableData"></DepartmentChoose>
            </a-form-item>
            <a-form-item label="产品类型">
              <a-select v-model="productType" :disabled="!searchable">
                <a-select-option value=""> 全部 </a-select-option>
                <a-select-option value="1"> 个人</a-select-option>
                <a-select-option value="2"> 企业 </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="产品编号">
              <a-input v-model="productNo" :disabled="!searchable"></a-input>
            </a-form-item>
            <a-form-item label="产品名称">
              <a-input v-model="productName" :disabled="!searchable"></a-input>
            </a-form-item>
          </a-form>
        </div>
        <div class="header-item">
          <a-button type="primary" @click="loadTableData">查询</a-button>
          <a-button @click="onReset">重置</a-button>
        </div>
      </div>
      <div class="table-main">
        <a-table :columns="columns" :data-source="data" :pagination="false" rowKey="custnum">
          <span slot="action" slot-scope="text, record">
            <a-button @click="onChoose(record)" type="primary">选择</a-button>
          </span>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";

export default {
  name: 'CustChooseDialog',
  components: {DepartmentChoose},
  model: {
    prop: 'chosenShowName',
    event: 'change'
  },
  props: {
    scope: {
      required: false,
      type: Object,
    },
    chosenShowName: {
      required: false,
      type: String,
    },
    departmentCode: { // 机构
      required: false,
      type: String,
    },
    channelNo: String,
    productId: String,
    productName: String,
    productType: String,
    searchable: { // 是否可以自定义搜索
      required: false,
      type: Boolean,
      default: true,
    },
    isDisabled: {
      required: false,
      type: Boolean,
    }
  },
  data() {
    const columns = [
      {
        title: '产品名称',
        dataIndex: 'productName',
        key: "productName",
        align: 'center',
      },
      {
        title: '产品编号',
        dataIndex: 'productId',
        key: 'productId',
        align: 'center',
      },
      {
        title: '渠道',
        dataIndex: 'channelNo',
        key: 'channelNo',
        align: 'center',
      },
      {
        title: '总授信额度',
        dataIndex: 'creditLimitAmount',
        key: 'creditLimitAmount',
        align: 'center',
      },
      {
        title: '基础利率',
        dataIndex: 'baseRate',
        key: 'baseRate',
        align: 'center',
      },
      {
        title: '还款方式',
        dataIndex: 'payMethod',
        key: 'payMethod',
        align: 'center',
      },
      {
        title: '操作',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        align: 'center',
      },
    ]
    const data = []
    return {
      choosing: false,
      columns,
      data,
    }
  },
  created() {
  },
  methods: {
    startChoose() {
      this.choosing = true
      // 加载客户列表
      this.loadTableData()
    },
    // 获取列表信息page
    async loadTableData() {
      let queryParam = {
        productType: this.productType,
        productId: this.productId,
        productName: this.productName,
        // departmentId:  this.departmentId,
        channelNo: this.channelNo,
      }
      let res = await this.api.creditProductInfolistApi(queryParam)
      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
      this.data = res.data
    },
    // 点击选择
    onChoose(record) {
      this.choosing = false
      this.$emit('change', record.productName)
      this.$emit('chooseDone', record, this.scope)
    },
    // 分页事件
    // onPagesizeChange(page) {
    //   this.pageNo = page
    //   this.loadTableData()
    // },
    onReset() {
      this.loadTableData()
    },
    //取消事件
    onCancel() {
      this.choosing = false
      this.$emit('chooseCancel')
    },
  },
}
</script>

<style lang="less" scoped>
.table-hide {
  display: none;
}

.table-show {
  display: revert;
}

/deep/ .ant-modal {
  width: 1200px;

  .ant-modal-content {
    width: 100%;
  }
}

/deep/ .table-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;

  .ant-select-selection--single {
    width: 200px;
  }
}

.paginationBox {
  margin: 10px 0;
  display: flex;
  justify-content: end;
}

/deep/ .ant-input-suffix {
  width: 0;
}
</style>
