import { render, staticRenderFns } from "./MchChooseDialog.vue?vue&type=template&id=42560a65&scoped=true"
import script from "./MchChooseDialog.vue?vue&type=script&lang=js"
export * from "./MchChooseDialog.vue?vue&type=script&lang=js"
import style0 from "./MchChooseDialog.vue?vue&type=style&index=0&id=42560a65&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42560a65",
  null
  
)

export default component.exports