<template>
  <!-- 个人客户关联用户页面 -->
  <a-card title="用户关联关系绑定" style="margin: 20px">
    <template slot="extra">
      <a-button type="primary" @click="isSave = true">保存</a-button>
      <a-button @click="isCancel = true">取消</a-button>
    </template>
    <!-- 主体部分 -->
    <div class="container">
      <div class="formBox">
        <a-form>
          <a-form-item label="用户名称">
            <a-select
              v-model="usernameValue"
              :options="userNameList"
              placeholder="请选择用户"
              @change="changeUserName($event)"
            >
            </a-select>
          </a-form-item>
          <a-form-item label="客户">
            <div
              class="custListItem"
              v-for="(item, index) in enterPriseList"
              :key="index"
            >
              <a-input
                v-model="item.custnum"
                disabled
                placeholder="客户编号"
              ></a-input>
              <a-input
                style="margin: 0 20px"
                v-model="item.custname"
                disabled
                placeholder="客户名称"
              ></a-input>
              <a-button @click="delConnectCust(index)" v-if="isShowDelBtn()"
                >-</a-button
              >
            </div>
          </a-form-item>
          <a-form-item label="客户类型">
            <div>企业</div>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <!-- 确定和取消弹框 -->
    <div class="saveBox">
      <a-modal
        title="提示"
        :visible="isSave"
        :body-style="{ textAlign: 'center' }"
        :centered="true"
        @cancel="isSave = false"
      >
        <template slot="footer">
          <a-button type="primary" @click="handleOk">保存</a-button>
          <a-button @click="isSave = false">取消</a-button>
        </template>
        <p>是否确认关联至该账号？</p>
      </a-modal>
    </div>
    <div class="cancelBox">
      <a-modal
        title="提示"
        :visible="isCancel"
        :body-style="{ textAlign: 'center' }"
        :centered="true"
        @cancel="isCancel = false"
      >
        <template slot="footer">
          <a-button type="primary" @click="cancleEvent">确认</a-button>
          <a-button @click="isCancel = false">取消</a-button>
        </template>
        <p>是否取消？取消后信息将不会被关联</p>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      // 客户编号
      custnum: "",
      // 客户名称
      custname: "",
      // 客户类型
      custtypecd: "2",
      // 用户名称
      userNameList: [],
      // 客户信息
      enterPriseList: [],
      isSave: false,
      isCancel: false,
      departmentId: "",
      usernameValue: "",
      userId: "",
      userList: [],
    };
  },
  computed: {
    ...mapState("customer", ["associatedEnterpriseParam"]),
    isShowDelBtn() {
      return function () {
        if (this.enterPriseList.length == 1) {
          return false;
        } else {
          return true;
        }
      };
    },
  },
  created() {
    this.enterPriseList = JSON.parse(
      JSON.stringify(this.associatedEnterpriseParam)
    );

    // 获取用户名
    this.getUserName();
  },

  methods: {
    changeUserName(event) {
      // console.log(event, 'ee')
      this.userId = event;
    },
    async getUserName() {
      let res = await this.api.getAssociatedCust();
      // console.log(res, "association");
      if (res.code == 200) {
        // 将获取到的用户信息保存起来
        this.userList = res.data;
        res.data.forEach((item) => {
          let obj = {
            value: item.id,
            label: item.username,
          };
          // 重构用户名
          this.userNameList.push(obj);
        });
      }
      // console.log(this.userList, "user");
    },
    // 保存弹框里的确定
    async handleOk() {
      let data = {};
      data.userId = this.userId;
      this.userList.forEach((item) => {
        if (item.id === this.userId) {
          data.departmentId = item.departmentId;
        }
      });
      let custUserInfos = [];
      this.enterPriseList.forEach((item) => {
        let obj = {};
        obj.id = item.id;
        obj.custname = item.custname;
        obj.custnum = item.custnum;
        obj.custtypecd = this.custtypecd;
        custUserInfos.push(obj);
      });
      data.custUserInfos = custUserInfos;
      let res = await this.api.EnterpriseAssociatedCustApi(data);
      // console.log(res, 'add')
      if (res.code == 200) {
        this.isSave = false;
        this.$router.back(-1);
        this.$message.success(res.info);
      } else {
        return this.$message.error(res.info);
      }
    },
    // 取消框里的确定
    cancleEvent() {
      this.$router.back(-1);
    },
    // 删除企业关联客户
    delConnectCust(index) {
      console.log(index);
      this.$store.commit("customer/delSetAssociatedEnterpriseParam", index);
      this.enterPriseList = JSON.parse(
        JSON.stringify(this.associatedEnterpriseParam)
      );
      // console.log(this.associatedEnterpriseParam, 'associatedEnterpriseParam')
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  .formBox {
    /deep/.ant-form-item {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      border: 1px solid #f2f2f2;
      background-color: #f2f2f2;
      .ant-form-item-label {
        flex: 1;
        text-align: left;
        background-color: #f2f2f2;
        padding-left: 20px;
      }
    }
    /deep/ .ant-col {
      flex: 3;
      padding-left: 50px;
      background-color: #fff;
      min-height: 40px;
    }
    .ant-select {
      max-width: 500px;
    }
    .ant-input {
      max-width: 500px;
    }
  }
}
</style>
