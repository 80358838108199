<template>
  <!-- 融资白名单 -->
  <div>

    <a-card title="融资白名单" style="margin: 20px">
      <template slot="extra">
        <a-select v-model="dbAccountId" placeholder="请选择担保账户" @change="changeDbAccount" style="min-width: 200px;">
          <a-select-option v-for="item in dbAccountList" :key="item.id" :value="item.id">{{ item.label
          }}</a-select-option>
        </a-select>
      </template>
      <div class="loan">
        <!-- !表单区域 -->
        <div class="formbox">
          <!-- 所属机构 -->
          <div class="inputbox">
            <div class="lefttitle">所属机构</div>
            <!-- 所属机构 -->
            <a-tree-select v-model.trim="form.departmentId" style="width: 200px"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="treeData" placeholder="请选择所属机构"
              tree-default-expand-all>
            </a-tree-select>
          </div>
          <div class="inputbox">
            <div class="lefttitle">渠道</div>
            <a-select v-model.trim="form.channelNo" placeholder="请选择渠道编号" style="width: 200px">
              <a-select-option v-for="item in companylist" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </div>
          <!-- 担保人账号 -->
          <!-- <div class="inputbox">
            <div class="lefttitle">担保人账号</div>
            <a-input v-model.trim="form.accountNoDb" placeholder="请输入担保人账号" />
          </div> -->
          <!--         担保人户名 -->
          <div class="inputbox">
            <div class="lefttitle">申请人姓名</div>
            <a-input v-model.trim="form.accountNameSq" placeholder="请输入申请人姓名" />
          </div>
          <!--         申请人账号-->
          <div class="inputbox">
            <div class="lefttitle">申请人账号</div>
            <a-input v-model.trim="form.accountNoSq" placeholder="请输入申请人账号" />
          </div>
          <!--         申请人户名-->
          <!-- <div class="inputbox">
            <div class="lefttitle">申请人户名</div>
            <a-input v-model.trim="form.accountNameSq" placeholder="请输入申请人户名" />
          </div> -->
          <!--         最大担保金额-->
          <div class="inputbox">
            <div class="lefttitle">担保金额</div>
            <a-input v-model.trim="form.maxAmount" placeholder="请输入最大担保金额" />
          </div>
          <!--         审批状态-->
          <div class="inputbox">
            <div class="lefttitle">审批状态</div>
            <a-select v-model.trim="form.checkStatus" placeholder="请输入审批状态" style="width: 200px;">
              <a-select-option value="0">待审批</a-select-option>
              <a-select-option value="1">通过</a-select-option>
              <a-select-option value="2">拒绝</a-select-option>
              <a-select-option value="3">异常</a-select-option>
              <a-select-option value="4">作废</a-select-option>
            </a-select>
          </div>

          <!-- 查询 -->
          <a-button class="inquire" @click="search" type="primary" v-if="$authority('FinancingwhitelistQuery')">
            查询
          </a-button>
          <!-- 重置 -->
          <a-button @click="reset" class="reset">重置</a-button>
        </div>

        <div class="Applyfor">
          <!--  新增  Add-->
          <a-button @click="newAdd" type="primary" v-if="$authority('FinancingwhitelistAdd')">
            新增
          </a-button>
          <a-button @click="editInfo" type="primary" v-if="$authority('financingwhitelistEditInfo')">
            编辑
          </a-button>


          <a-button @click="edit" type="primary" v-if="$authority('financingwhitelistEdit')">
            查看
          </a-button>
          <!--  审批-->
          <a-button @click="edit" type="primary" v-if="$authority('financingwhitelistApproval')">
            审批
          </a-button>
          <!-- 删除 -->
          <a-button @click="Deletebutton" type="danger" v-if="$authority('FinancingwhitelistDel')">
            删除
          </a-button>
          <!-- 同步状态 -->
          <a-button type="primary" @click="creditbmdAsync" v-if="$authority('financingwhitelistAsync')">同步状态</a-button>
        </div>
        <div class="tablebox">
          <!-- 表格 -->
          <div class="tableBody">
            <a-table ref="table" size="small" :rowKey="(record, index) => `${record.id}`" :row-selection="{
              selectedRowKeys: selectedRowKeys,
              hideDefaultSelections: false,
            }" :customRow="clickrow" :columns="columns" :data-source="data" :pagination="false">
              <span slot="accountNoSq" slot-scope="text">
                <a-tag color="blue">钱包</a-tag>{{ text }}</span>
              <span slot="accountNoDb" slot-scope="text">
                <a-tag color="blue">钱包</a-tag>{{ text }}</span>
            </a-table>
          </div>
          <!-- 分页部分 -->
          <div class="paginationBox">
            <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
              首页
            </a-button>
            <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total" show-size-changer
              :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
              :item-render="itemRender">
              <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
            </a-pagination>
            <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))
              " class="Trailingpage">
              尾页
            </a-button>
          </div>
        </div>
      </div>
    </a-card>
    <!-- 新增弹框 -->
    <a-modal class="add-modal" :title="isEdit ? '融资白名单/编辑' : '融资白名单/新增'" :visible="modalBool" @ok="handleOk"
      @cancel="handleCancel" style="width: 580px;">
      <a-form-model ref="addruleForm" :model="addform" :label-col="{ span: 7, offset: 1 }"
        :wrapperCol="{ span: 15, offset: 1 }" labelAlign="left">
        <!-- 申请人账户 -->
        <a-form-model-item label="贷款申请人" :rules="[
          { required: true, message: '请选择申请人钱包', trigger: 'change' },
        ]" prop="accountNameSq">
          <WalletChooseDialog v-model="addform.accountNameSq"  account-class="0"
            @chooseDone="accountNoSqcahnge" :isDisabled="isDisabled"></WalletChooseDialog>
        </a-form-model-item>
        <!-- 经营实体 -->
        <a-form-model-item label="经营实体">
          <a-radio-group v-model="flag" @change="chnageFlag">
            <a-radio-button value="0">无</a-radio-button>
            <a-radio-button value="1">有</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <!-- 电商钱包 有经营实体显示-->
        <a-form-model-item label="电商钱包" v-if="flag == '1'">
          <WalletChooseDialog v-model="addform.accountName" accountProperty="2" account-class="0"
            @chooseDone="chooseMchWallet"></WalletChooseDialog>
        </a-form-model-item>
        <!-- 与经营企业关系 -->
        <a-form-model-item label="与经营企业关系" v-if="flag == '1'">
          <a-select v-model="addform.relaType" placeholder="请选择与经营企业关系">
            <a-select-option v-for="item in whethen1list" :key="item.label" :value="item.value"> {{ item.label
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 产品 -->
        <a-form-model-item label="贷款产品" prop="productId" :rules="[
          { required: true, message: '请选择产品', trigger: 'change' },
        ]">
          <a-select v-model.trim="addform.productId" @change="productIdcahnge" placeholder="请选择产品" :disabled="isEdit">
            <a-select-option v-for="item in productIdlist" :channelNo="item.channelNo" :baseRate="item.baseRate"
              :key="item.id" :productId="item.productId" :value="item.productId">
              {{ item.productName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 担保人账户 -->
        <a-form-model-item label="一级核心" :rules="[
          { required: true, message: '请选择担保人账户', trigger: 'change' },
        ]" prop="accountNoDb">
          <a-select v-model.trim="addform.accountNoDb" @change="accountNoDbcahnge" placeholder="请选择担保人账户"
            :disabled="isEdit">
            <a-select-option v-for="item in suretylist" :item="item" :key="item.accountNameDb" :value="item.accountNoDb">
              <div>
                {{ item.accountNameDb }}
              </div>
              <WalletInfo :account-name="item.accountNameDb" :account-no="item.accountNoDb" account-class="0">
              </WalletInfo>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 一级核心担保账号 -->
        <!--        <a-form-model-item label="一级核心担保账号">-->
        <!--          <WalletInfo v-if="addform.accountNoDb" :accountClass="'0'" :accountNo="addform.accountNoDb"></WalletInfo>-->
        <!--        </a-form-model-item>-->
        <!-- 二级核心 -->
        <a-form-model-item label="二级核心">
          <a-select allowClear v-model.trim="addform.accountNoDb2" @change="accountNoDbcahngeTwo"
            @deselect="deselectAccount" placeholder="请选择担保人账户">
            <a-select-option v-for="item in suretylist" :item="item" :key="item.accountNameDb" :value="item.accountNoDb">
              <div>
                {{ item.accountNameDb }}
              </div>
              <WalletInfo :account-name="item.accountNameDb" :account-no="item.accountNoDb" account-class="0">
              </WalletInfo>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 二级核心担保账号 -->
        <!--        <a-form-model-item label="二级核心担保账号">-->
        <!--          <WalletInfo v-if="addform.accountNoDb2" :accountClass="'0'" :accountNo="addform.accountNoDb2"></WalletInfo>-->
        <!--        </a-form-model-item>-->
        <!-- 	最大担保金额(即贷款可申请的最大额度) -->
        <a-form-model-item label="最大担保金额" prop="maxAmount" :rules="[
          { required: true, message: '请输入最大担保金额', trigger: 'blur' },
        ]">
          <a-input v-model="addform.maxAmount" placeholder="请输入最大担保金额" suffix="万元" />
        </a-form-model-item>
        <a-form-model-item label="近三个月交易额" prop="threeMonthsAmount" :rules="[
          { required: true, message: '请输入金额', trigger: 'blur' },
        ]">
          <a-input v-model="addform.threeMonthsAmount" placeholder="请输入近三个月交易额" suffix="万元" />
        </a-form-model-item>

      </a-form-model>
    </a-modal>
    <!-- 删除 -->
    <a-modal title="提示" :maskClosable="false" :body-style="{ textAlign: 'center' }" @cancel="deleteshow = false"
      :centered="true" :visible="deleteshow">
      <div class="Openanaccount">
        是否确认删除所勾选数据？删除后数据将不可恢复
      </div>
      <template v-slot:footer>
        <div class="custom-footer">
          <a-button type="primary" @click="Confirmdelete">确认</a-button>
          <a-button @click="deleteshow = false">取消</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 审批 -->
    <ApproveDialog v-if="isShowApproveDialog" @approveDialogEvent="approveDialogEvent" :selectedData="selectedRows">
    </ApproveDialog>
    <!-- <a-modal title="提示" :maskClosable="true" @cancel="Examineandapproveshow = false" :centered="true" :foote="null"
      :footer="null" :visible="Examineandapproveshow">
      <div class="Openanaccount" style="display: flex; justify-content: space-between">
        <a-button style="width: 100px" type="primary" @click="ConfirmExamineandapproveshow">同意审批</a-button>
        <a-button style="width: 100px" @click="Examineandapproveshow = false">不同意</a-button>
      </div>
    
    </a-modal> -->
  </div>
</template>

<script>
import { BizDict } from "@/utils/bizDict/kvMap";
import { handleChannelNo } from "@/utils/commonMethods";
import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog.vue";
import WalletInfo from "@/view/components/WalletInfo"
import Balance from "@/view/components/Balance.vue";
// 引入审批弹框组件
import ApproveDialog from '../Components/ApproveDialog.vue'

export default {
  name: "Financingwhitelist",
  components: { WalletChooseDialog, WalletInfo, ApproveDialog, Balance },
  data() {
    return {
      // 担保账户
      dbAccountList: [],
      dbAccountId: undefined,
      whethen1list: BizDict.whethen1,
      treeData: [],
      deleteshow: false, // 贷款弹窗
      statuslist: BizDict.LoanAppStatus,
      total: 0,
      modalBool: false,
      Examineandapproveshow: false,
      selectedRowKeys: [],
      selectedRows: [], // 选数数组的值,
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      institutiontrees: {},
      // 渠道编号列表
      companylist: [],

      // 产品编号列表
      productIdlist: [],

      // 申请人列表
      accountNoDblist: [],
      // 担保人列表
      suretylist: [],
      data: [],
      isEdit: false,
      isDisabled: false,
      flag: '1',//是否经营企业
      orderAmount: "", // 近三个月交易额
      addform: {
        departmentId: "", // 机构id
        channelNo: "", // 渠道编号
        appNo: "", //	应用编号
        productId: undefined, // 产品编号
        accountNoDb: undefined, // 担保人账号
        accountNameDb: "", //担保人户名
        accountNoDb2: undefined,//担保人账号2
        accountNameDb2: '',//担保人户名2
        accountNo: '',//	融资电商A1账号
        accountName: '',//	融资电商A1户名
        relaType: undefined,//与经营企业关系
        accountNoSq: undefined, //申请人账号
        accountNameSq: "", //	申请人户名
        maxAmount: "", //	最大担保金额(即贷款可申请的最大额度)
        threeMonthsAmount: "", //	近三个月交易额
        businessRate: "", //	申贷款请利率(通过贷款产品表获取)
      },
      form: {
        pageNo: 1,
        pageSize: 10,
        departmentId: undefined, //部门id
        channelNo: "", // channelNo编号
        appNo: "", // 应用编号
        productId: "", // 产品编号
        serialNo: "", // 流水号
        accountNoDb: "", //担保人账号
        accountNameDb: "", //担保人户名
        accountNoSq: "", // 申请人账号
        accountNameSq: "", // 申请人户名
        maxAmount: "", //最大担保金额
        checkStatus: "", //	审批状态
      },
      isShowApproveDialog: false,//审批弹框
      columns: [
        {
          title: "No",
          dataIndex: "No",
          customRender: (text, record, index) =>
            `${Number(this.form.pageNo - 1) * Number(this.form.pageSize) +
            Number(index + 1)
            }`,
          align: "center",
          width: 50,
        },
        {
          title: "渠道",
          dataIndex: "channelNo",
          align: "center",
          customRender: (text) => {
            return handleChannelNo(
              JSON.parse(this.$session.getItem("coreCompanyList")),
              text,
              "1"
            );
          },
          width: 80,
        },
        {
          title: "申请人",
          dataIndex: "accountNameSq",
          align: "center",
          ellipsis: true,
          width: 100,
        },
        {
          title: "经营实体",
          dataIndex: "accountName",
          align: "center",
          width: 200,
          ellipsis: true,
        },

        {
          title: "担保金额",
          dataIndex: "maxAmount",
          align: "center",
          ellipsis: true,
          width: 120,
          customRender: (text) => {
            return <Balance amount={text / 10000} suffix="万元"></Balance>
          }
        },
        {
          title: "一级核心",
          dataIndex: "accountNameDb",
          align: "center",
          ellipsis: true,
        },
        {
          title: "二级核心",
          dataIndex: "accountNameDb2",
          align: "center",
          ellipsis: true,
        },
        {
          title: "审批状态",
          dataIndex: "checkStatus",
          align: "center",
          ellisis: true,
          customRender: (text) => {
            if (text == null) {
              return "";
            }
            if (Number(text) == 0) {
              return "待审批";
            }
            if (Number(text) == 1) {
              return "通过";
            }
            if (Number(text) == 2) {
              return "拒绝";
            }
            if (Number(text) == 3) {
              return "异常";
            }
            if (Number(text) == 4) {
              return "作废";
            }
          },
        },

        // {
        //   title: '所属机构',
        //   dataIndex: 'departmentId',
        //   align: 'center',
        //   customRender: (text) => {
        //     if (text == null) {
        //       return '---'
        //     } else {
        //       return this.findItemById(this.institutiontrees, text)
        //     }
        //   },
        // },
      ],
    };
  },
  created() {
    this.getCustList();
    this.getcommercePage();
    // this.form.departmentId = sessionStorage.getItem("companyId")
    // 获取 渠道编号列表和销售平台列表 companylistApi  salePlatformInfolistApi
    this.getcompanylist();
    //
    this.getDbAccount()
  },

  activated() {
    this.getcommercePage();
  },
  methods: {
    // 获取担保账户
    async getDbAccount() {
      this.dbAccountList = [];
      let data = {
        pageNo: 1,
        pageSize: 10000,
        // obj: {
        //   departmentId: this.departmentId,
        // }
      }
      const res = await this.api.guarantorListApi(data);
      if (res.code != 200) {
        this.$message.error(res.info);
        return;
      }
      this.dbAccountList = res.data.records.map(x => Object.assign(x, {
        id: x.id,

        label: x.accountNameDb,
        value: x.accountNoDb
      }))

    },
    changeDbAccount(value) {
      // console.log(value);
      this.dbAccountList.forEach(x => {
        if (x.id === value) {

          this.form.accountNoDb = x.accountNoDb
        }
      })

      this.search()
    },


    chooseMchWallet(mchWallet) {
      console.log(mchWallet, 'mchWallet');
      // 融资电商A1账号
      this.addform.accountNo = mchWallet.accountNo
      // 融资电商A1户名
      this.addform.accountName = mchWallet.accountName
      console.log(this.addform);

    },
    // 获取所属机构下拉框数据
    async getCustList() {
      let data = {
        username: this.$session.getItem("username"),
      };
      const res = await this.api.agencyList(data);
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      let trees = [];
      this.institutiontrees = res.data;
      trees.push(res.data);

      this.treeData = this.getResettingTrees(trees)
        ? this.getResettingTrees(trees)
        : [];
    },

    // 获取table列表
    async getcommercePage() {
      if(this.$route.query?.accountNoSq){
        this.form.accountNoSq=this.$route.query.accountNoSq
      }
      const res = await this.api.creditybmdpageApi(this.form);
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.total = res.data.total;
      this.form.pageNo = res.data.current;
      this.form.pageSize = res.data.size;
      this.data = res.data.records;
    },


    chnageFlag(event) {
      // console.log(event);
      // 再编辑状态下，如果选中的是无，要清空电商钱包和与经营企业关系字段----如果用户来回点击呢--------------i think 可以在最后提交的时候判断flag的状态值，如果为0则清空字段，如果为1就不做改变
    },

    // 点击新增
    newAdd() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.modalBool = true;
      // // 获取钱包和产品信息
      // this.creditProductInfolist();
    },
    // 获取店渠道编号列表
    async getcompanylist() {
      const res = await this.api.companylistApi();
      if (res.code !== 200) {
        return this.$message.error(res.info);
      }

      res.data.forEach((item) => {
        this.companylist.push({
          label: item.channelName,
          value: item.channelNo,
        });
      });
      this.companylist.unshift({
        label: "全部",
        value: "",
      });
    },

    //  获取产品编号列表
    async creditProductInfolist() {
      const res = await this.api.creditProductInfolistApi({
        channelNo: this.addform.channelNo
      });
      if (res.code !== 200) {
        this.$message.warning(res.info);
        return;
      }
      this.productIdlist = res.data;
    },
    // 获取融资白名单
    async getsuretylist() {
      let data = {
        channelNo: this.addform.channelNo,
        productId: this.addform.productId,
      };
      const res = await this.api.creditWhiteDbListApi(data);
      if (res.code !== 200) {
        this.$message.warning(res.info);
        return;
      }
      this.suretylist = res.data;
    },
    // 进行循环递归处理数据
    getResettingTrees(tree) {
      // 定义子级数组
      if (tree.length == 0) return [];
      let arr = [];
      tree.forEach((item) => {
        // 递归循环将每一项都处理城组件需要的的格式 [ {title:"" children:[{title:""}]  }]
        arr.push({
          title: item.coreDepartment.name,
          value: item.coreDepartment.id,
          key: item.coreDepartment.id,
          children:
            item.children && item.children.length > 0
              ? this.getResettingTrees(item.children)
              : [],
        });
      });
      // 处理好的数组返回出去
      return arr;
    },
    // 点击重置表单
    reset() {
      this.form = {
        pageNo: 1,
        pageSize: 10,
        departmentCode: undefined, //部门id

        channelNo: "", // channelNo编号
        appNo: "", // 应用编号
        productId: "", // 产品编号
        serialNo: "", // 流水号
        accountNoDb: "", //担保人账号
        accountNameDb: "", //担保人户名
        accountNoSq: "", // 申请人账号
        accountNameSq: "", // 申请人户名
        maxAmount: "", //最大担保金额
        checkStatus: "", //	审批状态
      };
      this.getcommercePage();
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.form.pageSize = pageSize;
      this.getcommercePage();
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return;
      }
      this.selectedRowKeys = []
      this.selectedRows = []
      this.form.pageNo = 1
      this.getcommercePage()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return;
      }
      this.selectedRowKeys = []
      this.selectedRows = []
      this.form.pageNo = Math.ceil(
        Number(this.total) / Number(this.form.pageSize)
      );

      this.getcommercePage();
    },
    pagechange(val) {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.form.pageNo = val;
      this.getcommercePage();
    },
    // 搜索查询
    search() {
      this.form.pageNo = 1;
      this.getcommercePage();
    },

    // 产品编号发生变化
    productIdcahnge(value, option) {
      // console.log(option, 'option');
      this.addform.channelNo = option.data.attrs.channelNo;
      this.addform.businessRate = option.data.attrs.baseRate;
      // this.addform.productId = option.data.attrs.productId
      this.getsuretylist();
    },

    // 担保人账户发生变化
    accountNoDbcahnge(value, option) {
      if (option) {
        this.addform.accountNameDb = option.data.attrs.item.accountNameDb;
      } else {
        this.addform.accountNameDb = ''
      }
      // this.addform.accountNameDb = option.data.attrs.item.accountNameDb;
    },
    accountNoDbcahngeTwo(value, option) {

      if (option) {
        this.addform.accountNameDb2 = option.data.attrs.item.accountNameDb;
      } else {
        this.addform.accountNameDb2 = ''
      }


    },
    deselectAccount(value) {
      // console.log(value, 'value');
    },
    // 申请人账户发生变化
    async accountNoSqcahnge(wallet) {
      // 回填钱包信息
      // console.info(wallet);
      this.addform.departmentId = wallet.departmentId;
      this.addform.appNo = wallet.appNo;
      // 申请人账号
      this.addform.accountNoSq = wallet.accountNo;
      // 申请人户名
      this.addform.accountNameSq = wallet.accountName
      this.addform.channelNo = wallet.channelNo;
      // 获取贷款产品
      this.creditProductInfolist();
    },

    // 点击新增确认
    handleOk() {
      // 首先进行表单校验
      this.$refs.addruleForm.validate((valid) => {
        if (valid) {
          if (this.flag == '0') {
            // 无经营企业
            this.addform.accountNo = this.addform.accountNoSq
            this.addform.accountName = this.addform.accountNameSq
          }
          if (this.isEdit) {
            this.addform.id = this.selectedRows[0].id
          } else {
            this.addform.id = ''
          }
          let transformedForm = Object.assign({}, this.addform)
          transformedForm.maxAmount = Number(this.addform.maxAmount) * 10000
          transformedForm.threeMonthsAmount = Number(this.addform.threeMonthsAmount) * 10000
          this.api.creditbmdbmdAddApi(transformedForm).then((res) => {
            if (res.code !== 200) {
              this.$message.warning(res.info);
              return;
            }
            this.$message.success(res.info);
            this.addform = {
              departmentId: "", // 机构id
              channelNo: "", // 渠道编号
              appNo: "", //	应用编号
              productId: undefined, // 产品编号
              accountNoDb: undefined, // 担保人账号
              accountNameDb: "", //担保人户名
              accountNoSq: undefined, //申请人账号
              accountNameSq: "", //	申请人户名
              maxAmount: "", //	最大担保金额(即贷款可申请的最大额度)
              threeMonthsAmount: "", //	近三个月交易额
              businessRate: "", //	申贷款请利率(通过贷款产品表获取)
            };
            this.isEdit = false
            this.isDisabled = false
            this.modalBool = false;
            this.selectedRowKeys = [];
            this.selectedRows = []; // 选数数组的值,
            this.getcommercePage();
          });
          // this.$emit("okModalHandle")
        } else {
          this.$message.warning("请先填写信息");
        }
      });
    },
    handleCancel() {
      this.modalBool = false
      if (this.isEdit) {
        this.flag = '1'
        this.addform = {
          departmentId: "", // 机构id
          channelNo: "", // 渠道编号
          appNo: "", //	应用编号
          productId: undefined, // 产品编号
          accountNoDb: undefined, // 担保人账号
          accountNameDb: "", //担保人户名
          accountNoDb2: undefined,//担保人账号2
          accountNameDb2: '',//担保人户名2
          accountNo: '',//	融资电商A1账号
          accountName: '',//	融资电商A1户名
          relaType: undefined,//与经营企业关系
          accountNoSq: undefined, //申请人账号
          accountNameSq: "", //	申请人户名
          maxAmount: "", //	最大担保金额(即贷款可申请的最大额度)
          threeMonthsAmount: "", //	近三个月交易额
          businessRate: "", //	申贷款请利率(通过贷款产品表获取)
        }
      }
      this.isEdit = false
      this.isDisabled = false
    },
    // 点击审批
    edit() {
      if (this.selectedRows.length == 0) {
        this.$message.warning("请先选择账户");
        return;
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning("请勿选中多条记录");
        return;
      }
      // this.Examineandapproveshow = true;
      this.isShowApproveDialog = true
      // console.log(this.selectedRows);
    },
    // 点击进行编辑
    editInfo() {
      if (this.selectedRows.length == 0) {
        this.$message.warning("请先选择账户");
        return;
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning("请勿选中多条记录");
        return;
      }
      // 调用详情接口
      this.getDetails()
      // 打开弹框
      this.isEdit = true
      this.isDisabled = true
      this.modalBool = true
    },
    // 获取列表详情
    async getDetails() {
      let id = this.selectedRows[0].id
      let res = await this.api.creditbmdbmdDetailApi(id)
      if (res.code == 200) {
        // this.addform = res.data
        this.addform.departmentId = res.data.departmentId
        this.addform.appNo = res.data.appNo
        this.addform.channelNo = res.data.channelNo
        this.addform.productId = res.data.productId
        this.addform.accountNoDb = res.data.accountNoDb
        this.addform.accountNameDb = res.data.accountNameDb
        this.addform.accountNameDb2 = res.data.accountNameDb2
        this.addform.accountNoDb2 = res.data.accountNoDb2
        this.addform.accountName = res.data.rzAccountNameA1
        this.addform.accountNo = res.data.rzAccountNoA1
        this.addform.relaType = res.data.relaType
        this.addform.accountNoSq = res.data.accountNoSq
        this.addform.accountNameSq = res.data.accountNameSq
        this.addform.maxAmount = res.data.maxAmount
        this.addform.threeMonthsAmount = res.data.threeMonthsAmount
        // 申请贷款利率
        this.addform.businessRate = res.data.productInfo?.baseRate
        // 判断有无经营实体
        this.flag = res.data.accountNoSq === res.data.rzAccountNoA1 ? '0' : '1'
        // 获取贷款产品
        this.creditProductInfolist()
        // 获取担保人账户
        this.getsuretylist()
      }
    },
    // 同步
    async creditbmdAsync() {
      // if (!this.form.accountNoDb) {
      //   return this.$message.warning('请先选择担保账户')
      // }
      if (this.selectedRows.length < 1) {
        this.$message.info("请先选中一条记录")
      }
      let res = await this.api.creditbmdAsyncApi({
        accountNo: this.selectedRows[0].accountNoSq,
        productId: this.selectedRows[0].productId
      })
      if (res.code === 200) {
        this.$message.success(res.info)
      } else {
        this.$message.error(res.info)
      }
    },
    approveDialogEvent(obj) {
      this.isShowApproveDialog = obj.bool
      if (obj.type == 'success') {
        // 审批成功，重新获取列表
        this.getcommercePage()
      }
    },
    // 删除
    Deletebutton() {
      if (this.selectedRows.length == 0) {
        this.$message.warning("请先选择账户");
        return;
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning("只能进行单项删除");
        return;
      }
      this.deleteshow = true;
    },
    // 点击删除
    async Confirmdelete() {
      // let data = []
      // this.selectedRows.forEach((item) => {
      //   data.push(item.id)
      // })
      const res = await this.api.creditbmdbmdbmdDelApi(this.selectedRows[0].id);
      if (res.code !== 200) {
        return this.$message.error(res.info);
      }
      this.$message.success("删除成功");
      this.selectedRowKeys = [];
      this.selectedRows = []; // 选数数组的值,
      this.deleteshow = false;
      this.getcommercePage();
    },
    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {
      // clickrow(selectedRows)
      // if (selectedRows.length > 1) {
      //   //获取选中的数据的key
      //   var selectNumber = this.selectedRowKeys[1]
      //   //清空选中的key
      //   this.selectedRowKeys = []
      //   //选中的数据的key重新赋值给selectedRowKeys
      //   this.selectedRowKeys.push(selectNumber)
      // }
      // //获取选中的数据详细信息
      // this.selectedRows = selectedRows[0]
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString();
            let index = this.selectedRowKeys.findIndex((item) => id == item);
            if (index == -1) {
              this.selectedRowKeys = [id]
              this.selectedRows = [record]
            } else {
              this.selectedRowKeys = []
              this.selectedRows = []
            }
            // console.info(this.selectedRows, this.selectedRowKyes)
          },
        },
      };
    },
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj == {}) return;
      if (dataObj.coreDepartment.id === idToFind) {
        return dataObj.coreDepartment.name;
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind);
          if (foundName) {
            return foundName;
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null;
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped >
.ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}

/* 自定义表头样式 */
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

.ant-modal-footer {
  padding-bottom: 20px;
}

/deep/.ant-col-15 {
  width: 260px;
}


.ant-modal-content {
  width: 600px;

  .ant-form-item {
    margin: 0 0 10px;

    // .ant-form-item-control {
    //   width: 260px;
    // }
  }





}

.loan {


  .formbox {
    display: flex;
    flex-wrap: wrap;

    .inputbox {
      width: 260px;
      margin-right: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      input {
        width: 180px;
      }

      .lefttitle {
        min-width: 80px;
      }
    }

    .zhaungtai {
      width: 280px;
      margin-right: 120px;
    }

    .reset {
      width: 64px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }

    button {
      margin-bottom: 20px;
    }

    .ant-tag {
      cursor: pointer;
    }
  }

  .tablebox {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .ant-tag {
      cursor: pointer;
    }
  }

  .paginationBox {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;

    // .ant-pagination-options {
    //   float: left;
    // }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: 1px solid #d9d9d9;
      width: 60px;
    }
  }

  .Applyfor {
    padding-top: 10px;
    margin-bottom: 20px;
  }
}
</style>