let cache = {}
export default(value)=>{
  // cache for performance
  if (cache.hasOwnProperty(value)) {
    return cache[value]
  }
  let array = JSON.parse(sessionStorage.getItem('authButton'))
  let hasAuth = array.some(item => {
    return item.component === value
  })
  cache[value] = hasAuth
  return hasAuth;
}
export function authorityInit(){
  cache = {}
}
