<template>
  <!-- 个人客户关联用户页面 -->
  <a-card title="用户关联关系绑定" style="margin: 20px">
    <template slot="extra">
      <a-button type="primary" @click="isSave = true">保存</a-button>
      <a-button @click="isCancel = true">取消</a-button>
    </template>
    <!-- 主体部分 -->
    <div class="container">
      <div class="formBox">
        <a-form>
          <a-form-item label="用户名称">
            <a-select placeholder="请选择用户" v-model="usernameValue" :options="userNameList"
              @change="changeUserName($event)">
            </a-select>
          </a-form-item>
          <a-form-item label="客户编号">
            <a-input v-model="custnum" disabled placeholder="客户编号"></a-input>
          </a-form-item>
          <a-form-item label="客户名称">
            <a-input v-model="custname" disabled placeholder="客户名称"></a-input>
          </a-form-item>
          <a-form-item label="客户类型">
            <div>个人</div>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <!-- 确定和取消弹框 -->
    <div class="saveBox">
      <a-modal title="提示" :visible="isSave" :body-style="{ textAlign: 'center' }" :centered="true" @ok="handleOk"
        @cancel="isSave = false">
        <p>是否关联至该账号？</p>
      </a-modal>
    </div>
    <div class="cancelBox">
      <a-modal title="提示" :visible="isCancel" :body-style="{ textAlign: 'center' }" :centered="true" @ok="cancleEvent"
        @cancel="isCancel = false">
        <p>是否取消？取消后信息将不会被关联</p>
      </a-modal>
    </div>
  </a-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "connectCust",
  data() {
    return {
      // 客户编号
      custnum: "",
      // 客户名称
      custname: "",
      // 客户类型
      custtypecd: "1",
      id: "",
      // 用户名称
      userNameList: [],
      isSave: false,
      isCancel: false,
      departmentId: "",
      usernameValue: "",
      userId: "",
    };
  },
  created() {
    // console.log(this.associatedPersonalParam, '0')
    let paramData = JSON.parse(JSON.stringify(this.associatedPersonalParam));
    this.id = paramData.id;
    this.custnum = paramData.custnum;
    this.custname = paramData.custname;
    this.departmentId = paramData.departmentId;
    // 获取用户名
    this.getUserName();
  },
  computed: {
    ...mapState("customer", ["associatedPersonalParam"]),
  },
  mounted() {
    sessionStorage.setItem('msgInfo', true)
  },
  methods: {
    changeUserName(event) {
      // console.log(event, 'ee')
      this.userId = event;
    },
    async getUserName() {
      let res = await this.api.getAssociatedCust();
      // console.log(res, "association");
      if (res.code == 200) {
        res.data.forEach((item) => {
          let obj = {
            value: item.id,
            label: item.username,
          };
          this.userNameList.push(obj);
          this.departmentId = item.departmentId;
          this.usernameValue = item.username;
        });
      }
    },

    // 保存弹框里的确定
    async handleOk() {
      let data = {};
      data.userId = this.userId;
      data.departmentId = this.departmentId;
      data.id = this.id;
      data.custnum = this.custnum;
      data.custname = this.custname;
      data.custtypecd = this.custtypecd;
      let res = await this.api.addAssociatedCust(data);
      // console.log(res, 'add')
      if (res.code == 200) {
        this.isSave = false;
        this.$message.success(res.info)
        this.$router.back(-1);
      } else {
        return this.$message.error(res.info);
      }
    },
    // 取消框里的确定
    cancleEvent() {
      this.$router.back(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  .formBox {
    /deep/.ant-form-item {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      border: 1px solid #f2f2f2;

      .ant-form-item-label {
        flex: 1;
        text-align: left;
        background-color: #f2f2f2;
        padding-left: 20px;
      }
    }

    /deep/ .ant-col {
      flex: 3;
      padding-left: 50px;
    }

    .ant-select {
      max-width: 500px;
    }

    .ant-input {
      max-width: 500px;
    }
  }
}
</style>
