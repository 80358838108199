<template>
  <div>
    <!-- 新增手续费弹框 -->
    <a-modal :title="title(propsData.type)" :visible="propsData.isShowDialog" :centered="true"
      :confirm-loading="confirmLoading" @cancel="handleCancel">
      <!-- 表单区域 -->
      <div class="formBox">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="left">
          <a-form-model-item label="服务费名目" prop="projectNo">
            <a-select placeholder="请选择服务费名目" @change="projectChange" v-model="form.projectNo">
              <a-select-option value="CORE">核心企业服务费(不含利息）</a-select-option>
              <a-select-option value="AGENCY">合作方渠道服务费(不含利息）</a-select-option>
              <a-select-option value="PLAT">平台技术服务费</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="服务费规则" prop="feeRuleNo">
            <a-select @change="handleChange($event)" v-model="form.feeRuleNo" placeholder="请选择服务费规则">
              <a-select-option v-for="item in feeRuleList" :key="item.id" :value="item.feeNo">{{ item.feeName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="收款方钱包" prop="feeAccountName">
            <WalletChooseDialog v-model="form.feeAccountName" :account-class="'0'" @chooseDone="onWalletChosen">
            </WalletChooseDialog>
          </a-form-model-item>
          <a-form-model-item label="备注">
            <a-textarea v-model.trim="form.note" class="textarea"></a-textarea>
          </a-form-model-item>
        </a-form-model>
      </div>
      <!-- 底部 -->
      <template slot="footer">
        <a-button type="primary" @click="handleOk">保存</a-button>
        <a-button @click="handleCancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog.vue";

export default {
  components: {
    WalletChooseDialog
  },
  props: {
    propsData: {
      type: Object,
      required: true
    },
    departmentId: {
      type: String,
      default: ''
    },
    custnum: {
      type: String,
      default: ''
    },
    payFee: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      confirmLoading: false,
      channelNo: '',
      accountName: '',
      form: {
        // 	服务费项目编号
        projectNo: '',
        // 	服务费项目名称
        projectName: '',
        // 	服务费账号
        feeAccountNo: undefined,
        // 	服务费户名
        feeAccountName: '',
        // 服务费规则编号
        feeRuleNo: undefined,
        // 服务费规则名称
        feeName: '',
        // 	备注
        note: ""
      },
      rules: {
        projectNo: [{
          required: true,
          message: '请填写服务费名目',
          trigger: 'blur'
        }],
        feeRuleNo: [{
          required: true,
          message: '请选择服务费规则',
          trigger: 'change'
        }],
        feeAccountName: [{
          required: true,
          message: '请选择收款方钱包',
          trigger: 'change'
        }]
      },
      // 服务费规则列表
      feeRuleList: [],
      // 收款钱包
      walletList: []
    }
  },
  watch: {
    'propsData.isShowDialog': {
      handler(oldValue) {
        if (oldValue) {
          this.getFeeRule(),
            this.getWallet()
          if (this.propsData.type == 'add') {
            this.form = {
              // 	服务费项目编号
              projectNo: undefined,
              // 	服务费项目名称
              projectName: undefined,
              // 	服务费账号
              feeAccountNo: undefined,
              // 	服务费户名
              feeAccountName: '',
              // 服务费规则编号
              feeRuleNo: undefined,
              // 服务费规则名称
              feeName: '',
              // 	备注
              note: ""
            }
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    title() {
      return function (type) {
        if (type == 'add') {
          return '新增服务费项目'
        } else if (type == 'edit') {
          return '编辑服务费项目'
        }
      }
    }
  },
  created() {

  },

  methods: {
    // 获取服务费规则
    async getFeeRule() {
      let data = {}
      let res = await this.api.getCommisionFeeRuleApi(data)
      if (res.code == 200) {
        // console.log(res, '服务费规则');
        if (this.payFee === '1') {
          this.feeRuleList =  res.data.filter(item => item.feeType !== '4');
        }else {

          this.feeRuleList = res.data
        }
      }
    },
    // 获取收款钱包数据 
    async getWallet() {
      let data = {}
      data.pageNo = 1
      data.pageSize = 10000
      data.departmentId = this.departmentId
      data.custnum = this.custnum
      data.channelNo = this.channelNo
      let res = await this.api.custaccountPageApi(data)
      if (res.code == 200) {
        // console.log(res, '收款钱包');
        this.walletList = res.data.records
      }
    },

    // 选中事件
    handleChange(event) {
      // 服务规则
      this.feeRuleList.forEach(item => {
        if (item.feeNo == event) {
          this.form.feeName = item.feeName
        }
      })
    },
    projectChange(value) {

      if (value === 'CORE') {
        this.form.projectName = '核心企业服务费(不含利息)'
      } else if (value === 'AGENCY') {
        this.form.projectName = '合作方渠道服务费(不含利息)'

      } else if (value === 'PLAT') {
        this.form.projectName = '平台技术服务费'
      }
    },
    // 选择钱包
    async onWalletChosen(wallet) {

      this.form.feeAccountNo = wallet.accountNo

    },
    // 取消
    handleCancel() {
      this.$emit('handleEmit', { bool: false, type: 'cancel' })
    },
    // 保存按钮
    handleOk() {
      this.onSubmit()
    },
    // 校验
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 校验成功
          console.log(this.form);
          this.$emit('handleEmit', { bool: false, type: 'ok', data: JSON.parse(JSON.stringify(this.form)) })
        } else {
          console.log("error submit!!");
        }
      });
    },
    // 重置
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },

  }

}
</script>

<style lang="less" scoped>
/deep/.ant-modal-content {
  width: 600px;
}

.formBox {
  .ant-form-item {
    display: flex;

    .ant-select {
      width: 300px;
    }

    .ant-input {
      width: 300px;
    }

    .textarea {
      margin-left: 45px;
    }
  }
}
</style>