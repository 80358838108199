export default {
  namespaced: true,
  state: {
    associatedPersonalParam: {},
    associatedEnterpriseParam: [],
  },
  getters: {},
  mutations: {
    setAssociatedPersonalParam(state, payload) {
      state.associatedPersonalParam = payload
    },
    setAssociatedEnterpriseParam(state, payload) {
      state.associatedEnterpriseParam = payload
    },
    delSetAssociatedEnterpriseParam(state, index) {
      state.associatedEnterpriseParam.splice(index, 1)
    },
  },
}
