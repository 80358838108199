<template>
  <div>
    <a-card>
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>发票登记</span>
          </div>
        </div>
      </template>

      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <div style="display:flex; justify-content: center;">
          <div style="width: 500px;margin: 0 20px">

            <a-form-model-item ref="invoiceType" label="发票类型" prop="invoiceType">

              <a-radio-group  v-model="form.invoiceType"  :default-value="1">
                <a-radio :value="1">
                  专票
                </a-radio>
                <a-radio :value="2">
                  普票
                </a-radio>
                <a-radio :value="3">
                  特殊发票
                </a-radio>

              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item v-if="form.invoiceType!=3" ref="collAccountName" label="发票文件" prop="collAccountName">
              <a-upload-dragger
                  name="file"
                  list-type="picture-card"
                  class="invoice-uploader"
                  :show-upload-list="false"
                  :action="licenseBusiURL"
                  :headers="headers"
                  @change="invoiceOcr"
                  accept="image/*,.pdf,.PDF"
              >
                <img v-if="imageUrl" :src="imageUrl" alt="非图片发票，无法预览" class="ant-upload ant-upload-select ant-upload-select-picture-card"/>
                <div v-else>
                  <p class="ant-upload-drag-icon">
                    <a-icon :type="loading ? 'loading' : 'inbox'"/>
                  </p>
                  <div class="ant-upload-text">
                    拖入或点击上传发票，支持图片与pdf
                  </div>
                </div>
              </a-upload-dragger>
            </a-form-model-item>

            <a-form-model-item ref="mchNo" label="商户" prop="mchNo">
              <a-select class="dianshang" :dropdownMatchSelectWidth="false" placeholder="请选择商户" :show-search="true" v-model.trim="form.mchNo"
                        style="width: 100%;" @change="getShopList">
                <a-select-option v-for="item in mchNolist" :key="item.value" :value="item.value">
                  <div style="display: flex; justify-content: space-between">
                    <span> {{ item.label }} </span>
                    <code style="color: grey;font-size: 0.8em">{{ item.value }}</code>
                  </div>
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="shopNo" label="收款账号" prop="shopNo">
              <a-select :dropdownMatchSelectWidth="false" placeholder="请选择回款账号" :show-search="true" v-model.trim="form.shopNo"
                        style="width: 100%;" @change="onShopChange">
                <a-select-option v-for="item in shoplist" :key="item.shopNo" :value="item.shopNo">
                  <div style="display: flex; justify-content: space-between">
                    <span>{{ item.shopName }}</span>
                    <code style="color: grey;font-size: 0.8em">{{ item.refundAccountNo }}</code></div>
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item ref="totalAmount" label="开票金额" prop="totalAmount">
              <a-input v-model="form.totalAmount" suffix="元"/>
            </a-form-model-item>
            <!--          <a-form-model-item ref="refundAccountName" label="销售方信息" prop="refundAccountName" v-if="selectedShop.refundAccountNo">-->
            <!--            <WalletInfo :accountName="selectedShop.refundAccountName" :accountNo="selectedShop.refundAccountNo" />-->
            <!--          </a-form-model-item>-->
            <!--          <a-form-model-item ref="collAccountName" label="归集账户" prop="collAccountName" v-if="selectedShop.collAccountNo">-->
            <!--            <WalletInfo :accountName="selectedShop.collAccountName" :accountNo="selectedShop.collAccountNo" />-->
            <!--          </a-form-model-item>-->
            <div class="buttons">
              <a-button type="primary" @click="submitInvoice">登记</a-button>
              <a-button @click="cancelInvoice">取消</a-button>
            </div>
          </div>
          <div style="width: 600px">
            <a-collapse :showArrow="false" defaultActiveKey="1">
              <a-collapse-panel header="发票详情" key="1">
                <a-form-model-item ref="invoiceCode" label="发票代码" prop="invoiceCode">
                  <a-input v-model="form.invoiceCode"/>
                </a-form-model-item>
                <a-form-model-item ref="invoiceNumber" label="发票号码" prop="invoiceNumber">
                  <a-input v-model="form.invoiceNumber"/>
                </a-form-model-item>
                <a-form-model-item ref="invoiceDate" label="开票日期" prop="invoiceDate">
                  <a-input v-model="form.invoiceDate"/>
                </a-form-model-item>
                <a-form-model-item ref="purchaserName" label="购买方名称" prop="purchaserName">
                  <a-input v-model="form.purchaserName"/>
                </a-form-model-item>
                <!--              <a-form-model-item ref="purchaserBankAccountInfo" label="购买方开户行与账号" prop="purchaserBankAccountInfo">-->
                <!--                <a-input v-model="form.purchaserBankAccountInfo"/>-->
                <!--              </a-form-model-item>-->
                <a-form-model-item ref="sellerName" label="销售方名称" prop="sellerName">
                  <a-input v-model="form.sellerName" disabled/>
                </a-form-model-item>
                <a-form-model-item ref="sellerBankAccountInfo" label="销售方开户行与账号" prop="sellerBankAccountInfo">
                  <a-input v-model="form.sellerBankAccountInfo" disabled/>
                </a-form-model-item>

              </a-collapse-panel>
            </a-collapse>
          </div>
        </div>
      </a-form-model>
      <div class="del-dialog">
        <a-modal
            title="发票信息差异确认"
            :visible="registerModalVisible"
            okText="确认登记"
            cancelText="返回修改"
            @ok="handleConfirm"
            @cancel="handleCancel"
        >
          <a-descriptions bordered :column="1" size="small">
            <a-descriptions-item label="发票代码">
              <span v-if="form.invoiceCode === rawInvoiceInfo.invoiceCode" class="pass">通过</span>
              <span v-else class="fail">
                <code>{{ form.invoiceCode }}</code>
                <span>{{ rawInvoiceInfo.invoiceCode }}</span>
              </span>
            </a-descriptions-item>
            <a-descriptions-item label="发票编号">
              <span v-if="form.invoiceNumber === rawInvoiceInfo.invoiceNumber" class="pass">通过</span>
              <span v-else class="fail">
                <code>{{ form.invoiceNumber }}</code>
                <span>{{ rawInvoiceInfo.invoiceNumber }}</span>
              </span>
            </a-descriptions-item>
            <a-descriptions-item label="开票日期">
              <span v-if="form.invoiceDate === rawInvoiceInfo.invoiceDate" class="pass">通过</span>
              <span v-else class="fail">
                <code>{{ form.invoiceDate }}</code>
                <span>{{ rawInvoiceInfo.invoiceDate }}</span>
              </span>
            </a-descriptions-item>
            <a-descriptions-item label="购买方">
              <span v-if="form.purchaserName === rawInvoiceInfo.purchaserName" class="pass">通过</span>
              <span v-else class="fail">
                <code>{{ form.purchaserName }}</code>
                <span>{{ rawInvoiceInfo.purchaserName }}</span>
              </span>
            </a-descriptions-item>
            <a-descriptions-item label="销售方">
              <span v-if="form.sellerName === rawInvoiceInfo.sellerName" class="pass">通过</span>
              <span v-else class="fail">
                <code>{{ form.sellerName }}</code>
                <span>{{ rawInvoiceInfo.sellerName }}</span>
              </span>
            </a-descriptions-item>
            <a-descriptions-item label="销售方账号">
              <span v-if="form.sellerBankAccountInfo === rawInvoiceInfo.sellerBankAccountInfo" class="pass">通过</span>
              <span v-else class="fail">
                <code>{{ form.sellerBankAccountInfo }}</code>
                <span>{{ rawInvoiceInfo.sellerBankAccountInfo }}</span>
              </span>
            </a-descriptions-item>
            <a-descriptions-item label="开票金额">
              <span v-if="Number(form.totalAmount) === Number(rawInvoiceInfo.totalAmount)" class="pass">通过</span>
              <span v-else class="fail">
                <code>{{ form.totalAmount }}</code>
                <span>{{ rawInvoiceInfo.totalAmount }}</span>
              </span>
            </a-descriptions-item>
          </a-descriptions>
        </a-modal>
      </div>
    </a-card>
  </div>
</template>
<script>
import {dict_rawdata as busiDict} from "@/utils/bizDict/rawdata";
import uploadImg from "@/components/uploadImg/index.vue";
import urlConfig from "@/utils/urlConfig";
import WalletInfo from "@/view/components/WalletInfo.vue";

export default {
  name: 'MchShopInvoiceAdd',
  // eslint-disable-next-line vue/no-unused-components
  components: {WalletInfo, uploadImg},
  data() {
    return {
      loading: false,
      registerModalVisible: false,
      imageUrl: '',

      licenseBusiURL: urlConfig.busiURL + "/base/ocr/recognizeInvoice",
      headers: {
        token: this.$session.getItem("token"),
      },
      selectedShop: {}, // 存储选中店铺的完整信息
      rawInvoiceInfo: {}, // 发票原始识别信息
      form: {
        mchNo: null,
        shopNo: null,
        fileNo: '',
        invoiceType: 1,
        invoiceCode: '',
        invoiceNumber: '',
        invoiceDate: '',
        sellerName: '',
        sellerBankAccountInfo: '',
        purchaserName: '',
        purchaserBankAccountInfo: '',
        totalAmount: '',
      },
      registerConfirmed: false,
      rules: {
        mchNo: [{required: true, message: '请选择电商', trigger: 'change'}],
        shopNo: [{required: true, message: '请选择店铺', trigger: 'change'}],
        invoiceCode: [{required: true, message: '请填写发票代码', trigger: 'change'}],
        invoiceNumber: [{required: true, message: '请填写发票号码', trigger: 'change'}],
        invoiceDate: [
          {required: true, message: '请填写开票日期', trigger: 'change'},
          {pattern: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/, message: '开票日期格式不正确'}
        ],
        purchaserName: [{required: true, message: '请填写购买方名称', trigger: 'change'}],
        sellerName: [{required: true, message: '请填写销售方名称', trigger: 'change'}],
        sellerBankAccountInfo: [{required: true, message: '请填写销售方开户行与账号', trigger: 'change'}],
        totalAmount: [
          {required: true, message: '请填写开票金额', trigger: 'change'},
          {pattern: /^\d+(\.\d{1,2})?$/, message: '金额格式不正确'}
        ],
      },
      mchNolist: [],
      shoplist: [],
      // labelCol: {span: 11},
      // wrapperCol: {span: 4},
      busiDict
    };
  },
  methods: {
    extractAccount() {
      //提取 5位以上连续数字结尾作为账号
      const match = this.form.sellerBankAccountInfo.match(new RegExp(/\d{5,}$/));
      if (match) {
        return match[0];
      } else {
        return '';
      }
    },

    async getMchList() {
      const res = await this.api.mchshopsApi({pageNo: 1, pageSize: 10000, mchType: 1});
      this.mchNolist = res.data.records.map(x => ({label: x.mchName, value: x.mchNo, fatherName: x.fatherName}));
      // 默认选中第一个，并刷新店铺列表
      if (this.mchNolist.length > 0) {
        this.form.mchNo = this.mchNolist[0].value
        await this.getShopList()
      }
    },
    async getShopList() {
      this.shoplist = [];
      const res = await this.api.managerpageApi({pageNo: 1, pageSize: 10000, mchType: 1, departmentId: this.departmentId, mchNo: this.form.mchNo});
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.shoplist = res.data.records;
      if (this.shoplist.length > 0) {
        this.form.shopNo = this.shoplist[0].shopNo
        this.onShopChange(this.form.shopNo)
      }
    },

    async handleConfirm() {
      this.registerModalVisible = false
      this.registerConfirmed = true
      await this.registerInvoice()
    },

    handleCancel() {
      this.registerModalVisible = false
    },
    fillinRefundAccountInfo() {
      if (this.selectedShop.refundAccountName && this.selectedShop.refundAccountNo) {
        this.form.sellerName = this.selectedShop.refundAccountName
        this.form.sellerBankAccountInfo = "浙江稠州商业银行股份有限公司" + this.selectedShop.refundAccountNo
      }
    },
    async submitInvoice() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if (this.infoMatch) {
            await this.registerInvoice()
          } else {
            this.registerConfirmed = false;
            this.registerModalVisible = true
          }
        } else {
          return false;
        }
      });
    },

    async registerInvoice() {
      const res = await this.api.mchShopInvoiceRegisterApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$message.success(res.info)
      this.$router.push({path: '/Mch/MchShopInvoice', query: this.$route.query});
    },
    formatInvoiceDate(input) {
      let parts = input.match(/(\d{4})年(\d{1,2})月(\d{1,2})日/);
      if (parts) {
        let year = parts[1], month = parts[2], day = parts[3];
        let date = new Date(year, month - 1, day);
        // 格式化日期为YYYY-MM-DD
        return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
      }
      return input
    },

    cancelInvoice() {
      //返回上一页
      // this.$router.go(-1);
      this.$router.push({path: '/Mch/MchShopInvoice', query: this.$route.query});
    },

    invoiceOcr: function (info) {
      if (info.file.status === 'uploading' && !this.loading) {
        this.$message.info(`识别中，请稍候`);
        this.loading = true
      }
      if (info.file.status === 'done') {
        this.loading = false
        if (info.file.response.code === 200) {
          this.$message.success(`${info.file.name} 识别成功,请核对发票信息`);
          const data = info.file.response.data;
          // 日期格式化一下
          data.ocrnvoiceResult.invoiceDate = this.formatInvoiceDate(data.ocrnvoiceResult.invoiceDate)

          this.rawInvoiceInfo = data.ocrnvoiceResult
          this.imageUrl = data.file_path;
          this.form.invoiceCode = data.ocrnvoiceResult.invoiceCode || '';
          this.form.invoiceNumber = data.ocrnvoiceResult.invoiceNumber || '';
          this.form.invoiceDate = data.ocrnvoiceResult.invoiceDate || '';

          this.form.purchaserName = data.ocrnvoiceResult.purchaserName || '';
          this.form.purchaserBankAccountInfo = data.ocrnvoiceResult.purchaserBankAccountInfo || '';
          this.form.totalAmount = data.ocrnvoiceResult.totalAmount || '';
          this.form.fileNo = data.file_no || '';
        } else {
          this.$message.error(`上传失败: ${info.file.response.message || '未知错误'}`);
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },
    onShopChange(newShopNo) {
      // 当选择器的值变化时，更新selectedShop
      const matchedShop = this.shoplist.find(item => item.shopNo === newShopNo);
      if (matchedShop) {
        this.selectedShop = matchedShop;
        this.fillinRefundAccountInfo()
      } else {
        this.selectedShop = {}; // 清空或设置默认值
      }
    },
  },
  created() {
    this.getMchList();
    // this.getShopList();
  },
  computed: {
    infoMatch() {
      return this.form.invoiceCode === this.rawInvoiceInfo.invoiceCode &&
          this.form.invoiceNumber === this.rawInvoiceInfo.invoiceNumber &&
          this.form.invoiceDate === this.rawInvoiceInfo.invoiceDate &&
          this.form.totalAmount === this.rawInvoiceInfo.totalAmount &&
          this.form.purchaserName === this.rawInvoiceInfo.purchaserName &&
          this.form.purchaserBankAccountInfo === this.rawInvoiceInfo.purchaserBankAccountInfo &&
          this.form.sellerName === this.rawInvoiceInfo.sellerName &&
          this.form.sellerBankAccountInfo === this.rawInvoiceInfo.sellerBankAccountInfo
    }
  }
};
</script>

<style scoped>


.top-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

a-input {
  width: 100%;
}

.invoice-uploader {
  width: 100px;
  height: 100px;

  .anticon-inbox /deep/ svg {
    font-size: 60px;
  }
}

.ant-form-horizontal {
  /deep/ .ant-form-item-label {
    width: 100px;
  }

  /deep/ .ant-form-item-control-wrapper {
    width: 300px;
    display: inline-block;
  }

  /deep/ .ant-collapse-item {

    /deep/ .ant-form-item-label {
      width: 140px;
    }

    /deep/ .ant-form-item-control-wrapper {
      width: 400px;
    }

    .ant-form-item {
      margin-bottom: 2px;
    }
  }

  /deep/ .ant-upload-select-picture-card {
    display: block;
    float: none;
    min-width: 300px;
    min-height: 200px;
    padding: 4px 0;
    object-fit: contain;
  }

  /deep/ .ant-collapse-content-box {
    background-color: #eaeaea;
  }
}

.ant-modal-root .ant-descriptions {
  span.pass {
    color: green;
  }

  span.fail {
    code {
      display: block;
      color: red;
    }

    span {
      border: 1px solid #eaeaea;
      color: gray;
    }
  }
}
</style>
