<template>
  <div id="role">
    <a-card title="角色管理" style="margin:20px">
      <!-- 搜索框 -->
      <div class="form">
        <view-select-form @selectHandle="selectHandle"></view-select-form>
      </div>
      <!-- 操作栏 -->
      <div class="action">
        <a-button v-if="$authority('role_add')" type="primary" @click="addHandle">新增</a-button>
        <a-button v-if="$authority('role_update')" @click="updateHandle">修改</a-button>
        <a-button v-if="$authority('role_delete')" type="danger" :disabled="selectedRowKeys.length === 0"
          @click="deleteHandle">删除</a-button>
      </div>
      <!-- 表格 -->
      <div class="table">
        <a-table :columns="columns" :data-source="tableData" :row-selection="rowSelection" :pagination="false"
          :customRow="clickrow" :rowKey="(record, index) => `${record.id}`">
          <template slot="number_index" slot-scope="text,record, index">
            <span>
              {{ (index + 1) + (currentPage - 1) * pageSize }}
            </span>
          </template>
          <template slot="look" slot-scope="text,record, index">
            <span style="cursor:pointer" @click="looKhandle(text, record, index)">
              查看
            </span>
          </template>
        </a-table>
      </div>
      <!-- page -->
      <div class="page">
        <a-pagination :current="currentPage" :total="total" show-size-changer show-quick-jumper style="float:right"
          @change="pageChange" @showSizeChange="sizePageChange" :show-total="total => `共 ${total} 条`" />
      </div>
      <!-- 模态框 -->
      <div class='modal' v-if="modalBool">
        <view-modal :modalBool="modalBool" :modalTitle="modalTitle" :selectedRows="selectedRows" :treeData="treeData"
          :treeNode="treeNodeUpdata" @okModalHandle="okModalHandle" @cancelModalHandle="cancelModalHandle"
          ref="modalChild"></view-modal>
      </div>
      <view-look-modal :lookBool="lookBool" :treeData="treeData" :treeNode="treeNode"
        @cancelPassHandle="cancelPassHandle">
      </view-look-modal>
    </a-card>
  </div>
</template>
<script>
import SelectForm from './SelectForm';
import Modal from "./Modal"
import LookModal from './LookModal'
const columns = [
  {
    title: '序号',
    dataIndex: 'number_index',
    align: 'center',
    scopedSlots: { customRender: 'number_index' },
  }, {
    title: '所属机构',
    dataIndex: 'depatName',
    align: 'center',

  },
  {
    title: '角色名称',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'action',
    align: 'center',
    scopedSlots: { customRender: 'look' },
  }
];
export default {
  name: 'rolePage',
  components: {
    "view-select-form": SelectForm,
    "view-modal": Modal,
    "view-look-modal": LookModal
  },
  data() {
    return {
      data: [],
      tableData: [],
      treeData: [],
      treeNode: [],
      treeNodeUpdata: [],
      selectData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      columns,
      modalBool: false,
      lookBool: false,
      modalTitle: "操作",
      selectedRowKeys: [],
      selectedRows: [{}]
    }
  },
  created() {
    this.tableRequestHandle()
    this.treeRequestHandle()
  },
  methods: {
    //查看
    looKhandle(text, val) {
      const data = val.functions
      let treeNode = []
      if (data && data.length !== 0) {
        treeNode = data.map(item => {
          return item.functionId
        })
      }
      this.treeNode = treeNode
      this.lookBool = true
    },
    cancelPassHandle() {
      this.lookBool = false
    },
    //页码操作
    pageChange(page, pageSize) {
      const data = this.data
      this.currentPage = page
      this.tableData = data.slice((page - 1) * pageSize, page * pageSize)
    },
    sizePageChange(current, size) {
      const data = this.data
      this.tableData = data.slice((current - 1) * size, current * size)
    },
    //搜索操作
    selectHandle(val) {
      this.selectedRowKeys = []
      this.selectData = val
      this.tableRequestHandle()
    },
    //数据增删改
    addHandle() {
      this.selectedRowKeys = []
      this.treeNodeUpdata = []
      this.selectedRows = [{}]
      this.modalTitle = "新增数据"
      this.modalBool = true
    },
    updateHandle() {
      const sum = this.selectedRowKeys
      if (sum.length !== 1) return this.$message.warn("只能选择一条数据")
      const data = this.selectedRows[0].functions
      let treeNode = []
      if (data && data.length !== 0) {
        treeNode = data.map(item => {
          return item.functionId
        })
      }
      this.treeNodeUpdata = treeNode
      this.modalTitle = "修改数据"
      this.modalBool = true
    },
    deleteHandle() {
      const data = this.selectedRowKeys
      const tableData = this.tableData
      const currentPage = this.currentPage
      this.$confirm({
        // title: '删除数据',
        // content: () => "是否删除此条数据？此过程不可逆！",
        title: '是否删除此条数据？此过程不可逆！',
        onOk: () => {
          this.$axios.apiPost('/user/role/del', data).then(res => {
            if (res.code !== 200) return this.$message.error(res.info)
            if (tableData.length === data.length && currentPage > 1) {
              this.currentPage = currentPage - 1
            }
            this.selectedRowKeys = []
            this.$message.success(res.info)
            this.tableRequestHandle()
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    //Modal操作
    okModalHandle(id, functions) {
      this.$refs['modalChild'].form.validateFields((err, values) => {
        if (!err) {
          let obj = {
            functions,
            name: values.name
          }
          if (!id) {
            this.$axios.apiPost('/user/role/save', obj).then(res => {
              this.$message.success(res.info)
              this.tableRequestHandle()
            })
          } else {
            this.$axios.apiPost('/user/role/save?id=' + id, obj).then(() => {
              this.$message.success("修改成功")
              this.tableRequestHandle()
              this.selectedRowKeys = []
            })
          }
          this.modalBool = false
        }
      })
    },
    cancelModalHandle() {
      this.modalBool = false
    },
    //列表数据
    tableRequestHandle() {
      const data = this.selectData
      this.$axios.apiGet('/user/role/list', data).then(res => {
        if (res.code !== 200) return this.$message.error(res.info)
        const data = res.data
        this.data = data
        this.total = data.length,
          this.tableData = data.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
      })
    },
    treeRequestHandle() {
      this.$axios.apiGet('/user/function/list2').then(res => {
        if (res.code !== 200) return this.$message.error(res.info)
        const data = res.data
        const array_tree = child(data)
        function child(data) {
          return data.map(item => {
            return {
              title: item.name,
              key: item.id,
              children: item.childList && item.childList.length !== 0 ? child(item.childList) : undefined
            }
          })
        }
        this.treeData = array_tree
      })
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            this.selectedRowKeys = [id]
            this.selectedRows = [record]
            // let index = this.selectedRowKeys.findIndex((item) => id == item)
            // if (index == -1) {
            //   this.selectedRowKeys.push(id)
            //   this.selectedRows.push(record)
            //
            // } else {
            //
            //   this.selectedRowKeys.splice(index, 1)
            //   this.selectedRows.splice(index, 1)
            // }
          }
        }
      }
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
        selectedRowKeys: this.selectedRowKeys
      };
    },
  }
}
</script>
<style lang="less" scoped>
#role {
  .page {
    margin: 12px 0;
  }

  .table {
    margin-top: 24px;
  }

  .form {
    margin-bottom: 16px;
  }
}
</style>