<template>
  <div>
    <img id="home" v-if="announcement.length===0" src="@/assets/home/home.jpg"/>
    <a-carousel v-else autoplay>
      <div v-for="item in announcement" class="container">
        <img v-if="item.type==='image'" :src="item.url"/>
        <p v-if="item.type==='plain'" v-html="item.content??''"></p>
      </div>
    </a-carousel>
  </div>
</template>
<script>


export default {
  name: 'home',
  data() {
    let announcement;
    try {
      announcement = JSON.parse(JSON.stringify(this.$store.state.announcement))
    } catch (e) {
      console.error(e)
      announcement = []
    }
    announcement = announcement.filter(item=>{
      if(item.startDate && this.$moment(item.startDate).isAfter(new Date())){
        return false
      }
      if(item.endDate && this.$moment(item.endDate).isBefore(new Date())){
        return false
      }
      return true
    })
    return {
      announcement,
    }
  },
  async created() {
    for (const item of this.announcement) {
      if (item.pop) {
        await this.$info({
          title: "通知",
          content: <p domPropsInnerHTML={item.content}></p>,
          onOk() {},
        })
      }
    }
  }
}

</script>
<style scoped>
#home {
  height: calc(100vh - 145px);
  width: 100%;
  object-fit: cover;
}

.container {
  height: calc(100vh - 145px);
  background: rgb(90, 190, 255);
  background: linear-gradient(0deg, rgba(90, 190, 255, 1) 0%, rgba(136, 206, 255, 0.43) 13%, rgba(0, 151, 255, 0.05) 100%);

  img, p, p a {
    margin: auto;
    height: 100%;
    width: 600px;
    object-fit: contain;
    font-size: 20px;
    color: #d9001b;
  }
  p /deep/ *{
    font-size: 20px;
  }
}
</style>