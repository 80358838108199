<template>
  <!-- 销售平台 -->
  <div>
    <a-card title="销售平台" style="margin: 20px">
      <div class="loan">
        <!-- !表单区域 -->
        <div class="formbox">


          <!--销售平台编号 -->
          <div class="inputbox">
            <div class="lefttitle">销售平台编号</div>
            <a-input v-model.trim="form.appNo" placeholder="请输入销售平台编号" />
          </div>
          <!--	销售平台名称 -->
          <div class="inputbox">
            <div class="lefttitle"> 销售平台名称</div>
            <a-input v-model.trim="form.spName" placeholder="请输入销售平台名称" />
          </div>
          <!--	销售平台付款账号 -->
          <div class="inputbox">
            <div class="lefttitle"> 销售平台付款账号</div>
            <a-input v-model.trim="form.spAccountNo" placeholder="请输入销售平台付款账号" />
          </div>
          <!--	销售平台付款户名 -->
          <div class="inputbox">
            <div class="lefttitle"> 销售平台付款户名</div>
            <a-input v-model.trim="form.spAccountName" placeholder="请输入销售平台付款户名" />
          </div>
          <!--		销售平台付款行号 -->
          <div class="inputbox">
            <div class="lefttitle"> 销售平台付款行号</div>
            <a-input v-model.trim="form.spBankNo" placeholder="请输入销售平台付款行号" />
          </div>
          <!--			销售平台付款行名 -->
          <div class="inputbox">
            <div class="lefttitle"> 销售平台付款行名</div>
            <a-input v-model.trim="form.spBankName" placeholder="请输入销售平台付款行名" />
          </div>
          <!-- 查询 -->

          <a-button class="inquire" @click="search" type="primary">
            查询
          </a-button>
          <!-- 重置 -->
          <a-button @click="reset" class="reset">重置</a-button>
        </div>

        <div class="Applyfor">
          <!--  新增  Add-->
          <a-button @click="newAdd" type="primary" v-if="$authority('SalesplatformAdd')">
            新增
          </a-button>
          <!--  编辑-->
          <a-button @click="edit" type="primary" v-if="$authority('SalesplatformEdit')"> 编辑 </a-button>

          <!-- 删除 -->
          <a-button @click="Deletebutton" type="danger" v-if="$authority('SalesplatformDel')"> 删除 </a-button>

        </div>
        <div class="tablebox">
          <!-- 表格 -->
          <div class="tableBody">
            <a-table ref="table" size="small" :rowKey="(record, index) => `${record.id}`" :customRow="clickrow"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                hideDefaultSelections: true,
                onChange: onSelectChange,
              }" :columns="columns" :data-source="data" :pagination="false">
              <span slot="fullPath" slot-scope="text"> <img style=" width: 48px; height: 48px;" :src="text" alt="">
              </span>

            </a-table>
          </div>
          <!-- 分页部分 -->
          <div class="paginationBox">
            <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
              首页
            </a-button> <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
              show-size-changer :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
              :item-render="itemRender">
              <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
            </a-pagination>
            <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
              class="Trailingpage">
              尾页
            </a-button>
          </div>
        </div>
      </div>
    </a-card>
    <a-modal title="新增" class="uploadaddform" :visible="modalBool" @ok="handleOk" @cancel="modalBool = false">
      <a-form-model class="context" ref="addruleForm" :model="addform" :label-col="{ span: 6, offset: 1 }"
        :wrapperCol="{ span: 15, offset: 1 }" labelAlign="left">


        <!-- 	销售平台编号 -->
        <a-form-model-item label="销售平台编号" prop="spNo"
          :rules="[{ required: true, message: '请输入销售平台编号', trigger: 'blur', }]">
          <a-input v-model.trim="addform.spNo" placeholder="请输入销售平台编号" />
        </a-form-model-item>

        <!-- 	销售平台名称 -->
        <a-form-model-item label="销售平台名称" prop="spName"
          :rules="[{ required: true, message: '请输入销售平台名称', trigger: 'blur', }]">
          <a-input v-model.trim="addform.spName" placeholder="请输入销售平台名称" />
        </a-form-model-item>
        <!-- 	销售平台付款账号 -->
        <a-form-model-item label="销售平台付款账号" prop="spAccountNo"
          :rules="[{ required: true, message: '请输入销售平台付款账号', trigger: 'blur', }]">
          <a-input v-model.trim="addform.spAccountNo" placeholder="请输入销售平台付款账号" />
        </a-form-model-item>
        <!-- 	销售平台付款户名 -->
        <a-form-model-item label="销售平台付款户名" prop="spAccountName"
          :rules="[{ required: true, message: '请输入销售平台付款户名', trigger: 'blur', }]">
          <a-input v-model.trim="addform.spAccountName" placeholder="请输入销售平台付款户名" />
        </a-form-model-item>
        <!-- 	销售平台付款行号 -->
        <a-form-model-item label="销售平台付款行号" prop="spBankNo"
          :rules="[{ required: true, message: '请输入销售平台付款行号', trigger: 'blur', }]">
          <a-input v-model.trim="addform.spBankNo" placeholder="请输入销售平台付款行号" />
        </a-form-model-item>
        <!-- 	销售平台付款行名 -->
        <a-form-model-item label="销售平台付款行名" prop="spBankName"
          :rules="[{ required: true, message: '请输入销售平台付款行名', trigger: 'blur', }]">
          <a-input v-model.trim="addform.spBankName" placeholder="请输入销售平台付款行名" />
        </a-form-model-item>
        <!-- 备注 -->
        <a-form-model-item label="备注" prop="note" :rules="[{ required: true, message: '请输入备注', trigger: 'blur', }]">
          <a-input v-model.trim="addform.note" placeholder="请输入备注" />
        </a-form-model-item>
        <!-- 文件编号 -->
        <a-form-model-item label="图片上传" prop="fileNo" :rules="[{ required: true, message: '请输入文件编号', trigger: 'blur', }]">
          <a-upload name="file" list-type="picture-card" accept="image/*" ref="Frontofidentitycard"
            class="avatar-uploader" :show-upload-list="false" :action="busiURL" :headers="headers"
            @change="changeIdUpload">
            <uploadImg :showEditicon="true" :showMask="false" v-show="Fileuploaddurl" @Uploaderror="
              Fileuploadloading = $event
              " @onloadsuccsse="
    Fileuploaddurl = $event
    " @fileData="FileuploadFile = $event; addform.fileNo = $event" @Editimg="FrontofidentitycardEdit" ref="IdUp">
            </uploadImg>
            <div v-show="Fileuploadloading == false &&
              !Fileuploaddurl
              ">
              <a-icon type="plus" />
            </div>
            <div v-if="Fileuploadloading == true &&
              Fileuploaddurl == ''
              " class="example">
              <a-spin />
            </div>
          </a-upload>
        </a-form-model-item>


      </a-form-model>
    </a-modal>
    <!-- 删除 -->
    <a-modal title="提示" :maskClosable="true" @cancel="deleteshow = false" :centered="true" :visible="deleteshow">
      <div class="Openanaccount">
        是否确认删除所勾选数据？删除后数据将不可恢复
      </div>
      <template v-slot:footer>
        <div class="custom-footer">
          <a-button type="primary" @click="Confirmdelete">确认</a-button>
          <a-button @click="deleteshow = false">取消</a-button>

        </div>

      </template>
    </a-modal>

  </div>
</template>

<script>

import { BizDict } from "@/utils/bizDict/kvMap";
import urlConfig from "@/utils/urlConfig";
const token = sessionStorage.getItem("token");

// import { handleChannelNo } from '@/utils/commonMethods'
// import dayjs from "dayjs";
import uploadImg from "../../../components/uploadImg/index";
export default {
  name: 'Salesplatform',
  data() {
    return {
      treeData: [],
      deleteshow: false, // 贷款弹窗
      statuslist: BizDict.LoanAppStatus,
      total: 0,
      modalBool: false,
      Examineandapproveshow: false,
      selectedRowKeys: [],
      busiURL: urlConfig.busiURL + "/file/manager/upload",
      headers: {
        token,
      },
      selectedRows: [], // 选数数组的值,
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      // 控制文件上传

      Fileupload: true, // 正面
      Fileuploaddurl: "", // 正面url
      FileuploadFile: "", // 图片数据
      Fileuploadloading: false,

      institutiontrees: {},

      data: [],
      addform: {
        spNo: undefined, //	销售平台编号
        spName: "",//	销售平台名称
        spAccountNo: '',//销售平台付款账号
        spAccountName: "",//销售平台付款户名
        spBankNo: '',//	销售平台付款行号
        spBankName: '',//	销售平台付款行名
        note: '',// 备注
        fileNo: '',// 文件编号
        filePath: '',//文件路径
      },
      form: {
        pageNo: 1,
        pageSize: 10,
        spNo: undefined, //	销售平台编号
        spName: "",//	销售平台名称
        spAccountNo: '',//销售平台付款账号
        spAccountName: "",//销售平台付款户名
        spBankNo: '',//	销售平台付款行号
        spBankName: '',//	销售平台付款行名
      },
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
            `${Number(this.form.pageNo - 1) * Number(this.form.pageSize) +
            Number(index + 1)
            }`,
          align: 'center',
        },
        {
          title: '销售平台编号',
          dataIndex: 'spNo',
          align: 'center',

        },

        {
          title: '文件图片',
          dataIndex: 'fullPath',
          align: 'center',
          key: 'fullPath',
          scopedSlots: { customRender: 'fullPath' },

        },
        {
          title: '销售平台名称',
          dataIndex: 'spName',
          align: 'center',


        },


        {
          title: '销售平台付款账号',
          dataIndex: 'spAccountNo',
          align: 'center',


        },
        {
          title: '销售平台付款户名',
          dataIndex: 'spAccountName',
          align: 'center',
          ellisis: true
        },
        {
          title: '销售平台付款行号',
          dataIndex: 'spBankNo',
          align: 'center',

        },
        {
          title: '销售平台付款行名',
          dataIndex: 'spBankName',
          align: 'center',

        },
        {
          title: '备注',
          dataIndex: 'note',
          align: 'center',

        },

        // {
        //   title: '文件路径',
        //   dataIndex: 'filePath',
        //   align: 'center',

        // },
        {
          title: '记录状态',
          dataIndex: 'recordStatus',
          align: 'center',
          customRender: (text) => {
            if (Number(text) == 0) {
              return ' 正常'
            }
            if (Number(text) == 1) {
              return ' 删除'
            }
          }

        },



      ],
    }
  },
  components: {
    uploadImg,
  },
  created() {

    this.getcommercePage()
    // this.form.departmentId = sessionStorage.getItem("companyId")


  },
  methods: {
    // 选择所属机构去触发
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    // 获取所属机构下拉框数据
    async getCustList() {
      let data = {
        username: this.$session.getItem('username'),
      }
      const res = await this.api.agencyList(data)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      let trees = []
      this.institutiontrees = res.data
      trees.push(res.data)

      this.treeData = this.getResettingTrees(trees)
        ? this.getResettingTrees(trees)
        : []
    },


    // 获取table列表
    async getcommercePage() {



      const res = await this.api.salePlatformInfopageApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.total = res.data.total
      this.form.pageNo = res.data.current
      this.form.pageSize = res.data.size

      this.data = res.data.records
    },


    // 点击新增
    newAdd() {
      this.addform = {
        spNo: undefined, //	销售平台编号
        spName: "",//	销售平台名称
        spAccountNo: '',//销售平台付款账号
        spAccountName: "",//销售平台付款户名
        spBankNo: '',//	销售平台付款行号
        spBankName: '',//	销售平台付款行名
        note: '',// 备注
        fileNo: '',// 文件编号
        filePath: '',//文件路径
      }

      setTimeout(() => {
        this.$nextTick(() => [
          this.$refs.IdUp.removeimg()
        ])
      }, 100)
      // removeimg
      this.modalBool = true

    },

    // 进行循环递归处理数据
    getResettingTrees(tree) {
      // 定义子级数组
      if (tree.length == 0) return []
      let arr = []

      tree.forEach((item) => {
        // 递归循环将每一项都处理城组件需要的的格式 [ {title:"" children:[{title:""}]  }]
        arr.push({
          title: item.coreDepartment.name,
          value: item.coreDepartment.id,
          key: item.coreDepartment.id,
          children:
            item.children && item.children.length > 0
              ? this.getResettingTrees(item.children)
              : [],
        })
      })
      // 处理好的数组返回出去
      return arr
    },
    // 点击重置表单
    reset() {
      this.form = {
        pageNo: 1,
        pageSize: 10,
        spNo: undefined, //	销售平台编号
        spName: "",//	销售平台名称
        spAccountNo: '',//销售平台付款账号
        spAccountName: "",//销售平台付款户名
        spBankNo: '',//	销售平台付款行号
        spBankName: '',//	销售平台付款行名
      }
      this.getcommercePage()
    },
    // 上传之前的处理
    handleBeforeUpload(file) {
      // console.log(file, 'file');
      const isLt2M = file.size / 1024 / 1024 <= 2;
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 2MB!");
      }
      return isLt2M;
    },
    changeIdUpload(info) {

      if (this.handleBeforeUpload(info.file) == false) return

      if (this.FileuploadFile !== "") {
        this.Fileuploadloading = false;
        this.FileuploadFile = "";
        this.Frontofidentitycardurl = "";
      }

      if (info.file.status === "uploading") {
        this.Fileuploadloading = true;
      }

      if (info.file.status === "done") {

        // 文件上传成功
        // console.log('文件上传成功', file.response)
        let res = info.file.response;
        if (res.code !== 200) {
          this.$message.error(res.info);
          this.$emit("Uploaderror", false);
          return;
        }
        this.filePath = res.data.file_path


      }
      this.$refs.IdUp.handleChangeUpload(info);



    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.form.pageSize = pageSize
      this.getcommercePage()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = 1,
        this.getcommercePage()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = Math.ceil(Number(this.total) / Number(this.form.pageSize))
      this.getcommercePage()
    },
    pagechange(val) {
      this.form.pageNo = val

      this.getcommercePage()
    },
    // 搜索查询
    search() {
      this.form.pageNo = 1
      this.getcommercePage()
    },

    // 点击新增确认
    handleOk() {
      // salePlatformInfoupdateApi
      // 首先进行表单校验


      this.$refs.addruleForm.validate((valid) => {
        if (valid) {

          if (this.addform.id) {

            this.api.salePlatformInfoupdateApi(this.addform).then((res) => {
              if (res.code !== 200) {
                this.$message.warning(res.info)
                return
              }

              this.$message.success('编辑成功')
              this.addform = {
                spNo: undefined, //	销售平台编号
                spName: "",//	销售平台名称
                spAccountNo: '',//销售平台付款账号
                spAccountName: "",//销售平台付款户名
                spBankNo: '',//	销售平台付款行号
                spBankName: '',//	销售平台付款行名
                note: '',// 备注
                fileNo: '',// 文件编号
                filePath: '',//文件路径
              }
              this.$refs.IdUp.removeimg()
              this.modalBool = false
              this.selectedRowKeys = []
              this.selectedRows = [] // 选数数组的值,
              this.getcommercePage()

            }
            )

          } else {
            this.api.salePlatformInfoaddApi(this.addform).then((res) => {
              if (res.code !== 200) {
                this.$message.warning(res.info)
                return
              }

              this.$message.success('新增成功')
              this.addform = {
                spNo: undefined, //	销售平台编号
                spName: "",//	销售平台名称
                spAccountNo: '',//销售平台付款账号
                spAccountName: "",//销售平台付款户名
                spBankNo: '',//	销售平台付款行号
                spBankName: '',//	销售平台付款行名
                note: '',// 备注
                fileNo: '',// 文件编号
                filePath: '',//文件路径
              }
              this.$refs.IdUp.removeimg()

              this.modalBool = false
              this.selectedRowKeys = []
              this.selectedRows = [] // 选数数组的值,
              this.getcommercePage()

            }
            )
          }



        } else {
          this.$message.warning("请先填写信息");
        }
      });
    },
    // 点击编辑
    edit() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择账户')
        return
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning('只能选择一个账户')
        return
      }
      this.modalBool = true
      let data = JSON.parse(JSON.stringify(this.selectedRows[0]))
      this.addform = data
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.IdUp.handleChangeUpload({
            file: {
              status: 'done',
              response: {
                code: 200,
                data: {
                }
              }
            },

          }, '', this.selectedRows[0].fullPath);

        })
      }, 800)
    },


    // 删除
    Deletebutton() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择账户')
        return
      }
      // if (this.selectedRows.length > 1) {
      //   this.$message.warning('只能进行单项删除')
      //   return
      // }
      this.deleteshow = true
    },
    // 点击删除
    async Confirmdelete() {
      let data = []
      this.selectedRows.forEach((item) => {
        data.push(item.id)
      })
      const res = await this.api.salePlatformInfodeleteApi(data)
      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
      this.$message.success('删除成功')
      this.selectedRowKeys = []
      this.selectedRows = [] // 选数数组的值,
      this.deleteshow = false
      this.getcommercePage()
    },
    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      // if (selectedRows.length > 1) {
      //   //获取选中的数据的key
      //   var selectNumber = this.selectedRowKeys[1]
      //   //清空选中的key
      //   this.selectedRowKeys = []
      //   //选中的数据的key重新赋值给selectedRowKeys
      //   this.selectedRowKeys.push(selectNumber)
      // }
      // //获取选中的数据详细信息
      // this.selectedRows = selectedRows[0]

    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            let index = this.selectedRowKeys.findIndex((item) => id == item)
            if (index == -1) {
              this.selectedRowKeys.push(id)
              this.selectedRows.push(record)

            } else {

              this.selectedRowKeys.splice(index, 1)
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj == {}) return
      if (dataObj.coreDepartment.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },

  },
}
</script>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.uploadaddform {
  .ant-modal {
    width: 800px !important;
  }

  .ant-modal-content {

    width: 820px;
  }

  .context {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .ant-form-item {
      width: 378px;


    }
  }

  .ant-upload {
    width: 204px;
  }
}
</style>
<style lang="less" scoped>
.ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}



/* 自定义表头样式 */
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

.ant-modal-footer {

  padding-bottom: 20px;
}

.ant-modal-content {
  width: 420px;
}

.loan {
  .formbox {
    display: flex;
    flex-wrap: wrap;

    .inputbox {
      width: 320px;
      margin-right: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      // input {
      //   width: 188px;
      // }

      .lefttitle {
        min-width: 80px;
      }
    }

    .zhaungtai {
      width: 280px;
      margin-right: 120px;
    }

    .reset {
      width: 64px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }

    button {
      margin-bottom: 20px;
    }

    .ant-tag {
      cursor: pointer;
    }
  }

  .tablebox {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .ant-tag {
      cursor: pointer;
    }
  }

  .paginationBox {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;

    // .ant-pagination-options {
    //   float: left;
    // }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: 1px solid #d9d9d9;
      width: 60px;
    }
  }

  .Applyfor {
    padding-top: 10px;
    margin-bottom: 20px;
  }
}
</style>
