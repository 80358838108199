<template>
  <div>
    <!-- 业务报表 -->
    <a-card title="业务报表" style="margin: 20px">
      <div class="select-time">
        <span class="text-tip">请选择日期</span>
        <a-range-picker v-model="timeRange" valueFormat="YYYY-MM-DD" @change="onChangeTime" style="width: 200px" />
      </div>
      <div class="container">
        <div id="linkedChart" style="width: 70%; height: 500px"></div>
        <div style="width: 30%; height: 500px">
          <a-table ref="table" size="small" :pagination="false"></a-table>
        </div>
      </div>
    </a-card>
  </div>
</template>
<script>
import * as echarts from "echarts";
import dayjs from "dayjs";
import { groupBy } from "@/utils/commonMethods";
export default {
  name: "Cockpit",
  data() {
    return {
      aggrData: [],
      linkedChart: undefined,
      timeRange: [],
    };
  },
  created() { },
  mounted() {
    // 初始化图表
    this.chartsInit();
    // 默认时间
    let startTime = dayjs().startOf("month").format("YYYY-MM-DD");
    let endTime = dayjs().format("YYYY-MM-DD");
    this.$set(this.timeRange, 0, startTime);
    this.$set(this.timeRange, 1, endTime);

    // 拉取数据
    this.dataReload();
  },
  destroyed() {
    if (this.linkedChart) {
      this.linkedChart.dispose();
      this.linkedChart = undefined;
    }
  },
  methods: {
    chartsInit() {
      // 联动图
      this.linkedChart = echarts.init(document.getElementById("linkedChart"));
      const linkedOption = {
        legend: {},
        tooltip: {
          trigger: 'axis',
          showContent: false
        },
        xAxis: { type: 'category' },
        yAxis: { gridIndex: 0 },
        grid: { bottom: '50%' },
        series: []
      }
      let linkedChart = this.linkedChart
      linkedChart.on('updateAxisPointer', function (event) {
        const xAxisInfo = event.axesInfo[0];
        if (xAxisInfo) {
          const dimension = xAxisInfo.value + 1;
          linkedChart.setOption({
            series: {
              id: 'pie',
              label: {
                formatter: '{b}: {@[' + dimension + ']}万\n({d}%)'
              },
              encode: {
                value: dimension,
                tooltip: dimension
              }
            }
          });
        }
      });

      this.linkedChart.setOption(linkedOption)
    },
    onChangeTime() {
      this.dataReload();
    },
    // 调用接口刷新数据
    async dataReload() {
      let startTime = `${this.timeRange[0]} 0:00:00`;
      let endTime = `${this.timeRange[1]} 23:59:59`;
      let data = {
        key: "flight_deck_1",
        sqlParame: {
          staDate: startTime,
          endDate: endTime,
          depatCode: this.$session.getItem("companyCode"),
        },
      };
      let res = await this.api.getEchartsDataApi(data);
      if (res.code != 200) {
        return this.$message.error(res.info);
      }

      this.aggrData = groupBy(res.data, ['日期', '渠道'], { sum: ['金额'] })
        .sort((a, b) => a['日期'].localeCompare(b['日期']) || a['渠道'].localeCompare(b['渠道']))
      // aggrData = [{"日期":"2023-09-01","渠道":"华岳"},{"日期":"2023-09-01","渠道":"巽平","合计#金额":18.1},{"日期":"2023-09-02","渠道":"华岳","合计#金额":234.96},{"日期":"2023-09-02","渠道":"巽平","合计#金额":22.38},{"日期":"2023-09-03","渠道":"华岳","合计#金额":19.310000000000002},{"日期":"2023-09-03","渠道":"巽平","合计#金额":20.78},{"日期":"2023-09-04","渠道":"华岳","合计#金额":49.16},{"日期":"2023-09-04","渠道":"巽平","合计#金额":13.690000000000001},{"日期":"2023-09-05","渠道":"华岳","合计#金额":95.19999999999999},{"日期":"2023-09-05","渠道":"巽平","合计#金额":15.51},{"日期":"2023-09-06","渠道":"华岳","合计#金额":37.21},{"日期":"2023-09-06","渠道":"巽平","合计#金额":9.780000000000001},{"日期":"2023-09-07","渠道":"华岳","合计#金额":32.54},{"日期":"2023-09-07","渠道":"巽平","合计#金额":22.73},{"日期":"2023-09-08","渠道":"华岳","合计#金额":6.79},{"日期":"2023-09-08","渠道":"巽平","合计#金额":20.8},{"日期":"2023-09-09","渠道":"华岳","合计#金额":55.14},{"日期":"2023-09-09","渠道":"巽平","合计#金额":30.82},{"日期":"2023-09-10","渠道":"华岳"},{"日期":"2023-09-10","渠道":"巽平","合计#金额":1.62},{"日期":"2023-09-11","渠道":"华岳","合计#金额":31.95},{"日期":"2023-09-11","渠道":"巽平","合计#金额":27.76}]

      this.chartRender()
      this.tableRender()
    },
    chartRender() {
      const dateList = Array.from(new Set(this.aggrData.map(i => i['日期'])))
      const channelList = Array.from(new Set(this.aggrData.map(i => i['渠道'])))
      const chartSource = [
        ['日期', ...dateList],
        ...channelList.map(channel =>
          [channel,
            ... this.aggrData
              .filter(row => row['渠道'] === channel)
              .map(item => (item['合计#金额'] ?? 0).toFixed(2))
          ]
        )
      ]
      // source =
      // [ ["日期","2023-09-01","2023-09-02","2023-09-03","2023-09-04","2023-09-05","2023-09-06","2023-09-07","2023-09-08","2023-09-09","2023-09-10"],
      // ["华岳",null,"234.96","19.31","49.16","95.20","37.21","32.54","6.79","55.14",null],
      // ["巽平","18.10","22.38","20.78","13.69","15.51","9.78","22.73","20.80","30.82","1.62"]]
      this.linkedChart.setOption({
        series: [
          // 每个渠道生成一条线
          ...channelList.map((channel) => new Object({
            type: 'line',
            id: channel,
            smooth: true,
            label: { show: true },
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' }
          })),
          // 饼图
          {
            id: "pie",
            type: 'pie',
            radius: '30%',
            center: ['50%', '75%'],
            emphasis: {
              focus: 'self'
            },
            label: {
              formatter: '{b}: {@' + dateList[dateList.length - 1] + '}万\n({d}%)'
            },
            encode: {
              itemName: '日期',
              value: dateList[dateList.length - 1],
              tooltip: dateList[dateList.length - 1]
            }
          },
        ],
        dataset: { source: chartSource }
      })
      // this.lineChart.setOption()
    },
    tableRender() {

      const table = this.$refs.table

      const channelList = Array.from(new Set(this.aggrData.map(i => i['渠道'])))

      table.scroll = { x: 100 + 100 * channelList.length, y: 450 }
      const data = this.aggrData.reduce((acc, cur) => {
        acc[cur['日期']] = acc[cur['日期']] ?? {}
        acc[cur['日期']][cur['渠道']] = cur['合计#金额']
        acc[' 合计'] = acc[' 合计'] ?? {}
        acc[' 合计'][cur['渠道']] = (acc[' 合计'][cur['渠道']] ?? 0) + (cur['合计#金额'] ?? 0)
        return acc
      }, {})

      table.dataSource = Object.keys(data).map(key => new Object({
        '日期': key,
        ...data[key]
      })).sort((a, b) => b['日期'].localeCompare(a['日期']))
      table.columns = [
        {
          key: '1',
          title: "日期",
          dataIndex: "日期",
          align: 'center',
        },
        ...channelList.map(channel => new Object({
          title: channel + '（万）',
          width: 100,
          dataIndex: channel,
          align: 'center',
          customRender: (text) => (text ?? 0).toFixed(2),
          customCell: () => new Object({
            style: {
              "text-align": "right",
              padding: "0 20px 0 0"
            }
          })
        }))
      ]

    }
  },
};
</script>

<style lang="less" scoped>
.select-time {
  float: left;
  margin-bottom: 30px;

  .text-tip {
    margin-right: 20px;
    font-size: 16px;
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
</style>