<template>
  <a-form
      class="ant-advanced-search-form"
      :label-col="{span: 4, offset: 1}"
      :wrapperCol="{span: 18, offset: 1}"
      labelAlign="left"
      :colon="false"
  >
    <a-row>
      <a-col :span="6">
        <a-form-item label="常量编码">
          <a-input v-model="selectFormData.constCode" placeholder="请输入常量编码"/>
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item label="常量名称">
          <a-input v-model="selectFormData.constName" placeholder="请输入常量名称"/>
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item label="常量数值">
          <a-input v-model="selectFormData.constValue" placeholder="请输入常量数值"/>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="6">
        <a-form-item label="常量类别">
          <a-input v-model="selectFormData.constType" placeholder="请输入常量类别"/>
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item label="常量类别编码">
          <a-input v-model="selectFormData.constTypeCode" placeholder="请输入常量类别编码"/>
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item label="标签">
          <a-input v-model="selectFormData.constFlag" placeholder="请输入标签"/>
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-button style="margin-top:3px;margin-left:20px" type="primary" @click="handleFormLayoutChange">
          查询
        </a-button>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      selectFormData: {
        constCode: undefined,
        constName: undefined,
        constValue: undefined,
        constType: undefined,
        constTypeCode: undefined,
        constFlag: undefined
      }
    };
  },
  computed: {},
  methods: {
    handleFormLayoutChange() {
      this.$emit('selectHandle', this.selectFormData)
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
  align-items: center;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.ant-advanced-search-form .ant-form-item {
  margin-bottom: 6px;
}
</style>