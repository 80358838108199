<template>
  <!-- 短信记录 -->
  <div>
    <a-card title="短信记录" style="margin: 20px">
      <div class="loan">
        <!-- !表单区域 -->
        <div class="formbox">


          <!-- 	交易码 -->
          <div class="inputbox">
            <div class="lefttitle">交易码</div>
            <a-input v-model.trim="form.tradeNo" placeholder="请输入交易码" />
          </div>
          <!-- 模版编号 -->
          <div class="inputbox">
            <div class="lefttitle">模版编号</div>
            <a-input v-model.trim="form.modelNo" placeholder="请输入模版编号" />
          </div>
          <!-- 	短信类型 -->
          <div class="inputbox">
            <div class="lefttitle">短信类型</div>

            <a-select style="width: 200px;" v-model.trim="form.tradeType" placeholder="请选择模版编号">
              <a-select-option v-for="item in tradeTypelist" :key="item.label" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </div>
          <!-- 	手机号 -->
          <div class="inputbox">
            <div class="lefttitle">手机号</div>
            <a-input v-model.trim="form.mobile" placeholder="请输入手机号" />
          </div>


          <!-- 查询 -->

          <a-button class="inquire" @click="search" type="primary" v-if="$authority('ShortmessagerecordQuery')">
            查询
          </a-button>
          <!-- 重置 -->
          <a-button @click="reset" class="reset">重置</a-button>
        </div>


        <div class="tablebox">
          <!-- 表格 -->
          <div class="tableBody">
            <a-table ref="table" size="small" :rowKey="(record, index) => `${record.id}`" :row-selection="{
              selectedRowKeys: selectedRowKeys,
              hideDefaultSelections: true,
              onChange: onSelectChange,
            }" :customRow="clickrow" :columns="columns" :data-source="data" :pagination="false">
            </a-table>
          </div>
          <!-- 分页部分 -->
          <div class="paginationBox">
            <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
              首页
            </a-button> <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
              show-size-changer :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
              :item-render="itemRender">
              <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
            </a-pagination>
            <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
              class="Trailingpage">
              尾页
            </a-button>
          </div>
        </div>
      </div>
    </a-card>
    <!-- 删除 -->
    <!-- <a-modal title="提示" :maskClosable="true" @cancel="deleteshow = false" :centered="true" :visible="deleteshow">
      <div class="Openanaccount">
        是否确认删除所勾选数据？删除后数据将不可恢复
      </div>
      <template v-slot:footer>
        <div class="custom-footer">
          <a-button type="primary" @click="Confirmdelete">确认</a-button>
          <a-button @click="deleteshow = false">取消</a-button>

        </div>

      </template>
    </a-modal> -->

  </div>
</template>

<script>

import { BizDict } from "@/utils/bizDict/kvMap";
// import { handleChannelNo } from '@/utils/commonMethods'

export default {
  name: 'Shortmessagerecord',
  data() {
    return {
      treeData: [],
      deleteshow: false, // 贷款弹窗
      statuslist: BizDict.LoanAppStatus,
      total: 0,
      modalBool: false,
      Examineandapproveshow: false,
      selectedRowKeys: [],
      selectedRows: [], // 选数数组的值,
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      institutiontrees: {},

      data: [],

      form: {
        pageNo: 1,
        pageSize: 10,
        tradeNo: '', // 交易码
        modelNo: '',// 模版编号
        tradeType: undefined,// 短信类型 
        mobile: '',	//手机号
      },
      // 短信类型
      tradeTypelist: [
        {
          label: '验证码 ',
          value: '1',
        },
        {
          label: '交易类 ',
          value: '2',
        },
        {
          label: '通知类',
          value: '3',
        },
        {
          label: '预警类 ',
          value: '4',
        },
        {
          label: '其他 ',
          value: '9',
        },
      ],

      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
            `${Number(this.form.pageNo - 1) * Number(this.form.pageSize) +
            Number(index + 1)
            }`,
          align: 'center',
        },

        {
          title: '交易码',
          dataIndex: 'tradeNo',
          align: 'center',
          ellisis: true,
        },
        {
          title: '模版编号',
          dataIndex: 'modelNo',
          align: 'center',
          ellisis: true,
        },
        {
          title: '短信类型',
          dataIndex: 'tradeType',
          align: 'center',
          ellisis: true,
          customRender: (text) => {
            if (Number(text) == 1) {
              return '验证码'
            }
            if (Number(text) == 2) {
              return '交易类'
            }
            if (Number(text) == 3) {
              return '通知类'
            }
            if (Number(text) == 4) {
              return '预警类'
            }
            if (Number(text) == 9) {
              return '其他'
            }
          }
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          align: 'center',
          ellisis: true,
        },
        {
          title: '短信内容',
          dataIndex: 'message',
          align: 'center',
          ellisis: true,
        },
        {
          title: '记录状态',
          dataIndex: 'recordStatus',
          align: 'center',
          ellisis: true,
          customRender: (text) => {
            if (Number(text) == 0) {
              return '正常'
            }
            if (Number(text) == 2) {
              return '删除'
            }

          }
        },



      ],
    }
  },
  created() {

    this.getcommercePage()



  },
  methods: {
    // 选择所属机构去触发
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    // 获取所属机构下拉框数据
    async getCustList() {
      let data = {
        username: this.$session.getItem('username'),
      }
      const res = await this.api.agencyList(data)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      let trees = []
      this.institutiontrees = res.data
      trees.push(res.data)

      this.treeData = this.getResettingTrees(trees)
        ? this.getResettingTrees(trees)
        : []
    },


    // 获取table列表
    async getcommercePage() {



      const res = await this.api.smspageApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.total = res.data.total
      this.form.pageNo = res.data.current
      this.form.pageSize = res.data.size

      this.data = res.data.records
    },



    // 进行循环递归处理数据
    getResettingTrees(tree) {
      // 定义子级数组
      if (tree.length == 0) return []
      let arr = []

      tree.forEach((item) => {
        // 递归循环将每一项都处理城组件需要的的格式 [ {title:"" children:[{title:""}]  }]
        arr.push({
          title: item.coreDepartment.name,
          value: item.coreDepartment.id,
          key: item.coreDepartment.id,
          children:
            item.children && item.children.length > 0
              ? this.getResettingTrees(item.children)
              : [],
        })
      })
      // 处理好的数组返回出去
      return arr
    },
    // 点击重置表单
    reset() {
      this.form = {
        pageNo: 1,
        pageSize: 10,
        tradeNo: '', // 交易码
        modelNo: '',// 模版编号
        tradeType: undefined,// 短信类型 
        mobile: '',	//手机号
      },
        this.getcommercePage()
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.form.pageSize = pageSize
      this.getcommercePage()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = 1,
        this.getcommercePage()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = Math.ceil(Number(this.total) / Number(this.form.pageSize))
      this.getcommercePage()
    },
    pagechange(val) {
      this.form.pageNo = val

      this.getcommercePage()
    },
    // 搜索查询
    search() {
      this.form.pageNo = 1
      this.getcommercePage()
    },


    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      // if (selectedRows.length > 1) {
      //   //获取选中的数据的key
      //   var selectNumber = this.selectedRowKeys[1]
      //   //清空选中的key
      //   this.selectedRowKeys = []
      //   //选中的数据的key重新赋值给selectedRowKeys
      //   this.selectedRowKeys.push(selectNumber)
      // }
      // //获取选中的数据详细信息
      // this.selectedRows = selectedRows[0]

    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            let index = this.selectedRowKeys.findIndex((item) => id == item)
            if (index == -1) {
              this.selectedRowKeys.push(id)
              this.selectedRows.push(record)

            } else {

              this.selectedRowKeys.splice(index, 1)
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj == {}) return
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },

  },
}
</script>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped>
.ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}

/* 自定义表头样式 */
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

.ant-modal-footer {

  padding-bottom: 20px;
}

.ant-modal-content {
  min-width: 420px;
}



.loan {
  .formbox {
    display: flex;
    flex-wrap: wrap;

    .inputbox {
      width: 260px;
      margin-right: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      input {
        width: 180px;
      }

      .lefttitle {
        min-width: 80px;
      }
    }

    .zhaungtai {
      width: 280px;
      margin-right: 120px;
    }

    .reset {
      width: 64px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }

    button {
      margin-bottom: 20px;
    }

    .ant-tag {
      cursor: pointer;
    }
  }

  .tablebox {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .ant-tag {
      cursor: pointer;
    }
  }

  .paginationBox {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;

    // .ant-pagination-options {
    //   float: left;
    // }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: 1px solid #d9d9d9;
      width: 60px;
    }
  }

  .Applyfor {
    padding-top: 10px;
    margin-bottom: 20px;
  }
}
</style>
