<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>发票管理</span>
          </div>
        </div>
      </template>
      <div class="loan">
        <div class="search">
          <a-form :model="form" layout="inline">
            <a-form-item label="机构">
              <departmentChoose :department-code.sync="form.departmentCode" @change="departmentChange"></departmentChoose>
            </a-form-item>
            <a-form-item label="开票日期">
              <a-month-picker @change="invoiceMonthChange"
                              v-model="invoiceMonth"
                              format="YYYY年MM月"
                              :allowClear="false"
              ></a-month-picker>
            </a-form-item>
            <a-form-item label="商户">
              <a-select :dropdownMatchSelectWidth="false" placeholder="请选择电商"
                        :show-search="true" v-model.trim="form.mchNo" @change="getMchShopListApi"
                        style="min-width: 300px;">
                <a-select-option v-for="item in mchNolist" :key="item.value" :value="item.value">
                  <div style="display: flex; justify-content: space-between">
                    <span> {{item.label}} </span>
                    <code style="color: grey;font-size: 0.8em">{{ item.value }}</code>
                  </div>
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="收款账号">
              <a-select
                  :dropdownMatchSelectWidth="false" placeholder="请选择收款账号"
                  :show-search="true" v-model="form.shopNo"
                  style="min-width: 200px;">
                <a-select-option v-for="item in shoplist" :key="item.value" :value="item.value">
                  <div style="display: flex; justify-content: space-between">
                  <span>{{ item.shopName }}</span>
                  <code style="color: grey;font-size: 0.8em">{{ item.refundAccountNo }}</code></div>
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="search">查询</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="Applyfor">
          <!--          <a-button @click="add" type="primary" v-if="$authority('invoiceAdd')">发票登记</a-button>-->
          <a-button @click="add" type="primary"> 发票登记</a-button>
          <!--          <a-button @click="importInvoiceReceipt" type="primary" v-if="$authority('importInvoiceReceipt')"> 发票到账明细导入</a-button>-->
          <a-button @click="invoiceDepositImport" type="primary" v-if="$authority('importInvoiceReceipt')"> 发票到账明细导入</a-button>
        </div>
        <div class="tablebox">
          <a-table :columns="columns" :data-source="data" :row-key="(record) => record.id" :pagination="false" bordered>
            <template slot="action" slot-scope="text, record">
              <a-button
                  type="normal"
                  style="color:forestgreen"
                  v-if="record.normalStatus !== 1 && $authority('invoiceNormal') "
                  @click="normal(record)"
                  aria-label="匹配操作">
                匹配来账
              </a-button>
              <a-button
                  type="normal"
                  v-if="(record.invoiceType == 1 || record.invoiceType == 2 ) && record.fileNo "
                  @click="invoiceFileView(record)"
                  aria-label="发票查看">
                发票文件查看
              </a-button>
              <a-button
                  type="normal"
                  style="color:orange"
                  v-if="record.clearStatus !== 1 && $authority('invoiceClearing') "
                  @click="clearing(record)"
                  aria-label="清算操作">
                清算
              </a-button>
              <a-button
                  type="normal"
                  style="color:red"
                  v-if=" $authority('invoiceInvalid') &&  record.normalStatus === 0 && record.clearStatus === 0 "
                  @click="invalid(record)"
                  aria-label="作废操作">
                作废
              </a-button>
            </template>

          </a-table>
        </div>

        <div class="paginationBox">
          <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
            首页
          </a-button>
          <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
                        show-size-changer :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
                        :item-render="itemRender">
          </a-pagination>
          <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
                    class="Trailingpage">
            尾页
          </a-button>
        </div>

        <div class="tablebox" v-if="statistics">
          <a-row>
            <a-col :span="24" class="text-center">
              <p>汇总订单已融资额度：{{ statisticsData.loanAmountSum || 0.00 }}</p>
              <p>预计应收（不含手续费）：{{ statisticsData.orderAmount3 || 0.00 }} <a @click="viewDetails">查看明细</a></p>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-card>
    <a-modal
        title="发票到账明细导入"
        :visible="importInvoiceReceiptVisible"
        @ok="importInvoiceReceiptOk"
        @cancel="importInvoiceReceiptCancel"
    >

      <a-form-model ref="ruleForm" :model="form">
        <a-form-model-item ref="mchNo" label="电商" prop="mchNo">
          <a-select class="dianshang" :dropdownMatchSelectWidth="false" placeholder="请选择电商" :show-search="true"
                    v-model.trim="form.mchNo"
                    style="width: 100%;" @change="getShopList">
            <a-select-option v-for="item in mchNolist" :key="item.value" :value="item.value">
                    <span>
                      <a-tag color="purple">融</a-tag>{{ item.value }}-{{ item.label }}<br/>
                      <a-tag color="blue" v-if="item.fatherName">控</a-tag>{{ item.fatherName || "" }}
                    </span>
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item ref="shopNo" label="平台/店铺" prop="shopNo">
          <a-select class="dianshang" :dropdownMatchSelectWidth="false" placeholder="请选择店铺" :show-search="true"
                    v-model.trim="form.shopNo"
                    style="width: 100%;">
            <a-select-option v-for="item in shoplist" :key="item.shopNo" :value="item.shopNo">
              {{ item.shopNo }}-{{ item.shopName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item ref="file" label="发票到账明细" prop="file">
          <a-upload :fileList="fileList" :remove="handleRemove" :customRequest="beforeUpload">
            <a-button>
              <a-icon type="upload"/>
              上传
            </a-button>
          </a-upload>
        </a-form-model-item>
      </a-form-model>

    </a-modal>
    <a-modal
        title="发票文件查看"
        :visible="invoiceImgFileVisible"
        @ok="invoiceImgFileVisible = false"
        @cancel="invoiceImgFileVisible = false"
        width="850px"
        :body-style="{ height: '750px', overflow: 'auto' }"
    >
      <img :src="fileUrl" style="width: 100%; height: 100%; object-fit: contain;" />
    </a-modal>



  </div>

</template>

<script>
import {BizDict} from "@/utils/bizDict/kvMap";
import {dict_rawdata as busiDict} from "@/utils/bizDict/rawdata";
import Balance from "@/view/components/Balance.vue";
import authority from "@/utils/authority";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
import DeptInfo from "@/view/components/DeptInfo.vue";

export default {
  name: 'MchShopInvoice',
  components: {
    DepartmentChoose,
    Balance
  },
  computed: {
    busiDict() {
      return busiDict
    }
  },
  data() {
    return {
      pageSizeOptions: [ '2','10', '20', '30', '40', '50'],
      fileList: [],
      selectedShop: {}, // 存储选中店铺的完整信息
      treeData: [],
      statuslist: BizDict.LoanAppStatus,
      data: [],
      statisticsData: {
        loanAmountSum: null,
        orderAmount3: null
      },
      statistics: false,
      importInvoiceReceiptVisible: false,
      fileUrl: '',
      invoiceImgFileVisible: false,
      mchNolist: [], // 电商列表
      shoplist: [], // 店铺列表
      invoiceMonth: this.$moment().startOf("month"),
      total: 0,
      form: {
        invoiceDate: this.$moment().startOf("month").format("YYYY-MM-DD"),
        mchNo: '', //电商编号
        shopNo: '', // 店铺号
        pageNo: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: '机构',
          align: 'center',
          dataIndex: 'departmentCode',
          customRender: (text) => {
            return <DeptInfo deptCode={text} />
          }
        },
        {
          title: '开票日期',
          align: 'center',
          dataIndex: 'invoiceDate',
        },
        {
          title: '发票编号',
          dataIndex: 'invoiceNo',
          align: 'center',
        },{
          title: '发票类型',
          dataIndex: 'invoiceType',
          align: 'center',
          customRender: (text) => {
            if (text == 1) {
              return '专票'
            } else if (text == 2) {
              return '普票'
            } else if (text == 3) {
              return '特殊发票'
            } else {
              return '其他'
            }
          }
        }, {
          title: '付款方',
          dataIndex: 'purchaserName',
        },
        {
          title: '开票金额',
          dataIndex: 'totalAmount',
          align: 'center',
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          }
        },
        {
          title: '到账金额',
          dataIndex: 'matchedRefundAmount',
          align: 'center',
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          }
        },
        {
          title: '清算金额',
          dataIndex: 'settleAmount',
          align: 'center',
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          }
        },
        {
          title: '到账状态',
          dataIndex: 'normalStatus',
          align: 'center',
          ellisis: true,
          customRender: (text) => {
            let matchStatus = this.matchStatus.find((item)=>String(item.value) === String(text))
            return <a-tag color={matchStatus.color}> {matchStatus.text} </a-tag>
          },
        },
        {
          title: '清算状态',
          dataIndex: 'clearStatus',
          align: 'center',
          ellisis: true,
          customRender: (text) => {
            let settleStatus = this.settleStatus.find((item)=>String(item.value) === String(text))
            return <a-tag color={settleStatus.color}> {settleStatus.text} </a-tag>
          },
        },
        ...(authority('invoiceNormal') || authority('invoiceClearing') || authority('invoiceInvalid') ? [
          {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            scopedSlots: {customRender: 'action'},
          }
        ] : [])
      ],
      settleStatus: [{
        text: '未清算',
        value: '0',
        color: 'red'
      }, {
        text: '已清算',
        value: '1',
        color: 'green'
      }, {
        text: '部分清算',
        value: '2',
        color: 'orange'
      }],
      matchStatus: [{
        text: '未到账',
        value: '0',
        color: 'red'
      }, {
        text: '已到账',
        value: '1',
        color: 'green'
      }, {
        text: '部分到账',
        value: '2',
        color: 'orange'
      }],
    }
  },
  created() {
    this.getMchLiveListApi()
  },
  mounted() {
  },
  activated() {

  },

  methods: {

// 获取发票文件
    async invoiceFileView(data) {
      const res = await this.api.downloadImage(data.fileNo);
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      console.log(res.data);

      // 直接使用 isFileType 方法判断类型
      if (this.isFileType(res.data, 'image')) {
        this.fileUrl = res.data;

        this.invoiceImgFileVisible = true;
      } else if (this.isFileType(res.data, 'pdf')) {

        window.open(res.data);
        // this.fileUrl = res.data;
        //
        // this.invoicePdfFileVisible = true;
      }
    },

    // 通用文件类型判断方法
    isFileType(fileUrl, type) {
      // 去除查询参数部分，只保留路径部分
      const cleanUrl = fileUrl.split('?')[0];

      const imageRegex = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
      const pdfRegex = /\.pdf$/i;

      if (type === 'image') {
        const isImage = imageRegex.test(cleanUrl);
        console.log("img", isImage);
        return isImage;
      } else if (type === 'pdf') {
        const isPDF = pdfRegex.test(cleanUrl);
        console.log("pdf", isPDF);
        return isPDF;
      }
      return false;
    },


    viewDetails() {
      this.$router.push({
        path: '/orders/Summaryorder',
        query: {
          invoiceDate: this.form.invoiceDate,
          mchNo: this.form.mchNo,
          shopNo: this.form.shopNo,
          pageNo: this.form.pageNo,
          pageSize: this.form.pageSize,
        },
      })
    },

    //匹配
    async normal(data) {
      data.refundAccountNo = this.selectedShop.refundAccountNo;
      data.collAccountName = this.selectedShop.collAccountName;
      this.$router.push({
        path: '/Mch/MchShopInvoiceNormal',
        query: {
          id: data.id,
          invoiceDate: this.form.invoiceDate,
          mchNo: this.form.mchNo,
          shopNo: this.form.shopNo,
          pageNo: this.form.pageNo,
          pageSize: this.form.pageSize,
        },
      })
    },
    //清算
    async clearing(data) {
      const res = await this.api.mchShopInvoiceSettleApi(data.id)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$message.info(res.info)
      await this.getList()
    },
    //作废
    async invalid(data) {
      const res = await this.api.mchShopInvoiceInvalidApi([data.id])
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      await this.getList()
    },
    // 获取table列表
    async getList() {
      // 检查必填项
      if (!this.form.invoiceDate || !this.form.mchNo || !this.form.shopNo) {
        return
      }
      const res = await this.api.mchShopInvoiceListApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return false
      }
      this.total = res.data.total
      this.form.pageNo = res.data.current
      this.form.pageSize = res.data.size

      this.data = res.data.records
      return true
    },
    async getStatistics() {
      // 检查必填项
      if (!this.form.invoiceDate || !this.form.mchNo || !this.form.shopNo) {
        return
      }
      const res = await this.api.collectorderstatisticsApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return false
      }
      this.statisticsData = res.data
      return true
    },
    invoiceMonthChange(date) {
      this.form.invoiceDate = date.format("YYYY-MM-DD")
      this.search()
    },
    // 获取电商信息列表
    async getMchLiveListApi() {
      this.mchNolist = [];
      const res = await this.api.mchshopsApi({
        pageNo: 1,
        pageSize: 10000,
        goodsType: '50',
        departmentCode: this.departmentCode,
        mchType: 1, //默认融资电商
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      // mchNolist
      this.mchNolist = res.data.records.map(x => Object.assign(x, {
        label: x.mchName,
        value: x.mchNo
      }))
      // 默认选中第一个
      if (this.mchNolist.length > 0) {
        this.form.mchNo = this.mchNolist[0].value
        await this.getMchShopListApi(this.form.mchNo)
      }
    },
    async departmentChange(){
      await this.getMchLiveListApi()
    },
    // 获取店铺信息列表
    async getMchShopListApi() {
      this.shoplist = [];
      const res = await this.api.managerpageApi({
        pageNo: 1,
        pageSize: 10000,
        mchNo: this.form.mchNo, //默认融资电商
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.shoplist = res.data.records.map(x => Object.assign(x, {
        label: x.shopName,
        value: x.shopNo
      }))
      // 默认选中第一个
      if (this.shoplist.length > 0) {
        this.form.shopNo = this.shoplist[0].value
        await this.search()
      } else {
        this.form.shopNo = ''
      }
    },

    // 点击重置表单
    reset() {
      this.statistics = false
      // 先重置
      this.data = []
      this.statisticsData= {
        loanAmountSum: null,
        orderAmount3: null
      }
      this.form.pageNo = 1
    },

    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.form.pageSize = pageSize
      this.getList()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = 1
      this.getList()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = Math.ceil(Number(this.total) / Number(this.form.pageSize))

      this.getList()
    },
    pagechange(val) {
      this.form.pageNo = val
      this.getList()
    },
    // 搜索查询
    async search() {
      this.reset()

      this.statistics = true
      await this.getList() && await this.getStatistics()
    },

    // 新增发票
    add() {
      this.$router.push({
        path: '/Mch/MchShopInvoiceAdd',
        query: {
          invoiceDate: this.form.invoiceDate,
          mchNo: this.form.mchNo,
          shopNo: this.form.shopNo,
          pageNo: this.form.pageNo,
          pageSize: this.form.pageSize,
        }
      })
    },

    // 发票到账明细导入
    async importInvoiceReceipt() {
      this.importInvoiceReceiptVisible = true
    },

    async invoiceDepositImport() {
      this.$router.push({
        path: '/Mch/MchShopInvoiceDepositImport',
        query: {
          invoiceDate: this.form.invoiceDate,
          mchNo: this.form.mchNo,
          shopNo: this.form.shopNo,
          pageNo: this.form.pageNo,
          pageSize: this.form.pageSize,
        }
      })
    },

    importInvoiceReceiptOk: async function () {

      if (this.form.shopNo == null || this.form.shopNo == '') {
        this.$message.error('请选择店铺')
        return
      }

      if (this.fileList.length == 0) {
        this.$message.error('请选择文件')
        return
      }

      const formData = new FormData();
      formData.append('file', this.fileList[0]);
      formData.append('shopNo', this.form.shopNo);

      const res = await this.api.mchShopInvoiceDepositApi(formData);
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.fileList = []
      this.importInvoiceReceiptVisible = false
      // this.search()
    },
    async importInvoiceReceiptCancel() {
      this.importInvoiceReceiptVisible = false
    },
    async getShopList() {
      this.shoplist = [];
      const res = await this.api.managerpageApi({
        pageNo: 1,
        pageSize: 10000,
        mchType: 1,
        mchNo: this.form.mchNo
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.shoplist = res.data.records;
    },
    //删除上传文件
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();

      newFileList.splice(index, 1);

      this.fileList = newFileList;
    },
    async beforeUpload(info) {
      this.fileList = [];
      this.fileList.push(info.file);
    },

  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped>
.ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}

/* 自定义表头样式 */
.ant-table-thead > tr > th {
  background-color: #f2f2f2;
}

.ant-modal-footer {

  padding-bottom: 20px;
}

.ant-modal-content {
  width: 420px;
}


.loan {

  .text-center {
    text-align: center;
    padding-top: 40px;
  }

  .paginationBox {
    display: flex;
    margin-top: 20px;
    justify-content: center;

    // .ant-pagination-options {
    //   float: left;
    // }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: 1px solid #d9d9d9;
      width: 60px;
    }
  }

  .Applyfor {
    padding-top: 10px;
    margin-bottom: 20px;
  }
}

// 贷款弹窗
.Openanaccount {
  padding: 30px;

  .personage {
    width: 100%;
    height: 40px !important;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 30px;
  }

  .firm {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 20px;
  }
}

/deep/ .ant-card-head-title {
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    flex-wrap: wrap;

    .dianshang {
      margin-left: 50px;
    }
  }
}
</style>
