<template>
  <div>
    <!-- 企业开户 -->
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>账户管理</span> <a-icon type="right" /> <span>修改账户</span>
          </div>
          <div style="display: flex">
            <!-- <div class="agreement">
              <div class="item">
                <a-checkbox v-model="Userauthorization">
                  本人已阅读并同意
                  <a-tooltip>
                    <template slot="title"> 点击下载 </template>
                    <a-button type="link" size="small" @click="downLoadTemplate('TMPL_CZCB_SRVC')">{{
                      mapMapList('TMPL_CZCB_SRVC').state }}</a-button>
                  </a-tooltip>
                  <a-tooltip>
                    <template slot="title"> 点击下载 </template>
                    <a-button type="link" size="small" @click="downLoadTemplate('TMPL_CZCB_PRVC')">{{
                      mapMapList('TMPL_CZCB_PRVC').state }}</a-button>
                  </a-tooltip>
                </a-checkbox>
              </div>
            </div> -->
            <div class="right">
              <!-- 确认 -->
              <a-button type="primary" @click="confirm"> 确认 </a-button>
              <!-- 取消 -->
              <a-button @click="cancelshow = true"> 取消 </a-button>
            </div>
          </div>
        </div>
      </template>
      <!-- 企业开户页面 -->
      <div class="Individualaccountopening">
        <!-- 基础信息 -->
        <div class="formbox">
          <div class="toptitle">基础信息*</div>
          <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <!-- 所属机构 -->
            <!--            <a-form-model-item class="departmentId" ref="departmentId" label="所属机构" prop="departmentId">-->
            <!--              <a-tree-select @change="departmentchange" v-model.trim="form.departmentId" style="width: 200px"-->
            <!--                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="treeData" placeholder="请选择所属机构"-->
            <!--                tree-default-expand-all>-->
            <!--              </a-tree-select>-->
            <!--            </a-form-model-item>-->

            <!-- 渠道编号 -->
            <!-- <a-form-model-item ref="channel_no" class="channel_no" label="渠道归属" prop="channel_no">
              <a-select v-model.trim="form.channel_no" @change="channel_nochange" placeholder="请选择核心企业"
                style="width: 200px">
                <a-select-option v-for="item in companylist" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->
            <!-- 客户名称 -->
            <a-form-model-item ref="account_name" label="客户" prop="account_name">
              <CustChooseDialog custtypecd="2" :department-id="form.departmentId" v-model="form.account_name"
                :custnum="form.custnum" @chooseDone="onCustChosen">
              </CustChooseDialog>
            </a-form-model-item>
            <!-- 应用编号 -->
            <a-form-model-item class="app_no" ref="app_no" prop="app_no" style="display: none">
              <template #label>
                <span>应用编号</span>
              </template>
              <!-- <span>应用编号：</span> -->
              <a-input :disabled="true" v-model.trim="form.app_no" placeholder="请输入应用编号"></a-input>
              <div></div>
              <!-- <p v-if="form.app_no == ''">请输入应用编号</p> -->
            </a-form-model-item>
            <!-- 证件类型 -->
            <a-form-model-item label="证件类型" class="id_type" ref="id_type" prop="id_type" style="display: none">
              营业执照
            </a-form-model-item>
            <!-- 证件号码 -->
            <a-form-model-item label="营业执照/统一社会信用代码" class="id_no" ref="id_no" prop="id_no">
              <a-input v-model.trim="form.id_no" @change="id_nochange" placeholder="请输入18位营业执照编号"></a-input>
            </a-form-model-item>
            <!-- 证件生效日期 -->
            <a-form-model-item label="营业执照发放日" class="id_startdate" ref="id_startdate" prop="id_startdate">
              <a-date-picker style="width: 200px" valueFormat="YYYY-MM-DD" v-model.trim="form.id_startdate" />
            </a-form-model-item>
            <!-- 证件失效日期 -->
            <a-form-model-item label="营业执照到期日" class="id_enddate" ref="id_enddate" prop="id_enddate">
              <a-date-picker style="width: 200px" ref="datePicker" valueFormat="YYYY-MM-DD"
                v-model.trim="form.id_enddate">
              </a-date-picker>
              <a-checkbox style="margin-left: 50px" @change="
                $event.target.checked
                  ? (form.id_enddate = '9999-12-31')
                  : (form.id_enddate = '')
                ">
                长期
              </a-checkbox>
            </a-form-model-item>
            <!-- 签发机关名称 -->
            <a-form-model-item label="营业执照签发机关" class="sign_name" ref="sign_name" prop="sign_name" style="display: none">
              <a-input v-model.trim="form.sign_name" placeholder="请输入签发机关名称"></a-input>
            </a-form-model-item>

            <!--! 企业银行卡号 -->
            <a-form-model-item label="企业银行账号" class="primary_account" ref="primary_account" prop="primary_account">
              <a-input :type="'number'" v-model.trim="form.primary_account" placeholder="请输入企业银行卡号"></a-input>
            </a-form-model-item>

            <a-form-model-item class="bank_no" ref="inputFieldRef" label="开户银行" prop="bank_name">
              <a-input v-model="form.bank_name" placeholder="请填写开户行名称"></a-input>
              <a-button type="link" @click="isShowBankChoose = true" style="display: inline-block;">快速检索</a-button>
              <span style="display: inline-block;">
                <BankChoose v-if="isShowBankChoose" @changeBank="changeBank"></BankChoose>
              </span>

            </a-form-model-item>
            <!--!企业开户行号 -->
            <a-form-model-item class="bank_no" ref="bank_no" label="开户大额行号" prop="bank_no">
              <a-input v-model.trim="form.bank_no" placeholder="请输入12位开户行号, 咨询开户行"></a-input>
            </a-form-model-item>

            <!--! 行业类别 -->
            <a-form-model-item label="行业类别" class="industry" ref="industry" prop="industry">
              <el-cascader v-model="form.industry" :options="industrylist" placeholder="请选择行业类别" :show-all-levels="false"
                :props="{ emitPath: false }" style="width: 200px" />
            </a-form-model-item>
            <!--!!!!!!!! 法定待表人 -->
            <div class="legalrepresentative">法定代表人</div>
            <!-- ?姓名 -->
            <a-form-model-item label="姓名" class="company_id_name" ref="company_id_name" prop="company_id_name">
              <CustChooseDialog custtypecd="1" :department-id="form.departmentId" @chooseDone="onLrChosen"
                v-model="form.company_id_name">
              </CustChooseDialog>
              <div style="color: red; margin-left: 00px">
                *请先在客户管理中维护法定代表人信息
              </div>
            </a-form-model-item>
            <!-- ?证件类型 -->
            <a-form-model-item label="证件类型" class="company_id_type" ref="company_id_type" prop="company_id_type">
              身份证
            </a-form-model-item>
            <!--? 证件号码 -->
            <a-form-model-item label="证件号码" class="company_id_no" ref="company_id_no" prop="company_id_no">
              <a-input v-model.trim="form.company_id_no" placeholder="请输入证件号码"></a-input>
            </a-form-model-item>

            <!--? 证件失效日期 -->
            <a-form-model-item label="证件失效日期" class="company_opto" ref="company_opto" prop="company_opto">
              <a-date-picker style="width: 200px" ref="company_opto" valueFormat="YYYY-MM-DD"
                v-model.trim="form.company_opto">
              </a-date-picker>
              <a-checkbox style="margin-left: 50px" @change="
                $event.target.checked
                  ? (form.company_opto = '9999-12-31')
                  : (form.company_opto = '')
                ">
                长期
              </a-checkbox>
            </a-form-model-item>

            <!-- 影像信息 -->
            <div class="legalrepresentative">影像信息</div>
            <a-form-model-item class="pricebox" ref="pricebox" prop="pricebox">
              <template #label>
                <!-- 自定义标签内容 -->
                <span class="custom-label">
                  <span style="color: #ff0000">*</span> 影像信息</span>
              </template>
              <div class="row">
                <!-- 营业执照 -->
                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 100px;
                    ">营业执照</span>
                  <a-upload name="file" accept="image/*" ref="BusinesslicenseEdit" list-type="picture-card"
                    class="avatar-uploader" :show-upload-list="false" :action="busiURL" :headers="headers"
                    :customRequest="portraitUpload">
                    <uploadImg v-show="verifyvadata.Businesslicenseurl" @Editimg="BusinesslicenseEdit" @Uploaderror="
                      verifyvadata.Businesslicenseloading = $event
                      " :showEditicon="true" @onloadsuccsse="verifyvadata.Businesslicenseurl = $event"
                      @fileData="verifyvadata.BusinesslicenseFile = $event" ref="Businesslicense">
                    </uploadImg>
                    <div v-show="verifyvadata.Businesslicenseloading == false &&
                      !verifyvadata.Businesslicenseurl
                      ">
                      <a-icon type="plus" />
                    </div>
                    <div v-if="verifyvadata.Businesslicenseloading == true &&
                      verifyvadata.Businesslicenseurl == ''
                      " class="example">
                      <a-spin />
                    </div>
                  </a-upload>
                  <span v-if="verifyvadata.Humanface == false" class="erero">请先上传图片</span>
                </div>
                <!-- 身份证 反面 -->
                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 100px;
                    ">法人身份证人像面</span>
                  <a-upload name="file" accept="image/*" ref="ReverseofIDcard" list-type="picture-card"
                    class="avatar-uploader" :show-upload-list="false" :action="busiURL" :headers="headers"
                    :customRequest="changeIdBack">
                    <uploadImg v-show="verifyvadata.ReverseofIDcardurl" @Editimg="ReverseofIDcardEdit" @Uploaderror="
                      verifyvadata.ReverseofIDcardloading = $event
                      " :showEditicon="true" @onloadsuccsse="verifyvadata.ReverseofIDcardurl = $event"
                      @fileData="verifyvadata.ReverseofIDcardFile = $event" ref="IdBack">
                    </uploadImg>
                    <div v-show="verifyvadata.ReverseofIDcardloading == false &&
                      !verifyvadata.ReverseofIDcardurl
                      ">
                      <a-icon type="plus" />

                    </div>
                    <div v-if="verifyvadata.ReverseofIDcardloading &&
                      verifyvadata.ReverseofIDcardurl == ''
                      " class="example">
                      <a-spin />
                    </div>
                  </a-upload>
                  <span v-if="verifyvadata.ReverseofIDcard == false" class="erero">请先上传照片</span>
                </div>
                <!-- 身份证 正面 -->
                <div class="item">
                  <span style="
                      margin-right: 20px;
                      display: inline-block;
                      width: 100px;
                    ">法人身份证国徽面</span>
                  <a-upload name="file" accept="image/*" ref="Frontofidentitycard" list-type="picture-card"
                    class="avatar-uploader" :show-upload-list="false" :action="busiURL" :headers="headers"
                    :customRequest="changeIdUpload">
                    <uploadImg v-show="verifyvadata.Frontofidentitycardurl" :showEditicon="true" @Uploaderror="
                      verifyvadata.Frontofidentitycardloading = $event
                      " @onloadsuccsse="
    verifyvadata.Frontofidentitycardurl = $event
    " @Editimg="FrontofidentitycardEdit" @fileData="verifyvadata.FrontofidentitycardFile = $event" ref="IdUp">
                    </uploadImg>
                    <div v-show="verifyvadata.Frontofidentitycardloading == false &&
                      !verifyvadata.Frontofidentitycardurl
                      ">
                      <a-icon type="plus" />
                    </div>
                    <div v-if="verifyvadata.Frontofidentitycardloading == true &&
                      verifyvadata.Frontofidentitycardurl == ''
                      " class="example">
                      <a-spin />
                    </div>
                  </a-upload>
                  <span v-if="verifyvadata.Frontofidentitycard == false" class="erero">请先上传照片</span>
                </div>
              </div>
              <!-- 提示 -->
              <div style="color: red; margin-left: 50px">
                *请确保身份证信息在有效期范围内，若证件信息过期
                需要去线下银行网点更新
              </div>
            </a-form-model-item>

            <div class="legalrepresentative">
              <a-switch v-model="samePerson" default-checked style="margin-right: 15px" />
              法定代表人、负责人、经办人均为同一人
            </div>
            <div v-if="!samePerson">
              <!-- !!!!!!!实际控制人 -->
              <div class="actualcontroller">
                实际控制人 <span></span>
                <a-button class="fill" @click="fillinfo(1)">
                  点击选择法定代表人信息
                </a-button>
              </div>

              <!-- ?证件类型 -->
              <a-form-model-item label="证件类型" class="controller_id_type" ref="controller_id_type"
                prop="controller_id_type">
                身份证
              </a-form-model-item>
              <!--? 证件号码 -->
              <a-form-model-item label="证件号码" class="controller_id_no" ref="controller_id_no" prop="controller_id_no">
                <a-input v-model.trim="form.controller_id_no" placeholder="请输入证件号码"></a-input>
              </a-form-model-item>
              <!-- ?姓名 -->
              <a-form-model-item label="姓名" class="controller_id_name" ref="controller_id_name" prop="controller_id_name">
                <a-input v-model.trim="form.controller_id_name" placeholder="请输入姓名"></a-input>
              </a-form-model-item>

              <!--? 证件失效日期 -->
              <a-form-model-item label="证件失效日期" class="controller_opto" ref="controller_opto" prop="controller_opto">
                <a-date-picker style="width: 200px" valueFormat="YYYY-MM-DD" ref="controller_opto"
                  v-model.trim="form.controller_opto">
                </a-date-picker>
                <a-checkbox style="margin-left: 50px" @change="
                  $event.target.checked
                    ? (form.controller_opto = '9999-12-31')
                    : (form.controller_opto = '')
                  ">
                  长期
                </a-checkbox>
              </a-form-model-item>
              <!-- !!!!!!!负责人 -->
              <div class="personincharge">
                负责人 <span></span>
                <a-button class="fill" @click="fillinfo(2)">
                  点击选择法定代表人信息
                </a-button>
              </div>
              <a-form-model-item label="证件类型" class="leader_id_type" ref="leader_id_type" prop="leader_id_type">
                身份证
              </a-form-model-item>
              <!--? 证件号码 -->
              <a-form-model-item label="证件号码" class="leader_id_no" ref="leader_id_no" prop="leader_id_no">
                <a-input v-model.trim="form.leader_id_no" placeholder="请输入证件号码"></a-input>
              </a-form-model-item>
              <!-- ?姓名 -->
              <a-form-model-item label="姓名" class="leader_id_name" ref="leader_id_name" prop="leader_id_name">
                <a-input v-model.trim="form.leader_id_name" placeholder="请输入姓名"></a-input>
              </a-form-model-item>
              <!-- 手机号码 -->
              <a-form-model-item label="手机号" class="leader_mobile" ref="leader_mobile" prop="leader_mobile">
                <a-input v-model.trim="form.leader_mobile" placeholder="请输入手机号码"></a-input>
              </a-form-model-item>

              <!--? 证件失效日期 -->
              <a-form-model-item label="证件失效日期" class="leader_opto" ref="leader_opto" prop="leader_opto">
                <a-date-picker style="width: 200px" valueFormat="YYYY-MM-DD" ref="leader_opto"
                  v-model.trim="form.leader_opto">
                </a-date-picker>
                <a-checkbox style="margin-left: 50px" @change="
                  $event.target.checked
                    ? (form.leader_opto = '9999-12-31')
                    : (form.leader_opto = '')
                  ">
                  长期
                </a-checkbox>
              </a-form-model-item>

              <!--!!!!!!!经办人 -->
              <div class="agent">
                经办人 <span></span>
                <a-button class="fill" @click="fillinfo(3)">
                  点击选择法定代表人信息
                </a-button>
              </div>
              <a-form-model-item label="证件类型" class="operator_id_type" ref="operator_id_type" prop="operator_id_type">
                身份证
              </a-form-model-item>
              <!--? 证件号码 -->
              <a-form-model-item label="证件号码" class="operator_id_no" ref="operator_id_no" prop="operator_id_no">
                <a-input v-model.trim="form.operator_id_no" placeholder="请输入证件号码"></a-input>
              </a-form-model-item>
              <!-- ?姓名 -->
              <a-form-model-item label="姓名" class="operator_id_name" ref="operator_id_name" prop="operator_id_name">
                <a-input v-model.trim="form.operator_id_name" placeholder="请输入姓名"></a-input>
              </a-form-model-item>
              <!-- 手机号码 -->
              <a-form-model-item label="手机号" class="operator_mobile" ref="operator_mobile" prop="operator_mobile">
                <a-input :type="'number'" v-model.trim="form.operator_mobile" placeholder="请输入手机号码"></a-input>
              </a-form-model-item>

              <!--? 证件失效日期 -->
              <a-form-model-item label="证件失效日期" class="operator_opto" ref="operator_opto" prop="operator_opto">
                <a-date-picker style="width: 200px" valueFormat="YYYY-MM-DD" ref="operator_opto"
                  v-model.trim="form.operator_opto">
                </a-date-picker>
                <a-checkbox style="margin-left: 50px" @change="
                  $event.target.checked
                    ? (form.operator_opto = '9999-12-31')
                    : (form.operator_opto = '')
                  ">
                  长期
                </a-checkbox>
              </a-form-model-item>
            </div>
            <!--!!!!!!! 受益人 -->
            <div class="beneficiary">受益人</div>
            <!-- ?姓名 -->
            <a-form-model-item label="姓名" class="bene_name" ref="bene_name" prop="bene_name">
              <a-input v-model.trim="form.bene_name" placeholder="请填写姓名"></a-input>
            </a-form-model-item>

            <!-- ?性别 -->
            <a-form-model-item label="性别" prop="bene_sex">
              <a-radio-group v-model.trim="form.bene_sex">
                <a-radio v-for="item in sexlist" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <!-- ?国籍  -->
            <a-form-model-item label="国籍 " prop="bene_nationality">
              <a-select v-model.trim="form.bene_nationality" placeholder="请选择国籍" style="width: 200px">
                <a-select-option v-for="item in nationalitylist" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- ?是否股东 -->
            <a-form-model-item label="是否股东" prop="bene_is_shareholider">
              <a-radio-group v-model.trim="form.bene_is_shareholider">
                <a-radio :value="1"> 是 </a-radio>
                <a-radio :value="2"> 否 </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <!-- ?股百分比 -->
            <a-form-model-item label="持股百分比" class="bene_ratio" ref="bene_ratio" prop="bene_ratio">
              <a-input type="number" v-model.trim="form.bene_ratio" placeholder="请输入百分比">
                <template #suffix> % </template></a-input>
            </a-form-model-item>

            <!-- ?地址 -->
            <a-form-model-item label="地址" class="bene_addr" ref="bene_addr" prop="bene_addr">
              <a-input v-model.trim="form.bene_addr" placeholder="请输入地址"></a-input>
            </a-form-model-item>
            <!-- ?类型 -->
            <a-form-model-item label="证件类型" class="bene_id_type" ref="bene_id_type" prop="bene_id_type"
              style="display: none">
              身份证
            </a-form-model-item>
            <!-- ?证件号码号码 -->
            <a-form-model-item label="证件号码" class="bene_id_no" ref="bene_id_no" prop="bene_id_no">
              <a-input v-model.trim="form.bene_id_no" placeholder="请输入证件号码"></a-input>
            </a-form-model-item>

            <!--? 证件失效日期 -->
            <a-form-model-item label="证件失效日期" class="bene_opto" ref="bene_opto" prop="bene_opto">
              <a-date-picker style="width: 200px" ref="bene_opto" valueFormat="YYYY-MM-DD" v-model.trim="form.bene_opto">
              </a-date-picker>
              <a-checkbox style="margin-left: 50px" @change="beneoptochange">
                长期
              </a-checkbox>
            </a-form-model-item>
            <div class="legalrepresentative">短信认证</div>
            <!-- 手机号 -->
            <a-form-model-item label="手机号" class="mobile" ref="mobile" prop="mobile">
              <a-input :type="'number'" v-model.trim="form.mobile" placeholder="请输入手机号"></a-input>
              <div></div>
            </a-form-model-item>
            <!-- 验证码 -->
            <a-form-model-item label="验证码" class="code" ref="code" prop="verify_code">
              <a-input v-model.trim="form.verify_code" placeholder="请输入验证码">
                <CountdownBtn slot="suffix" :wait-time="60" :error-wait-time="10" :send-call="sendcode"></CountdownBtn>
              </a-input>
              <div></div>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-card>
    <!-- 确认 -->
    <a-modal title="提示" class="confirmloading" :maskClosable="true" @cancel="confirmshow = false" :centered="true"
      :visible="confirmshow">
      <div class="text">是否确认修改？</div>
      <template v-slot:footer>
        <!-- 提现 -->
        <div class="custom-footer">
          <a-button type="primary" @click="ConfirmSave">保存</a-button>
          <a-button @click="confirmshow = false">取消</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 取消 -->
    <a-modal title="提示" class="confirmloading" :maskClosable="true" @cancel="cancelshow = false" :centered="true"
      :visible="cancelshow">
      <div class="canceltext">
        <!--个人开户  -->
        是否取消修改？
      </div>
      <template v-slot:footer>
        <!-- 提现 -->
        <div class="custom-footer">
          <a-button type="primary" @click="
            cancelshow = false
          $router.go(-1)
            ">确认</a-button>
          <a-button @click="cancelshow = false">取消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import dayjs from 'dayjs'

import { BizDict, BizDictCascade } from '../../../utils/bizDict/kvMap.js'

const getToken = () => sessionStorage.getItem('token')
import axios from 'axios'
import urlConfig from '@/utils/urlConfig'
import uploadImg from '../../../components/uploadImg/index.vue'
import CustChooseDialog from '@/view/components/ChooseDialog/CustChooseDialog.vue'
import { dict_rawdata_cascade } from '@/utils/bizDict/rawdata'
import { contractDocTemp } from '@/utils/contractDocTemp'
import CountdownBtn from '@/view/components/CountdownBtn.vue'
import BankChoose from '@/view/components/BankChoose.vue'
export default {
  components: {
    CountdownBtn,
    CustChooseDialog,
    BankChoose,
    uploadImg,
  },
  data() {
    let checkCertNum = (rule, value, callback) => {
      let custNumReg =
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      if (value === '') {
        callback(new Error('请输入证件号码'))
      } else if (!custNumReg.test(value)) {
        callback(new Error('身份证不符合规范'))
      } else {
        callback()
      }
    }
    return {
      samePerson: true, //"是否与法人一致"
      checkCertNum,
      confirmshow: false,
      cancelshow: false,
      latitude: '', // 经度
      busiURL: urlConfig.busiURL + '/file/manager/upload',
      longitude: '', // 维度
      labelCol: { span: 3 },
      Userauthorization: false, // 隐私协议
      // 行业列表
      industrylist: BizDictCascade.WorkCorpIndusCD,
      wrapperCol: { span: 21 },
      // 民族列表
      ethnicitylist: BizDict.RaceCd,
      // 性别list
      sexlist: BizDict.GndFlg,
      // 职业列表
      OcpCdlist: BizDictCascade.OcpCd,
      // 机构树形数组
      treeData: [],
      loading: false,
      headers: {
        token: getToken(),
      },
      // 影像信息
      file_list: [
        // 身份证照片正
        {
          file_no: '',

          file_type: '01',
          page: '1',
          channel_file_no: '',
        },
        // 身份证照片反
        {
          file_no: '',

          file_type: '02',
          page: '2',
          channel_file_no: '',
        },
        // 营业执照
        {
          file_no: '',

          file_type: '12',
          page: '3',

          channel_file_no: '',
        },
      ],
      // 控制影像是否上传
      verifyvadata: {
        // 身份证
        Frontofidentitycard: true, // 正面
        Frontofidentitycardurl: '', // 正面url
        FrontofidentitycardFile: '', // 图片数据
        Frontofidentitycardloading: false,
        ReverseofIDcard: true, // 反面
        ReverseofIDcardurl: '', // 反面
        ReverseofIDcardFile: '', // 反面
        ReverseofIDcardloading: false, // 反面
        // 营业执照
        Businesslicense: true,
        Businesslicenseurl: '',
        BusinesslicenseFile: '',
        Businesslicenseloading: false,
      },
      // 客户编号数据list
      custnumlist: [],
      account_namelist: [],
      // 国籍
      nationalitylist: BizDict.NtntyCd,
      Upimgshow: '', // 正面图片
      getUpFrontofidentitycard: '', // 正面图片数据
      getUplloading: false,
      Backimgshow: '', // 反面图片
      getBackFile: '', //反面图片数据
      getBackloading: false,
      portraitshow: '', // 人像图片
      portraitFile: '', // 人像数据
      portraitloading: false,
      show: true, // 验证码发送
      Countdown: 60, // 倒计时
      isShowBankChoose: false,
      form: {
        departmentId: undefined, /// 所属机构
        custnum: undefined, // /客户编号
        account_name: '', //客户名称
        channel_no: undefined, //渠道编号
        app_no: '', //应用编号
        account_no: '',
        account_property: '2', // 账号类型，2:企业
        mobile: '', // 手机号
        verify_code: '', // 验证码
        primary_account: '', // 企业银行账号
        bank_no: undefined, //企业户必输开户行号
        bank_name: '', // 开户行名称
        id_type: 'C', // 营业执照
        id_no: '', // 证件号码
        id_startdate: '', //证件有效日期
        id_enddate: '', //证件结束日期
        sign_name: '国家市场监督管理总局', //签发机关名称
        id_address: '', // 证件地址
        // 省市区字段
        distcd: '',
        orgcodes: '', //组织机构代码
        ratcodes: '', //税务登记号码
        zsopscope: '', //经营范围
        industry: 'F5219', //行业类别
        // 法定代表人
        company_id_type: 1, // 法定代表人证件类型
        company_id_no: '', // 法定代表人证件号码
        company_id_name: '', // 法定代表人名称
        company_opto: '', // 法定代表人有效期止

        // 实际控制人
        controller_id_type: 1, // 实际控制人证件类型
        controller_id_no: '', // 实际控制人证件号码
        controller_id_name: '', // 实际控制人名称
        controller_opto: '', // 实际控制人有效期止

        // 负责人
        leader_id_type: 1, // 负责人证件类型
        leader_id_no: '', // 负责人证件号码
        leader_id_name: '', // 负责人名称
        leader_mobile: '', // 负责人手机号
        leader_opto: '', // 负责人有效期止

        // 经办人
        operator_id_type: 1, // 经办人证件类型
        operator_id_no: '', // 经办人证件号码
        operator_id_name: '', // 经办人名称
        operator_mobile: '', // 经办人手机号
        operator_opto: '', // 经办人有效期止
        // 最终受益人
        bene_name: '', // 受益人姓名
        bene_sex: '1', // 受益人性别 0 男 1 女
        bene_nationality: '156', // 受益人国际
        bene_is_shareholider: 1, // 是否股东 1 是 2 否
        bene_ratio: 100, // 持股比列
        bene_addr: '', // 受益人地址
        bene_id_type: 1, // 身份证
        bene_id_no: '', // 受益人证件号码
        bene_opto: '', // 证件有效期至
      },
      // 身份证正
      IDcardfront: '',
      // 身份证反
      IDcardback: '',
      // 人脸
      faceimage: '',
      // 核心企业列表
      companylist: [],
      citylist: BizDictCascade.DstcCd,
      rules: {
        departmentId: [
          { required: true, message: '请选择所属机构', trigger: 'change' },
        ],
        custnum: [{ required: true, message: '请选择客户', trigger: 'change' }],
        account_name: [
          { required: true, message: '请输入客户名称', trigger: 'change' },
        ],
        app_no: [
          { required: true, message: '请输入应用编号', trigger: 'blur' },
        ],
        channel_no: [
          { required: true, message: '请输入渠道编号', trigger: 'change' },
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: '请输入有效的手机号码',
            trigger: 'blur',
          },
        ],
        verify_code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        primary_account: [
          {
            required: true,
            message: '请输入任意银行的结算账号',
            trigger: 'blur',
          },
        ],
        bank_no: [
          {
            required: true,
            message: '请输入12位开户行大额行号',
            trigger: 'blur',
          },
        ],
        bank_name: [
          { required: true, message: '请输入开户行名称', trigger: 'blur' },
        ],
        // id_startdate: [
        //   { required: true, message: '请选择证件生效日期', trigger: 'change' },
        // ],
        id_no: [
          {
            required: true,
            message: '请输入正确的18位营业执照编号',
            trigger: 'blur',
            pattern:
              /^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$/,
          },
        ],
        // id_enddate: [
        //   { required: true, message: '请选择证件失效日期', trigger: 'change' },
        // ],
        // sign_name: [
        //   { required: true, message: '请输入签发机关名称', trigger: 'blur' },
        // ],
        distcd: [
          { required: true, message: '请选择所属地区', trigger: 'change' },
        ],
        id_address: [
          { required: true, message: '请输入经营地址', trigger: 'blur' },
        ],
        orgcodes: [
          { required: true, message: '请输入组织机构代码', trigger: 'blur' },
        ],
        ratcodes: [
          { required: true, message: '请输入税务登记号码', trigger: 'blur' },
        ],
        zsopscope: [
          { required: true, message: '请输入经营范围', trigger: 'blur' },
        ],
        industry: [
          { required: true, message: '请选择行业类别', trigger: 'change' },
        ],
        // 法定待表人
        company_id_type: [
          { required: true, message: '请输入组织机构代码', trigger: 'blur' },
        ],
        company_id_no: [
          {
            required: true,
            trigger: 'blur',
            validator: checkCertNum,
          },
        ],
        company_id_name: [
          { required: true, message: '请输入法定代表人名称', trigger: 'blur' },
        ],
        company_opto: [
          {
            required: true,
            message: '请输入法定代表人有效期止',
            trigger: 'change',
          },
        ],
        // 实际控制人
        controller_id_no: [
          {
            pattern:
              /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            trigger: 'blur',
            message: '请输入有效身份证!',
          },
        ],
        // 负责人
        leader_id_no: [
          {
            pattern:
              /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            trigger: 'blur',
            message: '请输入有效身份证!',
          },
        ],
        // 经办人
        operator_id_no: [
          {
            pattern:
              /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            trigger: 'blur',
            message: '请输入有效身份证!',
          },
        ],
        operator_mobile: [
          {
            pattern: /^1[3-9]\d{9}$/,
            message: '请输入有效的手机号码',
            trigger: 'blur',
          },
        ],
        // 受益人
        // bene_id_no: [
        //   {
        //     required: true,
        //     trigger: 'blur',
        //     validator: checkCertNum,
        //   },
        // ],
        // bene_name: [
        //   { required: true, message: '请输入受益人姓名', trigger: 'change' },
        // ],
        // bene_sex: [
        //   { required: true, message: '请选择受益人性别', trigger: 'change' },
        // ],
        // bene_nationality: [
        //   { required: true, message: '请输入受益人国籍', trigger: 'change' },
        // ],
        // bene_is_shareholider: [
        //   { required: true, message: '请选择是否为股东', trigger: 'change' },
        // ],
        // bene_ratio: [
        //   { required: true, message: '请输入持股比列', trigger: 'blur' },
        // ],
        // bene_addr: [
        //   { required: true, message: '请输入受益人地址', trigger: 'blur' },
        // ],
        // bene_id_type: [{ required: true, message: '身份证', trigger: 'blur' }],

        // bene_opto: [
        //   {
        //     required: true,
        //     message: '请输入受益人有效期止',
        //     trigger: 'change',
        //   },
        // ],
      },
    }
  },
  created() {
    // this.getChannelList()
    // 加载选中客户信息
    this.getAccountDetails()

  },
  deactivated() {
    sessionStorage.removeItem('msgInfo')
  },
  mounted() {
    if (this.$route.params.type != 'add') {
      sessionStorage.setItem('msgInfo', true)
    }
  },
  methods: {
    // 获取详细信息
    async getAccountDetails() {
      let id = this.$store.state.accountEdit.id
      let res = await this.api.getAccountDetailsApi(id)
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      let custDetail = res.data
      this.form.channel_no = custDetail?.channelNo
      this.form.app_no = custDetail?.appNo
      this.form.account_no = custDetail?.accountNo

      this.form.account_name = custDetail.accountName
      this.form.id_no = custDetail.idInfo?.certnum
      this.form.id_startdate = custDetail.idInfo?.certeffectdt
      this.form.id_enddate = custDetail.idInfo?.certinvaliddt
      if (custDetail.bankCards) {
        this.form.primary_account = custDetail.bankCards[0]?.bankCardNo
        this.form.bank_name = custDetail.bankCards[0]?.bankName
        this.form.bank_no = custDetail.bankCards[0]?.bankNo
      }
      // 所属行业
      this.form.industry = custDetail.companyInfo?.belonginduscd ? custDetail.companyInfo?.belonginduscd : this.form.industry
      // 法定代表人
      custDetail.eandpInfos?.forEach(item => {
        if (item.islegalperson === '1') {
          // 法定代表人
          this.form.company_id_name = item.custname
          this.form.company_id_no = item.certnnum
          this.form.company_opto = item.certinvaliddt
        } else if (item.relatype === '0109') {
          // 实际控制人
          this.form.controller_id_no = item.certnnum
          this.form.controller_id_name = item.custname
          this.form.controller_opto = item.certinvaliddt
        } else if (item.relatype === '0102') {
          // 负责人
          this.form.leader_id_no = item.certnnum
          this.form.leader_id_name = item.custname
          this.form.leader_opto = item.certinvaliddt
          this.form.leader_mobile = item.continfo
        } else if (item.relatype === '0104') {
          // 经办人
          this.form.operator_id_no = item.certnnum
          this.form.operator_id_name = item.custname
          this.form.operator_opto = item.certinvaliddt
          this.form.operator_mobile = item.continfo
        } else if (item.relatype === '0112') {
          // 受益人
          this.form.bene_name = item.custname
          this.form.bene_sex = item.gendercd
          this.form.bene_nationality = item.nationcd
          // 是否股东()
          this.form.bene_is_shareholider = item.holdstockcnt
          this.form.bene_ratio = item.holdstockcnt
          this.form.bene_addr = item.addrinfo
          this.form.bene_id_no = item.custnum
          this.form.bene_opto = item.certinvaliddt
        }
      })
      // 影像信息
      this.file_list = custDetail.materials
      // 回显影像
      this.file_list.forEach(item => {
        if (item.fileType === '01') {
          // 人像面
          this.$refs.IdBack.HumanfaceEcho(item.fileNo);
        } else if (item.fileType === '02') {
          // 回显国徽面
          this.$refs.IdUp.HumanfaceEcho(item.fileNo);
        } else if (item.fileType === '12') {
          // 回显营业执照
          this.$refs.Businesslicense.HumanfaceEcho(item.fileNo)
        }
      })
      this.form.mobile = custDetail.mobile
    },
    changeBank(obj) {
      this.form.bank_no = obj.value
      this.form.bank_name = obj['option'].componentOptions.children[0].text
      this.$nextTick(() => {
        this.$refs.ruleForm.validateField('bank_name', 'bank_no');
      });
    },
    async onCustChosen(custChosen) {
      // 选定客户
      // 查询客户详细信息
      let res = await this.api.getEnterpriseCust(custChosen.custnum)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      console.log(res, 'info')
      //回填信息
      let detail = res.data
      let custCompanyInfo = detail.custCompanyInfo
      this.form.departmentId = custCompanyInfo.departmentId
      this.form.account_name = custCompanyInfo.custname
      this.form.custnum = custCompanyInfo.custnum

      this.form.id_no = detail.custIdInfo.certnum
      this.form.id_startdate = detail.custIdInfo.certeffectdt
      this.form.id_enddate = detail.custIdInfo.certinvaliddt
      if (detail.custAddressInfos && detail.custAddressInfos.length > 0) {
        let dict = dict_rawdata_cascade.region_raw.find(
          (i) => i.r === detail.custAddressInfos[0].distcd
        )

        this.form.distcd = detail.custAddressInfos[0].distcd
        this.form.id_address = detail.custAddressInfos[0].addrinfo
        if (
          dict &&
          detail.custAddressInfos[0].addrinfo?.substring(0, 5).indexOf('市') < 0
        ) {
          // 自动拼省市区到详细地址上
          this.form.id_address =
            (dict.p.indexOf('省') >= 0 ? dict.p : '') +
            dict.c +
            dict.d +
            this.form.id_address
        }
      }
      this.form.zsopscope = detail.custCompanyInfo.operbiz
      this.form.industry =
        detail.custCompanyInfo.belonginduscd || this.form.industry
      if (detail.custEandpInfos && detail.custEandpInfos.length > 0) {
        this.form.company_id_no = detail.custEandpInfos[0].certnnum
        this.form.company_id_name = detail.custEandpInfos[0].custname
        this.form.company_opto = detail.custEandpInfos[0].certinvaliddt
        this.form.bene_addr = detail.custEandpInfos[0].addrinfo
      }
    },
    async onLrChosen(cust) {
      // 选定法定代表人
      let res = await this.api.getCustData(cust.custnum)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      let custDetail = res.data

      // 自动填充受益人、 法定代表人姓名
      this.form.company_id_name = cust.custname
      this.form.bene_name = cust.custname

      if (custDetail.custIdInfo) {
        this.form.company_id_no = custDetail.custIdInfo.certnum // 证件号
        this.form.company_opto = custDetail.custIdInfo.certinvaliddt // 证件到期日期
        this.form.bene_id_no = custDetail.custIdInfo.certnum
        this.form.bene_opto = custDetail.custIdInfo.certinvaliddt // 证件到期日期
      }
      if (custDetail.custAddressInfos?.length > 0) {
        let custDistcd = custDetail.custAddressInfos[0].distcd
        let dict = dict_rawdata_cascade.region_raw.find(
          (i) => i.r === custDistcd
        )
        if (dict) {
          this.form.bene_addr =
            dict.c + dict.d + custDetail.custAddressInfos[0].addrinfo // 证件地址
        } else {
          this.form.bene_addr = custDetail.custAddressInfos[0].addrinfo // 证件地址
        }
      }
    },
    mapMapList(code) {
      let obj = ''
      this.$store.state.getMapList.forEach((item) => {
        if (item.code === code) {
          obj = item
        }
      })
      return obj
    },
    // 下载模板
    async downLoadTemplate(type) {
      contractDocTemp.call(this, type, this.api, this.$message)
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.form.open_longitude = position.coords.longitude
            this.form.open_dimensions = position.coords.latitude
            // 在这里使用经纬度进行其他操作
          },
          () => {
            // 处理错误情况

            this.$message.warning('请在浏览器隐私和安全授权获取位置信息')
          }
        )
      } else {
        // 浏览器不支持Geolocation API
      }
    },
    // 获取核心企业列表
    async getChannelList() {
      const res = await this.api.coreCompanyInfoApi()
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      // 处理核心企业的渠道编号
      this.companylist = []
      res.data.forEach((item) => {
        this.companylist.push({
          label: item.channelName + '（' + item.channelNo + '）',
          value: item.channelNo,
          info: item,
        })
      })
      if (this.companylist.length === 1) {
        this.form.channel_no = this.companylist[0]?.value
        this.channel_nochange(this.form.channel_no)
      }
    },
    // 渠道变更
    channel_nochange(val) {
      this.companylist.forEach((item) => {
        if (Number(val) == Number(item.value)) {
          this.form.app_no = item.info.appNo
        }
      })
    },
    // 发送验证码
    async sendcode() {
      // 手动触发表单校验
      var validateResult = []
      await this.$refs.ruleForm.validateField(
        [
          'app_no',
          'mobile',
          'channel_no',
          'primary_account',
          'primary_account',
          'id_no',
        ],
        (errorMsg) => validateResult.push(errorMsg)
      )
      if (validateResult.every((item) => item === '')) {
        const res = await this.api.vefifycodesend({
          appNo: this.form.app_no,
          channelNo: this.form.channel_no,
          mobile: Number(this.form.mobile),
          cardNo: this.form.primary_account,
          accountNo: this.form.account_no,
          accountName: this.form.account_name,
          idNo: this.form.id_no,
          tradeType: 4,
        })
        if (res.code === 200) {
          this.$message.success(res.info)
          return true
        } else {
          this.$message.error(res.msg)
          return false
        }
      } else {
        return false
      }
    },
    beneoptochange($event) {
      $event.target.checked
        ? (this.form.bene_opto = '9999-12-31')
        : (this.form.bene_opto = '')

      this.$refs.ruleForm.validateField(['bene_opto'])
    },
    // 点击快速填充
    fillinfo(type) {
      if (type == 1) {
        this.form.controller_id_no = this.form.company_id_no
        this.form.controller_id_name = this.form.company_id_name
        this.form.controller_opto = this.form.company_opto
      }
      if (type == 2) {
        this.form.leader_id_no = this.form.company_id_no
        this.form.leader_id_name = this.form.company_id_name
        this.form.leader_opto = this.form.company_opto
      }
      if (type == 3) {
        this.form.operator_id_no = this.form.company_id_no
        this.form.operator_id_name = this.form.company_id_name
        this.form.operator_opto = this.form.company_opto
      }
    },
    // 点击校验
    confirm() {
      // 首先先让用户同意开户协议

      //   Userauthorization: false,// 用户协议是否通过
      // if (!this.Userauthorization) {
      //   this.$message.warning(
      //     `请先同意${this.mapMapList('TMPL_CZCB_SRVC').state}和${this.mapMapList('TMPL_CZCB_PRVC').state
      //     }`
      //   )
      //   return
      // }
      // 校验身份证有没有过期
      var that = this
      if (this.samePerson == false) {
        var endtimearr = [
          this.form.id_enddate,
          this.form.company_opto,
          this.form.bene_opto,
          this.form.controller_opto,
          this.form.operator_opto,
          this.form.leader_opto,
        ]
      } else if (this.samePerson == true) {
        endtimearr = [
          this.form.id_enddate,
          this.form.company_opto,
          this.form.bene_opto,
        ]
      }
      // console.log(endtimearr);
      let arr = endtimearr.reduce((acc, item) => {
        if (item == '') {
          acc.push(this.endverify(dayjs().format('YYYY-MM-DD')))
        } else {
          acc.push(this.endverify(item))
        }
        return acc
      }, [])
      console.log(arr);
      var flag = arr.every((item) => item == 2)
      var flagbefore = arr.some((item) => item == 0)
      var flagafter = this.checkArrayConditions(arr)
      this.$refs.ruleForm.validate((valid, a) => {

        if (valid) {
          if (
            this.verifyvadata.FrontofidentitycardFile == '' ||
            this.verifyvadata.ReverseofIDcardFile == '' ||
            this.verifyvadata.BusinesslicenseFile == ''
          ) {
            this.$message.warning('请先填写影像信息')
          } else {
            if (flag) {
              that.confirmshow = true
              return
            }
            if (flagbefore) {
              this.$message.warning(
                '您的证件已经到期，建议使用最新的证件进行注册'
              )
              return
            }
            if (flagafter) {
              this.$confirm({
                title: '提示',
                content:
                  '您的证件将在半年内到期，届时银行将对您的账号进行管制。解除管制需要本人持有效证件前往稠州银行网点更新，建议使用最新的证件进行注册， 确定继续吗？',
                onOk() {
                  that.confirmshow = true
                },
                onCancel() {
                  // that.$refs.datePicker.focus();
                },
              })
              return
            }
          }
        } else {
          this.$message.warning('请先填写开户信息')
        }
      })
    },
    // 营业执照账号触发
    id_nochange() {
      this.form.orgcodes = this.form.id_no
      this.form.ratcodes = this.form.id_no
    },
    checkArrayConditions(arr) {
      const allOnes = arr.every((elem) => elem === 1)
      const allOnesAndTrue = arr.every((elem) => elem === 1 || elem === 2)

      return allOnes || allOnesAndTrue
    },
    endverify(time) {
      // 首先进项判断 如果省份证和当天时间相比过期 提示用户 不能进行开户，如果身份证过期时间跟当前相比低于180天，则提示用户
      const start = dayjs().format('YYYY-MM-DD')
      const end = dayjs(time)
      const daysDiff = end.diff(start, 'day')

      if (daysDiff <= 0) {
        return 0
      } else if (daysDiff > 0 && daysDiff < 180) {
        return 1
      } else if (daysDiff > 180) {
        return 2
      }
    },
    // 保存
    async ConfirmSave() {
      // 添加影像信息
      let arr = this.file_list.filter(obj => obj.fileType === '01' || obj.fileType === '02' || obj.fileType === '12')
      this.form.file_list = arr;
      let saveform = JSON.parse(JSON.stringify(this.form))
      delete saveform.departmentId
      if (saveform.zsopscope.length > 200) {
        saveform.zsopscope = saveform.zsopscope.substring(0, 200)
      }

      const res = await this.api.enterpriseAccountEditApi(saveform)
      if (res.code !== 200) {
        this.confirmshow = false
        this.$message.error(res.info)
        return
      }
      this.confirmshow = false
      this.$message.success('修改成功')
      this.$router.go(-1)
    },

    async compressImage(file) {
      // console.log(file, 'compress');
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const maxWidth = 1920; // 设置压缩后的图像宽度
            const maxHeight = 1080; // 设置压缩后的图像高度

            let newWidth = img.width;
            let newHeight = img.height;

            if (img.width > maxWidth) {
              newWidth = maxWidth;
              newHeight = (img.height * maxWidth) / img.width;
            }

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
              newWidth = (img.width * maxHeight) / img.height;
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // 将压缩后的图像转换为 Blob 对象
            canvas.toBlob(
              (blob) => {
                const compressedFile = new File([blob], file.name, {
                  type: 'image/jpeg',
                });
                resolve(compressedFile);
              },
              'image/jpeg',
              0.9
            );

          };
        };
      });
    },
    // 上传身份证正面
    changeIdUpload(info) {

      if (this.form.channel_no == undefined)
        return this.$message.error('请先选择渠道')
      if (this.verifyvadata.FrontofidentitycardFile !== '') {
        this.verifyvadata.Frontofidentitycardloading = false
        this.verifyvadata.FrontofidentitycardFile = ''
        this.verifyvadata.Frontofidentitycardurl = ''
      }
      const isLt1M = info.file.size / 1024 / 1024 > 1;
      if (isLt1M) {
        const result = this.compressImage(info.file);
        result.then((value) => {
          this.Fileupload("01", value);
        })
      } else {
        this.Fileupload("01", info.file);
      }

      this.verifyvadata.Frontofidentitycardloading = true

      // if (info.file.status === 'uploading') {
      //   this.verifyvadata.Frontofidentitycardloading = true;
      //   // return;
      // }
      // this.$refs.IdUp.handleChangeUpload(info);
    },
    // 身份证反面
    changeIdBack(info) {

      if (this.form.channel_no == undefined)
        return this.$message.error('请先选择渠道')
      if (this.verifyvadata.ReverseofIDcardFile !== '') {
        this.verifyvadata.ReverseofIDcardloading = false
        this.verifyvadata.ReverseofIDcardFile = ''
        this.verifyvadata.ReverseofIDcardurl = ''
      }
      this.verifyvadata.ReverseofIDcardloading = true
      const isLt1M = info.file.size / 1024 / 1024 > 1;
      if (isLt1M) {
        const result = this.compressImage(info.file);
        result.then((value) => {
          this.Fileupload("02", value);
        })
      } else {
        this.Fileupload("02", info.file);
      }

      // if (info.file.status === 'uploading') {
      //   this.verifyvadata.ReverseofIDcardloading = true;
      //   // return;
      // }
      // this.$refs.IdBack.handleChangeUpload(info);
    },
    // 营业执照
    portraitUpload(info) {
      // if (this.handleBeforeUpload(info.file) == false) return
      if (this.form.channel_no == undefined)
        return this.$message.error('请先选择渠道')
      if (this.verifyvadata.BusinesslicenseFile !== '') {
        this.verifyvadata.Businesslicenseloading = false
        this.verifyvadata.BusinesslicenseFile = ''
        this.verifyvadata.Businesslicenseurl = ''
      }
      this.verifyvadata.Businesslicenseloading = true
      const isLt1M = info.file.size / 1024 / 1024 > 1;
      if (isLt1M) {
        const result = this.compressImage(info.file);
        result.then((value) => {
          this.Fileupload("12", value);
        })
      } else {
        this.Fileupload("12", info.file);
      }

      // if (info.file.status === 'uploading') {
      //   this.verifyvadata.Businesslicenseloading = true;
      //   // return;
      // }
      // this.$refs.Businesslicense.handleChangeUpload(info);
    },
    // 文件上传
    Fileupload(type, file) {
      const formData = new FormData()
      formData.append('files', file)

      axios
        .post(
          urlConfig.busiURL +
          `/file/management/scfs/uploadscfs?channel_no=${this.form.channel_no}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              token: getToken(), // 替换YOUR_TOKEN为实际的Token值
            },
          }
        )
        .then((res) => {
          if (res.data.code !== 200) {
            this.$message.error(res.data.info)
            if (type == '01') {
              this.verifyvadata.Frontofidentitycardloading = false
            } else if (type == '02') {
              this.verifyvadata.ReverseofIDcardloading = false
            } else if (type == '12') {
              this.verifyvadata.Businesslicenseloading = false
            }

            return
          }
          if (type == '01') {
            this.$refs.IdUp.HumanfaceEcho(res.data.data[0].file_no)

            this.file_list[0].file_no = res.data.data[0].file_no
            this.file_list[0].channel_file_no = res.data.data[0].channel_file_no
            console.log(this.file_list)
          } else if (type == '02') {
            this.$refs.IdBack.HumanfaceEcho(res.data.data[0].file_no)

            this.file_list[1].file_no = res.data.data[0].file_no
            this.file_list[1].channel_file_no = res.data.data[0].channel_file_no
          } else if (type == '12') {
            this.$refs.Businesslicense.HumanfaceEcho(res.data.data[0].file_no)
            this.file_list[2].file_no = res.data.data[0].file_no
            this.file_list[2].channel_file_no = res.data.data[0].channel_file_no
          }
        })
        .catch(() => { })
    },
    BusinesslicenseEdit() {
      const inputElement =
        this.$refs.BusinesslicenseEdit.$el.querySelector('input[type="file"]');
      // 现在您可以操作inputElement，例如手动触发点击事件打开文件选择对话框
      inputElement.click();
    },
    // // 人像修改
    ReverseofIDcardEdit() {
      const inputElement =
        this.$refs.ReverseofIDcard.$el.querySelector('input[type="file"]');
      // 现在您可以操作inputElement，例如手动触发点击事件打开文件选择对话框
      inputElement.click();
    },

    // 国徽修改
    FrontofidentitycardEdit() {
      const inputElement =
        this.$refs.Frontofidentitycard.$el.querySelector('input[type="file"]');
      // 现在您可以操作inputElement，例如手动触发点击事件打开文件选择对话框
      inputElement.click();
    },


  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped>
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

/deep/.ant-modal-footer {
  border: none;
  padding-bottom: 20px;
}

/deep/.ant-card-head {
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #fff;
}

/deep/.ant-modal-content {
  width: 420px;
}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .agreement {
    /deep/.ant-checkbox-wrapper {
      margin-right: 2px;
    }

    margin-right: 100px;
    display: flex;
    align-items: center;

    .item {
      margin-left: 15px;

      span {
        &:hover {
          line-height: 20px;
          color: #40a9ff;
          border-bottom: 1px solid #40a9ff;
        }
      }
    }
  }

  .right {
    button {
      height: 38px;
      border: none;

      &:first-child {
        width: 108px;

        color: #ffff;
      }

      &:last-child {
        width: 108px;
        background-color: #d7d7d7;
        color: #ffff;
      }
    }
  }
}

.Individualaccountopening {
  padding-bottom: 100px;

  .departmentId {
    /deep/ .ant-form-item-control-wrapper {
      border-top: 1px solid #d7d7d7;
    }
  }

  .app_no {
    // /deep/.ant-form-item-required {
    //   display: none;
    // }

    .app_nobox {
      display: flex;

      span {
        display: flex;
        width: 120px;
        align-items: center;
      }
    }

    p {
      height: 20px;
    }
  }

  /deep/.ant-input-suffix {
    right: 0;
    width: 40px;
  }

  /deep/ .ant-form-item-label {
    min-height: 45px;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  // .channel_no {
  //   // /deep/ .ant-form-item-label {
  //   //   padding-top: 10px;

  //   // }
  // }

  .id_type,
  .job_note,
  .open_dimensions,
  .controller_id_type,
  .controller_id_no,
  .controller_id_name,
  .controller_opto,
  .leader_id_type,
  .leader_id_no,
  .leader_id_name,
  .leader_mobile,
  .leader_opto,
  .operator_id_type,
  .operator_id_no,
  .operator_id_name,
  .operator_mobile,
  .operator_opto,
  .open_longitude {
    /deep/ .ant-form-item-label {
      min-height: 45px;
      padding-left: 30px;
      display: flex;
      align-items: center;
    }
  }

  .code {
    /deep/.ant-input-suffix {
      right: -88px;
      width: 50px;
      border-radius: 6px;
      height: 30px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #02a7f0;
    }
  }

  .TaxRegistrationNumber {
    /deep/.ant-input-suffix {
      right: -168px;
    }
  }

  .pricebox {
    width: 100%;

    /deep/ .ant-form-item-label {
      min-height: 180px;
      padding-left: 30px;
      display: flex;
      align-items: center;
    }

    /deep/ .ant-form-item-control-wrapper {
      min-height: 180px;
    }

    .row {
      display: flex;

      .item {
        margin-left: 50px;
        display: flex;
        align-items: center;

        /deep/.ant-upload {
          width: 200px;
          height: 122px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          padding: 0px;
          overflow: hidden;

          .anticon-plus {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .ant-upload.ant-upload-select-picture-card {
          border: 1px solid #ccc;
        }

        .ant-upload.ant-upload-select-picture-card>.ant-upload {
          padding: 0;
        }

        .ant-upload-select-picture {
          width: 200px;
          height: 122px;
          border: 0;
        }
      }
    }
  }

  /deep/ .ant-form-item {
    background-color: #f2f2f2;
    min-height: 50px;
    margin-bottom: 0px !important;
  }

  /deep/ .ant-form-item-control-wrapper {
    padding-left: 35px;
    padding-left: 20px;
    background-color: #fff;
    border-bottom: 1px solid #d7d7d7;
    border-left: none;
    display: flex;
    min-height: 50px;
    align-items: center;

    .ant-form-item-control {

      // min-width: 200px;
      .ant-input {
        width: 200px;
      }

      .ant-select {
        width: 200px;
      }

    }
  }

  /deep/.ant-col .ant-col-21 .ant-form-item-control-wrapper {
    .ant-form-item-control {
      width: 100%;

      .ant-input {
        width: 200px;
      }
    }
  }

  .confirmloading {
    color: #333333;

    .text {
      padding: 20px 0px;
      text-align: center;
      font-size: 16px;
      color: #494949;
    }

    .canceltext {
      width: 200px;
      margin: auto;
      text-align: center;
    }

    .custom-footer {
      button {
        &:first-child {
          color: #fff;
        }

        &:last-child {
          background-color: #f2f2f2;
          color: #494949;
        }
      }
    }
  }

  .legalrepresentative,
  .actualcontroller,
  .personincharge,
  .agent,
  .beneficiary {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    font-weight: 600;

    span {
      font-weight: normal;
      margin-left: 5px;
    }

    .fill {
      margin-left: 20px;
      height: 24px;
    }
  }
}
</style>
