<template>
  <div>
    <a-tooltip>
      <template slot="title">
        <a-button v-if="status?.value=='04'" type="primary" size="small" @click="toSign">去签约<a-icon type="right" /></a-button>
        <a-button v-else-if="status?.value=='06'" type="primary" size="small" @click="toSign">查看合同<a-icon type="right" /></a-button>
        <template v-else>{{selectData.statusDesc}}</template>
      </template>
      <a-tag :color="status?.color">{{status?.label}}</a-tag>
    </a-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    selectData: {
      required: true,
      type: Object
    },
    status: {
      required: true,
      type: Object
    }
  },
  methods: {
    mounted(){
      console.info(this.status, this.selectData)
    },
    async toSign() {
      let data = {
        channel_no: this.selectData.channelNo,
        app_no: this.selectData.appNo,
        product_id: this.selectData.productId,
        online_serial_no: this.selectData.onlineSerialNo,
      }
      const res = await this.api.creditSignFlowsUrlsApi(data)
      // console.log(res);
      if (res.code !== 200) {
        this.$message.warning(res.info)
        return
      }
      window.open(res.data.recode_list[0].sign_url)
    }
  }
}
</script>

<style></style>