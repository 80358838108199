export default {
    clearEmptyParam(data) {
        const removeEmpty = (obj) => {
            if (obj && typeof obj === 'object') {
                if (Array.isArray(obj)) {
                    return obj.filter((item) => ![null, undefined, ''].includes(item));
                }
                const cleanedObj = { ...obj };
                Object.keys(cleanedObj).forEach((key) => {
                    const value = cleanedObj[key];
                    if (['', undefined, null].includes(value)) {
                        delete cleanedObj[key];
                    } else if (typeof value === 'object') {
                        cleanedObj[key] = removeEmpty(value);
                    }
                });
                return cleanedObj;
            }

            return obj;
        };

        return removeEmpty(data);
    },
};
