<template>
  <div>
    <a-modal
      :title="modalTitle"
      :visible="modalBool"
      @ok="handleOk"
      @cancel="handleCancel"
    >
       <a-form 
        :form="form"
        :label-col="{span: 4, offset: 1}"
        :wrapperCol ="{span: 18, offset: 1}"
        :colon = "false"
        labelAlign = "left"
       >
          <a-form-item label="角色名称" >
            <a-input
              v-decorator="[
                'name',
                { 
                  rules: [{ required: true, message: '请输入角色名称' }],
                  initialValue:selectedRows[0].name,
                },
              ]"
              placeholder="请输入角色名称"
            />
          </a-form-item>
          <a-form-item label="权限配置" >
            <div class=" tree">
              <a-tree
                showLine
                checkable
                checkStrictly
                defaultExpandAll
                :default-checked-keys="treeNode"
                :checkedKeys="treeNodeData"
                :tree-data="treeData"
                :auto-expand-parent="true"
                @check="onCheck"
              />
            </div>
          </a-form-item>
       </a-form>
    </a-modal>
  </div>
</template>
<script>
export default {
  props:{
    modalBool:Boolean,
    modalTitle:String,
    selectedRows:Array,
    treeData:Array,
    treeNode:Array
  },
  data() {
    
    return {
      treeNodeData:this.treeNode,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'modal_form' });
  },
  methods: {
    handleOk() {
      const id = this.selectedRows[0].id
      const data = this.treeNodeData
      const functions = data.map(item=>{
        return {
          functionId:item,
          authMode:true
        }
      })
      // console.log(this.treeNodeData,id,{functions})
      this.$emit("okModalHandle",id,functions)
    },
    handleCancel() {
      this.$emit("cancelModalHandle")
    },
    onCheck(checkedKeys) {
      console.log(checkedKeys.checked,'checkedKeys')
      this.treeNodeData = checkedKeys.checked
    }
  },
};
</script>

<style lang="less" scoped>
  .tree{
    height: 326px;
    overflow: auto;
  }
</style>