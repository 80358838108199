<template>
  <div>
    <a-modal :title="modalTitle" :visible="modalBool" @ok="handleOk" @cancel="handleCancel">
      <a-form :form="form" :label-col="{ span: 6, offset: 1 }" :wrapperCol="{ span: 16, offset: 1 }" :colon="false"
        labelAlign="left">
        <a-form-item label="机构名称">
          <a-input v-decorator="[
            'name',
            {
              rules: [{ required: true, message: '请输入机构名称' }],
              initialValue: selectedRows[0].name,
            },
          ]" placeholder="请输入机构名称" />
        </a-form-item>
        <a-form-item label="机构级别">
          <a-select v-decorator="[
            'level',
            {
              rules: [{ required: true, message: '请输入机构级别' }],
              initialValue: selectedRows[0].level,
            },
          ]" placeholder="请选择机构级别" @change="levelChange($event)" :disabled="typeof selectedRows[0].id === 'number'">
            <a-select-option :disabled="levelPart > 0" :value="1">
              一级
            </a-select-option>
            <a-select-option :disabled="levelPart > 1" :value="2">
              二级
            </a-select-option>
            <a-select-option :disabled="levelPart > 2" :value="3">
              三级
            </a-select-option>
            <a-select-option :disabled="levelPart > 3" :value="4">
              四级
            </a-select-option>
            <a-select-option :disabled="levelPart > 4" :value="5">
              五级
            </a-select-option>
            <a-select-option :disabled="levelPart > 5" :value="6">
              六级
            </a-select-option>
            <a-select-option :disabled="levelPart > 6" :value="7">
              七级
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="上级机构" v-if="level && level !== 1">
          <a-select v-decorator="[
            'parentId',
            {
              rules: [{ required: true, message: '请输入上级机构' }],
              initialValue: selectedRows[0].parentId,
            },
          ]" placeholder="请选择上级机构">
            <a-select-option v-for="item in superPart" :value="item.id" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注">
          <a-input v-decorator="[
            'note',
            {
              initialValue: selectedRows[0].note,
            }
          ]" placeholder="请输入备注" />
        </a-form-item>
        <a-form-item label="请求最大量" v-if="false">
          <a-input v-decorator="[
            'apiCallTimes',
            {
              rules: [{ required: true, message: '请输入请求最大量' }],
              initialValue: selectedRows[0].apiCallTimes || 100000,
            },
          ]" placeholder="100000" />
        </a-form-item>
        <a-form-item label="请求权限类型" v-if="false">
          <a-select v-decorator="[
            'apiType',
            {
              rules: [{ required: true, message: '请选择请求权限类型' }],
              initialValue: selectedRows[0].apiType || '1',
            },
          ]" placeholder="请选择请求权限类型" allowClear>
            <a-select-option value="1">
              白名单
            </a-select-option>
            <a-select-option value="2">
              灰名单
            </a-select-option>
            <a-select-option value="3">
              黑名单
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    modalBool: Boolean,
    modalTitle: String,
    selectedRows: Array,
    dataPart: Array,
  },
  data() {
    return {
      levelPart: this.$session.getItem('departmentLevel'),
      superPart: [],
      level: this.selectedRows[0].level
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'modal_form' });
  },
  created() {
    const bool = this.selectedRows[0].id
    const level = this.selectedRows[0].level
    if (!bool) return
    let arr = level > 1 ? this.dataPart.filter(e => e.level === level - 1) : [];
    this.level = level
    this.superPart = arr
  },
  methods: {
    handleOk() {
      this.$emit("okModalHandle")
    },
    handleCancel() {
      this.$emit("cancelModalHandle")
    },
    levelChange(e) {
      const level = e
      const id = this.$session.getItem("companyId")
      let arr = level > 1 ? this.dataPart.filter(e => e.level === level - 1 && e.id !== id) : [];
      this.level = level
      this.superPart = arr
    }
  },
};
</script>

<style lang="less" scoped></style>