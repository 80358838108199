// import axios from '@/utils/axios';
import custApi from './custApi'
import WalletApi from './WalletApi'
import fileApi from './fileApi'
import OrderApi from './orderApi'
import MchApi from './MchApi'
import BaseApi from './BaseApi'
export default {
  ...custApi,
  ...fileApi,
  ...WalletApi,
  ...OrderApi,
  ...MchApi,
  ...BaseApi
  // 客户管理-个人客户-基础信息
  // custPage: (data) => axios.apiGet( baseURL + '/cust/person/page', data),
}
