<template>
  <a-modal title="业务确认" class="QRConfirm" :keyboard="false" :maskClosable="false" :centered="true"
      :visible="display">
    <div>
      <div>
        <canvas ref="qrConfirmCanvas"></canvas>
      </div>
    </div>
    <p>您好，{{username}}</p>
    <p>正在办理{{busi_intro}}业务</p>
    <p>请使用微信扫码，并根据提示办理</p>

    <template v-slot:footer>
      <!-- 提现 -->
      <div style="text-align: center" class="custom-footer">
        <a-button type="primary" @click='$emit("update:display", false);'>确定</a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
import qrcode from "qrcode";

export default {
  name: "QRConfirm",
  props: {
    url: String,
    display: Boolean,
    busi_intro: String,
    username: String,
  },
  data: function () {
    return {
      baseurl: "",
    };
  },
  created: async function () {
    const records = await this.api.downLoadTemplateApi(["data_collection_confirm"]);
    if (records.code !== 200) {
      this.$message.warning(records.info);
    }
    this.baseurl = records.data.data_collection_confirm.constValue;
  },
  watch: {
    url(newVal, oldVal) {
      this.createQRCode()
    }
  },
  methods: {
    createQRCode() {

      this.$nextTick(() => {

        qrcode.toCanvas(
            this.$refs.qrConfirmCanvas,
            this.baseurl + this.url, {
              width: 250,
              height: 250,
            }, (error) => {
              if (error) {
                this.$message.error("无法获取二维码，请联系管理员")
                console.error("二维码生成失败", error);
              }
            });
      })
    },
  }
}


</script>
<style scoped lang="less">

</style>