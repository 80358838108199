<template>
  <a-select :disabled="disabled" :show-search="true" placeholder="请选择开户行" :options="options" v-model="value"
    :filter-option="filterOption" @change="handleChange"></a-select>
</template>
<script>
import { BizDict } from "@/utils/bizDict/kvMap";
export default {
  name: "Bank",
  props: {
    name: String,
    // value: String,
    disabled: Boolean,
  },
  data() {
    return {
      options: JSON.parse(JSON.stringify(BizDict.BankNo)),
      value: ''
    }
  },
  methods: {
    filterOption(input, option) {
      if (input.length < 2) return false;
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange(value, option) {
      this.$emit('changeBank', { value, option })
      // this.$emit('update:name', option.componentOptions.children[0].text)
      // this.$emit('update:value', value)

    }
  }
}
</script>
<style scoped lang="less"></style>