<template>
  <div class="container">
    <a-tag :color="tagColor">{{ tagText }}</a-tag>
    <span v-if="reason">{{ reason }}</span>
    <a-button v-if="retry" icon="shopping" type="link" @click="retryClick" size="small"></a-button>
  </div>
</template>

<script>
export default {
  name: "OrderStatus",
  props: {
    orderStatus: {
      required: true,
      type: String
    },
    reason: {
      required: false,
      type: String
    },
    retry: {
      required: false,
      type: Boolean,
      default: false
    },
    retryAction: {
      required: false,
      type: Function
    }
  },
  computed: {
    tagColor() {
      return this.orderStatus === "0" ? "blue" : this.orderStatus === "1" ? "green" : "red"
    },
    tagText() {
      return this.orderStatus === "0" ? "未下单" : this.orderStatus === "1" ? "下单成功" : "下单失败"
    },
  },
  methods: {
    retryClick(){
      if(this.retry && this.retryAction){
        this.retryAction()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>