import {dict_rawdata, dict_rawdata_cascade} from './rawdata'
// =======级联======
// BizDictCascade.WorkCorpIndusCD	贷款投向 (所属行业)
// BizDictCascade.DstcCd	行政区划代码
// BizDictCascade.OcpCd	职业

// =======平======
// BizDict.CstTp	客户类型
// BizDict.EcomnCompntCD	经济成分
// BizDict.GndFlg	性别
// BizDict.RaceCd	民族
// BizDict.MrtlSt	婚姻状况
// BizDict.HestDgr	最高学位
// BizDict.HestEdct	最高学历
// BizDict.WorkStatCD	从业状况
// BizDict.EntpTp	企业类型
// BizDict.EcnmChrctrstcCd	经济类型
// BizDict.CoChrctrstcTp	单位性质
// BizDict.WorkCorpTypeCD	工作单位性质
// BizDict.OcpCd	职业类型
// BizDict.OcpGrdg	职称
// BizDict.JobCd	职务类型
// BizDict.Pst	担任职务
// BizDict.RsdntDsc	居住状况
// BizDict.MajorEconSrcCD	主要经济来源
// BizDict.RsdnCstSt	居民性质
// BizDict.DmstOrOvrsInd	境内境外标志
// BizDict.CstSt	客户状态
// BizDict.InvestCurrCD	出资币种
// BizDict.InstCtgry	组织机构类别
// BizDict.NtnEcnmSctrCd	国民经济部门
// BizDict.CorpSizeCD	企业规模
// BizDict.FrgnExgEntpTp	外汇企业属性
// BizDict.FinOrgCustCD	同业客户类型
// BizDict.CorpOperStatCD	经营状态
// BizDict.CtcTp	联系类型
// BizDict.AdTyp	地址类型
// BizDict.NtntyCd	国籍
// BizDict.RltnpTp	关系类型
// BizDict.DepPrsnTp	存款人类别
// BizDict.InvestType	出资方式
// BizDict.FinOrgIndusCD	金融机构行业分类
// BizDict.InptMd	影像存储方式
// BizDict.AgrcltRelatedCustInd	涉农客户标志
// BizDict.MrchFlg	商户标志
// BizDict.RgstInfIntgrtyFlg	登记信息完整标志
// BizDict.LstdEntpFlg	上市公司标志
// BizDict.IndvBizInd	个体工商户标志
// BizDict.GreenCorpInd	是否绿色企业
// BizDict.TechEntpFlg	科技型企业标志
// BizDict.IssuerInd	发行人标志
// BizDict.MainIdentFlg	主证件标志
// BizDict.IsFlg1	是否标志1
// BizDict.MajorInd	主要标志
// BizDict.UdtftCheckInd	联网核查情况
// BizDict.ClsGrpSsts	是否集团客户
// BizDict.CstTp1	客户类型细分
// BizDict.BnBodyChkFlg	人脸识别标志
// BizDict.IsFlg4	手机号三方查验标志
// BizDict.IsFlg4	身份识别标志
// BizDict.AtchRltnpTp	归属关系
// BizDict.recdstatus	记录状态
// BizDict.microbizind	小微企业主标志
// BizDict.CstTp1	客户类型1
// BizDict.GroupType	集团类型
// BizDict.isexpcer	证件是否到期
// BizDict.RgstrtnFndCcyCd	注册资本币种
// BizDict.ActRcvFndCcyCd	实收资本币种
// BizDict.IdentTp	证件类型
// BizDict.InvFrmUsg  支农用途
// BizDict.whethen1 个人贷款申请-与经营实体关系

export const BizDict = dict_rawdata
export const BizDictCascade = {
         // 贷款投向
         WorkCorpIndusCD: (function() {
           let direction_formatted = [];
           let tempParent1 = {};
           let tempParent2 = {};
           let tempParent3 = {};
           for (let i in dict_rawdata_cascade.direction_raw) {
             let item = dict_rawdata_cascade.direction_raw[i];
             if (item.v.length === 1) {
               tempParent1 = {
                 value: item.v,
                 label: item.l,
                 children: [],
               };
               direction_formatted.push(tempParent1);
             } else if (item.v.length === 3) {
               tempParent2 = {
                 value: item.v,
                 label: item.l,
                 children: [],
               };
               tempParent1.children.push(tempParent2);
             } else if (item.v.length === 4) {
               tempParent3 = {
                 value: item.v,
                 label: item.l,
                 children: [],
               };
               tempParent2.children.push(tempParent3);
             } else if (item.v.length === 5) {
               let leaf = {
                 value: item.v,
                 label: item.l,
               };
               tempParent3.children.push(leaf);
             }
           }
           return direction_formatted;
         })(),
         // 省市区三级代码
         DstcCd: (function() {
           let region = [];
           dict_rawdata_cascade.region_raw.forEach((i,ind,arr) => {
             if (i.r.endsWith("0000")) return //首先去掉省级数据
             //去掉有区级的市级，保留直辖市
             if (i.r.endsWith('00')) {
              // substring方法截取字符串，包括前不包括后面
               let isCityStr = i.r.substring(0, 3)
               let nexStr = arr[ind + 1].r.substring(0, 3)
               if(isCityStr === nexStr) return
             }
             let p = region.find((o) => o.value === i.r.substring(0, 2));
             if (!p) {
               
               p = { value: i.r.substring(0, 2), label: i.p, children: [] };
               region.push(p);
             }
             let c = p.children.find((o) => o.value === i.r.substring(0, 4));
             if (!c) {
               c = { value: i.r.substring(0, 4), label: i.c, children: [] };
               p.children.push(c);
             }
             c.children.push({ value: i.r, label: i.d });
           });
           return region;
         })(),
         // 职业
         OcpCd: (function() {
           let job = [];
           dict_rawdata_cascade.job_raw.forEach((i) => {
             if (i.c.endsWith('0000')) {
               job.push({ value: i.c, label: i.l, children: [] });
             } else if (i.c.endsWith('00')) {
               job
                 .find((o) => o.value === i.c.substring(0, 1) + '0000')
                 .children.push({ value: i.c, label: i.l, children: [] });
             } else {
               job
                 .find((o) => o.value === i.c.substring(0, 1) + '0000')
                 .children.find((o) => o.value === i.c.substring(0, 3) + '00')
                 .children.push({ value: i.c, label: i.l,  });
             }
           });
           return job;
         })(),
        //职业
        OcpCd2: dict_rawdata_cascade.job_raw,
        WorkCorpIndusCD2: dict_rawdata_cascade.direction_raw,
};
