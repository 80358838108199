<template>
  <div>
    <a-modal
      :title="modalTitle"
      :visible="modalBool"
      @ok="handleOk"
      @cancel="handleCancel"
    >
       <a-form 
        :form="form"
        :label-col="{span: 4, offset: 1}"
        :wrapperCol ="{span: 18, offset: 1}"
        :colon = "false"
        labelAlign = "left"
       >
          <a-form-item label="资源名称" >
            <a-input
              v-decorator="[
                'name',
                { 
                  rules: [{ required: true, message: '请输入资源名称' }],
                  initialValue:selectedRows[0].name,
                },
              ]"
              placeholder="请输入资源名称"
            />
          </a-form-item>
          <a-form-item label="组件名称" >
            <a-input
              v-decorator="[
                'component',
                {
                  initialValue:selectedRows[0].component,
                },
              ]"
              placeholder="请输入组件名称"
            />
          </a-form-item>
          <a-form-item label="类型" >
            <a-select 
              v-decorator="[
                  'functionType',
                  { 
                    rules: [{ required: true, message: '请选择类型' }],
                    initialValue:selectedRows[0].functionType,
                  },
                ]"
              placeholder="请选择类型" 
            >
              <a-select-option :value="0">
                页面
              </a-select-option>
              <a-select-option :value="1">
                按钮
              </a-select-option>
            </a-select>
          </a-form-item> 
          <a-form-item label="资源路径" >
            <a-input
              v-decorator="[
                'path',
                { 
                  initialValue:selectedRows[0].path,
                },
              ]"
              placeholder="请输入资源路径"
            />
          </a-form-item>
          <a-form-item label="路径" >
             <a-textarea
               v-decorator="[
                'authUrls',
                  { 
                    initialValue:selectedRows[0].authUrls,
                  },
                ]"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
          </a-form-item>
       </a-form>
    </a-modal>
  </div>
</template>
<script>
export default {
  props:{
    modalBool:Boolean,
    modalTitle:String,
    selectedRows:Array
  },
  data() {
    return {
      ModalText: 'Content of the modal',
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'modal_form' });
  },
  methods: {
    handleOk() {
      const id = this.selectedRows[0].id
      this.$emit("okModalHandle",id)
    },
    handleCancel() {
      this.$emit("cancelModalHandle")
    },
  },
};
</script>

<style lang="less" scoped>

</style>