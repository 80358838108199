<template>
  <div id="user">
    <a-card title="用户管理" style="margin:20px">
      <!-- 搜索框 -->
      <div class="form">
        <view-select-form @selectHandle="selectHandle" :treeData="treeData" :roleData="roleData"></view-select-form>
      </div>
      <!-- 操作栏 -->
      <div class="action">
        <a-button type="primary" v-if="$authority('user_add')" @click="addHandle">新增</a-button>
        <!-- <a-button type="primary" v-if="$authority('user_add_client')" @click="addMobileAccount">新增手机账户</a-button>
        <a-button type="primary" v-if="$authority('user_bind_mch') && isShowBindBtn" @click="bindMch">绑定电商</a-button> -->
        <a-button v-if="$authority('user_update')" @click="updateHandle">修改</a-button>
        <a-button v-if="$authority('user_delete')" type="danger" :disabled="selectedRowKeys.length === 0"
          @click="deleteHandle">删除</a-button>
        <a-button v-if="$authority('user_lock')" @click="lockHandle">锁定</a-button>
        <a-button v-if="$authority('user_unlock')" @click="unlockHandle">解锁</a-button>
        <a-button v-if="$authority('user_password_update')" @click="updatePasswordHandle">密码修改</a-button>
        <a-button v-if="$authority('user_password_reset')" @click="resetPasswordHandle">密码重置</a-button>
      </div>
      <!-- 表格 -->
      <div class="table">
        <a-table :columns="columns" :data-source="tableData" :row-selection="rowSelection" :pagination="false"
          :customRow="clickrow" :rowKey="(record, index) => `${record.id}`">
          <template slot="number_index" slot-scope="text,record,index">
            <span>
              {{ (index + 1) + (currentPage - 1) * pageSize }}
            </span>
          </template>
          <template slot="departmentId" slot-scope="text">
            <span>
              {{ partName(text) }}
            </span>
          </template>
          <template slot="roleId" slot-scope="text">
            <span>
              {{ roleName(text) }}
            </span>
          </template>
          <template slot="status" slot-scope="text">
            <a-tag v-if='text==0' color="green">正常</a-tag>
            <a-tag v-if='text==1' color="red">锁定</a-tag>
          </template>
          <template slot="type" slot-scope="text">
            <span>

              {{ text === 0 ? '管理台账户' : text === 1 ? '接口账户' : "普通账户" }}
            </span>
          </template>
        </a-table>
      </div>
      <!-- page -->
      <div class="page">
        <a-pagination :current="currentPage" :total="total" show-size-changer show-quick-jumper style="float:right"
          @change="pageChange" @showSizeChange="sizePageChange" :show-total="total => `共 ${total} 条`" />
      </div>
      <!-- 模态框 -->
      <div class='modal' v-if="modalBool">
        <view-modal :modalBool="modalBool" :modalTitle="modalTitle" :selectedRows="selectedRows"
          @okModalHandle="okModalHandle" @cancelModalHandle="cancelModalHandle" ref="modalChild" :treeData="treeData"
          :roleData="roleData"></view-modal>
      </div>
      <div class='pass_modal' v-if="passBool">
        <view-pass-modal :passBool="passBool" @okPassHandle="okPassHandle" @cancelPassHandle="cancelPassHandle"
          ref="passChild"></view-pass-modal>
      </div>
      <!-- h5新增-模态框 -->
      <a-modal v-model="isShowAddModel" title="新增" @ok="handleAddAccountOk">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 6, offset: 1 }"
          :wrapperCol="{ span: 16, offset: 1 }" :colon="false" labelAlign="left">
          <a-form-model-item label="用户名称" prop="username">
            <a-input v-model="form.username" placeholder="请输入用户名称" />
          </a-form-model-item>
          <a-form-model-item label="手机号码" prop="mobile">
            <a-input v-model="form.mobile" placeholder="请输入手机号码" />
          </a-form-model-item>

          <a-form-model-item label="用户类型" prop="type">
            <a-select v-model="form.type" placeholder="请选择用户类型" @change="codeChange($event)" disabled>
              <a-select-option :value="false">管理台账户</a-select-option>
              <a-select-option :value="true">引擎接口账户</a-select-option>
              <a-select-option value="2">普通账户</a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="真实姓名">
            <a-input v-model="form.name" placeholder="请输入真实姓名" />
          </a-form-model-item>
          <a-form-model-item label="用户邮箱">
            <a-input v-model="form.email" placeholder="请输入用户邮箱" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <MchChooseDialog v-if="isShowMchChooseDialog" :isShowInput="false" :isShowModel="true" @chooseCancel="chooseCancel"
        @chooseDone="chooseDone">
      </MchChooseDialog>
    </a-card>
  </div>
</template>
<script>
import SelectForm from './SelectForm';
import Modal from "./Modal";
import PassModal from './PassModal';
import md5 from 'md5'
import MchChooseDialog from '../../components/ChooseDialog/MchChooseDialog.vue'

const columns = [
  {
    title: '序号',
    dataIndex: 'number_index',
    align: 'center',
    scopedSlots: { customRender: 'number_index' },
  },
  {
    title: '用户名称',
    dataIndex: 'username',
    align: 'center',
  },
  {
    title: '真实姓名',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: '所属机构',
    dataIndex: 'departmentId',
    align: 'center',
    scopedSlots: { customRender: 'departmentId' },
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    align: 'center',
  },
  {
    title: '用户角色',
    dataIndex: 'roleId',
    align: 'center',
    scopedSlots: { customRender: 'roleId' },
  },
  {
    title: '用户状态',
    dataIndex: 'status',
    align: 'center',
    scopedSlots: { customRender: 'status' },
  },
  // {
  //   title: '用户类型',
  //   dataIndex: 'type',
  //   align: 'center',
  //   scopedSlots: { customRender: 'type' },
  // },
  {
    title: 'userId',
    dataIndex: 'id',
    align: 'center',
  },
];

export default {
  name: 'userPage',
  components: {
    "view-select-form": SelectForm,
    "view-modal": Modal,
    "view-pass-modal": PassModal,
    MchChooseDialog
  },
  data() {
    return {
      isShowAddModel: false,
      isShowBindBtn: false,
      isShowMchChooseDialog: false,
      data: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      columns,
      modalBool: false,
      passBool: false,
      modalTitle: "操作",
      selectedRowKeys: [],
      selectedRows: [{}],
      treeData: [],
      companys: [],
      roleData: [],
      selectData: [],
      form: {
        username: '',
        mobile: '',
        type: '2',
        name: '',
        email: ''
      },
      rules: {
        username: [{
          required: true, message: '请输入用户名称', trigger: 'blur'
        }],
        mobile: [{
          required: true, message: '请输入手机号', trigger: 'blur'
        }, {
          pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '手机格式不正确'
        }],
        type: [{
          required: true, message: '请选择用户类型', trigger: 'change'
        }]

      }
    }
  },
  created() {
    this.tableRequestHandle()
    this.roleRequestHandle()
    this.partmentRequestHandle()
  },
  methods: {
    chooseCancel() {
      this.isShowMchChooseDialog = false
    },
    async chooseDone(record) {
      let data = {
        userId: this.selectedRows[0].id,
        userName: this.selectedRows[0].username,
        mobile: this.selectedRows[0].mobile,
        mchNo: record.mchNo,
        mchName: record.mchName,
      }
      this.isShowMchChooseDialog = false
      //调用绑定电商接口
      let res = await this.api.userBindMchApi(data)
      if (res.code != 200) return this.$message.error(res.info)
      this.$message.success(res.info)
    },
    handleAddAccountOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //调用新增接口
          this.$axios.apiPost('/user/user/save', this.form).then((res) => {
            if (res.code !== 200) return this.$message.error(res.info)
            this.tableRequestHandle()
            this.$message.success(res.info)
            this.isShowAddModel = false
          })
        }
      })
    },
    //页码操作
    pageChange(page, pageSize) {
      const data = this.data
      this.currentPage = page
      this.tableData = data.slice((page - 1) * pageSize, page * pageSize)
    },
    sizePageChange(current, size) {
      const data = this.data
      this.tableData = data.slice((current - 1) * size, current * size)
    },
    //搜索操作
    selectHandle(val) {
      this.selectedRowKeys = []
      this.selectData = val
      this.tableRequestHandle()
    },
    //数据增删改
    addHandle() {
      this.selectedRowKeys = []
      this.selectedRows = [{}]
      this.modalTitle = "新增数据"
      this.modalBool = true
    },
    //h5账户新增
    addMobileAccount() {
      this.isShowAddModel = true
    },
    //绑定电商
    bindMch() {
      this.isShowMchChooseDialog = true
    },
    updateHandle() {
      const sum = this.selectedRowKeys
      if (sum.length !== 1) return this.$message.warn("只能选择一条数据")
      this.modalTitle = "修改数据"
      this.modalBool = true
    },
    deleteHandle() {
      const data = this.selectedRowKeys
      const tableData = this.tableData
      const currentPage = this.currentPage
      this.$confirm({
        // title: '数据删除',
        // content: () => "是否删除此条数据？此过程不可逆！",
        title: '是否删除此条数据？此过程不可逆！',
        onOk: () => {
          this.$axios.apiPost('/user/user/del', data).then(res => {
            if (res.code !== 200) this.$message.error(res.info)
            if (tableData.length === data.length && currentPage > 1) {
              this.currentPage = currentPage - 1
            }
            this.$message.success(res.info)
            this.selectedRowKeys = []
            this.selectedRows = [{}]
            this.tableRequestHandle()
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    lockHandle() {
      const data = this.selectedRowKeys
      if (data.length !== 1) return this.$message.warn("请选择一条数据进行修改")
      this.$confirm({
        title: '是否锁定该用户?',
        // content: () => "是否锁定该用户！",
        onOk: () => {
          this.$axios.apiPost('/user/user/lock?id=' + data[0]).then((res) => {
            if (res.code !== 200) return this.$message.error(res.info)
            this.$message.success("操作成功")
            this.tableRequestHandle()
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    unlockHandle() {
      const data = this.selectedRowKeys
      if (data.length !== 1) return this.$message.warn("请选择一条数据进行修改")
      this.$confirm({
        title: '是否解锁该用户?',
        // content: () => "是否解锁定该用户！",
        onOk: () => {
          this.$axios.apiPost('/user/user/unlock?id=' + data[0]).then((res) => {
            if (res.code !== 200) return this.$message.error(res.info)
            this.$message.success("操作成功")
            this.tableRequestHandle()
            this.selectedRowKeys = []
            this.selectedRows = [{}]
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    updatePasswordHandle() {
      const data = this.selectedRowKeys
      if (data.length !== 1) return this.$message.warn("请选择一条数据进行修改")
      this.passBool = true
    },
    resetPasswordHandle() {
      const data = this.selectedRowKeys
      if (data.length !== 1) return this.$message.warn("请选择一条数据进行修改")
      this.$confirm({
        // title: '密码重置',
        // content: () => "是否让密码恢复初始状态！",
        title: '是否让密码恢复初始状态?',
        onOk: () => {
          this.$axios.apiPost('/user/user/password/reset?id=' + data[0]).then((res) => {
            if (res.code !== 200) return this.$message.error(res.info)
            this.$message.success(res.info)
            this.tableRequestHandle()
            this.selectedRowKeys = []
            this.selectedRows = [{}]
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    //Modal操作
    okModalHandle(id) {
      this.$refs['modalChild'].form.validateFields((err, values) => {
        if (!err) {
          if (id) {
            this.$axios.apiPost("/user/user/save?id=" + id, values).then((res) => {
              if (res.code !== 200) return this.$message.error(res.info)
              this.tableRequestHandle()
              this.selectedRowKeys = []
              this.$message.success("修改成功")
            })
          } else {
            this.$axios.apiPost('/user/user/save', values).then((res) => {
              if (res.code !== 200) return this.$message.error(res.info)
              this.tableRequestHandle()
              this.$message.success(res.info)
            })
          }
          this.selectedRowKeys = []
          this.selectedRows = [{}]
          this.modalBool = false
        }
      })
    },
    cancelModalHandle() {
      this.modalBool = false
    },
    //密码操作
    okPassHandle() {
      const id = this.selectedRowKeys[0]
      this.$refs['passChild'].form.validateFields((err, values) => {
        if (!err) {
          const { password } = values
          this.$axios.apiPost("/user/user/password/modify?id=" + id, { password: md5(password) }).then((res) => {
            if (res.code !== 200) return this.$message.error(res.info)
            this.tableRequestHandle()
            this.selectedRowKeys = []
            this.selectedRows = [{}]
            this.$message.success(res.info)
            this.passBool = false
          })
        }
      })
    },
    cancelPassHandle() {
      this.passBool = false
    },
    //列表数据
    tableRequestHandle() {
      const values = this.selectData
      this.$axios.apiGet('/user/user/list', values).then((res) => {
        if (res.code !== 200) return this.$message.error(res.info)
        const data = res.data
        this.tableData = data.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
        this.total = data.length
        this.data = res.data
      })
    },
    partmentRequestHandle() {
      this.$axios.apiGet('/user/department/list').then((res) => {
        if (res.code !== 200) return this.$message.error(res.info)
        let data = res.data
        var arrObj = []
        var sum_bool = []
        for (const value of data) {
          if (sum_bool.indexOf(value.level) == -1) {
            sum_bool.push(value.level)
          }
        }
        var max = Math.max.apply(this, sum_bool)
        var min = Math.min.apply(this, sum_bool)
        console.log(min, max)
        for (let i = min; i < max + 1; i++) {
          first(i, data)
        }
        function first(min, data) {
          for (let value of data) {
            if (value.level == min) {
              if (value.parentId) {
                if (arrObj.length) {
                  for (let value_1 of arrObj) {
                    recu(value_1, value)
                  }
                } else {
                  arrObj.push({
                    title: value.name,
                    value: value.id,
                    key: value.id,
                    children: []
                  })
                }
              } else {
                arrObj.push({
                  title: value.name,
                  value: value.id,
                  key: value.id,
                  children: []
                })
              }
            }
          }
        }
        function recu(value_1, value) {
          if (value_1.key == value.parentId) {
            value_1.children.push({
              title: value.name,
              value: value.id,
              key: value.id,
              children: []
            })
            return
          } else {
            if (!value_1.children) {
              return
            }
            for (let value_2 of value_1.children) {
              recu(value_2, value)
            }
          }
        }
        this.treeData = arrObj
        this.companys = data
      })
    },
    roleRequestHandle() {
      this.$axios.apiGet("/user/role/list").then(res => {
        if (res.code !== 200) return this.$message.error(res.info)
        this.roleData = res.data
      })
    },
    roleName(id) {
      const roleList = this.roleData.filter(e => e.id === id);
      return roleList.length > 0 ? roleList[0].name : null;
    },
    partName(text) {
      const company = text ? this.companys.filter(e => e.id === text) : [];
      return company.length > 0 ? company[0].name : undefined;
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            this.selectedRowKeys = [id]
            this.selectedRows = [record]
            this.isShowBindBtn = record.type === 2
            // let index = this.selectedRowKeys.findIndex((item) => id == item)
            // if (index == -1) {
            //   this.selectedRowKeys.push(id)
            //   this.selectedRows.push(record)
            //
            // } else {
            //
            //   this.selectedRowKeys.splice(index, 1)
            //   this.selectedRows.splice(index, 1)
            // }
          }
        }
      }
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
        selectedRowKeys: this.selectedRowKeys
      };
    },

  }
}
</script>
<style lang="less" scoped>
#user {
  .page {
    margin: 12px 0;
  }

  .table {
    margin-top: 24px;
  }

  .form {
    margin-bottom: 16px;
  }
}
</style>