<template>
  <div>
    <a-modal :title="modalTitle" :visible="modalBool" @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 6, offset: 1 }" :wrapperCol="{ span: 15, offset: 1 }"
        labelAlign="left">
        <a-form-model-item label="常量编码" prop="constCode"
          :rules="[{ required: true, message: '请输入常量编码', trigger: 'blur', }]">
          <a-input v-model="form.constCode" placeholder="请输入常量编码" />
        </a-form-model-item>
        <a-form-model-item label="常量名称" prop="constName"
          :rules="[{ required: true, message: '请输入常量名称', trigger: 'blur', }]">
          <a-input v-model="form.constName" placeholder="请输入常量名称" />
        </a-form-model-item>
        <a-form-model-item label="常量数值" prop="constValue"
          :rules="[{ required: true, message: '请输入常量数值', trigger: 'blur', }]">
          <a-textarea  v-model="form.constValue"  :rows="8" placeholder="请输入常量数值" />
        </a-form-model-item>

        <a-form-model-item label="常量类别" prop="constType"
          :rules="[{ required: true, message: '请输入常量类别', trigger: 'blur', }]">
          <a-input v-model="form.constType" placeholder="请输入常量类别" />
        </a-form-model-item>
        <a-form-model-item label="常量类别编码" prop="constTypeCode"
          :rules="[{ required: true, message: '请输入常量类别编码', trigger: 'blur', }]">
          <a-input v-model="form.constTypeCode" placeholder="请输入常量类别编码" />
        </a-form-model-item>
        <a-form-model-item label="顺序" prop="constSeq" :rules="[{ required: true, message: '请输入顺序', trigger: 'blur', }]">
          <a-input v-model="form.constSeq" placeholder="请输入顺序" />
        </a-form-model-item>
        <a-form-model-item label="标签" prop="constFlag" :rules="[{ required: true, message: '请输入标签', trigger: 'blur', }]">
          <a-input v-model="form.constFlag" :max-length="2" placeholder="请输入标签" />
        </a-form-model-item>


      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    modalBool: Boolean,
    modalTitle: String,
    selectedRows: Array
  },
  data() {
    return {
      ModalText: 'Content of the modal',
      type: 'add',
      form: {
        constCode: "",// 	常量编码
        constName: '',// 常量类别
        constValue: '',// 常量数值
        constType: '',// 常量类别
        constTypeCode: '',// 常量类编码constSeq
        constSeq: '',// 顺序
        constFlag: '',// 标签
      }
    };
  },
  beforeCreate() {
    // this.form = this.$form.createForm(this, { name: 'modal_form' });
  },
  methods: {
    handleOk() {

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.type == 'add') {
            this.api.configparamadd(this.form).then((res) => {
              if (res.code !== 200) {
                this.$message.warning(res.info)
                return
              }
              this.$emit("okModalHandle")
            })
          }
          if (this.type == 'edit') {
            this.type = 'edit'
            this.api.configparamaupdate(this.form).then((res) => {
              if (res.code !== 200) {
                this.$message.warning(res.info)
                return
              }
              this.$emit("okModalHandle")
            })
          }

          // this.$emit("okModalHandle")
        } else {
          this.$message.warning("请先填写信息");
        }
      });

    },
    handleCancel() {
      this.$emit("cancelModalHandle")
    },
    edit(type, obj) {
      if (type == 'add') {
        this.type = 'add'
        this.form = {
          constCode: "",// 	常量编码
          constName: '',// 常量类别
          constValue: '',// 常量数值
          constType: '',// 常量类别
          constTypeCode: '',// 常量类编码constSeq
          constSeq: '',// 顺序
          constFlag: '',// 标签
        }
      }
      if (type == 'edit') {
        this.type = 'edit'
        this.form = obj
      }
    }

  },


};
</script>

<style lang="less" scoped></style>