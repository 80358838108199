<template>
  <div>
    <!-- 电商提现管理page页面 -->
    <a-card title="待审批事项" style="margin: 20px">
      <!-- 筛选区域 -->
      <div class="selectBox">
        <div class="item">
          <span>所属机构</span>
          <DepartmentChoose :department-code.sync="paramsData.departmentCode" @change="handleQuery"></DepartmentChoose>
        </div>
<!--        <div class="item">-->
<!--          <span>编号/账号</span>-->
<!--          <a-input v-model.trim="paramsData.code" placeholder="请输入编号/账号"/>-->
<!--        </div>-->
<!--        <div class="item">-->
<!--          <span>客户名称</span>-->
<!--          <a-input v-model.trim="paramsData.name" placeholder="请输入客户名称"/>-->
<!--        </div>-->


        <div class="item">
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </div>
      </div>

      <!-- 表格部分 -->
      <div class="tableBox">
        <!-- 表格 -->
        <div class="tableBody">
          <a-table size="small" :columns="columns" :data-source="data" :pagination="false">
           <span slot="mchLink" slot-scope="text, record">
              <router-link
                           :to="{ name: 'MchManage', query: { mchNo: record['电商编号'] } }" target="_self"
                           rel="opener">{{ record['电商编号'] }}</router-link>
            </span>
            <span slot="protocolLink" slot-scope="text, record">
              <router-link v-if="record['类型']==='协议'"
                           :to="{ name: 'PaymentProtocolManage', query: { mchNo: record['电商编号'] } }" target="_self"
                           rel="opener">{{ record['摘要'] }}</router-link>
              <span v-else>{{ record['摘要'] }}</span>
            </span>
            <span slot="sqLink" slot-scope="text, record">
              <router-link :to="{ name: 'Financingwhitelist', query: { accountNoSq: record['贷款人账号'] } }"
                           target="_self"
                           rel="opener">{{ record['贷款人'] }}</router-link>
            </span>
          </a-table>
        </div>
      </div>

    </a-card>
  </div>
</template>
<script>
import {message} from "ant-design-vue";
import {BizDict, BizDictCascade} from "@/utils/bizDict/kvMap";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";

const data = [];
export default {
  name: "CheckList",
  components: {DepartmentChoose},
  data() {
    const columns = [
      {
        title: "No",
        customRender: (text, record, index) => {
          return index + 1
        },
        align: "center",
        width: 80,
      },
      {
        title: "渠道",
        dataIndex: "渠道",
        align: "center",
        width: 60,
        ellipsis: true,
      },
      {
        title: "类型",
        dataIndex: "类型",
        align: "center",
        width: 60,
      },
      {
        title: "电商",
        dataIndex: "电商名称",
        align: "center",
      },
      {
        title: "电商编号",
        dataIndex: "电商编号",
        scopedSlots: {customRender: 'mchLink'},
        align: "center",
        width: 120,
      },
      {
        title: "账号",
        dataIndex: "账号",
        align: "center",
        width: 200,
      },
      {
        title: "贷款人",
        dataIndex: "贷款人",
        scopedSlots: {customRender: 'sqLink'},
        align: "center",
        width: 100,
      },
      {
        title: "摘要",
        dataIndex: "摘要",
        align: "center",
        scopedSlots: {customRender: 'protocolLink'},
      },
      {
        title: "创建时间",
        dataIndex: "创建时间",
        align: "center",
        width: 180,
      },
      {
        title: "更新时间",
        dataIndex: "更新时间",
        align: "center",
        width: 180,
      }
    ];
    return {
      // 机构
      departmentData: {},
      data,
      columns,
      selectedRowKeys: [],
      total: 0,
      // 筛选参数
      departmentId: undefined,
      paramsData: {
        departmentCode: undefined,
        // 电商编号
        code: "",
        // 电商名称
        name: "",

      }
    };
  },
  created() {
    this.busiDict = {...BizDict, ...BizDictCascade};
    // 获取机构列表
    // 获取表格
    this.departmentId = sessionStorage.getItem("companyId");
    this.paramsData.departmentCode = sessionStorage.getItem("companyCode");
    this.getTableData();
  },
  methods: {
    // 获取表格数据
    async getTableData() {
      const res = await this.api.getRzDetailsApi(
          {
            "sqlParame": {
              "depCode": this.paramsData.departmentCode,
              "code": this.paramsData.code,
              "name": this.paramsData.name
            },
            "key": "check_list"
          }
      );
      if (res.code == 200) {
        this.data = res.data;
      } else {
        return message.error(res.info);
      }
    },
    // 根据id找所属机构
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name;
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind);
          if (foundName) {
            return foundName;
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null;
    },
    // 查询
    handleQuery() {
      this.getTableData();
    },
    // 重置
    handleReset() {
      this.paramsData = {
        departmentCode: undefined,
        code: "",
        name: "",

      };
      this.getTableData();
    },

    // 首页
    Homepage() {
      this.getTableData()
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-card-body {
  padding: 24px 34px;
}

.selectBox {
  display: flex;
  flex-wrap: wrap;

  /deep/ .item {
    margin-bottom: 20px;
    margin-right: 20px;

    .ant-input {
      width: 200px;
      margin-left: 20px;
    }

    .ant-select {
      width: 200px;
      margin-left: 20px;
    }
  }
}

.tableBox {
  margin-bottom: 20px;

  .handleBtn {
    margin-bottom: 20px;
  }
}

/deep/ .paginationBox {
  // float: right;
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;

  // .ant-pagination-options {
  //   float: left;
  // }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}
</style>
