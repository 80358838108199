// 获取 contractDocument 数据，如果不存在则从接口中获取
async function getOrFetchContractDocument(api) {
    const storedData = sessionStorage.getItem('contractDocument');

    if (storedData) {
        return JSON.parse(storedData);
    } else {
        const info = await api.downLoadTemplateApi(["contract_document"]);

        if (info.code === 200) {
            const contractDocument = JSON.parse(info.data.contract_document.constValue);
            const fileMap = {};
            contractDocument.forEach(item => {
                fileMap[item.code] = item.file_no;
            });
            sessionStorage.setItem('contractDocument', JSON.stringify(fileMap));
            return fileMap;
        } else {
            console.error('Failed to get contractDocument data.');
            return {};
        }
    }
}

// 实用函数，用于下载合同文件
export async function contractDocTemp(type, api, message) {
    const contractDocument = await getOrFetchContractDocument.call(this, api);
// console.log(contractDocument,'contractDocument');
  if (contractDocument.hasOwnProperty(type)) {
      
        const fileNo = contractDocument[type];
        const res = await api.downloadImage(fileNo);

        if (res.code !== 200) {
            return message.error(res.info);
        }

        const link = document.createElement('a');
        link.href = res.data;
        link.target = '_blank';
        link.download = `template.${getFileExtension(res.data)}`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        console.error(`Type "${type}" not found in contractDocument.`);
    }
}

function getFileExtension(filename) {
    return filename.split('.').pop();
}

