<template>
  <div>
    <a-modal
      title="设置密码"
      :visible="passBool"
      @ok="handleOk"
      @cancel="handleCancel"
    >
       <a-form 
        :form="form"
        :label-col="{span: 4, offset: 1}"
        :wrapperCol ="{span: 18, offset: 1}"
        :colon = "false"
        labelAlign = "left"
       >
          <a-form-item
            label="新密码"
          >
            <a-input
              type = "password"
              v-decorator="[
                'password',
                { 
                  rules: [{ required: true, message: '请输入新密码' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item
            label="密码确认"
          >
            <a-input
              type = "password"
              v-decorator="[
                'name',
                { 
                  validateTrigger: 'blur',
                  rules: [
                    { required: true, message: '请输入密码' },
                    { validator: checkConfirm }
                  ],
                },
              ]"
            />
          </a-form-item>
       </a-form>
    </a-modal>
  </div>
</template>
<script>
export default {
  props:{
    passBool:Boolean,
  },
  data() {
    return {
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'modal_form' });
  },
  methods: {
    handleOk() {
      this.$emit("okPassHandle")
    },
    handleCancel() {
      this.$emit("cancelPassHandle")
    },
    checkConfirm  (rule, value, callback) {
      if (value && value !== this.form.getFieldValue('password')) {
        callback('两次输入的密码不匹配!');
      } else {
        callback();
      }
    },
  },
};
</script>

<style lang="less" scoped>

</style>