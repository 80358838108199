<template>
  <div class="uploadImage">
    <img v-if="imageUrl" @load="onload" :src="imageUrl" alt="" />
    <div v-if="showMask" class="Overlay" @click="preventUpload">
      <div class="Preview" @click.stop="Preview"><a-icon type="eye" /></div>
      <div v-if="showEditicon" style="margin-left: 15px" class="showEdit" @click="Editimg">
        <a-icon type="edit" />
      </div>
      <div v-if="showdelete" class="delete" style="margin-left: 15px" @click.stop="removeimg">
        <a-icon type="delete" />
      </div>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    showEditicon: {
      type: Boolean,
      default: false,
    },
    showMask: {
      type: Boolean,
      default: true,
    },
    showdelete: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      imageUrl: "",
      previewVisible: false,
      fileNo: "",
      file_path: "",
      channel_file_no: "",
      file_index: "",
      previewImage: "",
      filePath: "",
      type: "",
    };
  },
  mounted() { },
  methods: {
    setImageUrl(fileNo) {
      // console.log('up')
      this.api.downloadImage(fileNo).then((res) => {
        if (res.code == 200) {
          this.imageUrl = res.data;
          // this.$message.error(res.info)
        }
      });
    },
    handleChangeUpload(info, type, img) {
      this.type = type;
      const { file } = info;
      if (file.status === "done") {
        // 文件上传成功
        // console.log('文件上传成功', file.response)
        let res = file.response;
        if (res.code !== 200) {
          this.$message.error(res.info);
          this.$emit("Uploaderror", false);
          return;

        }
        // 将识别到的数据给到父组件回显
        this.$emit('completedUpload', res)
        // 判断数组还是对象
        if (res.data.constructor == Array) {
          console.log('array');
          this.fileNo = res.data[0].file_no;
          this.filePath = res.data[0].filePath;
          this.channel_file_no = res.data[0].channel_file_no;
          //处理pdf和word上传 传了第三个参数就不用走下载的接口
          if (img) {
            this.imageUrl = img
            return
          }
          this.api.downloadImage(res.data[0].file_no).then((res) => {
            if (res.code !== 200) {
              this.$message.error(res.info);
            }
            this.imageUrl = res.data;
          });
        }

        if (res.data.constructor == Object) {
          // console.log('obj');
          this.fileNo = res.data.file_no;
          if (res.data.channel_file_no) {
            this.channel_file_no = res.data.channel_file_no;
          }
          if (res.data.filePath) {
            this.filePath = res.data.filePath;
          }
          if (img) {
            this.imageUrl = img
            return
          }

          this.api.downloadImage(res.data.file_no).then((res) => {
            if (res.code !== 200) {
              this.$message.error(res.info);
            }
            this.imageUrl = res.data;
          });
        }
      } else if (file.status === "error") {
        // 文件上传失败
        // this.$message.error();
        this.$emit("Uploaderror", false);
      }
      // this.$emit('closeLoading');

    },
    preventUpload(event) {
      event.stopPropagation(); // 阻止事件传播，不触发文件上传
    },
    // 图片修改
    Editimg() {
      this.$emit("Editimg", true);
    },

    onload() {
      this.$emit("onloadsuccsse", this.imageUrl);
      this.$emit("fileData", this.fileNo);
      this.$emit("fileNo", this.fileNo);
      this.$emit("fileindex", this.file_index);
      this.$emit("channelfileno", this.channel_file_no);
      this.$emit("filePath", this.filePath);
      this.$emit("Uploaderror", false);
      // 如果是修改图片就调用修改图片接口
      if (this.type == "Edit") {
        this.$emit("CallEditimgApi");
      }
    },
    // 人脸识别回显
    HumanfaceEcho(file_no) {
      this.fileNo = file_no;
      this.api.downloadImage(file_no).then((res) => {
        if (res.code !== 200) {
          this.$message.error(res.info);
        }
        this.imageUrl = res.data;
      });
    },

    handleCancel() {
      this.previewVisible = false;
    },
    // 删除
    removeimg() {
      this.$emit("onloadsuccsse", "");
      this.$emit("fileData", "");
      this.$emit("fileNo", "");
      this.$emit("fileindex", "");
      this.$emit("channelfileno", "");
      this.imageUrl = "";
      if (
        this.$route.path == "/cust/personage/addCust" ||
        this.$route.path == "/cust/enterprise/addEnterprise"
      ) {
        if (this.$route.query.type == "edit") {
          this.$emit("deleteimg");
        }
      }
    },
    // 预览
    Preview() {
      this.previewImage = this.imageUrl;
      this.previewVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.uploadImage {
  width: 100%;
  height: 100%;
  position: relative;

  .Overlay {
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    position: absolute;

    display: none;

    .marsk {
      width: 100px;
      height: 100px;
      background-color: pink;
      z-index: 99999;
      display: none;
    }
  }

  &:hover .Overlay {
    width: 100%;
    height: 100%;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    position: absolute;
    background-color: #2c2522;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.8;
    transition: all 0.3s;

    /deep/ .anticon svg {
      font-size: 30px;
      color: #fff;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
