<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>驾驶舱/融资电商</span>
          </div>
          <div class="right">
            <!-- 请选择机构 -->
            <!-- 所属机构 -->
            <a-tree-select v-model="departmentId" style="width: 200px"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" @change="departmentIdchange"
              :tree-data="treeData" placeholder="请选择所属机构" tree-default-expand-all>
            </a-tree-select>
            <!--融资电商-->
            <a-select class="dianshang" :dropdownMatchSelectWidth="false" placeholder="请选择电商" @change="mchNochange"
              :filter-option="filterOption" :show-search="true" v-model.trim="mchId" style="min-width: 200px;">
              <a-select-option v-for="item in mchNolist" :key="item.value" :value="item.id" :item="item">

                <span>
                  <a-tag color="purple">融</a-tag>{{ item.value }}-{{ item.label }}<br />
                  <a-tag color="blue" v-if="item.fatherName">控</a-tag>
                  {{ item.fatherName || "" }}
                </span>
              </a-select-option>
            </a-select>
          </div>
        </div>
      </template>
      <!-- -->
      <div class="trill">
        <div class="trilllist">
          <a-row type="flex">
            <a-col :span="8">
              <a-col :span="8">
                累计融资:
              </a-col>
              <a-col :span="6">
                <div style="float: right;">
                  <Balance :amount="detailsInfo['融资总额']"></Balance>
                </div>
              </a-col>
            </a-col>
            <a-col :span="8">
              <a-col :span="8">
                电商活期:
              </a-col>
              <a-col :span="12">
                <WalletInfo :accountClass="'0'" :balance="currentBalance" :accountName="selectMchInfo.accountName"
                  :accountNo="selectMchInfo.accountNoA1" :showAccountNo="false">
                </WalletInfo>
              </a-col>
            </a-col>
          </a-row>
          <a-row type="flex">
            <a-col :span="8">
              <a-col :span="8">
                贷款余额:
              </a-col>
              <a-col :span="6">
                <div style="float: right;">
                  <Balance :amount="detailsInfo['融资余额']"></Balance>

                </div>
              </a-col>
            </a-col>
            <a-col :span="8">
              <a-col :span="8">
                冻结回款:
              </a-col>
              <a-col :span="12">
                <WalletInfo :accountClass="'6'" :balance="A2Balance" :accountName="selectMchInfo.accountName"
                  :accountNo="selectMchInfo.accountNoA1" :showAccountNo="false"></WalletInfo>
              </a-col>
            </a-col>
          </a-row>
          <a-row type="flex">
            <a-col :span="8" style="display: flex; align-items: center;">
              <a-col :span="8">
                可融资金额:
              </a-col>
              <a-col :span="6">
                <div style="float: right;">
                  <Balance :amount="detailsInfo['可融资金额']"></Balance>
                </div>
              </a-col>
              <a-col :span="6" style="display: flex;">
                <a-button type="link" @click="genOrder">追加订单</a-button>
                <a-button type="link" @click="toRz">去融资</a-button>
              </a-col>
            </a-col>
            <!-- <a-col :span="8">
              <a-col :span="8">
                贷款余额:
              </a-col>
              <a-col :span="12" style="display: flex;">
                <WalletInfo :accountClass="'7'" :balance="A3LoanBalance"></WalletInfo>
              </a-col>
            </a-col> -->
          </a-row>
        </div>
        <div class="trilllist" style="margin-top: 30px;">
          <a-row type="flex">
            <a-col :span="8">
              <a-col :span="8">
                今日融资:
              </a-col>
              <a-col :span="6">
                <div style="float: right;">
                  <Balance :amount="detailsInfo['今日融资金额']
                    "></Balance>
                </div>

              </a-col>
            </a-col>
            <a-col :span="8">
              <a-col :span="8">
                昨日融资:
              </a-col>
              <a-col :span="6">
                <div style="float: right;">
                  <Balance :amount="detailsInfo['昨日融资金额']
                    "></Balance>
                </div>

              </a-col>
            </a-col>
            <a-col :span="8">
              <a-col :span="8">
                近7日融资:
              </a-col>
              <a-col :span="6">
                <div style="float: right;">
                  <Balance :amount="detailsInfo['7天融资金额']"></Balance>
                </div>
              </a-col>
            </a-col>
          </a-row>
          <a-row type="flex">
            <a-col :span="8">
              <a-col :span="8">
                今日还款:
              </a-col>
              <a-col :span="6">
                <div style="float: right;">
                  <Balance :amount="detailsInfo['今日还本']"></Balance>
                </div>
              </a-col>
            </a-col>
            <a-col :span="8">
              <a-col :span="8">
                昨日还款:
              </a-col>
              <a-col :span="6">
                <div style="float: right;">
                  <Balance :amount="detailsInfo['昨日还本']"></Balance>
                </div>

              </a-col>
            </a-col>
            <a-col :span="8">
              <a-col :span="8">
                近7日还款:
              </a-col>
              <a-col :span="6">
                <div style="float: right;">
                  <Balance :amount="detailsInfo['7天还本']"></Balance>
                </div>

              </a-col>
            </a-col>
          </a-row>
          <a-row type="flex">
            <a-col :span="8">
              <a-col :span="8">
                今日应还:
              </a-col>
              <a-col :span="6">
                <div style="float: right;">
                  <Balance :amount="detailsInfo['今日应还']"></Balance>
                </div>

              </a-col>
            </a-col>
            <a-col :span="8">
              <a-col :span="8">
                明日应还:
              </a-col>
              <a-col :span="6">
                <div style="float: right;">
                  <Balance :amount="detailsInfo['昨日应还']"></Balance>
                </div>

              </a-col>
            </a-col>
            <a-col :span="8">
              <a-col :span="8">
                7日内应还:
              </a-col>
              <a-col :span="6">
                <div style="float: right;">
                  <Balance :amount="detailsInfo['7日内应还']"></Balance>
                </div>

              </a-col>
            </a-col>
          </a-row>
          <a-row type="flex">
            <a-col :span="8">

              <a-col :span="8">
                剩余应还:
              </a-col>
              <a-col :span="6">
                <div style="float: right;">
                  <Balance></Balance>
                </div>
              </a-col>
            </a-col>
          </a-row>
        </div>

      </div>
      <div class="Toleadinto">
        每日清单情况
      </div>
      <div class="searchbox">
        <div class="lefttime">
          <a-range-picker v-model.trim="searchtime" valueFormat="YYYY-MM-DD" @change="onChange" />
          <a-radio-group v-model="datater" @change="dataonChange">
            <a-radio value="1">
              近七天
            </a-radio>
            <a-radio value="2">
              当月
            </a-radio>
            <a-radio value="3">
              上月
            </a-radio>
          </a-radio-group>
        </div>
        <div class="right">
          <a-button type="link" @click="downloadRzMchLive">
            明细导出
          </a-button>
        </div>
      </div>
      <div class="tablebox">
        <!-- 表格 -->
        <div class="tableBody">
          <a-table ref="table" :rowKey="(record, index) => `${record.id}`" :columns="columns" :data-source="data"
            size="small" :pagination="false" :scroll="{ y: 200 }">
            <!-- <template slot="Controls" slot-scope="text, record">
              <div class="Controlsbox">
              </div>
            </template> -->
          </a-table>
        </div>
      </div>

    </a-card>

  </div>
</template>

<script>
import { Bus } from '@/utils/bus.js'
import Balance from '@/view/components/Balance'
import WalletInfo from "@/view/components/WalletInfo.vue";
import dayjs from 'dayjs';

import { utils, writeFile } from 'xlsx';

export default {
  name: 'financialPage',
  components: {
    Balance,
    WalletInfo
  },
  data() {
    return {
      // 选中电商id
      mchId: undefined,
      // 选中电商信息
      selectMchInfo: {},
      // 获取概况信息
      detailsInfo: {},
      searchtime: [],
      datater: '2',
      // 可控电商
      FatherMcData: {},
      A2Balance: 0,
      A3LoanBalance: 0,
      // 担保人账号
      accountNoDb: undefined,
      accountNameDb: undefined,
      // 渠道
      channelNo: '',
      appNo: '',
      // 企业活期余额
      currentBalance: 0,
      marginBalance: 0,//保证金余额
      marginUsageRate: 0,//保证金使用率
      surplusLoanable: 0,//剩余可贷
      ecommerceLoanBalance: 0,//贷款余额
      coreCompanyId: '',
      value: "1",
      value1: "",
      treeData: [],
      fileList: [],
      binarylist: [],
      Orderentryvisible: false,
      institutiontrees: [],
      title: "原始订单导入",
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      total: 0, // 数量
      time: [],
      mchNolist: [], // 电商列表
      departmentId: undefined,
      columns: [
        {
          title: "订单日期",
          dataIndex: "订单日期",
          align: "center",
          ellipsis: true,
        },
        {
          title: "预计清算日",
          dataIndex: "预计清算日",
          align: "center",
          ellipsis: true,
        },
        {
          title: "清算状态",
          dataIndex: "清算状态",
          align: "center",
        },
        {
          title: "融资金额",
          dataIndex: "贷款金额",
          align: "center",
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          },
        },
        {
          title: "应收利息",
          dataIndex: "应收利息",
          align: "center",
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          },
        },
        {
          title: "已还本金",
          dataIndex: "归还本金",
          align: "center",
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          },
        },
        {
          title: "已还利息",
          dataIndex: "归还利息",
          align: "center",
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          },
        },
        {
          title: "剩余应还本息(不含手续费)",
          dataIndex: "归还利息",
          align: "center",
          customRender: (text, row) => {
            let balance = row['应收利息'] + row['贷款金额'] - row['归还本金'] - row['归还利息']
            if (balance < 0.01) balance = 0;
            return <Balance amount={balance.toFixed(2)} dcflag={balance > 0 ? "c" : ""}></Balance>
          },
        }
        // {
        //   title: "手续费",
        //   dataIndex: "手续费",
        //   align: "center",
        //   customRender: (text) => {
        //     return <Balance amount={text}></Balance>
        //   },
        //
        // },

      ],
      data: [],
      arrNum: undefined,
    };
  },
  created() {
    this.getCustList();
    // table表格
    this.departmentId = this.$session.getItem("companyId");
    // 获取融资电商
    this.getmchshopsApi();
    // 默认当月时间
    this.dataonChange()
  },
  methods: {
    filterOption(input, option) {
      if (input.length < 2) return false;
      const children = option.componentOptions.children[0].children.map(m => m.text).filter(m => m)

      return (
        children.find(m => m.toLowerCase().indexOf(input.toLowerCase()) >= 0)
      );
    },
    // 获取选中电商活期/冻结余额
    async getfindAuthoShop() {
      const res = await this.api.accountqueryBalancedApi({
        channel_no: this.selectMchInfo.channelNo,
        account_no: this.selectMchInfo.accountNoA1,
        relation_acct: '1'
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      res.data.detail_list.forEach(item => {
        if (item.account_class === '0') {
          // 活期余额
          this.currentBalance = item.cur_balance
        } else if (item.account_class === '6') {
          // 冻结余额
          this.A2Balance = item.cur_balance
        } else if (item.account_class === '7') {
          // 贷款余额
          this.A3LoanBalance = item.cur_balance
        }
      })

    },


    // 获取所属机构下拉框数据
    async getCustList() {
      let data = {
        username: this.$session.getItem("username"),
      };
      const res = await this.api.agencyList(data);
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      let trees = [];
      this.institutiontrees.push(res.data);
      trees.push(res.data);

      this.treeData = this.getResettingTrees(trees)
        ? this.getResettingTrees(trees)
        : [];

    },

    // 进行循环递归处理数据
    getResettingTrees(tree) {
      // 定义子级数组
      if (tree.length == 0) return [];
      let arr = [];
      tree.forEach((item) => {
        // 递归循环将每一项都处理城组件需要的的格式 [ {title:"" children:[{title:""}]  }]
        arr.push({
          title: item.coreDepartment.name,
          value: item.coreDepartment.id,
          key: item.coreDepartment.id,
          children:
            item.children && item.children.length > 0
              ? this.getResettingTrees(item.children)
              : [],
        });
      });

      // 处理好的数组返回出去
      return arr;
    },
    onCopy() {
      this.$message.info("已复制");
    },
    onCopyErr() {
      this.$message.info("复制失败");
    },
    // 所选机构触发
    departmentIdchange() {
      //机构改变
      // this.accountNameDb = undefined
      // this.accountNoDb = ''
      // this.currentBalance = 0
      // this.marginBalance = 0
      // this.marginUsageRate = 0
      // this.surplusLoanable = 0
      // this.ecommerceLoanBalance = 0
      // this.data = []

      // 获取融资电商账户
      this.getmchshopsApi();
    },

    // 改变电商列表触发
    mchNochange(event, option) {
      this.selectMchInfo = option.data.attrs.item
      // 获取活期余额(冻结余额默认取选中电商的，如果有可控电商，后面会覆盖)
      this.getfindAuthoShop()
      if (this.selectMchInfo.fatherName) {
        //获取可控电商
        this.getFatherMc()
      }
      // 获取概况信息
      this.getDetailsInfo()
      // 获取资金信息
      this.getDetailsList()
    },
    // 获取可控电商
    async getFatherMc() {
      let data = {
        pageNo: 1,
        pageSize: 10
      }
      data.obj = {
        id: this.selectMchInfo.fatherId,
        mchName: this.selectMchInfo.fatherName
      }
      let res = await this.api.getEcListApi(data)
      if (res.code == 200) {
        this.FatherMcData = res.data.records[0]
      }
      this.getFatherMcBalance()
    },
    // 获取可空电商A2余额
    async getFatherMcBalance() {
      const res = await this.api.accountqueryBalancedApi({
        channel_no: this.FatherMcData.channelNo,
        account_no: this.FatherMcData.accountNoA2,

      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      res.data.detail_list.forEach(item => {
        if (item.account_class === '6') {
          // 冻结余额
          this.A2Balance = item.cur_balance
        }
      })
    },
    //获取概况信息
    async getDetailsInfo() {
      let data = {
        sqlParame: {
          // depatCode: this.departmentId,
          mchNo: this.selectMchInfo.mchNo,
        },
        key: 'financing_e_commerce_2'
      }
      let res = await this.api.getRzDetailsApi(data)
      if (res.code == 200) {
        this.detailsInfo = res.data[0]

      }
    },
    //获取资金列表信息
    async getDetailsList() {
      if (!this.selectMchInfo.mchNo) {
        return this.$message.warning('请先选择电商')
      }
      let data = {
        sqlParame: {
          mchNo: this.selectMchInfo.mchNo,
          starDate: this.searchtime[0],
          endDate: this.searchtime[1]
        },
        key: "financing_e_commerce_1"
      }
      let res = await this.api.getRzDetailsApi(data)
      if (res.code == 200) {
        this.data = res.data
      }
    },
    onChange() {
      // console.log(this.searchtime);
      this.getDetailsList()
    },
    dataonChange() {
      if (this.datater === '1') {
        // 近七天
        let startTime = dayjs().format('YYYY-MM-DD')
        let endTime = dayjs(startTime).subtract(7, 'day').format('YYYY-MM-DD')
        this.$set(this.searchtime, 0, endTime)
        this.$set(this.searchtime, 1, startTime)

      } else if (this.datater === '2') {
        // 当月
        let startTime = dayjs().format('YYYY-MM-DD')
        let endTime = dayjs(startTime).startOf('month').format('YYYY-MM-DD')
        this.$set(this.searchtime, 0, endTime)
        this.$set(this.searchtime, 1, startTime)
      } else if (this.datater === '3') {
        // 上月
        let startTime = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
        let endTime = dayjs(startTime).endOf('month').format('YYYY-MM-DD')
        this.$set(this.searchtime, 0, startTime)
        this.$set(this.searchtime, 1, endTime)
      }
      this.onChange()

    },
    // 试算更新
    async genOrder() {
      if (!this.selectMchInfo.mchNo) {
        return this.$message.warning('请先选择电商')
      }
      let today = dayjs().format("YYYY-MM-DD")
      const res = await this.api.collectOrdersGenerateApi({
        orderDate: today,
        mchNo: this.selectMchInfo.mchNo,
        mchName: this.selectMchInfo.mchName,
      })
      if (res.code !== 200) {
        this.$message.error(res.info)
      } else {
        this.$message.success('订单已追加')
      }
    },
    toRz() {
      // 融资
      if (!this.selectMchInfo.mchNo) {
        return this.$message.warning('请先选择电商')
      }
      let obj = {
        url: '/orders/PaymentOrders/AddPaymentOrders',
        mchNo: this.selectMchInfo.mchNo
      }
      // 存到vuex
      this.$store.commit('saveOrderInfo', obj)
      Bus.$emit('send', obj)
    },
    // 明细导出
    // async Detailexport() {
    //   if (!this.selectMchInfo.mchNo) {
    //     return this.$message.warning('请先选择电商')
    //   }
    //   let title = this.selectMchInfo.accountName + '每日资金变动明细'
    //   let arr = []
    //   this.data.forEach(x => {
    //     let obj = {
    //       orderDate: x['订单日期'],
    //       orderStatus: x['清算状态'],
    //       rzAmount: x['贷款金额'].toString(),
    //       // rePrincipal: x['归金'],
    //       // reRate: x['归还利息'],
    //       serviceFee: x['手续费'].toString()
    //     }
    //     arr.push(obj)
    //   })

    //   let data = {
    //     title: title,
    //     head: {
    //       orderDate: '订单日期',
    //       orderStatus: '清算状态',
    //       rzAmount: '融资金额',
    //       // rePrincipal: '归还本金',
    //       // reRate: '归还利息',
    //       serviceFee: '手续费'
    //     },
    //     array: arr
    //   }
    //   let res = await this.api.DetailexportApi(data)
    //   this.$uploadfile.ExcelDownload(data.title, res)
    // },
    // 明细导出
    downloadRzMchLive() {
      let wb = utils.book_new()
      // 明细导出
      let data1 = [
        ["电商编号", "电商名称", "清算状态", "订单日期", "融资金额"]
      ]
      for (let mch of this.data) {
        let row = [
          mch['mch_no'],
          mch['mch_name'],
          mch['清算状态'],
          mch['订单日期'],
          mch['贷款金额'],
        ]
        data1.push(row)
      }
      let ws1 = utils.aoa_to_sheet(data1)
      ws1["!cols"] = [
        { wch: 10 }, { wch: 24 }, { wch: 18 }, { wch: 12 }, { wch: 16 },
      ]
      utils.book_append_sheet(wb, ws1, this.selectMchInfo.mchName + '-明细')
      writeFile(wb, `融资电商-${this.selectMchInfo.mchName}.xlsx`);
    },

    // 获取融资电商列表
    async getmchshopsApi() {

      this.mchNolist = [];
      let data = {
        pageNo: 1,
        pageSize: 10000,
        obj: {
          mchType: '1',
          departmentId: this.departmentId,

        }
      }
      const res = await this.api.getEcListApi(data);
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      // mchNolist
      this.mchNolist = res.data.records.map(x => Object.assign(x, {
        label: x.mchName,
        value: x.mchNo
      }))
      // console.log(this.mchNolist);

    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-card-body {
  padding-left: 0px;
  padding-right: 0px;
}

.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

.trill {
  padding-top: 20px;
  width: 100%;
  padding: 0 44px;
  border-bottom: 1px solid #d7d7d7;
  height: 340px;

  .trilllist {
    width: 100%;

    .ant-row-flex {
      margin-bottom: 10px;
    }

  }

  .footertishi {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    color: #969696;
  }
}

.formbox {
  display: flex;
  flex-wrap: wrap;
  padding-top: 35px;
  display: flex;
  padding-left: 30px;
  align-items: center;

  button {
    margin-bottom: 20px;
  }

  .inputbox {
    width: 260px;
    margin-right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    input {
      width: 180px;
    }
  }

  button {
    margin-bottom: 20px;
  }

  .reset {
    width: 64px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}



.tablebox {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  padding: 0 32px;
}

.paginationBox {
  display: flex;
  justify-content: flex-end;

  padding: 0 32px;
  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}

/deep/ .ant-card-head-title {
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .dianshang {
      margin-left: 50px;
      width: 260px;
    }
  }
}

.Toleadinto {
  padding: 30px 30px 0 30px;
}

.searchbox {
  min-height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 10px;

  .lefttime {
    .ant-calendar-picker {
      margin-left: 20px;
      margin-right: 20px;
      width: 200px;
      border-radius: 6px;
      height: 24px !important;
    }
  }


}
</style>
