import axios from '@/utils/axios';
import urlConfig from '@/utils/urlConfig';


export default {

  /**
   *  五级行政区划级联查询
   * @param {*} data 
   * @returns 
   */

  fiveCascadeQuery: (data) => axios.apiPost(urlConfig.busiURL + `/scfs/communal/data/fiveCascadeQuery`, data),

};
 