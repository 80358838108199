<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>汇总订单管理</span> <a-icon type="right" />
            <span style="font-weight: 500">清算还款</span>
          </div>
        </div>
      </template>
      <template slot="extra">
        <a-button @click="toBack">
          返回
        </a-button>
      </template>
      <!-- 原始订单清算还款 -->
      <div class="viewbox">
        <!-- 汇总订单信息 -->
        <div class="Basicinformation">
          <h2>汇总订单信息</h2>
          <div class="list">
            <div class="item">
              <div class="row">汇总订单编号</div>
              <div class="row">订单状态</div>
              <div class="row">清算状态</div>
              <div class="row">更新人</div>
              <div class="row">预计退款总额（¥）</div>
              <div class="row">实际退款总额（¥）</div>
              <div class="row">放款总额（累计融资金额）（¥）</div>
              <div class="row">累计已补足差额（¥）</div>
            </div>
            <div class="item">
              <div class="row">
                {{ ordersdetails?.busiCollectOrders?.orderNo ? ordersdetails?.busiCollectOrders?.orderNo :
                  "0.00" }}
              </div>
              <div class="row">
                {{ ordersdetails.busiCollectOrders?.orderStatus ?
                  customRender(ordersdetails.busiCollectOrders.orderStatus)
                  : '----' }}
              </div>
              <div class="row">
                {{ ordersdetails.busiCollectOrders?.settleStatus ?
                  settleStatus(ordersdetails.busiCollectOrders?.settleStatus)
                  : '----' }}
              </div>
              <div class="row">
                {{ ordersdetails.busiCollectOrders?.updater ? ordersdetails.busiCollectOrders.updater : '----' }}
              </div>
              <div class="row">
                <Balance :amount="ordersdetails.busiCollectOrders?.orderAmount2"></Balance>
              </div>

              <div class="row">

                <Balance :amount="ordersdetails.busiCollectOrders?.orderAmount4"></Balance>

              </div>
              <div class="row">

                <Balance :amount="ordersdetails.busiCollectOrders?.loanAmountSum"></Balance>

              </div>
              <div class="row">
                <Balance :amount="ordersdetails.busiCollectOrders?.supDiffAmount"></Balance>
              </div>
            </div>
            <div class="item">
              <div class="row">汇总订单生成时间</div>
              <div class="row">订单总额（¥）</div>
              <div class="row">清算时间</div>
              <div class="row">更新时间</div>
              <div class="row">已发货未收款总额（¥）</div>
              <div class="row">预计应收款总额（¥）</div>
              <div class="row">订单融资额度（可融资额度）（¥）</div>
            </div>
            <div class="item">
              <div class="row">
                {{ ordersdetails.busiCollectOrders?.createTime ? ordersdetails.busiCollectOrders.createTime : '----'
                }}
              </div>
              <div class="row">
                <Balance :amount="ordersdetails.busiCollectOrders?.orderAmount"></Balance>
              </div>
              <div class="row">
                {{ ordersdetails.busiCollectOrders?.settleTime ? ordersdetails.busiCollectOrders.settleTime : '----'
                }}
              </div>
              <div class="row">
                {{ ordersdetails.busiCollectOrders?.updateTime ? ordersdetails.busiCollectOrders.updateTime : '----'
                }}
              </div>
              <div class="row">

                <Balance :amount="ordersdetails.busiCollectOrders?.orderAmount1"></Balance>

              </div>
              <div class="row">

                <Balance :amount="ordersdetails.busiCollectOrders?.orderAmount3"></Balance>

              </div>
              <div class="row">

                <Balance :amount="ordersdetails.busiCollectOrders?.creditLimit"></Balance>

              </div>
            </div>
          </div>
        </div>
        <!-- 贷款信息 -->

        <div class="Loaninformation">
          <h2>贷款信息</h2>
          <div class="list">
            <div class="head">
              <span>贷款产品编号</span>
              <span>贷款合同编号</span>
              <span>申请人账号</span>
              <span>申请人户名</span>
              <span>贷款账号（借据编号）</span>
              <span>贷款账号序号</span>
              <span>放款金额（元）</span>
              <span>放款时间</span>
              <span>还款状态</span>
              <span>还款本金</span>
              <span>还款利息</span>
              <span>还款时间</span>
            </div>
            <div class="body" v-for="(item, ind) in ordersdetails.busiCollectOrdersCreditList" :key="ind">
              <span>{{ item.productId }}</span>
              <span>{{ item.contractNo }}</span>
              <span>{{ item.accountNo }}</span>
              <span>{{ item.accountName }}</span>
              <span>{{ item.loanacctno }}</span>
              <span>{{ item.acctseqno }}</span>
              <span>
                <Balance :amount="item.loanAmount"></Balance>

              </span>
              <span>{{ item.loanDate }}</span>

              <span><RepayStatus :text="item.repayStatus"></RepayStatus></span>
              <span>
                <Balance :amount="item.repayAmountBj"></Balance>

              </span>
              <span>
                <Balance :amount="item.repayAmountLx"></Balance>

              </span>
              <span>{{ item.repayTime }}</span>
            </div>
          </div>
        </div>


        <!-- 服务费信息 -->

        <div class="Loaninformation">
          <h2>服务费信息</h2>
          <div class="list">
            <div class="head">
              <span>NO</span>
              <span>服务费项目编号</span>
              <span>服务费项目名称</span>
              <span>服务费账号</span>
              <span>服务费户名</span>
              <span>贷款账号（借据编号）</span>
              <span>服务费金额（元）</span>

            </div>
            <div class="body" v-for="(item, ind) in ordersdetails.busiCollectOrdersFeeList" :key="ind">
              <span>{{ ind + 1 }}</span>
              <span>{{ item.projectNo }}</span>
              <span>{{ item.projectName }}</span>
              <span>{{ item.feeAccountNo }}</span>
              <span>{{ item.feeAccountName }}</span>
              <span>{{ item.loanacctno }}</span>
              <span>
                <Balance :amount="item.feeAmount"></Balance>
              </span>
            </div>
          </div>
        </div>
        <!-- 可控电商 -->
        <div class="Controllede-commerce">
          <div class="item">

            <div class="left">
              <h2>回款电商</h2>

              <P><span>户名:</span><span> {{ ordersdetails.mchLiveInfoKK?.accountName ?
                ordersdetails?.mchLiveInfoKK?.accountName : '----'
              }}</span></P>

              <!-- 0 活期 -->
              <P><span>活期账号:</span><span>{{
                ordersdetails.mchLiveInfoKK?.accountNoA1

              }}</span></P>

              <!-- 6 冻结 -->
              <P><span>冻结账号:</span><span>{{ ordersdetails.mchLiveInfoKK?.accountNoA2
              }}</span></P>
            </div>
            <div class="right">
              <div class="leftbox">
                <h3>活期余额（元）</h3>

                <h2>
                  <Balance
                    :amount="info[0].detail_list?.find(function (x) { return x.account_class === '0' })?.avail_balance">
                  </Balance>
                </h2>
              </div>
              <span></span>
              <div class="rightbox">
                <h3>冻结余额（元）</h3>
                <h2>
                  <Balance
                    :amount="info[0].detail_list?.find(function (x) { return x.account_class === '6' })?.avail_balance">
                  </Balance>
                </h2>
              </div>
            </div>

          </div>
          <div class="item">

            <div class="left">
              <h2>融资电商</h2>
              <P><span>户名:</span><span> {{ ordersdetails?.mchLiveInfoRZ?.accountName ?
                ordersdetails?.mchLiveInfoRZ?.accountName : '----'
              }}</span></P>
              <P><span>活期账号:</span><span>{{
                ordersdetails.mchLiveInfoRZ?.accountNoA1
              }}</span></P>
              <P><span>冻结账号:</span><span>{{ ordersdetails.mchLiveInfoRZ?.accountNoA2
              }}</span></P>
            </div>
            <div class="right">
              <div class="leftbox">
                <h3>活期余额（元）</h3>
                <h2>
                  <Balance
                    :amount="info[1].detail_list?.find(function (x) { return x.account_class === '0' })?.avail_balance">
                  </Balance>
                </h2>
              </div>
              <span></span>
              <div class="rightbox">
                <h3>冻结余额（元）</h3>
                <h2>
                  <Balance
                    :amount="info[1].detail_list?.find(function (x) { return x.account_class === '6' })?.avail_balance">
                  </Balance>
                </h2>
              </div>
            </div>

          </div>

        </div>


        <!-- 金额展示 -->
        <div class="Amountdisplay">
          <h2>金额展示</h2>
          <div class="context">
            <div class="left">
              <div><span>累计已还本金（元）：</span> <span>
                  <Balance :amount="ordersdetails.relatedAmount?.order_amount_ljyhbj"></Balance>
                </span></div>
              <div><span>累计已还利息（元）：</span> <span>
                  <Balance :amount="ordersdetails.relatedAmount?.order_amount_ljyhlx"></Balance>

                </span></div>
              <div><span>累计已补足差额（元）：</span> <span>
                  <Balance :amount="ordersdetails.relatedAmount?.order_amount_ljybz"></Balance>

                </span></div>
            </div>
            <div class="right">
              <div><span>预计应收金额（元）：</span> <span>
                  <Balance :amount="ordersdetails.relatedAmount?.order_amount_yjys"></Balance>

<!--                <Balance :amount="lineAmount"></Balance>-->
                </span></div>
              <div><span>服务费（元）：</span> <span>
                  <Balance :amount="ordersdetails.relatedAmount?.order_amount_yjyf_fee"></Balance>

                </span></div>
              <div><span>应还本金（元）：</span> <span>
                  <Balance :amount="ordersdetails.relatedAmount?.order_amount_yjyhbj"></Balance>

                </span></div>
              <div><span>应还利息（元）：</span> <span>
                  <Balance :amount="ordersdetails.relatedAmount?.order_amount_yjyhlx"></Balance>

                </span></div>
              <div><span>{{ ordersdetails.relatedAmount?.order_amount_ce >=0 ? "尾款（元）":"待补足（元）"}}:</span> <span>
                  <Balance :amount="ordersdetails.relatedAmount?.order_amount_ce"></Balance>
                </span></div>
<!--              <a-button v-if="ordersdetails.relatedAmount?.order_amount_ce > info[0]?.detail_list[1]?.avail_balance"-->
              <a-button v-if="ordersdetails.relatedAmount?.order_amount_ce < 0"
                @click="balance" class="balance" type="primary">
                差额补足
              </a-button>

              <a-button @click="confim" v-else class="cofim" type="primary">
                清算还款
                <!--  -->
              </a-button>


            </div>
          </div>
        </div>



      </div>
    </a-card>
    <!-- 是否确认进行清算还款？ -->
    <a-modal title="提示" :maskClosable="true" :centered="true" :visible="repaymentvisible"
      @cancel="repaymentvisible = false">
      <div class="synchronizationvisible">
        <h3>
          是否确认进行清算还款？
        </h3>
      </div>
      <template v-slot:footer>
        <div class="SecuritydepositSuccessfulcustom-footer">
          <a-button type="primary" @click="confirmrepayment">确认</a-button>
          <a-button @click="repaymentvisible = false" class="reset">取消</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 是否确认转账？ -->
    <a-modal title="提示" :maskClosable="true" :centered="true" :visible="Transferornotvisible"
      @cancel="Transferornotvisible = false">
      <div class="synchronizationvisible">

        <h3>
          是否确认转账？
        </h3>





      </div>
      <template v-slot:footer>
        <div class="SecuritydepositSuccessfulcustom-footer">

          <a-button type="primary" @click="confirmpayment">确认</a-button>
          <a-button @click="Transferornotvisible = false" class="reset">取消</a-button>
        </div>

      </template>
    </a-modal>
    <!-- 当前A1账户余额不足，请充值后再进行操作 -->
    <a-modal title="提示" :maskClosable="true" :centered="true" :visible="Insufficientbalancevisible"
      @cancel="Insufficientbalancevisible = false">
      <div class="synchronizationvisible">

        <h3>
          当前活期账户余额不足，请充值后再进行操作
        </h3>





      </div>
      <template v-slot:footer>
        <div class="SecuritydepositSuccessfulcustom-footer">

          <a-button type="primary" @click="Insufficientbalancevisible = false">确认</a-button>
          <a-button @click="Insufficientbalancevisible = false" class="reset">取消</a-button>
        </div>

      </template>
    </a-modal>
    <!-- 差额补足 relatedAmount-->
    <a-modal :maskClosable="true" class="Differencevisiblebox" title="差额补足" :centered="true" :visible="Differencevisible"
      @cancel="Differencevisible = false">

      <div class="SecuritydepositRelease">

        <div class="Difference">
          <!-- 用融资的a1 去补足 -->

<!--          <div><span>汇总订单编号：</span> <span> {{ ordersdetails.busiCollectOrders?.orderNo ?-->
<!--            ordersdetails.busiCollectOrders.orderNo : '&#45;&#45;&#45;&#45;' }}</span></div>-->
          <div><span>扣款钱包：</span><span><WalletInfo :account-class="0" :account-no="this.ordersdetails.mchLiveInfoRZ?.accountNoA1"
          :account-name="this.ordersdetails.mchLiveInfoRZ?.accountName" ></WalletInfo></span></div>
          <div><span>可用余额：</span> <span><balance :amount="info[1]?.detail_list[0]?.avail_balance"></balance></span></div>
          <div><span>待补足金额：</span> <span ><balance :amount="-ordersdetails.relatedAmount?.order_amount_ce" ></balance></span></div>
          <div><span>手机号：</span> <span>{{ info[1]?.mobile }}</span></div>
        </div>
        <div class="Payoverbox">

          <div class="left-title">
            补足金额：
          </div>
          <a-input type="number" v-model.trim="amount" class="Payover">

            <template #suffix>
              <div class="yaun">元</div>
            </template>
          </a-input>
        </div>



        <div class="codebox">
          <div class="left-title">短信验证码： </div>
          <a-input v-model.trim="code" class="code">
          </a-input>
          <a-button v-if="show" type="primary" @click="sendcode">
            发送验证码
          </a-button>
          <div style="padding-left: 35px;" v-else>{{ Countdown }}S</div>
        </div>

      </div>

      <template v-slot:footer>
        <div class="custom-footer">
          <a-button style="margin-right: 50px;" @click="Differencevisible = false">取消</a-button>
          <a-button type="primary" style="margin-right: 0px;" @click="confirmTransferornot">确认转账</a-button>
        </div>

      </template>
    </a-modal>
  </div>
</template>

<script>
import Balance from '@/view/components/Balance.vue'
import WalletInfo from "@/view/components/WalletInfo.vue";
export default {
  components: {
    Balance,
    WalletInfo,

    RepayStatus:{
      props:{ text:{ type:String, } },
      render(){
        if (this.text === '0') {
          return <span><a-tag color="grey">未还款</a-tag></span>
        } else if (this.text === '1') {
          return <span><a-tag color="green">还款成功</a-tag></span>
        } else if (this.text === '2') {
          return <span><a-tag color="red">还款失败</a-tag></span>
        } else if (this.text === '3') {
          return <span><a-tag color="yellow">部分还款</a-tag></span>
        } else {
          return <span><a-tag color="grey">{this.text}</a-tag></span>
        }
      }
    }
  },
  data() {
    return {
      ordersdetails: {},
      // 还款
      repaymentvisible: false,
      // 是否确认转账
      Transferornotvisible: false,
      id: "",
      show: true,
      Countdown: 60,

      // 余额不足
      Insufficientbalancevisible: false,
      amount: "", // 转账金额
      code: '', // 验证码
      Differencevisible: false,
      info: [{ detail_list: [] }, { detail_list: [] }] // 查询到的电商余额


    }
  },
  computed:{
    lineAmount(){
      return ((this.ordersdetails.relatedAmount?.order_amount_ce??0)
          + (this.ordersdetails.relatedAmount?.order_amount_yjyhlx??0)
          + (this.ordersdetails.relatedAmount?.order_amount_yjyhbj??0)
          + (this.ordersdetails.relatedAmount?.order_amount_yjyf_fee??0)).toFixed(2)
    }
  },
  created() {
    this.getcollectordersdetails()
  },
  mounted() {
    sessionStorage.setItem('msgInfo', true)
  },
  methods: {
    // 获取数据
    async getcollectordersdetails() {
      const res = await this.api.collectordersdetailsApi(this.$route.query.id)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.ordersdetails = res.data

      this.id = res.data.busiCollectOrders ? res.data.busiCollectOrders.id : ""

      // 获取数据完成后，调用接口 获取可控电商和融资电商的余额手机号等信息  avibaleBalanceApi


      const info = await Promise.all([this.getControlledecommerce({

        channel_no: res.data.mchLiveInfoKK.channelNo,
        account_no: res.data.mchLiveInfoKK.accountNoA1,
        relation_acct: '1'
      }), this.getFinancingecommerce({

        channel_no: res.data.mchLiveInfoRZ.channelNo,
        account_no: res.data.mchLiveInfoRZ.accountNoA1,
        relation_acct: '1'
      }),])

      // 数组第一项可控  第二项融资
      this.info = []
      info.forEach((item) => {

        this.info.push(item.data)
      })



    },


    // 获取可控
    getControlledecommerce(data) {
      return this.api.avibaleBalanceApi(data)
    },


    // 获取融资
    getFinancingecommerce(data) {
      return this.api.avibaleBalanceApi(data)
    },
    customRender: (text) => {

      if (Number(text) == 0) {
        return '已登记'

      } else if (Number(text) == 1) {
        return '已修正'
      }
      else if (Number(text) == 2) {
        return '已结算'
      } else if (Number(text) == 9) {
        return '已完结'
      } else {
        return text
      }
    },
    settleStatus: (text) => {

      if (Number(text) == 0) {
        return '未清算'

      } else if (Number(text) == 1) {
        return '已清算并还款 '
      }
      else if (Number(text) == 2) {
        return '清算失败'
      } else {
        return text
      }
    },
    //  
    repayStatus: (text) => {

      if (Number(text) == 0) {
        return '未还款'

      } else if (Number(text) == 1) {
        return '还款成功 '
      }
      else if (Number(text) == 2) {
        return '还款失败'
      } else {
        return text
      }
    },
    async sendcode() {
      // 使用可控的A1 账户
      const res = await this.api.vefifycodesend({
        appNo: Number(this.ordersdetails.mchLiveInfoKK.appNo),
        channelNo: Number(this.ordersdetails.mchLiveInfoKK.channelNo),
        mobile: Number(this.info[1]?.mobile),
        modelNo: "102106",
        tradeType: 9,
      })
      console.log(res);
      if (res.code == 200) {

        this.$message.success('验证码发送成功');
        this.show = false
        // 60秒倒计时

        var timer = setInterval(() => {
          this.Countdown--
          if (this.Countdown <= 0) {
            this.show = true
            this.Countdown = 60
            clearInterval(timer)
          }
        }, 1000)

      } else {
        this.$message.error(res.msg);
      }

    },
    //  差额补足
    balance() {
      this.Differencevisible = true
      this.amount =  -this.ordersdetails.relatedAmount?.order_amount_ce
    },
    // 确认清算
    confim() {
      if(this.ordersdetails.relatedAmount?.order_amount_ce > this.info[0].detail_list.find(acct=>acct.account_class == "6")?.avail_balance){
        this.$message.error(`回款电商冻结账户${this.info[0].detail_list.find(acct=>acct.account_class == "6")?.account_no}余额为${this.info[0].detail_list.find(acct=>acct.account_class == "6")?.avail_balance},不足${this.ordersdetails.relatedAmount?.order_amount_ce}，请先在平台提现或充值`)
        return
      }

      this.repaymentvisible = true
    },
    // 弹窗确认清算
    async confirmrepayment() {
      // 调用清算还款
      const res = await this.api.collectorderssettleApi(this.id)
      if (res.code !== 200) {
        this.$message.error(res.info);
        return
      }
      this.$message.success('清算还款成功');
      this.repaymentvisible = false
      this.getcollectordersdetails()
    },
    // 确认转帐
    confirmTransferornot() {
      // 首先判断如果Ai账号余额不足，要提示客户去充值

      console.log(0 == '');
      if (this.amount === '') {
        this.$message.warning('请填写金额')
        return
      } if (this.code === '') {
        this.$message.warning('请填写验证码')
        return
      }
      if (this.code !== '' && this.amount !== '') {
        // 校验A1账户余额是否大于充值金额
        if (this.info[0]?.detail_list.find(acct=>acct.account_class==0)?.avail_balance < this.ordersdetails.relatedAmount?.order_amount_ce) {
          this.Insufficientbalancevisible = true
          this.Differencevisible = false
        } else {
          this.Transferornotvisible = true
          this.Differencevisible = false
          this.show = true
          this.Countdown = 60

        }
      }

    },
    // 差额补足，确认支付  
    async confirmpayment() {
      // getcollectorderscompensationApi
      let data = {
        channel_no: this.ordersdetails?.mchLiveInfoRZ?.channelNo,
        app_no: this.ordersdetails?.mchLiveInfoRZ?.appNo,

        order_no: this.ordersdetails?.busiCollectOrders?.orderNo,
        pay_amount: this.amount,

        verify_code: this.code,
      }
      const res = await this.api.getcollectorderscompensationApi(data)
      if (res.code !== 200) {
        this.$message.warning(res.info)
        return
      }
      this.$message.success('操作成功')
      this.getcollectordersdetails()
      this.Transferornotvisible = false
    },
    toBack() {
      this.$router.back(-1)
    }

  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;

}
</style>

<style lang="less" scoped>
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .right {
    button {
      height: 24px;
    }
  }
}

/deep/.ant-modal-content {
  width: 420px;
}


/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
  padding-bottom: 30px;

  .ant-btn-primary {
    margin-right: 60px;
  }
}

.Differencevisiblebox {
  /deep/.ant-modal-content {
    width: 500px;
  }
}

.SecuritydepositRelease {
  padding-bottom: 20px;

  .Difference {
    div {
      padding-left: 25px;
      margin-bottom: 20px;
      display: flex;

      span {

        &:first-child {
          display: flex;
          width: 140px;
        }
      }
    }
  }

  .Payoverbox {
    display: flex;
    padding-right: 0px;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;
    margin-bottom: 20px;


    .left-title {
      flex: 0 0 140px;
      text-align: left;
    }



    .Payover {
      .ant-input {
        position: relative;
        width: 220px;
        padding-right: 0px !important;

      }

      /deep/.ant-input-suffix {
        position: absolute;
        width: 40px;
        right: 0px;
        top: 0;
        margin-top: 16px;
        margin-left: 20px;
        height: 32px;
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        justify-content: center;
        ;
      }
    }


  }

  /deep/.ant-modal-footer {
    text-align: center;
    border-top: none;
    padding-bottom: 30px;


  }


  .codebox {
    display: flex;
    display: flex;
    padding-right: 0px;
    // justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;

    .left-title {
      flex: 0 0 140px;
      text-align: left;
    }

    .code {
      width: 110px;
      margin-right: 10px;
    }

  }

}

.synchronizationvisible {

  h3 {
    padding: 0 20px;
    // margin-bottom: 20px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

  }



}

.viewbox {

  // 可控电商
  .Controllede-commerce {
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;

    .item {
      width: 800px;
      min-height: 192px;
      box-shadow: 0 0 3px#d7d7d7;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;

      .left {
        flex: 1;
        height: 100%;
        border-right: 1px solid#f2f2f2;
        padding: 15px 30px;

        h2 {
          font-weight: 700;
          margin-bottom: 20px;
          font-size: 16px;
        }

        p {
          margin-bottom: 15px;
          display: flex;
          font-size: 16px;

          span {
            &:first-child {
              margin-right: 25px;
              display: flex;
              min-width: 60px;
            }
          }
        }
      }

      .right {
        flex: 1;
        padding: 15px 35px;
        padding-top: 50px;
        display: flex;
        justify-content: space-between;

        .leftbox {
          flex: 1;

          h2 {
            font-weight: 700;
            font-size: 18px;
            margin-top: 20px;
            padding-left: 32px;
          }

          h3 {
            font-size: 16px;
            padding-left: 32px;
          }
        }

        span {
          display: flex;
          width: 1px;
          background-color: #f2f2f2;
          height: 50px;
          margin-top: 10px;
          margin-right: 8px;
        }

        .rightbox {
          flex: 1;

          h2 {
            font-weight: 700;
            font-size: 18px;
            margin-top: 20px;
            display: flex;
            padding-left: 32px;
          }

          h3 {
            font-size: 16px;
            display: flex;
            padding-left: 32px;
          }
        }
      }
    }
  }

  .Basicinformation {
    h2 {
      margin-bottom: 12px;
      font-weight: 500;
    }

    .list {
      margin-bottom: 20px;
      display: flex;

      .item {
        flex: 1;

        .row {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:nth-child(2n + 1) {
          .row {
            background-color: #f2f2f2;
            height: 35px;
            width: 100%;
          }
        }

        &:nth-child(2n) {
          .row {
            height: 35px;
            width: 100%;
            border-bottom: 1px solid #f2f2f2;

            &:first-child {
              border-top: 1px solid #f2f2f2;
            }
          }
        }

        &:nth-child(4) {
          .row {
            height: 35px;
            width: 100%;

            border-right: 1px solid #f2f2f2;
          }
        }
      }
    }
  }




  .Loaninformation {
    min-height: 188px;
    padding: 16px;
    border: 1px solid #f2f2f2;
    margin-bottom: 45px;

    h2 {
      margin-bottom: 15px;
      font-weight: 500;
    }

    .list {
      .head {
        display: flex;
        // ul li:nth-child(-n+5) {
        //   /* 在这里设置样式 */
        //   color: red;
        // }

        // ul li:nth-last-child(-n+5) {
        //   /* 在这里设置样式 */
        //   color: blue;
        justify-content: space-between;
        // }

        span {
          flex: 1;
          display: flex;

          align-items: center;
          height: 32px;
          justify-content: center;

          // &:nth-child(-n+5) {
          //   /* 在这里设置样式 */
          //   color: red;
          // }
          background-color: #f2f2f2;
        }
      }

      .body {
        display: flex;

        // ul li:nth-last-child(-n+5) {
        //   /* 在这里设置样式 */
        //   color: blue;
        justify-content: space-between;
        // }

        span {
          flex: 1;
          display: flex;
          height: 52px;

          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #f2f2f2;
          border-right: 1px solid #f2f2f2;

          &:nth-child(1) {
            /* 在这里设置样式 */
            border-left: 1px solid #f2f2f2;
          }
        }
      }
    }
  }

  .Amountdisplay {
    width: 100%;
    min-height: 268px;
    padding: 16px;
    border: 1px solid #f2f2f2;
    margin-bottom: 50px;

    h2 {
      font-weight: 600;
      margin-bottom: 25px;
    }

    .context {
      padding: 0 20px;
      height: 100%;
      display: flex;
      justify-content: space-between;

      .left {
        flex: 1;


        div {
          margin-bottom: 25px;
          display: flex;

          span {
            &:first-child {
              width: 154px;
              display: flex;
              margin-right: 70px;
            }
          }
        }
      }

      .right {
        position: relative;
        flex: 1;
        height: 100%;

        div {
          margin-bottom: 25px;
          display: flex;


          span {
            &:first-child {
              display: flex;
              margin-right: 70px;
              width: 140px;
            }
          }
        }

        .balance {
          position: absolute;
          right: 50px;
          bottom: 90px;
        }

        .cofim {
          position: absolute;
          right: 50px;
          bottom: 40px;
        }
      }
    }

  }
}

/deep/ .ant-card-head-title {
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .dianshang {
      margin-left: 50px;
    }
  }
}
</style>
