import dayjs  from "dayjs";
export default {

// 下载pdf
  ExcelDownload(name, file) {
    // console.log(name, file);
    let blob = new Blob([file],
      { type: "application/vnd.ms-excel" });
    // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
    let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
    // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
    let a = document.createElement("a");
    a.href = url;
    //
    // a.download = "'原始销售-' + . 'xls'";
    a.download = `${name}-${dayjs().format('YYYY-MM-DD')}.xlsx`
    a.click();
    // 5.释放这个临时的对象url
    window.URL.revokeObjectURL(url);
  },
  PDFDownload(name, file) {
    const base64String = file; // 将你的Base64编码字符串替换到这里
    // 将Base64编码转换为Blob对象
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" }); // 将文件的MIME类型替换到这里

    // 创建一个临时链接并进行文件下载
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${name}-${dayjs().format('YYYY-MM-DD')}.pdf`
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

};