<template>
  <div id="configparam">
    <a-card title="参数配置" style="margin:20px">
      <!-- 搜索框 -->
      <div class="form">
        <view-select-form @selectHandle="selectHandle"></view-select-form>
      </div>
      <!-- 操作栏 -->
      <div class="action">
        <a-button type="primary" @click="addHandle" v-if="$authority('configparamAdd')">新增</a-button>
        <a-button @click="updateHandle" v-if="$authority('configparamEdit')">修改</a-button>
        <a-button type="danger" :disabled="selectedRowKeys.length === 0" @click="deleteHandle"
          v-if="$authority('configparamDel')">删除</a-button>
      </div>
      <!-- 表格 -->
      <div class="table">
        <a-table :columns="columns" :customRow="clickrow" :data-source="tableData"
                 :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :pagination="false" :rowKey="(record, index) => `${record.id}`">
          <template v-slot:number_index="text, record, index">
            <span>
              {{ (index + 1) + (currentPage - 1) * pageSize }}
            </span>
          </template>
          <template v-slot:look="text, record, index">
            <span style="cursor:pointer" @click="looKhandle(text, record, index)">
              查看
            </span>
          </template>
        </a-table>
      </div>
      <!-- page -->
      <div class="page">
        <a-pagination :current="currentPage" :total="total" show-size-changer show-quick-jumper style="float:right"
          @change="pageChange" @showSizeChange="sizePageChange" :show-total="total => `共 ${total} 条`" />
      </div>
      <!-- 模态框 -->
      <div class='modal'>
        <view-modal :modalBool="modalBool" :modalTitle="modalTitle" :selectedRows="selectedRows"
          @okModalHandle="okModalHandle" @cancelModalHandle="cancelModalHandle" ref="modalChild"></view-modal>
      </div>
    </a-card>
  </div>
</template>
<script>
import SelectForm from './SelectForm';
import Modal from "./Modal"

const columns = [
  {
    title: '序号',
    dataIndex: 'number_index',
    align: 'center',
    scopedSlots: { customRender: 'number_index' },
    width: 60
  },
  {
    title: '常量编码',
    dataIndex: 'constCode',
    align: 'center',
    width: 100
  },
  {
    title: '常量名称',
    dataIndex: 'constName',
    align: 'center',
    width: 100

  },
  {
    title: '常量数值',
    dataIndex: 'constValue',
    align: 'center',
    ellipsis: true,

  },
  {
    title: '常量类别',
    dataIndex: 'constType',
    align: 'center',
    width: 100,
    customRender: (text) => {
      return text != null ? text : '---'
    }
  },
  {
    title: '常量类别编码',
    dataIndex: 'constTypeCode',
    align: 'center',
    width: 150,
    customRender: (text) => {
      return text != null ? text : '---'
    }

  },
  {
    title: '顺序',
    dataIndex: 'constSeq',
    align: 'center',
    width: 60,
    customRender: (text) => {
      return text != null ? text : '---'
    }

  },
  {
    title: '标签',
    dataIndex: 'constFlag',
    align: 'center',
    width: 60,
    customRender: (text) => {
      return text != null ? text : '---'
    }

  },
  // {
  //   title: '操作',
  //   dataIndex: 'action',
  //   align: 'center',
  //   scopedSlots: { customRender: 'look' },
  //   width: 60

  // }
];
export default {
  name: "configParamPage",
  components: {
    "view-select-form": SelectForm,
    "view-modal": Modal,
  },
  data() {
    return {
      data: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      columns,
      modalBool: false,
      modalTitle: "操作",
      selectedRowKeys: [],
      selectedRowData: {},
      selectedRows: [{}]
    }
  },
  created() {
    this.tableRequestHandle()
  },
  methods: {
    //查看
    // looKhandle(text, val, index) {
    //   console.log(val, text, index, 'val')
    // },
    //页码操作
    pageChange(page) {
      this.currentPage = page
      this.selectedRowKeys = []
      this.tableRequestHandle()
    },
    sizePageChange(current, size) {
      this.selectedRowKeys = []
      const data = this.data
      this.pageSize = size
      this.tableData = data.slice((current - 1) * size, current * size)
      this.tableRequestHandle()
    },
    //搜索操作
    selectHandle(val) {
      this.selectedRowKeys = []
      this.selectData = val
      this.tableRequestHandle()
    },
    //数据增删改
    addHandle() {
      this.selectedRowKeys = []
      this.selectedRows = [{}]
      this.modalTitle = "新增数据"
      this.modalBool = true
      this.$refs.modalChild.edit('add')
    },
    updateHandle() {
      const sum = this.selectedRowKeys
      if (sum.length !== 1) return this.$message.warn("只能选择一条数据")
      this.modalTitle = "修改数据"
      this.$refs.modalChild.edit('edit', this.selectedRowData)
      this.modalBool = true
    },
    deleteHandle() {
      // const data = this.selectedRowKeys
      // const tableData = this.tableData
      let ids = [this.selectedRowData.id]
      // const currentPage = this.currentPage
      var that = this
      this.$confirm({
        // title: '删除数据',
        // content: () => "是否删除此条数据？此过程不可逆！",
        title: '是否删除此条数据？此过程不可逆！',
        onOk: () => {
          // configparamadelete
          // console.log('OK');
          that.api.configparamadelete(ids).then((res) => {
            if (res.code !== 200) {
              this.$message.warning(res.info)
              return
            }
          })
          that.$message.success('删除成功')
          this.tableRequestHandle()
          // if (tableData.length === data.length && currentPage > 1) {
          //   this.currentPage = currentPage - 1
          // }
        },
        onCancel() {
          console.log('Cancel');
        },

      });
    },
    //Modal操作
    okModalHandle() {
      this.selectedRowKeys = []
      this.modalBool = false
      this.tableRequestHandle()

    },
    cancelModalHandle() {
      this.modalBool = false
      this.tableRequestHandle()
    },
    //table数据
    tableRequestHandle() {
      const size = this.pageSize
      const page = this.currentPage
      const values = this.selectData
      this.$axios.apiPost('/business/base/configparam/page?pageNo=' + page + '&pageSize=' + size,  values).then((res) => {

        if (res.code !== 200) return this.$message.error(res.info)
        this.tableData = res.data.records
        this.total = res.data.total //符合查询条件的总数
        // 总共页数  res.data.pages
        this.currentPage = res.data.current
        this.pageSize = res.data.size
        this.data = res.data.records
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      if (selectedRows.length > 1) {
        //获取选中的数据的key
        var selectNumber = this.selectedRowKeys[1]
        //清空选中的key
        this.selectedRowKeys = []
        //选中的数据的key重新赋值给selectedRowKeys
        this.selectedRowKeys.push(selectNumber)
      }
      //获取选中的数据详细信息
      this.selectedRowData = selectedRows[0]

    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            const id = record.id.toString();
            if (this.selectedRowKeys.includes(id)) {
              // 如果点击的行已经选中，则取消选中状态
              this.selectedRowKeys = this.selectedRowKeys.filter(key => key !== id);
              this.selectedRowData = {};
            } else {
              // 否则，选中点击的行，并更新selectedRowData
              this.selectedRowKeys = [id];
              this.selectedRowData = record;
            }
          }
        }
      }
    },
  },
  computed: {
  }
}
</script>
<style lang="less" scoped>
.page {
  margin: 12px 0;
}

.table {
  margin-top: 24px;
}

.form {
  margin-bottom: 16px;
}
</style>