import axios from '@/utils/axios';
import urlConfig from '@/utils/urlConfig';


export default {

  /**
   *  关联账户page 钱包页面
   * @param {*} data 
   * @returns 
   */ 
  
  custaccountPageApi: (data) => axios.apiPost(urlConfig.busiURL + `/cust/account/page?pageNo=${data.pageNo}&pageSize=${data.pageSize}`, data),
  // 是否有经营企业
  isBusRelatApi:(data) => axios.apiPost(urlConfig.busiURL + `/creditBusRelat/isBusRelat?leaderIdNo=${data}`),
  // 树形账户
  accountListPageApi: (data) => axios.apiPost(urlConfig.busiURL + `/cust/account/pageMaster?pageNo=${data.pageNo}&pageSize=${data.pageSize}`, data),
  // 保证金缴纳
  bailbailInsureApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/bail/bailInsure', data),

  // 保证金释放
  bailRepayApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/bail/bailRepay', data),


  // 个人开户
  openAccPersonalApi: (data) => axios.apiPost(urlConfig.busiURL + '/cust/account/openAccPersonal', data),
  //内部户开户
  openAccInnerApi: (data) => axios.apiPost(urlConfig.busiURL + '/cust/account/openAccInner', data),

  //从账户开户
  openAccSub: (data) => axios.apiPost(urlConfig.busiURL + '/cust/account/openAccSub', data),
  //开户信息暂存
  custOpenAccTaskSave: (data) => axios.apiPost(urlConfig.busiURL + '/custOpenAccTask/save', data),
  // 获取核心企业列表
  companylistApi: (data) => axios.apiPost(urlConfig.busiURL + '/core/company/list', data),

  // 企业信息开户基础信息
  companyPage: (data) =>
  axios.apiPost(
    urlConfig.busiURL +
      '/cust/company/page?' +
      'pageNo=' +
      data.pageNo +
      '&pageSize=' +
      data.pageSize,
    data.obj
  ),
  // 获取当前用户所属核心企业
  coreCompanyInfoApi: (data) => axios.apiPost(urlConfig.busiURL + '/core/company/getByUsername', data),

  // 企业开户
  openAccCompanyApi: (data) => axios.apiPost(urlConfig.busiURL + '/cust/account/openAccCompany', data),
  // 提现
  
  merWithdrawApi: (data) => axios.apiPost(urlConfig.busiURL + '/cust/account/merWithdraw', data),

  // 短信验证码
  vefifycodesend: (data) => axios.apiPost(urlConfig.busiURL + '/sms/vefifycode/send', data),
  // 查询交易明细
  queryTradeDetailApi: (data) => axios.apiPost(urlConfig.busiURL + '/cust/account/queryTradeDetail', data),
  // 回单下载 
  returnBillDownloadApi: (data) => axios.apiPost(urlConfig.busiURL + '/cust/account/returnBillDownload', data),
  // 余额同步查询 
  accountqueryBalancedApi: (data) => axios.apiPost(urlConfig.busiURL + '/cust/account/queryBalance', data),
  // 对账单下载  
  checkBillDownloadApi: (data) => axios.apiPost(urlConfig.busiURL + '/cust/account/checkBillDownload', data),
  //贷款接口 
  loanlistApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/manager/page?' + 'pageNo=' + data.pageNo + '&pageSize=' + data.pageSize,data),

  // 个人贷款申请
  // creditApplyApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/manager/creditApply', data),
  // creditApplyApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/manager/creditApplyV2', data),
  creditApplyApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/manager/applyPerson', data),
  // 个人贷款获取产品编号
  creditProductInfolistApi: (data) => axios.apiPost(urlConfig.busiURL + '/creditProductInfo/list', data),


  
  // 企业贷款申请
  // creditQyApplyApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/manager/creditQyApply', data),
  // creditQyApplyApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/manager/creditQyApplyV2', data),

  creditQyApplyApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/manager/applyCompany', data),
  // 银行卡信息page
  bankcardpageApi: (pageNo, pageSize, data) => axios.apiPost(urlConfig.busiURL + `/cust/bankcard/page?pageNo=${pageNo}&pageSize=${pageSize}`, data),
  
  // 获取流程签署地址 
  creditSignFlowsUrlsApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/manager/creditSignFlowsUrls', data),

  // 贷款申请详情(指定客户)：
  creaditApplyInfoByCust: (data) => axios.apiPost(urlConfig.busiURL + '/credit/manager/queryCreditInfoByCustNum?custNum=' + data),
  
  // 生成人脸拍照任务 /base/face/photograph
  facePhotographApi: (data) => axios.apiPost(urlConfig.busiURL + '/base/face/photograph', data),
  // 人脸拍照任务状态
  facePhotographQueryApi: (data) => axios.apiPost(urlConfig.busiURL + '/base/face/photographQuery?task_no=' + data),
  // 融资白名单页面 /credit/bmd/page
  creditybmdpageApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/bmd/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
  // 融资白名单新增
  creditbmdbmdAddApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/bmd/bmdAdd', data),
    // 融资白名单详情
    creditbmdbmdDetailApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/bmd/details?id='+data),
  // 融资白名单删除
  creditbmdbmdbmdDelApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/bmd/bmdDel?id=' + data),
  // 融资名单审批
  creditbmdbmdApproveApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/bmd/finWhiteApproval', data),
  // 融资白名单同步
  creditbmdAsyncApi: (data) => axios.apiPost(urlConfig.busiURL + '/credit/bmd/finWhiteSynch', data),
  // 融资白名单list 
  creditWhiteDbListApi: (data) => axios.apiPost(urlConfig.busiURL + '/creditWhiteDbList/list', data),
  // 担保人page
  guarantorListApi: (data) => axios.apiPost(urlConfig.busiURL + `/creditWhiteDbList/page?pageNo=${data.pageNo}&pageSize=${data.pageSize}`, data.obj),
  // 新增担保人
  addDbAccountApi: (data) => axios.apiPost(urlConfig.busiURL + '/creditWhiteDbList/addOrUpdate', data),
   // 担保人详情
  detailsDbAccountApi: (data) => axios.apiGet(urlConfig.busiURL + '/creditWhiteDbList/getDetails?id=' + data),
   // 担保人删除
   delDbAccountApi: (data) => axios.apiGet(urlConfig.busiURL + '/creditWhiteDbList/delete?id='+data),
   //清算名单page
  settleAccountApi: (data) => axios.apiPost(urlConfig.busiURL + `/busiSettleAccount/page?pageNo=${data.pageNo}&pageSize=${data.pageSize}`, data.obj),
   // 新增清算账户
  addSettleAccountApi: (data) => axios.apiPost(urlConfig.busiURL + '/busiSettleAccount/addOrUpdate', data),
  // 清算账户详情
  detailsSettleAccountApi: (data) => axios.apiGet(urlConfig.busiURL + '/busiSettleAccount/getDetails?id=' + data),
    // 清算账户删除
  delSettleAccountApi: (data) => axios.apiGet(urlConfig.busiURL + '/busiSettleAccount/delete?id=' + data),
   // 核心企业获取
  getCoreComponyApi: (data) => axios.apiGet(urlConfig.busiURL + '/statisticalRawSql/coreEnterprise?id=' + data),
   // 融资电商概况获取
  getRzDetailsApi: (data) => axios.apiPost(urlConfig.busiURL + '/statisticalRawSql/executeSQL', data),
   // 融资白名单-审批-电商同步
  mchSyncToBankApi: (data) => axios.apiPost(urlConfig.busiURL + `/mch/live/syncToBank?id=${data.id}&bmdid=${data.bmdid}`),
  // 电商管理-电商同步
  mchSyncToApi: (data) => axios.apiPost(urlConfig.busiURL + `/mch/live/syncToBank?id=${data.id}`),
   // 融资白名单-审批-支付协议同步
  paySyncToBankApi: (data) => axios.apiGet(urlConfig.busiURL + `/busi/payorcers/contract/syncToBank?id=${data.id}`),
   // 融资白名单-审批-代销协议同步
  settleSyncToBankApi: (data) => axios.apiGet(urlConfig.busiURL + `/busiSettleContract/syncToBank?id=${data.id}`),
   // 融资电商明细导出
  DetailexportApi: (data) => axios.apiPost(urlConfig.busiURL + '/core/export/customExport', data),
  //  银行卡绑定
  bindBankCardApi: (data) => axios.apiPost(urlConfig.busiURL + '/cust/bankcard/addPrimAcc', data),
    // 获取账户详情
  getAccountDetailsApi: (id) => axios.apiGet(urlConfig.busiURL + `/cust/account/accountDetails?id=${id}`),
     // 个人账户修改
  personAccountEditApi: (data) => axios.apiPost(urlConfig.busiURL + '/cust/account/modAccPersonal', data),
      // 企业账户修改
  enterpriseAccountEditApi: (data) => axios.apiPost(urlConfig.busiURL + '/cust/account/modAccCompany',data),
  //白名单新增
  bmdAddApi: (data) => axios.apiPost(urlConfig.busiURL + '/cust/account/bmdAdd',data),
  //白名单查询
  bmdListApi: (data) => axios.apiPost(urlConfig.busiURL + '/cust/account/bmdList?accountNo='+data),

  getAmountAuthApi: (data) => axios.apiPost(urlConfig.busiURL + '/custAccountAuth/getAccountAuth?accountNo='+data),
  smallAmountAuthApi: (data) => axios.apiPost(urlConfig.busiURL + '/custAccountAuth/smallAmountAuth?accountNo='+data),
  legalPersonAuthApi: (data) => axios.apiPost(urlConfig.busiURL + '/custAccountAuth/legalPersonAuth?accountNo='+data),
  corporateAuthApi: (data) => axios.apiPost(urlConfig.busiURL + '/custAccountAuth/corporateAuth?accountNo='+data),
  getLegalPerson: (data) => axios.apiGet(urlConfig.busiURL + '/cust/account/getLegalPerson?accountNo='+data),





};
 