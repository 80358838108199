<template>
  <div class="dialog-box">
    <a-modal :visible="visible" @ok="handleOk" @cancel="handleCancel" :footer="null">
      <template slot="title">
        融资白名单/审批打印
        <span class="no-print">
          <a-button type="link" @click="downloadXlsx">下载</a-button>
          <a-button type="link" @click="printPage">打印</a-button>
        </span>
      </template>
      <div class="container">
        <a-row>
          <a-col :span="12">
            <div class="container-item">
              <a-row>
                <a-col :span="8">贷款申请人:</a-col>
                <a-col :span="16">{{ detailsInfo.accountNameSq }}</a-col>
              </a-row>
            </div>
            <div class="container-item">
              <a-row>
                <a-col :span="8">申请人证件号:</a-col>
                <a-col :span="16">{{ detailsInfo.sqCertnum }}</a-col>
              </a-row>
            </div>
            <div class="container-item">
              <a-row>
                <a-col :span="8">申请人手机号:</a-col>
                <a-col :span="16">{{ detailsInfo.sqPhoneNumber }}</a-col>
              </a-row>
            </div>
            <div class="container-item">
              <a-row>
                <a-col :span="8">申请人A1账号:</a-col>
                <a-col :span="16">{{ detailsInfo.accountNoSq }}</a-col>
              </a-row>
            </div>
            <div class="container-item">
              <a-row>
                <a-col :span="8">一级核心户名:</a-col>
                <a-col :span="16">{{ detailsInfo.accountNameDb }}</a-col>
              </a-row>
            </div>
            <div class="container-item">
              <a-row>
                <a-col :span="8">一级核心担保A1:</a-col>
                <a-col :span="16">{{ detailsInfo.accountNoDb }}</a-col>
              </a-row>
            </div>
            <div class="container-item">
              <a-row>
                <a-col :span="8">建议(担保)额度:</a-col>
                <a-col :span="16" style="display: flex">
                  <Balance :amount="detailsInfo.maxAmount / 10000" suffix="万元"></Balance>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <a-col :span="12">

            <div class="container-item">
              <a-row>
                <a-col :span="8">与经营实体关系:</a-col>
                <a-col :span="16">{{ BizDict.whethen1.find(x => x.value === detailsInfo.relaType)?.label }}</a-col>
              </a-row>
            </div>
            <div class="container-item">
              <a-row>
                <a-col :span="8">经营实体营业执照:</a-col>
                <a-col :span="16">{{ detailsInfo.accountNoSq != detailsInfo.rzAccountNoA1 ? detailsInfo.entityBusiLicense
                  : ""
                }}</a-col>
              </a-row>
            </div>
            <div class="container-item">
              <a-row>
                <a-col :span="8">经营实体:</a-col>
                <a-col :span="16">{{ detailsInfo.accountNoSq != detailsInfo.rzAccountNoA1 ? detailsInfo.rzAccountNameA1 :
                  ""
                }}</a-col>
              </a-row>
            </div>
            <div class="container-item">
              <a-row>
                <a-col :span="8">经营实体A1:</a-col>
                <a-col :span="16">{{ detailsInfo.accountNoSq != detailsInfo.rzAccountNoA1 ? detailsInfo.rzAccountNoA1 : ""
                }}
                </a-col>
              </a-row>
            </div>
            <div class="container-item">
              <a-row>
                <a-col :span="8">二级核心户名:</a-col>
                <a-col :span="16">{{ detailsInfo.accountNameDb2 }}</a-col>
              </a-row>
            </div>
            <div class="container-item">
              <a-row>
                <a-col :span="8">二级核心担保A1:</a-col>
                <a-col :span="16">{{ detailsInfo.accountNoDb2 }}</a-col>
              </a-row>
            </div>
            <div class="container-item">
              <a-row>
                <a-col :span="8">近三个月交易额:</a-col>
                <a-col :span="16" style="display: flex">
                  <Balance v-if="detailsInfo.threeMonthsAmount" :amount="detailsInfo.threeMonthsAmount / 10000"
                    suffix="万元"></Balance>
                  <template v-else>未知</template>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>

        <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="电商清单">
            <a-table :columns="rzMchLiveColumns" :data-source="rzMchLive" size="small" :pagination="false">
              <template slot="control" slot-scope="text,record">
                <div class="control-box" v-if="record.synch == 0" @click="asyncEvent(record, '1')"
                  :class="[$authority('financingwhitelistAsync') === true ? '' : 'canot-click']">
                  <img src="../../../assets/img/async.png" alt="">
                  <span>{{ text.goodsType == '10' ? '🛍' : text.goodsType == '20' ? '🎤' : text.goodsType == '30' ? '💰'
                    : ''
                  }}</span>
                </div>
              </template>
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="2" tab="支付对象">
            <a-table :columns="payContractsColumns" :data-source="payContracts" size="small" :pagination="false"
              :scroll="{ x: 800 }">
              <template slot="control" slot-scope="text,record">
                <div class="control-box" v-if="record.synch == 0" @click="asyncEvent(record, '2')"
                  :class="[$authority('financingwhitelistAsync') === true ? '' : 'canot-click']">
                  <img src="../../../assets/img/async.png" alt="">

                </div>
              </template>
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="3" tab="代销协议">
            <a-table :columns="settleContractsColumns" :data-source="settleContracts" size="small" :pagination="false"
              :scroll="{ x: 1200 }">
              <template slot="control" slot-scope="text,record">
                <div v-if="record.synch == 0" class="control-box" @click="asyncEvent(record, '3')"
                  :class="[$authority('financingwhitelistAsync') === true ? '' : 'canot-click']">
                  <img src="../../../assets/img/async.png" alt="">

                </div>
              </template>
            </a-table>
          </a-tab-pane>

        </a-tabs>
        <a-divider class="no-print" v-if="$authority('financingwhitelistApproval')">审批栏</a-divider>
        <a-row class="no-print" v-if="$authority('financingwhitelistApproval')">
          <a-col :span="12">
            <div class="container-item ">
              <a-row>
                <a-col :span="8">推荐人:</a-col>
                <a-col :span="16" style="display: flex">
                  <a-input v-if="!showInputUserSelect" v-model="form.inputUserName" placeholder="请输入推荐人名称"></a-input>
                  <a-select v-if="showInputUserSelect" v-model="form.inputUserId" @change="onInputUserChange" size="small"
                    placeholder="请选择推荐人">
                    <a-select-option v-for=" item  in  presetInputUserList " :key="item.inputUserId"
                      :value="item.inputUserId">
                      {{ item.inputUserId + "-" + item.inputUserName }}
                    </a-select-option>
                  </a-select>
                  <a-button v-if="!showInputUserSelect" @click="showInputUserSelect = true" type="link">选择常用</a-button>
                  <a-button v-if="showInputUserSelect" @click="showInputUserSelect = false" type="link">手动输入</a-button>
                </a-col>
              </a-row>
            </div>
            <div class="container-item " v-if="!showInputUserSelect">
              <a-row>
                <a-col :span="8">法人机构名称:</a-col>
                <a-col :span="16" style="display: flex">
                  <a-input v-model="form.corpOrgName" placeholder="请输入法人机构名称"></a-input>
                </a-col>
              </a-row>
            </div>
            <div class="container-item" v-if="!showInputUserSelect">
              <a-row>
                <a-col :span="8">所属机构名称:</a-col>
                <a-col :span="16" style="display: flex">
                  <a-input v-model="form.operateOrgName" placeholder="请输入所属机构名称"></a-input>
                </a-col>
              </a-row>
            </div>
            <div class="container-item">
              <a-row>
                <a-col :span="8">审核意见:</a-col>
                <a-col :span="16">
                  <a-textarea v-model.trim="detailsInfo.failMsg" :rows="3" style="width: 300px;height: 74px;">
                  </a-textarea>
                </a-col>
              </a-row>

            </div>
          </a-col>
          <a-col :span="12">
            <div class="container-item" v-if="!showInputUserSelect">
              <a-row>
                <a-col :span="8">推荐人工号:</a-col>
                <a-col :span="16" style="display: flex">
                  <a-input v-model="form.inputUserId" placeholder="请输入法人机构号"></a-input>
                </a-col>
              </a-row>
            </div>
            <div class="container-item" v-if="!showInputUserSelect">
              <a-row>
                <a-col :span="8">法人机构号:</a-col>
                <a-col :span="16" style="display: flex">
                  <a-input v-model="form.corpOrgId" placeholder="请输入推荐人工号"></a-input>
                </a-col>
              </a-row>
            </div>
            <div class="container-item" v-if="!showInputUserSelect">
              <a-row>
                <a-col :span="8">所属机构号:</a-col>
                <a-col :span="16" style="display: flex">
                  <a-input v-model="form.operateOrgId" placeholder="请输入所属机构号"></a-input>
                </a-col>
              </a-row>
            </div>
            <div class="container-item">
              <a-row>
                <a-col :span="8">审核状态:</a-col>
                <a-col :span="16">
                  <a-col :span="5" v-if="selectedInfo.checkStatus == '0'">待审核 </a-col>
                  <a-col :span="5" v-if="selectedInfo.checkStatus == '1'" style="color: #60ac08">通过 </a-col>
                  <a-col :span="5" v-if="selectedInfo.checkStatus == '2'" style="color: #ff4d4f">拒绝 </a-col>
                  <a-col :span="5" v-if="selectedInfo.checkStatus == '3'" style="color: #7a6400">异常 </a-col>
                </a-col>
              </a-row>
            </div>
            <div class="container-item no-print">
              <a-row>
                <a-col :span="8">
                  <a-button style="background-color: #60ac08; color: #fff" @click="pass"
                    v-if="$authority('financingwhitelistApproval')">通过 </a-button>
                </a-col>
                <a-col :span="8">
                  <a-button type="danger" @click="reject" v-if="$authority('financingwhitelistApproval')">拒绝</a-button>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>

      </div>
    </a-modal>
  </div>
</template>

<script>
import Balance from "@/view/components/Balance";
import WalletInfo from "@/view/components/WalletInfo.vue";
import { BizDict } from "@/utils/bizDict/kvMap";
import { utils, writeFile } from 'xlsx';


export default {
  name: "approveDialog",
  props: {
    selectedData: {
      required: true,
      type: Array,
    },
  },
  components: {
    Balance,
    WalletInfo,
  },
  data() {
    return {
      feeRuleList: [],
      showInputUserSelect: true,

      visible: true,
      selectedInfo: {},
      // 获取到的详细信息
      detailsInfo: {},
      // 电商清单
      rzMchLive: [],
      rzMchLiveColumns: [
        {
          title: '',
          align: 'center',
          key: 'control',
          width: 45,
          scopedSlots: { customRender: 'control' },
        },
        {
          title: "编号",
          dataIndex: "mchNo",
          width: 100,
          align: "center",
        },
        {
          title: "电商",
          dataIndex: "mchName",
          align: "center",
          customRender: (text, row) => {
            if (!row.fatherId)
              return <div><a-tag color="purple">融资</a-tag>{row.mchName}</div>
            else
              return <div><div><a-tag color="purple">融资</a-tag>{row.mchName}</div><div><a-tag color="blue">回款</a-tag>{row.fatherName}</div></div>
          }
        },
        {
          title: "商品类目",
          dataIndex: "goodsType",
          width: 100,
          align: "center",
          customRender: (text) => {
            if (text === 10) {
              return '商品类'
            } else if (text === 20) {
              return '娱乐类'
            } else if (text === 30) {
              return '资金类'
            } else if (text === 40) {
              return '代销类'
            } else if (text === 41) {
              return '代销类(特殊)'
            }
          }
        },
        {
          title: "融资/回款账号",
          dataIndex: "accountName",
          align: "center",
          width: 220,
          customRender: (text, row) => {
            if (!row.fatherAccountName) {
              return <WalletInfo accountName={text} accountNo={row.accountNoA1} accountClass="0"></WalletInfo>
            } else {
              return <div>
                <WalletInfo accountName={text} accountNo={row.accountNoA1} accountClass="0"></WalletInfo>
                <WalletInfo accountName={row.fatherAccountName} accountNo={row.fatherA2} accountClass="6"></WalletInfo>
              </div>
            }
          }
        },
        {
          title: "融资率",
          dataIndex: "creditProp",
          align: "center",
          width: 60,
          customRender: (v) => {
            return v * 100 + '％'
          },
        },
        {
          title: "退货率",
          dataIndex: "refundRate",
          align: "center",
          width: 60,
          customRender: (v) => {
            return v * 100 + '％'
          },
        }, {
          title: "手续费",
          dataIndex: "mchFeeInfos",
          align: "center",
          customRender: (v, row) => {
            let feeList = []
            if (row.feeRuleNo) {
              feeList.push({
                feeAccountNo: row.feeAccountNo,
                feeAccountName: row.feeAccountName,
                feeRuleNo: row.feeRuleNo,
                projectName: "综合费率",
                color: "orange"
              })
            }
            let list = feeList.concat((v ?? [])).map(o => {
              let rule = this.feeRuleList.find(x => x.feeNo == o.feeRuleNo)
              return {
                feeName: rule?.feeName,
                feeType: { "1": "定额", "2": "比例", "3": "分段", "4": "日息" }[rule?.feeType],
                feeAmount: rule?.fixedFee ?? (rule?.feeProportion * 100 + "%") ?? 0,
                accountNo: o.feeAccountNo,
                accountName: o.feeAccountName,
                projectName: o.projectName,
                color: o.color ?? '',
              }
            })
            return <div>
              {
                list.map(o => {
                  return <a-popover>
                    <template slot="title">{o.projectName}</template>
                    <template slot="content">
                      <div>户名：{o.accountName}</div>
                      <div>账号：{o.accountNo}</div>
                      <div>类型：{o.feeType}</div>
                      <div>费用：{o.feeAmount}</div>
                    </template>
                    <a-tag color={o.color}>{o.feeName}</a-tag></a-popover>
                })
              }
            </div>
          },
        }
      ],
      // 支付协议表
      payContracts: [],
      payContractsColumns: [

        // {
        //   title: "电商编号",
        //   dataIndex: "mchNo",
        //   width: 100,
        //   align: "center",
        // },
        // {
        //   title: "电商名称",
        //   dataIndex: "mchName",
        //   align: "center",
        // customRender: (text, row) => {
        //   if (!row.fatherId)
        //     return <div><a-tag color="purple">融资</a-tag>{row.mchName}</div>
        //   else
        //     return <div><div><a-tag color="purple">融资</a-tag>{row.mchName}</div><div><a-tag color="blue">回款</a-tag>{row.fatherName}</div></div>
        // }
        // },
        // {
        //   title: "订单类型",
        //   dataIndex: "orderType",
        //   align: "center",
        //   // width: 220,
        //   customRender: (text) => {
        //     if (text == "1") {
        //       return '支付MCN '
        //     } else if (text == '2') {
        //       return '支付主播'
        //     } else if (text == '3') {
        //       return '支付厂家'
        //     } else if (text == '9') {
        //       return '未定义场景'
        //     } else {
        //       return '---'
        //     }
        //   }
        // },
        {
          title: '',
          align: 'center',
          key: 'control',
          width: 45,
          scopedSlots: { customRender: 'control' },
        },
        {
          title: "电商编号",
          dataIndex: "mchNo",
          align: "center",
          width: 100,
        },
        {
          title: "付款户名",
          dataIndex: "accountName",
          align: "center",
          // width: 60,
        },
        {
          title: "付款账号",
          dataIndex: "accountNo",
          align: "center",
          // width: 60,
          customRender: (text, record) => {
            return <WalletInfo accountName={record.accountName}
              accountNo={record.accountNo}
            ></WalletInfo>
          }
        },
        {
          title: "收款账户",
          dataIndex: "oppAccountName",
          align: "center",
        },
        {
          title: "收款账号",
          dataIndex: "oppAccountNo",
          align: "center",
          // width: 60,
          customRender: (text, record) => {
            return <WalletInfo accountName={record.oppAccountName}
              accountNo={record.oppAccountNo}
              bankName={record.oppBankName} bankNo={record.oppBankNo}
              isWallet={record.oppAccountType == '2' ? false : true}
            ></WalletInfo>
          },
        },
      ],
      // 代销协议表
      settleContracts: [],
      settleContractsColumns: [
        {
          title: '',
          align: 'center',
          key: 'control',
          width: 45,
          scopedSlots: { customRender: 'control' },
        },

        {
          title: "电商编号",
          dataIndex: "mchNo",
          width: 100,
          align: "center",
        },
        {
          title: "电商名称",
          dataIndex: "mchName",
          align: "center",
          // customRender: (text, row) => {
          //   if (!row.fatherId)
          //     return <div><a-tag color="purple">融资</a-tag>{row.mchName}</div>
          //   else
          //     return <div><div><a-tag color="purple">融资</a-tag>{row.mchName}</div><div><a-tag color="blue">回款</a-tag>{row.fatherName}</div></div>
          // }
        },
        {
          title: "协议类型",
          dataIndex: "contractType",
          align: "center",
          // width: 220,
          customRender: (text) => {
            if (text == "1") {
              return '代销协议'
            } else {
              return '---'
            }
          }
        },
        {
          title: "协议户名(电商A2)",
          dataIndex: "accountName",
          align: "center",
          // width: 60,

        },
        {
          title: "协议账号(电商A2)",
          dataIndex: "accountNo",
          align: "center",
          // width: 60,
          customRender: (text) => {
            return <WalletInfo accountNo={text}></WalletInfo>
          },
        },
        {
          title: "待清算户名1",
          dataIndex: "accountNameSettling1",
          align: "center",
          // width: 60,

        },
        {
          title: "待清算账号1",
          dataIndex: "accountNoSettling1",
          align: "center",
          // width: 60,
          customRender: (text) => {
            return <WalletInfo accountNo={text}></WalletInfo>
          },
        },
        // {
        //   title: "待清算余额1",
        //   dataIndex: "settling1Balance",
        //   align: "center",
        //   // width: 60,
        //   customRender: (text) => {
        //     return <Balance amount={text}></Balance>
        //   },
        //
        // },
      ],

      presetInputUserList: this.$store.state.mchInputUser,
      form: {
        corpOrgId: "", //法人机构号
        corpOrgName: "", //法人机构名称
        inputUserId: "", //推荐人工号
        inputUserName: "", //推荐人名称
        operateOrgId: "", //所属机构号
        operateOrgName: "",//所属机构名称
        comment: "",//审核意见
      },
    };
  },
  async mounted() {
    this.selectedInfo = this.selectedData[0];
    // 获取详情信息
    await this.creditbmdbmdDetail();
    //获取服务费规则
    await this.getFeeRule()
  },
  computed: {
    BizDict() {
      return BizDict
    }
  },
  methods: {
    // 点击同步
    asyncEvent(record, type) {
      if (type === '1') {
        // 电商同步 暂时关闭
        // this.mchSyncToBank(record)
        return this.$message.error("未录入客户经理，请到电商界面审批")
      } else if (type === '2') {
        // 支付协议同步
        this.paySyncToBank(record)
      } else if (type === '3') {
        // 代销协议同步
        this.settleSyncToBank(record)
      }
    },
    // 电商同步
    async mchSyncToBank(record) {
      let res = await this.api.mchSyncToApi({
        id: record.id,
      })
      if (res.code == 200) {
        this.$message.success(res.info)
        // 更新一下状态
        this.creditbmdbmdDetail()
      } else {
        this.$message.error(res.info)
      }
    },
    // 支付协议同步
    async paySyncToBank(record) {
      let res = await this.api.paySyncToBankApi({
        id: record.id
      })

      if (res.code == 200) {
        this.$message.success(res.info)
        // 更新一下状态
        this.creditbmdbmdDetail()
      } else {
        this.$message.error(res.info)
      }
    },
    // 代销协议同步
    async settleSyncToBank(record) {
      let res = await this.api.settleSyncToBankApi({
        id: record.id
      })
      if (res.code == 200) {
        this.$message.success(res.info)
        // 更新一下状态
        this.creditbmdbmdDetail()
      } else {
        this.$message.error(res.info)
      }
    },
    // 获取详情信息
    async creditbmdbmdDetail() {
      let res = await this.api.creditbmdbmdDetailApi(this.selectedInfo.id);
      if (res.code != 200) {
        return this.$message.error(res.info);
      }
      this.detailsInfo = res.data;
      this.form.inputUserId = this.detailsInfo.inputUserId
      this.form.inputUserName = this.detailsInfo.inputUserName
      this.form.corpOrgId = this.detailsInfo.corpOrgId
      this.form.corpOrgName = this.detailsInfo.corpOrgName
      this.form.operateOrgId = this.detailsInfo.operateOrgId
      this.form.operateOrgName = this.detailsInfo.operateOrgName
      // 电商清单
      let data = []
      for (let mch of (res.data.rzMchLive || [])) {
        if (mch.fatherId) {
          let fatherMch = await this.getMchManageData(mch.fatherId)
          mch.fatherA2 = fatherMch.accountNoA2
          mch.fatherAccountName = fatherMch.accountName
        } else {
          mch.fatherA2 = ""
        }
        data.push(mch)
      }
      this.rzMchLive = data.sort((a, b) => b.id - a.id) // 电商倒序
      // 支付协议表
      this.payContracts = res.data.payContracts.sort((a, b) => b.id - a.id)
      // 代销协议表
      this.settleContracts = res.data.settleContracts.sort((a, b) => b.id - a.id)
    },
    // 获取手续费列表
    async getFeeRule() {
      let res = await this.api.feeRulepageApi({ pageNo: 1, pageSize: 1000, })
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.feeRuleList = res.data.records
    },
    // 获取可控电商详情
    async getMchManageData(id) {
      try {
        let res = await this.api.getMchManageDataApi({ id });
        return res.data
      } catch (e) {
        return {}
      }
    },
    onInputUserChange(inputUserId) {
      let inputUser = this.presetInputUserList.find(s => s.inputUserId == inputUserId)
      this.form.inputUserId = inputUser.inputUserId
      this.form.inputUserName = inputUser.inputUserName
      this.form.corpOrgId = inputUser.corpOrgId
      this.form.corpOrgName = inputUser.corpOrgName
      this.form.operateOrgId = inputUser.operateOrgId
      this.form.operateOrgName = inputUser.operateOrgName
    },
    downloadXlsx() {
      this.downloadRzMchLive()
    },
    // 电商清单下载
    downloadRzMchLive() {
      let wb = utils.book_new()
      // 基本信息
      let data = [
        ["申请人户名", "申请人证件号", "申请人手机号", "申请人A1",
          "申请人与经营实体关系", "经营实体户名", "经营实体营业执照号", "核心企业建议额度（万元）", "近三个月交易额（万元）",
          "一级核心企业保证金户名", "一级核心企业保证金账号", "二级核心企业保证金户名", "二级核心企业保证金账号",]
      ]
      let row = [
        this.detailsInfo.accountNameSq, //申请人户名
        this.detailsInfo.sqCertnum, // 申请人证件号
        this.detailsInfo.sqPhoneNumber, // 申请人手机号
        this.detailsInfo.accountNoSq, // 申请人A1
        BizDict.whethen1.find(x => x.value === this.detailsInfo.relaType)?.label, // 申请人与经营实体关系
        this.detailsInfo.accountNoSq != this.detailsInfo.rzAccountNoA1 ? this.detailsInfo.rzAccountNameA1 : "",// 对应经营实体户名,
        this.detailsInfo.accountNoSq != this.detailsInfo.rzAccountNoA1 ? this.detailsInfo.entityBusiLicense : "",// 经营实体营业执照号,
        this.detailsInfo.maxAmount / 10000,// 核心企业建议额度（元）,
        this.detailsInfo.threeMonthsAmount / 10000,// 近三个月交易额（元）,
        this.detailsInfo.accountNameDb, // 一级核心企业保证金户名,
        this.detailsInfo.accountNoDb, // 一级核心企业保证金账号,
        this.detailsInfo.accountNameDb2,// 二级核心企业保证金户名,
        this.detailsInfo.accountNoDb2,// 二级核心企业保证金账号,
      ]
      data.push(row)

      let ws = utils.aoa_to_sheet(data)
      ws["!cols"] = [
        { wch: 10 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 20 }, { wch: 25 }, { wch: 30 }, { wch: 30 }, { wch: 20 }, { wch: 30 }, { wch: 25 }, { wch: 30 }, { wch: 25 },
      ]
      utils.book_append_sheet(wb, ws, this.detailsInfo.accountNameSq + '-基本信息')
      // 电商清单
      let data1 = [
        ["序号",
          "融资比例", "退款比例", "电商类型", "回款周期", "商户名称",
          "回款电商", "回款电商户名", "回款电商A2", "手续费"]
      ]
      // 循环电商清单
      for (let mch of this.rzMchLive) {
        // 综合
        let mchFeeList = []
        if (mch.feeRuleNo) {
          mchFeeList.push({
            feeRuleNo: mch.feeRuleNo,
            projectName: "综合费率",
          })
        }
        // 明细
        let detailFeeList = []
        // 循环电商清单里的电商服务费项目表，目的是为了拿到明细费率
        if (mch.mchFeeInfos) {
          for (let fee of mch.mchFeeInfos) {
            if (fee.feeRuleNo) {
              detailFeeList.push({
                feeRuleNo: fee.feeRuleNo,
              })
            }
          }
        }

        // 得到明细和综合的所有数据
        let totalFeeList = mchFeeList.concat(detailFeeList).map(obj => {
          let rule = this.feeRuleList.find(x => x.feeNo == obj.feeRuleNo)
          if (obj.projectName) {
            return rule?.feeName + `(${obj.projectName})` ?? ''
          } else {
            return rule?.feeName ?? ''
          }
        })
        const joinTotalFeeList = totalFeeList.join(' ,')
        let row = [
          mch.mchNo, // 序号
          mch.creditProp * 100 + '%',// 融资比例
          mch.refundRate * 100 + '%',// 退款比例
          { "10": "商品类", "20": "娱乐类", "30": "资金类", "40": "代销类", "41": "代销类(特殊)" }[mch.goodsType] ?? "",//商品类型
          mch.orderType,// 回款周期
          mch.mchName,// 商户名称
          mch.fatherName,// 可控电商,
          mch.fatherAccountName,// 可控电商户名,
          mch.fatherA2,// 可控电商A2,
          joinTotalFeeList //手续费
        ]
        data1.push(row)
      }
      let ws1 = utils.aoa_to_sheet(data1)
      ws1["!cols"] = [
        { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 25 }, { wch: 25 }, { wch: 25 },
        { wch: 25 }, { wch: 25 },
      ]
      utils.book_append_sheet(wb, ws1, this.detailsInfo.accountNameSq + '-电商清单')
      // 支付协议
      let data2 = [
        ["序号",
          "付款户名", "付款账号", "收款户名", "收款账号", "收款行名", "收款行号",
        ]
      ]
      for (let mch of this.payContracts) {
        let row = [
          mch.mchNo, // 序号
          mch.accountName,//付款户名
          mch.accountNo,//付款账号
          mch.oppAccountName,//收款户名
          mch.oppAccountNo,//收款账号
          mch.oppBankName,//收款行名
          mch.oppBankNo,//收款行号
        ]
        data2.push(row)
      }
      let ws2 = utils.aoa_to_sheet(data2)
      ws2["!cols"] = [
        { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 30 }, { wch: 30 },
      ]
      utils.book_append_sheet(wb, ws2, this.detailsInfo.accountNameSq + '-支付协议')
      // 代销协议
      let data3 = [
        ["序号", "申请人户名", "申请人证件号", "申请人手机号", "申请人A1",
          "申请人与经营实体关系", "经营实体户名", "经营实体营业执照号", "核心企业建议额度（万元）", "近三个月交易额（万元）",
          "协议户名（电商A2）", "	协议账号（电商A2）", "协议户名（电商A1）", "	协议账号（电商A1）", "待清算户名1", "待清算账号1", "待清算余额1",
          "待清算户名2", "待清算账号2", "待清算余额2",
          "一级核心企业保证金户名", "一级核心企业保证金账号", "二级核心企业保证金户名", "二级核心企业保证金账号",]
      ]
      for (let mch of this.settleContracts) {
        let row = [
          mch.mchNo, // 序号
          this.detailsInfo.accountNameSq, //申请人户名
          this.detailsInfo.sqCertnum, // 申请人证件号
          this.detailsInfo.sqPhoneNumber, // 申请人手机号
          this.detailsInfo.accountNoSq, // 申请人A1
          BizDict.whethen1.find(x => x.value === this.detailsInfo.relaType)?.label, // 申请人与经营实体关系
          this.detailsInfo.accountNoSq != this.detailsInfo.accountNo ? this.detailsInfo.rzAccountNameA1 : "",// 对应经营实体户名,
          this.detailsInfo.accountNoSq != this.detailsInfo.accountNo ? this.detailsInfo.entityBusiLicense : "",// 经营实体营业执照号,
          this.detailsInfo.maxAmount / 10000,// 核心企业建议额度（元）,
          this.detailsInfo.threeMonthsAmount / 10000,// 近三个月交易额（元）,
          mch.accountName,//协议户名（电商A2）
          mch.accountNo,//协议账号（电商A2）
          mch.accountName1,//协议户名（电商A1）
          mch.accountNo1,//协议账号（电商A1）
          mch.accountNameSettling1,//待清算户名1
          mch.accountNoSettling1,//待清算账号1
          mch.settling1Balance,//待清算余额1
          mch.accountNameSettling2,//待清算户名2
          mch.accountNoSettling2,//待清算账号2
          mch.settling2Balance,//待清算余额2
          this.detailsInfo.accountNameDb, // 一级核心企业保证金户名,
          this.detailsInfo.accountNoDb, // 一级核心企业保证金账号,
          this.detailsInfo.accountNameDb2,// 二级核心企业保证金户名,
          this.detailsInfo.accountNoDb2,// 二级核心企业保证金账号,
        ]
        data3.push(row)
      }
      let ws3 = utils.aoa_to_sheet(data3)
      ws3["!cols"] = [
        { wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 25 }, { wch: 25 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 25 }, { wch: 25 }, { wch: 20 }, { wch: 25 }, { wch: 25 }, { wch: 20 },
        { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 22 },
      ]
      utils.book_append_sheet(wb, ws3, this.detailsInfo.accountNameSq + '-代销协议')
      writeFile(wb, `融资白名单-${this.detailsInfo.accountNameSq}.xlsx`);
    },
    printPage() {
      // 隐藏不需要打印的部分
      let noPrintEls = document.querySelectorAll(".no-print")
      noPrintEls.forEach(el => el.style.display = "none")
      window.print();
      // 恢复不需要打印的部分的显示
      noPrintEls.forEach(el => el.style.display = "")
    },
    // 点击通过
    async pass() {
      // 校验
      let maxAmount = this.detailsInfo.maxAmount / 10000
      // 找出电商清单中不是商品类的，如果有，就代表这个数组中不全是商品类
      let newArr = this.rzMchLive.filter(item => item.goodsType !== '10')
      if (maxAmount > 500 && newArr.length == 0) {
        return this.$message.warning('商品类额度上限为500万元！')
      }
      let result = await this.saveCheckStatus(1)
      if (result) this.handleOk()
    },
    // 点击拒绝
    async reject() {
      if (this.detailsInfo.failMsg == "") {
        return this.$message.warning("请填写审核意见");
      }
      let result = await this.saveCheckStatus(2)
      if (result) this.handleOk()
    },
    async saveCheckStatus(status) {
      let data = {
        // bmdId: this.selectedInfo.id,
        // checkStatus: status,
        // failMsg: this.form.comment,
        id: this.selectedInfo.id,
        checkStatus: status,
        failMsg: this.detailsInfo.failMsg,
        corpOrgId: this.form.corpOrgId,//法人机构号
        corpOrgName: this.form.corpOrgName,//法人机构名称
        inputUserId: this.form.inputUserId,//推荐人工号
        inputUserName: this.form.inputUserName,//推荐人名称
        operateOrgId: this.form.operateOrgId,//所属机构号
        operateOrgName: this.form.operateOrgName//所属机构名称
      };
      const res = await this.api.creditbmdbmdApproveApi(data);
      if (res.code !== 200) {
        this.$message.warning(res.info);
        this.selectedInfo.checkStatus = "3";
        return;
      }
      this.$message.success(res.info);
      this.selectedInfo.checkStatus = status;
      return true
    },
    handleOk() {
      this.$emit("approveDialogEvent", { bool: false });
    },
    handleCancel() {
      this.$emit("approveDialogEvent", { bool: false });
    },

  },
};
</script>

<style lang="less" scoped>
@media print {
  @page {
    size: landscape; // 强制横向打印
    margin: 0
  }

  body {
    margin: 0.5cm;
  }

  .no-print {
    display: none;
  }
}

/deep/ .ant-modal {
  width: 1000px !important;

  .ant-modal-content {
    width: 100% !important;
  }

  .ant-row {
    display: flex;
    align-items: center;
  }
}

.control-box {
  img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 5px;
  }


}

.control-box.canot-click {
  display: flex;
  align-items: center;
  pointer-events: none;
}



.container {
  .container-item {
    min-height: 20px;
    margin-bottom: 10px;
    // font-weight: bold;
    color: #2b2b2b;



    .ant-input,
    .ant-select,
    .ant-select-selection--single {
      width: 200px;
      height: 24px;
    }

    .ant-col-8,
    .ant-col-6,
    .ant-col-4 {
      text-align: right;
      padding-right: 20px;
      font-weight: bold;
    }
  }
}
</style>
