<template>
  <div>
    <a-card style="margin:20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>原始订单管理</span> <a-icon type="right" /> <span style="font-weight: 500;">查看</span>
          </div>

        </div>
      </template>
      <template slot="extra">
        <a-button @click="toBack">
          返回
        </a-button>
      </template>
      <!-- 原始订单管理查看 -->
      <div class="viewbox">

        <!-- 基本信息 -->
        <div class="Basicinformation">
          <h2>
            基本信息
          </h2>
          <div class="list">

            <div class="item">
              <div class="row">订单编号</div>
              <div class="row">订单类型</div>
              <div class="row">售后状态</div>
              <div class="row">电商编号</div>
              <div class="row">店铺编号/ID</div>
              <div class="row">汇总订单编号</div>
            </div>
            <div class="item">
              <div class="row">{{ ordersdetails.orderNo ? ordersdetails.orderNo : '----' }}</div>
              <div class="row">{{ ordersdetails.orderType ? ordertype(ordersdetails.orderType) : '----' }}</div>
              <div class="row">{{ Number(ordersdetails.refundStatus) == 0 ? '未退款' : ' 已退款' }}</div>
              <div class="row">{{ ordersdetails.mchNo ? ordersdetails.mchNo : '----' }}</div>
              <div class="row">{{ ordersdetails.shopNo ? ordersdetails.shopNo : '----' }}</div>
              <div class="row">{{ ordersdetails.collectOrdersNo ? ordersdetails.collectOrdersNo : '----' }}</div>
            </div>
            <div class="item">
              <div class="row">下单时间</div>
              <div class="row">订单状态</div>
              <div class="row">结算状态</div>
              <div class="row">电商名称</div>
              <div class="row">店铺名称</div>
              <div class="row">汇总订单生成时间</div>
            </div>
            <div class="item">
              <div class="row">{{ ordersdetails.orderTime ? ordersdetails.orderTime : '----' }}</div>
              <div class="row">{{ ordersdetails.orderType ? orderStatus(ordersdetails.orderStatus) : '----' }}</div>
              <div class="row">{{ Number(ordersdetails.settleStatus) == 0 ? '未结算' : "已结算" }}</div>
              <div class="row">{{ ordersdetails.mchName ? ordersdetails.mchName : '----' }}</div>
              <div class="row">{{ ordersdetails.shopName ? ordersdetails.shopName : '----' }}</div>
              <div class="row">{{ ordersdetails.collectOrdersTime ? ordersdetails.collectOrdersTime : '----' }}</div>
            </div>
          </div>
        </div>

        <!-- 主播达人 -->
        <div class="anchorman">
          <h2>
            主播/达人
          </h2>
          <div class="list">

            <div class="item">
              <div class="row">主播/达人ID</div>

            </div>
            <div class="item">
              <div class="row">{{ ordersdetails.broadcastId ? ordersdetails.broadcastId : '----' }}</div>


            </div>
            <div class="item">
              <div class="row">达人昵称</div>

            </div>
            <div class="item">
              <div class="row">{{ ordersdetails.broadcastName ? ordersdetails.broadcastName : '----' }}</div>

            </div>
          </div>
        </div>
        <!--  费用相关  -->
        <div class="Expensecorrelation">
          <h2>
            费用相关
          </h2>
          <div class="list">

            <div class="item">
              <div class="row">订单金额（¥）</div>
              <div class="row">订单实付金额（¥）</div>
              <div class="row">主播补贴金额（¥）</div>
              <div class="row">达人佣金（¥）</div>
              <div class="row">快赚客佣金（¥）</div>
              <div class="row">订单退款金额（¥）</div>
            </div>
            <div class="item">
              <div class="row">{{ ordersdetails.orderAmount ? ordersdetails.orderAmount : '0.00' }}</div>
              <div class="row">{{ ordersdetails.payAmount ? ordersdetails.payAmount : '0.00' }}</div>
              <div class="row">{{ ordersdetails.zbBtAmount ? ordersdetails.zbBtAmount : '0.00' }}</div>
              <div class="row">{{ ordersdetails.zbFeeAmount ? ordersdetails.zbFeeAmount : '0.00' }}</div>
              <div class="row">{{ ordersdetails.kzkFeeAmount ? ordersdetails.kzkFeeAmount : '0.00' }}</div>
              <div class="row">{{ ordersdetails.refundAmount ? ordersdetails.refundAmount : '0.00' }}</div>
            </div>
            <div class="item">
              <div class="row">优惠总金额（¥）</div>
              <div class="row">平台补贴金额（¥）</div>
              <div class="row">技术服务费（¥）</div>
              <div class="row">团长佣金（¥）</div>
              <div class="row">服务商佣金（¥）</div>
              <div class="row">结算金额（¥）</div>
            </div>
            <div class="item">
              <div class="row">{{ ordersdetails.couponAmount ? ordersdetails.couponAmount : '0.00' }}</div>
              <div class="row">{{ ordersdetails.ptBtAmount ? ordersdetails.ptBtAmount : '0.00' }}</div>
              <div class="row">{{ ordersdetails.jsFeeAmount ? ordersdetails.jsFeeAmount : '0.00' }}</div>
              <div class="row">{{ ordersdetails.tzFeeAmount ? ordersdetails.tzFeeAmount : '0.00' }}</div>
              <div class="row">{{ ordersdetails.fwsFeeAmount ? ordersdetails.fwsFeeAmount : '0.00' }}</div>
              <div class="row">{{ ordersdetails.settleAmount ? ordersdetails.settleAmount : '0.00' }}</div>
            </div>
          </div>
        </div>

        <!-- 商品相关 -->
        <div class="Commoditycorrelation">
          <h2>
            商品相关
          </h2>
          <div class="list">

            <div class="item">
              <div class="row">商品ID</div>


            </div>
            <div class="item">
              <div class="row">{{ ordersdetails.goodsNo ? ordersdetails.goodsNo : '----' }}</div>


            </div>
            <div class="item">
              <div class="row">商品名称</div>

            </div>
            <div class="item">
              <div class="row">{{ ordersdetails.goodsName ? ordersdetails.goodsName : '----' }}</div>


            </div>
          </div>
        </div>
      </div>

    </a-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ordersdetails: {}
    }
  },
  created() {
    this.getoriordersdetails()
  },
  mounted() {
    sessionStorage.setItem('msgInfo', true)
  },
  methods: {
    async getoriordersdetails() {
      const res = await this.api.oriordersdetailsApi(this.$route.query.id)
      if (res.code !== 200) {
        this.$message.error(res.info);
        return
      }
      this.ordersdetails = res.data

    },
    ordertype: (text) => {

      if (Number(text) == 0) {
        return '普通订单'
      } else if (Number(text) == 1) {
        return '秒杀订单'
      } else if (Number(text) == 2) {
        return '预售订单'
      } else if (Number(text) == 3) {
        return '虚拟商品订单'
      } else if (Number(text) == 4) {
        return '分销订单'
      } else if (Number(text) == 5) {
        return '赊销订单'
      } else if (Number(text) == 6) {
        return '电子券（poi核销）'
      } else if (Number(text) == 7) {
        return '"三方核销'
      }
    },

    // * 0.待支付：订单已创建，但尚未完成支付。
    // * 1.已支付：订单已完成支付，等待商家确认。
    // * 2.待发货：商家已确认订单并准备发货。
    // * 3.已发货：商家已发货，等待用户收货。
    // * 4.已完成：订单已成功交易，用户确认收货。
    // * 5.已关闭：订单交易失败或取消，订单生命周期结束。
    orderStatus: (text) => {

      if (Number(text) == 0) {
        return '待支付'
      } else if (Number(text) == 1) {
        return '已支付'
      } else if (Number(text) == 2) {
        return '待发货'
      } else if (Number(text) == 3) {
        return '已发货'
      } else if (Number(text) == 4) {
        return '已完成'
      } else if (Number(text) == 5) {
        return '已关闭'
      }
    },
    toBack() {
      this.$router.back(-1)
    }
  }

}
</script>

<style lang="less" scoped>
.ant-table-thead>tr>th {
  background-color: #f2f2f2;

}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .right {
    button {
      height: 24px;
    }
  }
}

.viewbox {

  .Basicinformation,
  .anchorman,
  .Expensecorrelation,
  .Commoditycorrelation {
    h2 {
      margin-bottom: 12px;
    }

    .list {
      margin-bottom: 20px;
      display: flex;

      .item {

        flex: 1;

        .row {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:nth-child(2n+1) {
          .row {
            background-color: #f2f2f2;
            height: 35px;
            width: 100%;
          }
        }

        &:nth-child(2n) {
          .row {

            height: 35px;
            width: 100%;
            border-bottom: 1px solid #f2f2f2;

            &:first-child {
              border-top: 1px solid #f2f2f2;
            }
          }
        }

        &:nth-child(4) {
          .row {

            height: 35px;
            width: 100%;

            border-right: 1px solid #f2f2f2;


          }
        }


      }
    }
  }
}

/deep/ .ant-card-head-title {
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .dianshang {
      margin-left: 50px;
    }
  }


}
</style>