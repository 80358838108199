<template>
  <div>
    <a-card style="margin:20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>账户管理</span> <a-icon type="right" /> <span>账户信息</span>
          </div>
        </div>
      </template>
      <template slot="extra">
        <a-button @click="toBack">返回</a-button>
      </template>

      <div class="Accountview">
        <!-- 用户信息区域 -->

        <div class="userinfo">
          <div class="userinfo-title">
            {{ this.userinfo.accountName }}
          </div>
          <a-menu
              v-model="selectedKey"
              style="display: flex; flex-wrap: nowrap; white-space: nowrap; overflow-x: auto; "
              :default-open-keys="openKey"

          >
            <a-menu-item v-for="(item, index) in accountInfoList" :key="index" @click="clickMenuItem($event)"   style="flex: none;">
                <a-card style=" width: 300px; height: 150px;border-radius: 8px;padding: 0 8px;"
                        v-if="item.account_class !== '6' || $authority('A2BalanceVisible')"
                        :style="selectedKey == index ? 'border-color:#1890ff' : 'border-color:#e8e8e8'">

                  <a-tag :color="tagColor(item.account_class)">{{ tagText(item.account_class) }}</a-tag>
                  :
                  <span>{{ item.account_no }}</span>
                  <div class="balance-box">
                    <div class="box-left">
                      余额:
                    </div>
                    <Balance :amount="item.cur_balance" style="font-weight: bold; "></Balance>
                    <!-- 提现 -->
                    <a-button @click="withdraw" v-if="Number(item.account_class) == 0 && $authority('withdrawDeposit')"
                              type="link">提现</a-button>
                    <!-- 保证金释放 -->
                    <a-button @click="Marginrelease(item)" type="link" v-if="$authority('marginRelease') && item.account_class == '2'">保证金释放</a-button>
                  </div>
                </a-card>

            </a-menu-item>
          </a-menu>
        </div>
        <!-- 表格 -->
        <!-- table 区域 -->

        <div class="tablebox">
          <!-- 搜索查询 -->
          <!-- 表格 -->
          <div class="searchbox">
            <div class="lefttime">
              <span>交易时间:</span>
              <a-range-picker v-model.trim="searchtime" valueFormat="YYYY-MM-DD" @change="onChange" />
              <a-radio-group v-model="datater" @change="dataonChange">
                <a-radio :value="1">
                  今天
                </a-radio>
                <a-radio :value="2">
                  昨天
                </a-radio>
                <a-radio :value="3">
                  近七天
                </a-radio>
                <a-radio :value="4">
                  当月
                </a-radio>
                <a-radio :value="5">
                  上月
                </a-radio>
              </a-radio-group>
            </div>

            <div class="right">
              <a-button type="primary" @click="search">
                查询
              </a-button>
              <a-button @click="reset">重置</a-button>
            </div>
          </div>
          <!-- 下载 -->

          <div class="uploadbox">
            <div>
              <a-button type="primary" @click="returnBillDownload" v-if="$authority('receiptDownload')">
                回单下载
              </a-button>
              <a-button type="primary" @click="checkBillDownload" v-if="$authority('statementDownload')">
                对账单下载
              </a-button>
              <a-button @click="Detailexport" type="primary" v-if="$authority('Detailexport')">
                明细导出
              </a-button>
            </div>
          </div>
          <div class="tableBody">
            <a-table :rowKey="(record, index) => `${record.id}`" :columns="columns" :data-source="data" size="small"
              :scroll="{ x: 1800, y: 500 }"
              :row-selection="{ selectedRowKeys: selectedRowKeys, hideDefaultSelections: true, onChange: onSelectChange, }"
              :pagination="false">
            </a-table>
          </div>
        </div>

      </div> <!-- 分页部分 -->
      <div class="paginationBox">
        <!-- <a-pagination v-model.trim="form.page" :page-size-options="pageSizeOptions" :total="total" show-size-changer
            :page-size="form.row" @change="pagechange" @showSizeChange="onShowSizeChange" :item-render="itemRender"
            show-quick-jumper>

          </a-pagination> -->
      </div>
    </a-card>
    <!-- 保证金释放 -->
    <a-modal :maskClosable="true" title="保证金释放" :centered="true" :visible="Securitydepositvisible"
      @cancel="Securitydepositvisible = false">

      <!-- 保证金释放 -->
      <div class="SecuritydepositRelease" >
        <a-descriptions :column="1" bordered size="small">
          <a-descriptions-item label="账户名称">
            {{ userinfo.accountName }}
          </a-descriptions-item>
          <a-descriptions-item label="保证金账号">
            {{ bailAccountInfo.account_no }}
          </a-descriptions-item>
          <a-descriptions-item label="活期账号">
            {{ userinfo.accountNo }}
          </a-descriptions-item>
          <a-descriptions-item label="保证金余额">
            <Balance :amount="bailAccountInfo.avail_balance" :align-right="false"></Balance>
          </a-descriptions-item>
        </a-descriptions>
        <a-divider></a-divider>
        <!-- 释放金额 -->
        <div class="Payoverbox">
          <div class="left-title">
            释放金额
          </div>
          <a-input type="number" v-model.trim="Releaseamount" suffix="元" @blur="formatReleaseamt"> </a-input>
          <a-button type="link" @click="topReleaseAmount">全部</a-button>
        </div>

        <!--短信验证码-->

        <div class="codebox">
          <div class="left-title">短信验证码：</div>
          <a-input v-model.trim="Releasecode" class="code">
            <template #suffix>
              <CountdownBtn :sendCall="()=>{return sendcode(1)}"/>
            </template>
          </a-input>
        </div>
      </div>

      <template v-slot:footer>
        <!-- 释放 -->
        <div class="custom-footer">
          <a-button @click="Securitydepositvisible = false; show = true, Countdown = 60">取消</a-button>
          <a-button type="primary" @click="Release">释放</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 提现弹窗 -->
    <a-modal :maskClosable="true" title="提现" :centered="true" :visible="withdrawvisible"
      @cancel="withdrawvisible = false">
      <!-- 提现 -->
      <div class="Confirmedwithdrawal">

        <a-descriptions :column="1" bordered size="small">

          <a-descriptions-item label="付款账户" :span="2">
            {{ userinfo.accountName }}
          </a-descriptions-item>
          <a-descriptions-item label="付款账号" :span="2">
            {{ userinfo.accountNo }}
          </a-descriptions-item>
          <a-descriptions-item label="手机号" :span="2">
            {{userinfo.mobile}}
          </a-descriptions-item>
          <a-descriptions-item label="可提现">
            <Balance :amount="userinfo.withdrawBalance" :align-right="false"></Balance>
          </a-descriptions-item>
          <a-descriptions-item label="手续费">
            <Balance :amount="0" :align-right="false"></Balance>
          </a-descriptions-item>
        </a-descriptions>
          <!-- 银行卡号 -->
        <a-divider></a-divider>

        <div class="Bankcard">
          <div class="left-title">收款账号 :</div>
          <a-select v-model.trim="Bankcardnumber" placeholder="请选择银行卡号" @change="Bankcardchange" style="width: 245px">
            <a-select-option v-for="(item, ind) in Bankcardlist" :key="ind" :value="item.value">
              {{  item.label }}<br>{{ item.bankName }}
            </a-select-option>
          </a-select>
        </div>
        <!-- 提现金额 -->
        <div class="Payoverbox">
          <div class="left-title">
            提现金额 :
          </div>
          <a-input type="number" v-model.trim="withdrawamount" suffix="元" @blur="formatReleaseamt"/>
          <a-button type="link" @click="topAmount">全部</a-button>
        </div>
        <!--短信验证码-->
        <div class="codebox">
          <div class="left-title">短信验证码 ：</div>
          <a-input v-model.trim="withdrawcode" class="code">
            <template #suffix>
              <CountdownBtn :sendCall="()=>{return sendcode(2)}" :disabled="!Bankcardnumber||!withdrawamount"></CountdownBtn>
            </template>
          </a-input>
        </div>
      </div>
      <template v-slot:footer>
        <!-- 提现 -->
        <div class="custom-footer">
          <a-button @click="withdrawvisible = false">取消</a-button>
          <a-button type="primary" @click="Confirmedwithdrawal" :disabled="!Bankcardnumber||!withdrawamount">提现</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import Balance from '@/view/components/Balance.vue'
import CountdownBtn from "@/view/components/CountdownBtn.vue";

// import moment from "moment"

export default {
  components: {
    CountdownBtn,
    Balance
  },
  data() {
    return {
      selectedKey: [0],
      openKey: [],
      accountInfoList: [],
      userinfo: {},
      bailAccountInfo: {},
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      searchtime: [],
      selectedRowKeys: [],
      selectedRows: [],
      show: true,
      Countdown: 60,
      // 保证金释放弹窗
      Securitydepositvisible: false,
      // 提现弹窗
      withdrawvisible: false,
      withdrawcode: '', // 提现验证码
      withdrawamount: '', //提现金额
      Bankcardnumber: undefined, // 银行卡号
      Bankcardname: '', //银行名称
      Bankcardlist: [],
      datater: "",// 日期
      // 保证金金额
      Releaseamount: '',
      form: {
        page: 0,
        channel_no: "", // 渠道编号
        app_no: '',//	应用编号	
        account_no: "",// 账号
        start_date: '',
        end_date: '',
      },
      // 验证码
      Releasecode: '',
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) => `${index + 1}`,
          align: 'center',
          width: 60,
        },
        {
          title: '流水号',
          dataIndex: 'serial_no',
          align: 'center',
          width: 230,
          ellipsis: true,
        },
        {
          title: '记账日期',
          dataIndex: 'account_date',
          align: 'center',
          width: 120,
        },
        {
          title: '交易类型',
          dataIndex: 'trans_type',
          align: 'center',
          width: 80,

          customRender: (text) => {
            if (Number(text) == 0) {
              return '其他'
            } else if (Number(text) == 1) {
              return '开户'
            }
            else if (Number(text) == 2) {
              return '修改'
            }
            else if (Number(text) == 3) {
              return '销户'
            }
            else if (Number(text) == 4) {
              return '存款'
            }
            else if (Number(text) == 5) {
              return '取款'
            }
            else if (Number(text) == 6) {
              return '转入'
            }
            else if (Number(text) == 7) {
              return '转出'
            }
            else if (Number(text) == 8) {
              return '撤销'
            } else {
              return text;
            }
          },
        },
        {
          title: '对方户名',
          dataIndex: 'opp_account_name',
          align: 'center',
          width: 200,
          ellipsis: true,
        },
        {
          title: '对方账户',
          dataIndex: 'opp_account_no',
          align: 'center',
          width: 160,
        },
        {
          title: '交易金额',
          dataIndex: 'trans_amount',
          align: 'center',
          customRender: (text, row) => {
            let dcflag = ''
            if (['4', '6'].indexOf(row.trans_type) >= 0) dcflag = "d"
            if (['5', '7'].indexOf(row.trans_type) >= 0) dcflag = "c"
            return <Balance amount={text} dcflag={dcflag}></Balance>
          },
          width: 140,
        },
        {
          title: '交易后余额',
          dataIndex: 'balance',
          align: 'center',
          width: 140,
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          },
        },
        {
          title: '交易时间',
          dataIndex: 'trade_time',
          align: 'center',
          width: 180,
        },
        {
          title: '摘要',
          dataIndex: 'remark',
          align: 'center',
          ellipsis: true,
        },

      ],

      // 表格数据
      data: [],
      total: 0, // 总数

    }
  },

  created() {
    // 获取存储的用户信息
    this.userinfo = this.$store.state.Accountmanagementinfo
    this.form.app_no = this.$store.state.Accountmanagementinfo.appNo
    this.form.channel_no = this.$store.state.Accountmanagementinfo.channelNo
    this.form.account_no = this.$store.state.Accountmanagementinfo.accountNo
    const currentTime = dayjs(); // 获取当前日期
    const startOfMonth = dayjs().startOf('month'); // 获取本月月初时间
    this.form.start_date = startOfMonth.format('YYYY-MM-DD')
    this.form.end_date = currentTime/*.add(1, 'day')*/.format('YYYY-MM-DD')
    this.searchtime[0] = this.form.start_date
    this.searchtime[1] = this.form.end_date
    // 获取账号
    this.getAccountNum()
    // 获取交易详细
    this.getqueryTradeDetail()
  },
  mounted() {
    sessionStorage.setItem('msgInfo', true)
  },

  computed: {
    tagColor() {
      return function (type) {
        if (type == '0') {
          return 'green'
        } else if (type == '6') {
          return 'blue'
        } else if (type == '8') {
          return 'blue'
        } else if (type == '7') {
          return 'purple'
        } else if (type == '2') {
          return 'yellow'
        }
      }
    },
    tagText() {
      return function (type) {
        if (type == '0') {
          return '活期A1'
        } else if (type == '6') {
          return '冻结A2'
        } else if (type == '7') {
          return '贷款A3'
        } else if (type == '2') {
          return '保证A6'
        } else if (type == '8') {
          return '分户A7'
        }
      }
    },
  },
  methods: {
    // 保证金释放函数
    Marginrelease(item) {
      this.bailAccountInfo = item
      this.Securitydepositvisible = true
    },
    dataonChange($event) {
      //  今天 1 昨天2 近七天 3 当月4 上月5 

      this.$set(this.searchtime, 0, dayjs().format('YYYY-MM-DD')); // 替换数组中的某一项
      if ($event.target.value == 1) {
        this.form.start_date = dayjs().format('YYYY-MM-DD')
        this.form.end_date = dayjs().format('YYYY-MM-DD')
      }
      else if ($event.target.value == 2) {
        this.form.start_date = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        this.form.end_date = dayjs().subtract(1,'day').format('YYYY-MM-DD')
      }
      else if ($event.target.value == 3) {
        this.form.start_date = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
        this.form.end_date = dayjs()/*.add(1, 'day')*/.format('YYYY-MM-DD')
      }
      else if ($event.target.value == 4) {
        this.form.start_date = dayjs().startOf('month').format('YYYY-MM-DD')
        this.form.end_date = dayjs()/*.add(1, 'day')*/.format('YYYY-MM-DD')
      }
      else if ($event.target.value == 5) {
        this.form.start_date = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
        this.form.end_date = dayjs(this.form.start_date).endOf('month')/*.add(1, 'day')*/.format('YYYY-MM-DD')
      }
      // 开始时间
      this.$set(this.searchtime, 0, this.form.start_date);
      // 结束时间
      this.$set(this.searchtime, 1, this.form.end_date);


    },
    // 获取数据
    async getqueryTradeDetail() {

      const res = await this.api.queryTradeDetailApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info);
        return
      }
      this.total = res.data.total
      this.form.page = res.data.current;
      // this.form.row = res.data.size;

      this.data = res.data.detail_list
    },
    async getAccountNum() {
      const res = await this.api.accountqueryBalancedApi({
        channel_no: this.userinfo.channelNo,
        account_no: this.userinfo.majorAccountNo || this.userinfo.accountNo,
        relation_acct: this.userinfo.accountRelation,
      })
      if (res.code !== 200) {
        return this.$message.error(res.info);
      }
      this.accountInfoList = res.data.detail_list
      let keyarr = []
      this.accountInfoList.map((item, index) => {
        keyarr.push(index)
      })
      this.openKey = keyarr
    },
    // 明细导出
    async Detailexport() {
      // accountTradeDetailExportApi
      let data = {
        channel_no: this.userinfo.channelNo,
        app_no: this.userinfo.appNo,
        account_no: this.form.account_no,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
      }
      const res = await this.api.accountTradeDetailExportApi(data)
      this.$uploadfile.ExcelDownload('交易明细', res)
    },


    search() {
      this.getqueryTradeDetail()
    },
    // 选择名称
    Bankcardchange(value) {
      this.Bankcardlist.forEach((item) => {

        if (Number(item.value) == value) {
          this.Bankcardname = item.bankName
        }
      })
    },
    // 提现函数
    async withdraw() {
      const res = await this.api.bankcardpageApi(0, 100000, {
        custnum: this.userinfo.custnum,
        accountNo: this.userinfo.majorAccountNo || this.userinfo.accountNo,
      })

      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }

      this.Bankcardlist= []
      res.data.records.forEach((item) => {
        this.Bankcardlist.push({
          label: item.bankCardNo,
          value: item.bankCardNo,
          bankName: item.bankName,
        })
      })
      this.withdrawvisible = true
    },
    formatWithdrawamt(){
      this.withdrawamount = Number(this.withdrawamount).toFixed(2)
    },
    formatReleaseamt(){
      this.Releaseamount = Number(this.Releaseamount).toFixed(2)
    },
    // 时间选择器
    onChange() {
      this.form.start_date = this.searchtime[0]
      // this.form.end_date = this.searchtime[1]
      this.form.end_date = dayjs(this.searchtime[1])/*.add(1, 'day')*/.format('YYYY-MM-DD')
      this.datater = ''
    },
    // 查看客户详情
    Customerdetail() {
      // 个人
      if (Number(this.userinfo.accountProperty) == 1) {
        this.$router.push({
          path: "/cust/personage/addCust",
          query: {
            custnum: this.userinfo.custnum,
            type: 'view'
          }
        })
      }
      // 企业
      if (Number(this.userinfo.accountProperty) == 2) {
        this.$router.push({
          name: 'addEnterprise',
          query: {
            custnum: this.userinfo.custnum,
            type: 'view',
          },
        })
      }



    },
    // 回单下载
    async returnBillDownload() {
      if (this.selectedRows.length == 0 || !this.selectedRows) {

        this.$message.warning('请先选择一条记录',);
        return

      }
      // returnBillDownloadApi
      let data = {
        channel_no: this.userinfo.channelNo,
        app_no: this.userinfo.appNo,
        account_no: this.form.account_no,
        original_serialno: this.selectedRows[0].original_serialno

      }
      const res = await this.api.returnBillDownloadApi(data)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      if (res.data.fileList == null) {

        this.$message.warning('此账号暂无回单信息')
      }
      res.data.fileList?.forEach(item => {

        this.$uploadfile.PDFDownload('回单下载', item.file_data)
      });


    },

    // 对账单下载
    async checkBillDownload() {
      // checkBillDownloadApi
      let data = {
        //  this.form.app_no = this.$store.state.Accountmanagementinfo.appNo
        //         this.form.channel_no = this.$store.state.Accountmanagementinfo.channelNo

        // this.form.account_no = this.$store.state.Accountmanagementinfo.accountNo
        channel_no: this.userinfo.channelNo,
        app_no: this.userinfo.appNo,
        account_no: this.form.account_no,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
      }
      const res = await this.api.checkBillDownloadApi(data)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$uploadfile.PDFDownload('对账单下载', res.data.file_data)

    },
    reset() {
      this.searchtime = []
      this.form.start_date = ''
      this.form.pageNo = 0
      this.form.end_date = ''
    },
    topAmount(){
      this.withdrawamount = Number(this.userinfo.balance).toFixed(2)
    },
    topReleaseAmount(){
      this.Releaseamount = Number(this.bailAccountInfo.avail_balance).toFixed(2)

    },
    // 确认提现
    async Confirmedwithdrawal() {

      // 校验卡号，金额，验证码  

      if (this.withdrawcode == '' || this.withdrawamount == '' || this.Bankcardnumber == '') {
        this.$message.warning('请先选择银行卡并且输入提现金额和验证码');
        return
      }
      // 校验验证码,金额
      if (
        this.withdrawcode !== '' &&
        this.withdrawamount !== '' &&
        this.Bankcardnumber !== ''
      ) {

        // 发送提现请求
        let data = {
          channel_no: this.userinfo.channelNo,
          app_no: this.userinfo.appNo,
          account_no: this.userinfo.accountNo,
          account_name: this.userinfo.accountName,
          primary_account: this.Bankcardnumber,
          amount: this.withdrawamount,
          verify_code: this.withdrawcode,
        }
        const res = await this.api.merWithdrawApi(data)
        if (res.code !== 200) {
          this.$message.error(res.info)
          return
        }
        this.$message.success('提现成功')
        this.withdrawvisible = false
      }
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {

      if (type === 'prev') {
        return <a>上一页</a>;
      } else if (type === 'next') {
        return <a>下一页</a>;
      }
      return originalElement;
    },
    // pagechange(val) {

    //   this.form.page = val;
    //   this.getcustaccountPage()
    // },
    // 发送验证码
    async sendcode(type) {
      // 1 释放 ， 提现 2
      let data = {
        appNo: Number(this.userinfo.appNo),
        channelNo: Number(this.userinfo.channelNo),
        mobile: Number(this.userinfo.mobile),
        tradeType: '',
      }
      if (type == 1) {
        data.modelNo = '102011'
        data.tradeType = 9;
      } else if (type == 2) {
        data.tradeType = 3;
        data.accountNo = this.userinfo.accountNo
        data.cardNo = this.Bankcardnumber
        data.idNo = this.userinfo.certnum
        data.accountName = this.userinfo.accountName
        data.amount = this.withdrawamount
      }
      const res = await this.api.vefifycodesend(data)
      if (res.code == 200) {

        this.$message.success('验证码发送成功');
        this.show = false
      } else {
        this.$message.error(res.msg);
      }

    },
    //选中时触发的回调 
    onSelectChange(selectedRowKeys, selectedRows) {


      this.selectedRowKeys = selectedRowKeys
      if (selectedRows.length > 1) {
        //获取选中的数据的key
        var selectNumber = this.selectedRowKeys[1]
        //清空选中的key
        this.selectedRowKeys = []
        //选中的数据的key重新赋值给selectedRowKeys
        this.selectedRowKeys.push(selectNumber)
      }
      //获取选中的数据详细信息
      this.selectedRows = [selectedRows[0]]

      if (this.selectedRows[0])
        this.form.account_no = this.selectedRows[0].account_no
      else
        this.form.account_no = ""

    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {

      this.form.row = pageSize;
      this.getcustaccountPage()
    },

    // 确认释放函数
    async Release() {

      if (this.Releaseamount == '' || this.Releasecode == '') {
        this.$message.warning('请先输入释放金额和验证码');
        return
      }
      // 校验验证码,金额
      if (this.Releaseamount !== '' && this.Releasecode !== '') {

        // 验证码校验
        let data = {
          channel_no: this.userinfo.channelNo,
          app_no: this.userinfo.appNo,
          account_no: this.userinfo.accountNo,
          account_name: this.userinfo.accountName,
          trade_amount: this.Releaseamount,
          verify_code: this.Releasecode,
          remark: '',
        }
        const res = await this.api.bailRepayApi(data)

        if (res.code !== 200) {
          this.$message.error(res.info);
          return
        }
        this.$message.success('保证金释放成功');
        this.Securitydepositvisible = false
        this.Releasecode = ''
        this.Releaseamount = ''

        setTimeout(() => {
          this.$router.go(-1)
        }, 500)

      }
    },
    clickMenuItem(item) {
      this.accountInfoList.forEach((obj, ind) => {
        if (item.key == ind) {
          this.form.account_no = obj.account_no
        }
      })
      this.form.page = 0

    },
    toBack() {
      // 返回列表页
      let toURl = ''
      if (this.userinfo.arrList) {
        // 账户列表页
        toURl = '/wallet/accountlist'
      } else {
        // 账户管理
        toURl = '/wallet/accountmanagement'
      }
      this.$router.push(toURl)
    }
  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped>
/* 自定义表头样式 */
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}



.ant-menu-horizontal>.ant-menu-item,
/deep/.ant-menu-horizontal>.ant-menu-submenu {
  border-bottom: 10px solid transparent;
  transition: color 0
}

.ant-menu-horizontal>.ant-menu-item-selected {
  border-bottom: 2px solid #1890ff;
}

/deep/.ant-card-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    span {
      &:first-child {
        font-size: 15px;
        font-weight: 700;
      }

      // &:last-child {}
    }
  }

  .right {
    button {
      height: 24px;
    }
  }
}

.Accountview {
  width: 100%;

  .userinfo {
    position: relative;
    width: 100%;
    min-height: 200px;

    .userinfo-title {
      font-size: 16px;
      font-weight: bold;
      color: #262626;
      margin: 0 0 20px 20px;
    }

    .balance-box {
      display: flex;
      align-items: center;
      margin-top: 15px;
      margin-bottom: 15px;

      .box-left {
        margin-right: 35px;
        font-weight: bold;
        color: #262626;
        font-size: 16px;
      }
    }

    .content {
      width: 100%;
      padding: 0 24px;

      .mian {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
        width: 100%;
        padding-bottom: 10px;
        min-height: 138px;
        box-shadow: inset 0 0 1px 1px #f2f2f2;

        .toptext {


          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          font-size: 20px;

          .item {
            width: 188px;
            margin-bottom: 25px;

            span {
              &:first-child {
                margin-right: 10px;
              }
            }


            &:nth-child(2) {
              width: 300px;
            }

            &:nth-child(4) {
              width: 200px;
            }
          }
        }

        .Currentbalance {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 600;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-bottom: 30px;
          padding-right: 60px;

          >div {
            display: flex;
          }

          .Current {
            // width: 300px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              font-size: 18px;
              font-weight: 700;
              color: #333333;
              margin-right: 10px;

              &:last-child {
                // margin-left: 5px;
                // margin-top: 5px;
                font-weight: 600;
              }
            }
          }

          .usable {
            margin-left: 210px;
            width: 140px;
            display: flex;
            justify-content: space-between;

            span {
              font-size: 18px;
              font-weight: 700;

              &:last-child {
                font-weight: 600;
                margin-top: 5px;
              }
            }
          }

          .Customerdetails {


            button {
              height: 24px;
            }
          }
        }

        .Yesterdaybalance {
          span {
            font-size: 13px;
            color: #aaaaaa;
          }
        }
      }
    }

    .Breadcrab {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 35px;
      display: flex;
      padding-left: 24px;
      align-items: center;
      border-bottom: 1px solid #d7d7d7;
      margin-bottom: 10px;
      padding-bottom: 10px;
      justify-content: space-between;
      padding-right: 25px;

      .left {
        span {
          &:first-child {
            font-size: 15px;
            font-weight: 700;
          }

          // &:last-child {}
        }
      }
    }
  }

  .tablebox {
    padding: 0 24px;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .searchbox {
      min-height: 44px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding-top: 15px;


      .lefttime {
        .ant-calendar-picker {
          margin-left: 20px;
          margin-right: 20px;
          width: 200px;
          border-radius: 6px;
          height: 24px !important;
        }
      }

      .right {
        margin-left: 100px;

        button {
          height: 32px;
        }
      }
    }

    .uploadbox {
      display: flex;
      justify-content: flex-end;

      button {
        height: 28px;
      }

      margin-bottom: 20px;
    }
  }

  .paginationBox {
    display: flex;
    justify-content: flex-end;

    // .ant-pagination-options {
    //   float: left;
    // }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: 1px solid #d9d9d9;
      width: 60px;
    }
  }

  // 开户弹框
}

.Openanaccount {
  padding: 30px;

  .personage {
    width: 100%;
    height: 40px !important;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 30px;
  }

  .firm {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 20px;
  }
}

.ant-modal-footer {
  border: none;
  padding-bottom: 20px;
}

.ant-modal-content {
  width: 420px;

  .Payover {
    /deep/.ant-input {
      width: 250px !important;;
      //position: relative;
      //width: 110px;
      //padding-right: 0px !important;
    }

    ///deep/.ant-input-suffix {
    //  position: absolute;
    //  width: 40px;
    //  right: 95px;
    //  top: 0;
    //  margin-top: 16px;
    //  margin-left: 20px;
    //  height: 32px;
    //  background-color: #f2f2f2;
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
    //}
  }
}

.SecuritydepositRelease,
.Confirmedwithdrawal {
  padding-bottom: 20px;

  .userinfo {
    padding: 20px;
    background-color: #f5f8ff;
    min-height: 130px;


    div {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .money {
      display: flex;
    }

    .blane {
      margin-bottom: 0px;
    }

    margin-bottom: 30px;
  }

  .Payoverbox {
    display: flex;
    align-items: center;
    padding-right: 30px;
    width: 100%;
    padding-left: 25px;
    margin-bottom: 20px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }
  }

  .codebox {
    display: flex;

    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }
  }
}

.Confirmedwithdrawal {
  .Bankcard {
    display: flex;
    display: flex;
    padding-right: 0px;

    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;
    margin-bottom: 20px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }
  }

  .Bankaccountopening {
    display: flex;
    display: flex;
    padding-right: 0px;
    // justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;
    margin-bottom: 20px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }
  }
}

.ant-menu-vertical > .ant-menu-item,
/deep/ .ant-menu-vertical > .ant-menu-submenu {
  height: 150px;
}



</style>
