<template>
  <div id="log">
    <a-card title="日志管理" style="margin:20px">
      <!-- 搜索框 -->
      <div class="form">
        <view-select-form @selectHandle="selectHandle"></view-select-form>
      </div>
      <!-- 操作栏 -->
      <div class="action">
        <a-button v-if="$authority('log_export')" @click="downHandle">导出</a-button>
      </div>
      <!-- 表格 -->
      <div class="table">
        <a-table :columns="columns" :data-source="tableData" :pagination="false" rowKey="id">
          <template slot="number_index" slot-scope="text,record, index">
            <span>
              {{ (index + 1) + (currentPage - 1) * pageSize }}
            </span>
          </template>
        </a-table>
      </div>
      <!-- page -->
      <div class="page">
        <a-pagination :current="currentPage" :total="total" show-size-changer show-quick-jumper style="float:right"
          @change="pageChange" @showSizeChange="sizePageChange" :show-total="total => `共 ${total} 条`" />
      </div>
    </a-card>
  </div>
</template>
<script>
import SelectForm from './SelectForm';
const columns = [
  {
    title: '序号',
    dataIndex: 'number_index',
    align: 'center',
    scopedSlots: { customRender: 'number_index' },
  },
  {
    title: '操作人',
    dataIndex: 'userName',
    align: 'center',
  },
  {
    title: '操作内容',
    dataIndex: 'note',
    align: 'center',
  },
  {
    title: '操作时间',
    dataIndex: 'createdTime',
    align: 'center',
  }
];
export default {
  name: 'logPage',
  components: {
    "view-select-form": SelectForm,
  },
  data() {
    return {
      data: [],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      columns,
      selectData: {}
    }
  },
  created() {
    this.tableRequestHandle()
  },
  methods: {
    //页码操作
    pageChange(page) {
      this.currentPage = page
      this.tableRequestHandle()
    },
    sizePageChange(current, size) {
      this.pageSize = size
      this.tableRequestHandle()
    },
    //搜索操作
    selectHandle(val) {
      const obj = {}
      let date = val.dateTime
      obj.startDate = (date && date.length !== 0) ? date[0].format('YYYY-MM-DD') : undefined
      obj.endDate = (date && date.length !== 0) ? date[1].format('YYYY-MM-DD') : undefined
      obj.userName = val.userName
      this.selectData = obj
      this.tableRequestHandle()
    },
    //数据导出
    downHandle() {
      const selectData = this.selectData
      this.$axios.apiDown('/user/logInfo/OperationLog/export', { ...selectData }).then(res => {
        const file = document.createElement('a');
        const time = this.$moment().format('YYYY-MM-DD hh:mm:ss')
        file.href = window.URL.createObjectURL(res);
        file.style.display = 'none';
        file.download = `${time}_日志.xls`;
        file.click();
        document.body.removeChild(file); //下载完成移除元素
      })
    },
    //table数据
    tableRequestHandle() {
      const size = this.pageSize
      const page = this.currentPage
      const selectData = this.selectData
      this.$axios.apiGet('/user/logInfo/OperationLog/list', { size, page, ...selectData }).then(res => {
        if (res.code !== 200) return this.$message.error(res.info)
        const data = res.data.listLog
        this.total = data.totalElements
        this.tableData = data.content
      })
    }
  }
}
</script>
<style lang="less" scoped>
#log {
  .page {
    margin: 12px 0;
  }

  .table {
    margin-top: 24px;
  }

  .form {
    margin-bottom: 16px;
  }
}
</style>