<template>
  <div>
    <a-button @click="sendCode" :disabled="(counting || sending || disabled)" :loading="sending" type="primary" >{{ content }}</a-button>
  </div>
</template>
<script>
export default {
  props: {
    sendCall: { // 调用发送，一般是后台api
      type: Function,
    },
    sendText: {
      type: String,
      default: "发送"
    },
    sendingText: {
      type: String,
      default: "发送中"
    },
    waitingText: {
      type: String,
      default: "秒"
    },
    waitTime: {
      type: Number,
      default: 60,
    },
    errorWaitTime: {
      type: Number,
      default: 10,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    content() {
      if (this.sending) {
        return "发送中"
      }
      if (this.timer > 0) {
        return this.timer + this.waitingText
      }
      return this.sendText
    },
    counting() {
      return this.timer > 0
    }
  },
  data() {
    return {
      timer: 0,
      sending: false,//发送中
    }
  },
  methods: {
    async sendCode() {
      this.timer = this.waitTime; // 计时时长
      this.sending = true; // 发送状态

      if (this.sendCall) {// 有发送函数，调用
        try {
          let res = await this.sendCall()
          this.sending = false
          if (res === false) {// 调用发送函数失败
            this.timer = this.errorWaitTime;
          }
        } catch (e) {
          this.timer = this.errorWaitTime;
        }
      }
      this.sending = false
      this.countDown(); // 开始计时
    },
    countDown() {
      this.timer--
      // 计时，不用interval避免非正常刷新导致的重复计算数
      if (this.timer > 0) {
        window.setTimeout(this.countDown, 1000)
      }
    }
  }
}

</script>
<style scoped lang="less">
.ant-btn {
  min-width: 70px;
  margin: 0;
  right: -12px;
}
</style>