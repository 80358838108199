<template>
  <div>
    <a-modal :title="modalTitle" :visible="modalBool" @ok="handleOk" @cancel="handleCancel">
      <a-form :form="form" :label-col="{ span: 6, offset: 1 }" :wrapperCol="{ span: 16, offset: 1 }" :colon="false"
        labelAlign="left">
        <a-form-item label="用户名称">
          <a-input v-decorator="[
            'username',
            {
              rules: [{ required: true, message: '请输入用户名称' }],
              initialValue: selectedRows[0].username,
            },
          ]" placeholder="请输入用户名称" />
        </a-form-item>
        <a-form-item label="手机号码">
          <a-input v-decorator="[
            'mobile',
            {
              rules: [
                { required: true, message: '请输入手机号码' },
                { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '手机格式不正确' }
              ],
              initialValue: selectedRows[0].mobile,
              validateTrigger: 'blur',
            },
          ]" placeholder="请输入手机号码" />
        </a-form-item>
        <a-form-item label="登录验证码">
          <a-radio-group v-decorator="[
            'ischeck',
            {
              rules: [{ required: true, message: '请选择登录是否校验手机验证码' }],
              initialValue: selectedRows[0].ischeck || true,
            },
          ]">
            <a-radio-button :value="true">校验</a-radio-button>
            <a-radio-button :value="false">不校验</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="所属机构">
          <a-tree-select v-decorator="[
            'departmentId',
            {
              rules: [{ required: true, message: '请选择所属机构' }],
              initialValue: selectedRows[0].departmentId,
            },
          ]" :tree-data="treeData" placeholder="请选择所属机构" tree-default-expand-all>
          </a-tree-select>
        </a-form-item>
        <a-form-item label="用户角色">
          <a-select v-decorator="[
            'roleId',
            {
              rules: [{ required: true, message: '请选择用户角色' }],
              initialValue: selectedRows[0].roleId,
            },
          ]" placeholder="请选择用户角色">
            <a-select-option v-for="item in roleData" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="用户类型">
          <a-select placeholder="请选择用户类型" v-decorator="[
            'type',
            {
              rules: [{ required: true, message: '请选择用户类型', type: 'boolean' }],
              initialValue: selectedRows[0].type || false,
            },
          ]" @change="codeChange($event)" disabled>
            <a-select-option :value="false">管理台账户</a-select-option>
            <a-select-option :value="true">引擎接口账户</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="codeBool" label="引擎码">
          <a-input v-decorator="[
            'apiCode',
            {
              rules: [
                { required: true, message: '请填写引擎码' },
                { pattern: /^[0-9]\d*$/, message: '请填写数字' }
              ],
              initialValue: selectedRows[0].apiCode,
            },
          ]" placeholder="请输入引擎码" />
        </a-form-item>
        <a-form-item label="真实姓名">
          <a-input v-decorator="[
            'name',
            {
              initialValue: selectedRows[0].name,
            },
          ]" placeholder="请输入真实姓名" />
        </a-form-item>
        <a-form-item label="用户邮箱">
          <a-input v-decorator="[
            'email',
            {
              initialValue: selectedRows[0].email,
            },
          ]" placeholder="请输入用户邮箱" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    modalBool: Boolean,
    modalTitle: String,
    selectedRows: Array,
    treeData: Array,
    roleData: Array
  },
  data() {
    return {
      codeBool: false
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'modal_form' });
  },
  created() {
    const bool = this.selectedRows[0].type
    if (bool) {
      this.codeBool = bool
    }
  },
  methods: {
    handleOk() {
      this.$emit("okModalHandle", this.selectedRows[0].id)
    },
    handleCancel() {
      this.$emit("cancelModalHandle")
    },
    codeChange(val) {
      const id = this.selectedRows[0].id
      this.codeBool = val
      if (id && !val) {
        this.form.resetFields(['apiCode'])
      }
    }
  },
};
</script>

<style lang="less" scoped></style>